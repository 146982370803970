import type { Log } from '@blakeelearning/log'
import { service } from '@ember/service'
import { isPresent } from '@ember/utils'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import type { SelectOption } from 'district-ui-client/components/form-components/form-select/option'
import type CleverTeacher from 'district-ui-client/models/clever/clever-teacher'
import type CleverService from 'district-ui-client/services/clever'
import type FlashQueueService from 'district-ui-client/services/flash-queue'
import { t, type IntlService } from 'ember-intl'
import { Tooltip } from 'district-ui-client/components/tooltip'
import { UiButton } from 'district-ui-client/components/ui-button'
import { UiButtonState } from 'district-ui-client/components/ui-button/state'
import { not } from 'ember-truth-helpers'
import { fn } from '@ember/helper'
import { on } from '@ember/modifier'
import { TeacherMatchErrorComponent } from 'district-ui-client/components/clever-ui/teacher-match-error/component'
import { FormComponentsFormSelect } from 'district-ui-client/components/form-components/form-select'

const optionTemplate = '${text}: ${email}'

export interface BlakeTeacherOption extends SelectOption {
  value: string
}

interface Signature {
  Args: {
    disableMatchButtons?: boolean
    data: CleverTeacher
    matchAction: (cleverRecord: CleverTeacher, createOrId: Nullable<string>) => Promise<void>
    resetAction: (cleverRecord: CleverTeacher) => Promise<void>
    blakeUserOptions: BlakeTeacherOption[]
    highlightClass: string
    onSelect?: () => void
    isSelected: boolean
    refreshAction?: () => void
  }
  Element: HTMLTableRowElement
}

export default class MatchTeachersTableRowComponent extends Component<Signature> {
  @service clever!: CleverService

  @service intl!: IntlService

  @service flashQueue!: FlashQueueService

  @service log!: Log

  get allOptions() {
    return [...this.actionOptions, ...this.args.blakeUserOptions]
  }

  get actionOptions() {
    const cleverTeacherEmail = this.cleverRecord.email

    // check the if the clever users email exists in the blake user list
    // if true we dont want to create another user
    const { blakeUserOptions } = this.args
    const emailExits = blakeUserOptions.filter(({ email }) => email === cleverTeacherEmail) || []
    if (isPresent(emailExits)) return []

    const { intl } = this
    const createNewTeacherText = intl.t('clever.createNewTeacherText')
    const createTeacherOption = { text: createNewTeacherText, value: 'create' }
    return [createTeacherOption]
  }

  @tracked highlight = false

  get cleverRecord() {
    return this.args.data
  }

  get isResetButtonDisabled() {
    return this.args.disableMatchButtons || this.isMatchInProgress
  }

  get isSubmitButtonDisabled() {
    return this.args.disableMatchButtons || !this.selectedOption || this.isMatchInProgress
  }

  get buttonText() {
    if (this.isCreateOptionSelected) {
      return this.intl.t('clever.createWord')
    } else {
      return this.intl.t('clever.matchWord')
    }
  }

  // The option selected in the match dropdown
  @tracked _selectedOption: Nullable<SelectOption> = null

  get selectedOption() {
    const { allOptions, _selectedOption } = this

    /* A previously selected option may no longer be a valid option anymore, if it was matched in a dropdown on a
     * different row. So the find() call is to check that the option selected is within the available options.
     */
    return allOptions.find((option) => option.value === _selectedOption?.value)
  }

  set selectedOption(value) {
    this._selectedOption = value ?? null
  }

  get isCreateOptionSelected() {
    return this.selectedOption?.value === 'create'
  }

  get selectedBlakeUserId() {
    if (this.isCreateOptionSelected) return null
    return this.selectedOption?.value ?? null
  }

  optionClick = (option: BlakeTeacherOption) => {
    this.selectedOption = option
  }

  @tracked isMatchInProgress = false

  match = async () => {
    this.isMatchInProgress = true
    await this.args.matchAction(this.cleverRecord, this.isCreateOptionSelected ? 'create' : this.selectedBlakeUserId)
    this.isMatchInProgress = false
  }

  reset = async () => {
    this.isMatchInProgress = true
    await this.args.resetAction(this.cleverRecord)
    this.isMatchInProgress = false
  }

  /**
   * set the background colour of the row when we open the dropdown to keep the attention
   * on the selected row
   * @returns void
   */

  dropdownOpen = () => {
    this.highlight = true
  }

  /**
   * remove the background colour of the row when we open the dropdown
   * @returns void
   */

  dropdownClose = () => {
    this.highlight = false
  }

  <template>
    <tr data-test-teachers-table-row class={{if this.highlight @highlightClass}} ...attributes>
      {{#if @onSelect}}
        <td class="td-checkbox text-center">
          <input
            data-test-match-record-checkbox
            type="checkbox"
            checked={{@isSelected}}
            aria-label={{if @isSelected (t "clever.deselectTeacher") (t "selectTeacher")}}
            {{on "click" (fn @onSelect (not @isSelected))}}
          />
        </td>
      {{/if}}
      <td data-test-teacher-cell-sisid={{@data.sisId}} class="td-sisid">
        <div class="truncate">
          {{@data.sisId}}
          <Tooltip @text={{@data.sisId}} />
        </div>
      </td>
      <td>
        <div>
          {{@data.firstName}}
        </div>
      </td>
      <td>
        <div>
          {{@data.lastName}}
        </div>
      </td>
      <td class="text-center">
        <div>
          {{@data.email}}
        </div>
      </td>
      {{#if @data.matchErrorCode}}
        <TeacherMatchErrorComponent @teacher={{@data}} @refreshAction={{@refreshAction}} as |TeacherMatchError|>
          <td class="td-matching whitespace-nowrap">
            <TeacherMatchError.summary />
          </td>
          <td class="text-center">
            <TeacherMatchError.moreInfo @disabled={{@disableMatchButtons}} />
          </td>
        </TeacherMatchErrorComponent>
      {{else}}
        <td class="td-matching">
          <div>
            {{#if @data.matched}}
              {{#if @data.blakeTeacher}}
                <span>
                  {{@data.blakeTeacher.fullName}}
                  :
                  <span>{{@data.blakeTeacher.email}}</span>
                </span>
              {{else}}
                <span></span>
              {{/if}}
            {{else}}
              <FormComponentsFormSelect
                @defaultText={{t "selectTeacher"}}
                @search={{true}}
                @options={{@blakeUserOptions}}
                @staticBeforeOptions={{this.actionOptions}}
                @searchPlaceholder={{t "clever.searchTeachersPlaceholder"}}
                @value={{this.selectedOption.value}}
                @optionClick={{this.optionClick}}
                @openAction={{this.dropdownOpen}}
                @closeAction={{this.dropdownClose}}
                @optionTemplate={{optionTemplate}}
                @selectedTemplate={{optionTemplate}}
              />
            {{/if}}
          </div>
        </td>
        <td class="text-center">
          {{#if @data.matched}}
            <UiButton
              data-test-reset-match-teacher
              class="{{if this.isResetButtonDisabled 'muted' 'regular'}}"
              disabled={{this.isResetButtonDisabled}}
              {{on "click" this.reset}}
            >
              <UiButtonState @isLoading={{this.isMatchInProgress}}>
                {{t "clever.resetMatchText"}}
              </UiButtonState>
            </UiButton>

          {{else}}
            <UiButton
              data-test-submit-match-teacher
              class="{{if this.isSubmitButtonDisabled 'muted' 'regular'}}"
              disabled={{this.isSubmitButtonDisabled}}
              {{on "click" this.match}}
            >
              <UiButtonState @isLoading={{this.isMatchInProgress}}>
                {{this.buttonText}}
              </UiButtonState>
            </UiButton>
          {{/if}}
        </td>
      {{/if}}
    </tr>
  </template>
}
