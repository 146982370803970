import type { TOC } from '@ember/component/template-only'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import { t } from 'ember-intl'
import { on } from '@ember/modifier'
import { BaseModal } from 'district-ui-client/components/base-modal'
import { ThemedButton } from 'district-ui-client/components/themed-button'

interface Signature {
  Element: HTMLDivElement
  Args: {
    show?: boolean
    close: () => void
    containerClass?: string // CSS class name(s) to append to modal root div.
  }
  Blocks: {
    default: []
  }
}

export const ThemedModal: TOC<Signature> = <template>
  {{#if @show}}
    <BaseModal @close={{@close}} @containerClass={{@containerClass}}>
      <div ...attributes data-test-modal>
        {{yield}}
      </div>
    </BaseModal>
  {{/if}}
</template>

interface ModalHeaderSignature {
  Element: HTMLDivElement
  Blocks: {
    default: []
  }
}

export const ModalHeader: TOC<ModalHeaderSignature> = <template>
  <div class="flex items-center bg-white px-6 pb-4 pt-6" ...attributes data-test-modal-header>
    {{yield}}
  </div>
</template>

interface ModalHeadingSignature {
  Element: HTMLHeadingElement
  Blocks: {
    default: []
  }
}

export const ModalHeading: TOC<ModalHeadingSignature> = <template>
  <h3 class="m-0 flex-grow text-xl font-medium leading-6 text-neutral-400" ...attributes data-test-modal-heading>
    {{yield}}
  </h3>
</template>

interface ModalBodySignature {
  Element: HTMLDivElement
  Blocks: {
    default: []
  }
}

export const ModalBody: TOC<ModalBodySignature> = <template>
  <div class="px-6 py-2" ...attributes data-test-modal-body>
    {{yield}}
  </div>
</template>

interface ModalFooterSignature {
  Element: HTMLDivElement
  Blocks: {
    default: []
  }
}

export const ModalFooter: TOC<ModalFooterSignature> = <template>
  <div class="flex justify-end space-x-2 px-6 pb-6 pt-4" ...attributes data-test-modal-footer>
    {{yield}}
  </div>
</template>

interface CloseButtonSignature {
  Args: {
    close: () => void
  }
}

export const CloseButton: TOC<CloseButtonSignature> = <template>
  <ThemedButton
    @style="icon-only"
    type="button"
    class="text-base leading-4"
    aria-label={{t "close"}}
    {{on "click" @close}}
    data-test-modal-close
  >
    <FaIcon class="h-4 w-4" @icon="xmark" />
  </ThemedButton>
</template>
