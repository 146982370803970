import Service, { service } from '@ember/service'
import { tracked } from '@glimmer/tracking'
import { Precinct } from 'district-ui-client/domain/precinct'
import config from 'district-ui-client/config/environment'
import { join, joinQueryParams } from 'district-ui-client/utils/uri'
import type FeaturesService from 'district-ui-client/services/features'
import type { Log } from '@blakeelearning/log'

export interface LessonInfoCache extends Record<Precinct, { lessons: object[] }> {
  [Precinct.RE_READING]: {
    lessons: {
      id: string
      lesson_number: string
      lesson_position: number
      map: number
      name: string
      grade_lesson_number: number
      grade_position: number
      grade: number
    }[]
  }
  [Precinct.RE_SPELLING]: {
    lessons: {
      id: string
      lesson_number: string
      lesson_position: number
      map: number
      name: string
      grade_lesson_number: number
      grade_position: number
      grade: number
    }[]
  }
  [Precinct.RE_STORYLANDS]: {
    lessons: {
      id: string
      lesson_number: string
      lesson_position: number
      map: number
      name: string
      grade_lesson_number: number
      grade_position: number
      grade: number
    }[]
  }
  [Precinct.RE_DRIVING_TESTS]: {
    lessons: {
      id: string
      lesson_number: string
      lesson_position: number
      name: string
      grade: number
      grade_lesson_number: number
      grade_position: number
      category: 'content_words' | 'letters_and_sounds' | 'sight_words'
    }[]
  }
  [Precinct.REX_READING]: {
    lessons: {
      id: string
      lesson_number: string
      lesson_position: number
      map: number
      name: string
      grade_lesson_number: number
      grade_position: number
      grade: number
    }[]
  }
  [Precinct.REX_SPELLING]: {
    lessons: {
      id: string
      lesson_number: string
      lesson_position: number
      map: number
      name: string
      grade_lesson_number: number
      grade_position: number
      grade: number
    }[]
  }
  [Precinct.FP_PEAKS]: {
    lessons: {
      id: number
      set: number
      phase: number
      peak: number
      lesson_number: number
      name: string
      words: string[]
      books: string[]
      sounds: string[]
    }[]
  }
  [Precinct.MS_LESSONS]: {
    lessons: {
      id: string
      lesson_number: string
      lesson_position: number
      map: number
      name: string
      grade_lesson_number: number
      grade_position: number
      grade: number
    }[]
  }
  [Precinct.MS_MENTAL_MINUTE]: {
    lessons: {
      id: string
      lesson_number: number
      lesson_position: number
      name: string
      grade: number
      category: 'Addition' | 'Subtraction' | 'Multiplication' | 'Division'
    }[]
  }
  [Precinct.MS_DRIVING_TESTS]: {
    lessons: {
      id: string
      lesson_number: string
      lesson_position: number
      name: string
      grade: number
      grade_lesson_number: number
      grade_position: number
      category: 'data' | 'geometry' | 'measurement' | 'number' | 'operations' | 'patterns'
    }[]
  }
  [Precinct.WL_WRITING]: {
    lessons: {
      lesson: number
      lesson_number: number
      map_number: number
    }[]
  }
}

export type LessonInfoResponse<P extends Precinct> = LessonInfoCache[P]

export type Lessons<P extends Precinct> = LessonInfoCache[P]['lessons']
export type Lesson<P extends Precinct> = LessonInfoCache[P]['lessons'][number]

/**
 * Given a precinct, this returns the lesson info keys - these are used by the lesson_info request, and also as
 * translation keys when loading the lesson info into translations.
 */
function lessonInfoKeysForPrecinct(precinct: Precinct) {
  switch (precinct) {
    case Precinct.RE_READING:
      return { productKey: 're', precinctKey: 'lessons' }
    case Precinct.RE_SPELLING:
      return { productKey: 're', precinctKey: 'spelling' }
    case Precinct.RE_STORYLANDS:
      return { productKey: 're', precinctKey: 'storylands' }
    case Precinct.RE_DRIVING_TESTS:
      return { productKey: 're', precinctKey: 'driving_tests' }
    case Precinct.REX_READING:
      return { productKey: 'rex', precinctKey: 'my_lessons' }
    case Precinct.REX_SPELLING:
      return { productKey: 'rex', precinctKey: 'english_skills_spelling' }
    case Precinct.FP_PEAKS:
      return { productKey: 'fast_phonics', precinctKey: 'lessons' }
    case Precinct.MS_LESSONS:
      return { productKey: 'maths', precinctKey: 'lessons' }
    case Precinct.MS_MENTAL_MINUTE:
      return { productKey: 'maths', precinctKey: 'mental_minute' }
    case Precinct.MS_DRIVING_TESTS:
      return { productKey: 'maths', precinctKey: 'driving_tests' }
    case Precinct.WL_WRITING:
      return { productKey: 'writing_legends', precinctKey: 'lessons' }
    default:
      return {}
  }
}

function urlForLessonInfo(precinct: Precinct): Nullable<URL> {
  const { productKey } = lessonInfoKeysForPrecinct(precinct)
  if (!productKey) return null

  return join(config.lessonsApiV1Url, productKey, 'lesson_info')
}

/**
 * Returns the query params to use for the lesson_info request for a precinct. Can be used to form this part of the url
 * ?product=re&precinct=reading
 */
function requestParamsForPrecinct(precinct: Precinct) {
  const { productKey, precinctKey } = lessonInfoKeysForPrecinct(precinct)
  return productKey && precinctKey ? { product: productKey, precinct: precinctKey } : {}
}

export default class LessonInfoService extends Service {
  @service log!: Log

  @service features!: FeaturesService

  /**
   * holds lesson info responses for each precinct, when they are loaded
   */
  @tracked cache: Partial<LessonInfoCache> = {}

  /**
   * A convenience method to call loadLessonInfo() for multiple precincts
   * @see loadLessonInfo
   */
  loadLessonInfoFor(precincts: Precinct[]) {
    return Promise.all(precincts.map((precinct) => this.loadLessonInfo(precinct)))
  }

  /**
   * Loads lesson info for given precinct if not already loaded
   */
  async loadLessonInfo<P extends Precinct>(precinct: P) {
    if (this.cache[precinct]) return

    const url = urlForLessonInfo(precinct)
    if (!url) return

    const urlWithQPs = joinQueryParams(url, {
      ...requestParamsForPrecinct(precinct),
    })

    const response: LessonInfoResponse<P> | undefined = await this.fetchLessonInfo<P>(urlWithQPs.toString())
    if (response) this.cache = { ...this.cache, [precinct]: response }
  }

  async fetchLessonInfo<P extends Precinct>(url: string) {
    try {
      const response = await fetch(url)
      if (response.ok) {
        const result: LessonInfoResponse<P> | undefined = await response.json()
        if (result) return result

        this.log.error(`Failed to fetch lesson info; Reason: (empty data)`, url)
      } else {
        this.log.error(`Failed to fetch lesson info; Reason: (${response.status})`, url)
      }
    } catch (e: any) {
      if (e instanceof DOMException && e.name === 'AbortError') {
        // Fetch aborted by user action, eg closed tab, stop button, network outage. Do nothing.
      } else {
        this.log.error(`Failed to fetch lesson info; Reason: (error thrown)`, e, url)
      }
    }
  }

  /**
   * Return the lessons for a precinct, if it has been loaded
   */
  lessonsFor<P extends Precinct>(precinct: P): Lessons<P> {
    let lessons = this.cache[precinct]?.lessons ?? []
    if (!this.features.isEnabled('duiReMap13FF') && precinct === Precinct.RE_READING) {
      lessons = this.filterByMapLessThan(lessons, 13)
    }
    return lessons
  }

  filterByMapLessThan(lessons: object[], mapNumber: number) {
    return lessons.filter((lesson) => 'map' in lesson && typeof lesson.map === 'number' && lesson.map < mapNumber)
  }
}

declare module '@ember/service' {
  interface Registry {
    'lesson-info': LessonInfoService
  }
}
