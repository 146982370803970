import type { TOC } from '@ember/component/template-only'
import { ReportingDistrictHeader } from 'district-ui-client/components/reporting/district-header'
import { ReportingNoDataMessageIf } from 'district-ui-client/components/reporting/no-data-message-if'
import { or, not } from 'ember-truth-helpers'
import DetailedControls from 'district-ui-client/components/reporting/detailed-controls'

interface Signature {
  Args: {
    viewMode?: 'chart' | 'table'
    empty?: boolean
  }
  Blocks: {
    default: []
  }
}
export const DetailedPageWrapper: TOC<Signature> = <template>
  <ReportingDistrictHeader />

  <DetailedControls class="my-3" @viewMode={{or @viewMode "chart"}} @isDataPresent={{not @empty}} />

  <ReportingNoDataMessageIf @on={{Boolean @empty}}>
    {{yield}}
  </ReportingNoDataMessageIf>
</template>

export default DetailedPageWrapper
