import Component from '@glimmer/component'
import { isPresent } from '@ember/utils'
import { themeKeys as statBoxThemes } from 'district-ui-client/components/stat-box'
import { ReportingDistrictHeader } from 'district-ui-client/components/reporting/district-header'
import { DashboardControls } from 'district-ui-client/components/reporting/dashboard-controls'
import { DynamicDashboardChartWrapper } from 'district-ui-client/components/reporting/dashboard-chart-wrapper/dynamic'
import { StaticDashboardChartWrapper } from 'district-ui-client/components/reporting/dashboard-chart-wrapper/static'
import { AwardsEarnedTrophies } from 'district-ui-client/components/charts/dashboard/awards-earned/trophies'
import { GiraffeChartsTotalUsageOverTime } from 'district-ui-client/components/charts/dashboard/total-usage-over-time/component'
import { ChartsDashboardCourseProgress } from 'district-ui-client/components/charts/dashboard/course-progress/component'
import { LexileGrowthDashboardChart } from 'district-ui-client/components/charts/dashboard/lexile-growth/component'
import { DashboardAssessmentScoresChart } from 'district-ui-client/components/charts/dashboard/assessment-scores/component'
import { WorkingAtGradeLevelDashboardChart } from 'district-ui-client/components/charts/dashboard/working-at-grade-level/component'

import { BooksReadByGenreDashboardChart } from 'district-ui-client/components/charts/dashboard/books-read/by-genre/component'

import { BooksReadByLevelDashboardChart } from 'district-ui-client/components/charts/dashboard/books-read/by-level/component'
import { StadiumEventsDashboardChart } from 'district-ui-client/components/charts/dashboard/stadium-events/component'
import { t } from 'ember-intl'
import { not } from 'ember-truth-helpers'
import RouteTemplate from 'ember-route-template'
import type RouterService from '@ember/routing/router-service'
import { service } from '@ember/service'
import type ReportingRexDashboardRoute from 'district-ui-client/reporting/ui-scope/rex/dashboard/index/route'

interface Args {
  model: ModelFor<ReportingRexDashboardRoute>
}

interface Signature {
  Args: Args
}

export class ReportingRexDashboardRouteTemplate extends Component<Signature> {
  @service router!: RouterService

  goToCategory = (category: string) => {
    const path = {
      lessons: 'course-progress.totals',
      quizzes: 'quiz-scores',
      assessments: 'assessment-scores',
      books: 'books-read',
      spelling: 'spelling-progress.totals',
    }[category]

    const { scope: scopeName, id: scopeId } = this.args.model.scope
    this.router.transitionTo(`reporting.ui-scope.rex.${path}`, scopeName, scopeId)
  }

  get totalUsageBoxes() {
    return [
      {
        category: 'lessons',
        theme: statBoxThemes.THEME_OCEANY_BLUE,
      },
      {
        category: 'quizzes',
        theme: statBoxThemes.THEME_STORM_BLUE,
      },
      {
        category: 'assessments',
        theme: statBoxThemes.THEME_JUICY_ORANGE,
      },
      {
        category: 'books',
        theme: statBoxThemes.THEME_PURPLY_PINK,
      },
      {
        category: 'spelling',
        theme: statBoxThemes.THEME_GRAPEY_GREEN,
      },
    ]
  }

  get isSummaryDataPresent() {
    return isPresent(this.args.model.totalUsageOverTime)
  }

  <template>
    <ReportingDistrictHeader />

    <DashboardControls class="my-3" @viewMode="chart" @isDataPresent={{this.isSummaryDataPresent}} />

    {{! Total Usage Over Time Chart }}
    <DynamicDashboardChartWrapper
      class="mb-6"
      @empty={{not this.isSummaryDataPresent}}
      @title={{t "reporting.chartTitles.dashboard.totalUsageOverTime"}}
      @tooltip={{t "reporting.tooltips.dashboard.totalUsageOverTime"}}
    >
      {{#if @model.totalUsageOverTime}}
        <GiraffeChartsTotalUsageOverTime
          @product={{@model.product}}
          @boxes={{this.totalUsageBoxes}}
          @data={{@model.totalUsageOverTime}}
          @tileClickAction={{this.goToCategory}}
          @uiScope={{@model.scope.scope}}
        />
      {{/if}}
    </DynamicDashboardChartWrapper>

    <div class="grid w-full auto-rows-[26rem] grid-cols-2 gap-x-7 gap-y-6 xl:grid-cols-3 print:grid-cols-3">

      {{! Comprehension Progress Chart }}
      <StaticDashboardChartWrapper
        @title={{t "reporting.chartTitles.dashboard.courseProgressByGrade.rex"}}
        @tooltip={{t "reporting.tooltips.dashboard.courseProgressByGrade.rex"}}
        @linkPath="reporting.ui-scope.rex.course-progress.averages"
        @empty={{not @model.courseProgressByGrade}}
      >
        {{#if @model.courseProgressByGrade}}
          <ChartsDashboardCourseProgress
            @data={{@model.courseProgressByGrade}}
            @product={{@model.product}}
            @precinct="my_lessons"
          />
        {{/if}}
      </StaticDashboardChartWrapper>

      {{! Average Lexile Growth Chart }}
      <StaticDashboardChartWrapper
        @title={{t "reporting.chartTitles.dashboard.lexileGrowth"}}
        @tooltip={{t "reporting.tooltips.dashboard.lexileGrowth"}}
        @linkPath="reporting.ui-scope.rex.lexile-details"
        @empty={{not @model.lexileGrowth}}
      >
        {{#if @model.lexileGrowth}}
          <LexileGrowthDashboardChart @data={{@model.lexileGrowth}} />
        {{/if}}
      </StaticDashboardChartWrapper>

      {{! Assessments Completed Chart }}
      <StaticDashboardChartWrapper
        @title={{t "reporting.chartTitles.dashboard.assessmentScores.rex"}}
        @tooltip={{t "reporting.tooltips.dashboard.assessmentScores.rex"}}
        @linkPath="reporting.ui-scope.rex.assessment-scores"
        @empty={{not @model.assessmentScores}}
      >
        {{#if @model.assessmentScores}}
          <DashboardAssessmentScoresChart @data={{@model.assessmentScores}} />
        {{/if}}
      </StaticDashboardChartWrapper>

      {{! Spelling Progress Chart }}
      <StaticDashboardChartWrapper
        @title={{t "reporting.chartTitles.dashboard.spellingCourseProgress"}}
        @tooltip={{t "reporting.tooltips.dashboard.spellingCourseProgress"}}
        @linkPath="reporting.ui-scope.rex.spelling-progress.averages"
        @empty={{not @model.spellingCourseProgress}}
      >
        {{#if @model.spellingCourseProgress}}
          <ChartsDashboardCourseProgress
            @data={{@model.spellingCourseProgress}}
            @product={{@model.product}}
            @precinct="spelling"
          />
        {{/if}}
      </StaticDashboardChartWrapper>

      {{! Students Working at Grade Level Chart }}
      <StaticDashboardChartWrapper
        @title={{t "reporting.chartTitles.dashboard.workingAtGradeLevel"}}
        @tooltip={{t "reporting.tooltips.dashboard.workingAtGradeLevel"}}
        @linkPath="reporting.ui-scope.rex.working-at-grade-level"
        @empty={{not @model.workingAtGradeLevel}}
      >
        {{#if @model.workingAtGradeLevel}}
          <WorkingAtGradeLevelDashboardChart @data={{@model.workingAtGradeLevel}} @product={{@model.product}} />
        {{/if}}
      </StaticDashboardChartWrapper>

      {{! Books ready by genre chart }}
      <StaticDashboardChartWrapper
        @title={{t "reporting.chartTitles.dashboard.booksReadByGenre.rex"}}
        @tooltip={{t "reporting.tooltips.dashboard.booksReadByGenre"}}
        @linkPath="reporting.ui-scope.rex.books-read"
        @empty={{not @model.booksReadByGenre}}
      >
        {{#if @model.booksReadByGenre}}
          <BooksReadByGenreDashboardChart @data={{@model.booksReadByGenre}} @product={{@model.product}} />
        {{/if}}
      </StaticDashboardChartWrapper>

      {{! Books ready by level chart }}
      <StaticDashboardChartWrapper
        @title={{t "reporting.chartTitles.dashboard.booksReadByLevel"}}
        @tooltip={{t "reporting.tooltips.dashboard.booksReadByLevel"}}
        @linkPath="reporting.ui-scope.rex.books-read.by-level"
        @empty={{not @model.booksReadByLevel}}
      >
        {{#if @model.booksReadByLevel}}
          <BooksReadByLevelDashboardChart @data={{@model.booksReadByLevel}} />
        {{/if}}
      </StaticDashboardChartWrapper>

      {{! Stadium Events Chart }}
      <StaticDashboardChartWrapper
        @title={{t "reporting.chartTitles.dashboard.stadiumEvents"}}
        @tooltip={{t "reporting.tooltips.dashboard.stadiumEvents"}}
        @empty={{not @model.stadiumEvents}}
      >
        {{#if @model.stadiumEvents}}
          <StadiumEventsDashboardChart @data={{@model.stadiumEvents}} />
        {{/if}}
      </StaticDashboardChartWrapper>

      {{! Trophies Earned Chart }}
      <StaticDashboardChartWrapper
        @title={{t "reporting.chartTitles.dashboard.awardsEarned.rex"}}
        @tooltip={{t "reporting.tooltips.dashboard.awardsEarned.rex"}}
        @linkPath="reporting.ui-scope.rex.awards-earned"
        @empty={{not @model.awardsEarned}}
      >
        {{#if @model.awardsEarned}}
          <AwardsEarnedTrophies @data={{@model.awardsEarned}} />
        {{/if}}
      </StaticDashboardChartWrapper>
    </div>
  </template>
}

export default RouteTemplate(ReportingRexDashboardRouteTemplate)
