/**
 * Creates a container element in document body if it does not yet exist in the DOM.
 * If #ember-testing exists, will instead create the element there, to help with tests.
 */
export function createContainerElement(containerId: string): HTMLElement {
  let containerElement = document.getElementById(containerId)
  // If the destination element doesn't exist, go ahead and create it.
  if (!containerElement) {
    containerElement = document.createElement('div')
    containerElement.id = containerId

    // If we're running in a test environment, this element id will be present, and we should scope to it
    const testRootElement = document.getElementById('ember-testing')
    if (testRootElement) {
      testRootElement.appendChild(containerElement)
    } else {
      // Prepending (rather than appending) seems to improve positioning when body not overflowing/scrolling
      document.body.prepend(containerElement)
    }
  }
  return containerElement
}
