import Component from '@glimmer/component'
import { service } from '@ember/service'
import { formatValue } from 'district-ui-client/utils/format-value'
import type { IntlService } from 'ember-intl'

export interface SkillsMasteredData {
  skills_mastered: {
    number: {
      average?: number
    }
    fractions: {
      average?: number
    }
    operations: {
      average?: number
    }
    measurement: {
      average?: number
    }
    geometry: {
      average?: number
    }
    data: {
      average?: number
    }
  }
}

interface Signature {
  Args: {
    data?: SkillsMasteredData
  }
}

const categoryThemes = {
  number: 'text-mid-orange-300',
  fractions: 'text-oceany-blue-300',
  operations: 'text-rexy-purple-200',
  measurement: 'text-purply-pink-300',
  geometry: 'text-storm-blue-300',
  data: 'text-juicy-orange-300',
}

export class SkillsMasteredDashboardChart extends Component<Signature> {
  @service intl!: IntlService

  get skillsMasteredData() {
    return this.args.data?.skills_mastered
  }

  get skillsMasteredCategoryData() {
    const skillsMasteredData = this.skillsMasteredData

    const categories = ['number', 'fractions', 'operations', 'measurement', 'geometry', 'data'] as const

    return categories.map((category) => {
      const title = this.intl.t(`reporting.charts.dashboard.skillsMastered.categories.${category}`)

      const categoryData = skillsMasteredData?.[category]

      const value = formatValue(categoryData?.average, { replaceZero: true })

      const theme = categoryThemes[category]

      return { title, category, value, theme }
    })
  }

  <template>
    <div data-test-skills-mastered class="flex h-full flex-wrap items-center">
      {{#each this.skillsMasteredCategoryData as |categoryData|}}
        <div
          class="w-1/2 text-center font-bold print:!font-bold"
          data-test-skills-mastered-stat={{categoryData.category}}
        >
          <div data-test-skills-mastered-value class="{{categoryData.theme}} text-4xl print:text-3xl">
            {{categoryData.value}}
          </div>
          <div class="text-black" data-test-skills-mastered-title>{{categoryData.title}}</div>
          <hr class="border-dusty-black-100 mx-7 my-3 border-b" />
        </div>
      {{/each}}
    </div>
  </template>
}

export default SkillsMasteredDashboardChart
