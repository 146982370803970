import Component from '@glimmer/component'
import type Owner from '@ember/owner'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import { Tile, Panel, PanelHeader, PanelTitle, PanelBody } from 'district-ui-client/components/section'
import { Pill } from 'district-ui-client/components/pills'
import { formatValue } from 'district-ui-client/utils/format-value'
import { get } from '@ember/helper'
import type { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import { formatNumber, t, type IntlService } from 'ember-intl'
import type { ReportPath } from 'district-ui-client/services/report-registry'
import type ReportingService from 'district-ui-client/services/reporting'
import { type BaseReport, type BaseReportMetaStandardsSet } from 'district-ui-client/services/reporting'
import { ThemedLinkTo } from 'district-ui-client/components/themed-button'
import { service } from '@ember/service'
import { TooltipInfo } from 'district-ui-client/components/tooltip'
import { Product } from 'district-ui-client/domain/product'

interface Signature {
  Args: {
    reportData: StandardsPerformanceOverviewReportData
    isReloading?: boolean
    lessonPerformanceReport?: ReportPath
  }
}

export interface StandardsPerformanceOverviewReportData extends BaseReport {
  overview: {
    total_students: number
    standards_practiced: number
    total_lessons_completed: number
    average_lessons_completed_per_student: Nullable<number>
    average_score_across_standards: Nullable<number>
    average_lesson_performance: Nullable<DistributionBands>
  }
  meta: BaseReportMetaStandardsSet
}

type DistributionBands = [
  { band: '0-49'; total_students: number },
  { band: '50-79'; total_students: number },
  { band: '80-100'; total_students: number },
]

type BandKey = '0-49' | '50-79' | '80-100'

interface BandTheme {
  backgroundColor: string
  icon: IconName
  iconPrefix: IconPrefix
  iconColor: string
}

const distributionBandTheme: Record<BandKey, BandTheme> = {
  '0-49': {
    backgroundColor: 'bg-red-50',
    icon: 'star',
    iconPrefix: 'far',
    iconColor: 'text-red-300',
  },
  '50-79': {
    backgroundColor: 'bg-yellow-50',
    icon: 'star-half-stroke',
    iconPrefix: 'far',
    iconColor: 'text-yellow-400',
  },
  '80-100': {
    backgroundColor: 'bg-green-50',
    icon: 'star',
    iconPrefix: 'fas',
    iconColor: 'text-green-300',
  },
}

export class StandardsPerformanceOverview extends Component<Signature> {
  @service intl!: IntlService
  @service reporting!: ReportingService

  constructor(owner: Owner, args: Signature['Args']) {
    super(owner, args)
    this.reporting.makeImpression()
  }

  get isFastPhonics() {
    return this.reporting.product === Product.FP
  }

  get averagePerStudentDescription() {
    if (this.isFastPhonics) {
      return this.intl.t('reporting.standardsPerformance.averageLessonsCompletedPerStudentPeaks')
    }
    return this.intl.t('reporting.standardsPerformance.averageLessonsCompletedPerStudent')
  }

  get averagePerStudentTotal() {
    const count = this.intl.formatNumber(this.args.reportData.overview.total_lessons_completed)
    if (this.isFastPhonics) {
      return this.intl.t('reporting.standardsPerformance.totalLessonsCompletedPeaks', { count })
    }
    return this.intl.t('reporting.standardsPerformance.totalLessonsCompleted', { count })
  }

  get lessonPerformanceOfStudents() {
    const count = this.intl.formatNumber(this.args.reportData.overview.total_students)
    return this.isFastPhonics
      ? this.intl.t('reporting.standardsPerformance.lessonPerformanceOfStudentsPeaks', { count })
      : this.intl.t('reporting.standardsPerformance.lessonPerformanceOfStudents', { count })
  }

  <template>
    <div class="flex flex-wrap {{if @isReloading 'opacity-50'}} gap-4">
      <Tile data-test-standards-performance-overview-tile class="flex-shrink flex-grow basis-0">
        <div class="flex items-baseline">
          <FaIcon @icon="clipboard" class="text-blue-325 mr-3 text-xl" />
          <span class="text-3xl font-semibold text-neutral-400">
            {{formatNumber @reportData.overview.standards_practiced}}
          </span>
        </div>
        <div class="mt-1 text-base">
          {{t "reporting.standardsPerformance.standardsPracticed"}}
        </div>
      </Tile>
      <Tile data-test-standards-performance-overview-tile class="flex-shrink flex-grow basis-0">
        <div class="flex items-baseline">
          <FaIcon @icon="book" class="text-blue-325 mr-3 text-xl" />
          <span class="text-3xl font-semibold text-neutral-400">
            {{#let @reportData.overview.average_lessons_completed_per_student as |averageLessons|}}
              {{if averageLessons (formatNumber averageLessons) (formatValue averageLessons)}}
            {{/let}}
          </span>
          {{#if this.isFastPhonics}}
            <TooltipInfo class="ml-auto self-center" @text={{t "reporting.standardsPerformance.peaksTooltip"}} />
          {{/if}}
        </div>
        <div class="mt-1 text-base">
          {{this.averagePerStudentDescription}}
        </div>
        <Pill class="mt-4">
          {{this.averagePerStudentTotal}}
        </Pill>
      </Tile>
      <Tile data-test-standards-performance-overview-tile class="flex-shrink flex-grow basis-0">
        <div class="flex items-baseline">
          <FaIcon @icon="gauge-simple" class="text-blue-325 mr-3 text-xl" />
          <span class="text-3xl font-semibold text-neutral-400">
            {{formatValue @reportData.overview.average_score_across_standards append="%"}}
          </span>
        </div>
        <div class="mt-1 text-base">
          {{t "reporting.standardsPerformance.averageScoreAcrossStandards"}}
        </div>
      </Tile>
    </div>
    <Panel data-test-standards-performance-overview-panel class={{if @isReloading "opacity-50"}}>
      <PanelHeader>
        <PanelTitle class="flex flex-wrap justify-between">
          {{this.lessonPerformanceOfStudents}}
          {{#if @lessonPerformanceReport}}
            <ThemedLinkTo
              data-test-lesson-performance-link
              class="ml-auto print:hidden"
              @style="link"
              @disabled={{@isReloading}}
              @route={{@lessonPerformanceReport}}
            >
              <span>{{t "reporting.viewStudentReport"}}</span><FaIcon @icon="arrow-right" />
            </ThemedLinkTo>
          {{/if}}
        </PanelTitle>
      </PanelHeader>
      <PanelBody class="flex flex-wrap gap-4">
        {{#each @reportData.overview.average_lesson_performance as |distributionItem|}}
          {{#let (get distributionBandTheme distributionItem.band) as |bandTheme|}}
            <section class="flex-shrink flex-grow basis-0 rounded-lg p-4 {{bandTheme.backgroundColor}}">
              <div class="flex items-baseline">
                <FaIcon
                  @icon={{bandTheme.icon}}
                  @prefix={{bandTheme.iconPrefix}}
                  class="{{bandTheme.iconColor}} mr-3 text-xl"
                />
                <span class="mr-2 text-3xl font-semibold text-neutral-400">
                  {{formatNumber distributionItem.total_students}}
                </span>
                <span class="text-2xl font-semibold text-neutral-400">
                  {{t "reporting.standardsPerformance.students" count=distributionItem.total_students}}
                </span>
              </div>
              <div class="mt-1 text-base">
                {{t "reporting.standardsPerformance.averageScore" score=distributionItem.band}}
              </div>
            </section>
          {{/let}}
        {{/each}}
      </PanelBody>
    </Panel>
  </template>
}
