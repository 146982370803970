import Component from '@glimmer/component'
import { service } from '@ember/service'
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter'
import colors from '@blakeelearning/blake-colours/colours'
import type { IntlService } from 'ember-intl'
import { LeftSplitChart } from 'district-ui-client/components/left-split-chart'
import { SummaryBox, SummaryBoxColorScheme } from 'district-ui-client/components/summary-box'
import { hash } from '@ember/helper'
import { eq } from 'ember-truth-helpers'
import { TooltipInfo } from 'district-ui-client/components/tooltip'
import SeriesBar from 'district-ui-client/components/primitives/series-bar/component'
import ChartLegend from 'district-ui-client/components/chart-legend'
import type { SeriesBarOptions } from 'highcharts'

const colorMap = {
  re: {
    below: colors.oceanyBlue100,
    at: colors.oceanyBlue300,
    above: colors.oceanyBlue400,
  },
  rex: {
    below: colors.oceanyBlue100,
    at: colors.oceanyBlue300,
    above: colors.oceanyBlue400,
  },
  maths: {
    below: colors.msGreen150,
    at: colors.msGreen300,
    above: colors.msGreen400,
  },
}

/**
 * Puts together all the pieces for the working at grade level totals chart.
 */

export interface WorkingAtGradeLevelTotalsData {
  grade_position: number
  student_count: number
  below: number
  at: number
  above: number
}

interface Signature {
  Args: {
    data: WorkingAtGradeLevelTotalsData
    product: 're' | 'rex' | 'maths'
    grade?: string
    tooltip?: string
    title?: string
  }
  Element: HTMLDivElement
}

export class ChartsWorkingAtGradeLevelTotals extends Component<Signature> {
  @service intl!: IntlService

  get colours() {
    return colorMap[this.args.product]
  }

  /**
   * The input data for the chart, formatted for Highcharts.
   * Includes the colours to lock highcharts in to using those specific colours
   */
  get chartData(): SeriesBarOptions[] {
    const { below, at, above } = this.args.data
    const { below: belowColor, at: atColor, above: aboveColor } = this.colours

    return [
      { name: 'Below', data: [{ y: below, color: belowColor }], type: 'bar' },
      { name: 'On', data: [{ y: at, color: atColor }], type: 'bar' },
      { name: 'Above', data: [{ y: above, color: aboveColor }], type: 'bar' },
    ]
  }

  get yAxis() {
    const { below, at, above } = this.args.data
    const max = below + at + above

    return {
      startOnTick: false,
      endOnTick: false,
      gridLineWidth: 0,
      max,
      labels: { enabled: false },
      title: { text: null },
      reversedStacks: false,
    }
  }

  /**
   * Data that will be provided to the legend for labels
   */
  get legendData() {
    const gradeData = this.args.data
    const categories = [
      { key: 'below', label: 'Below' },
      { key: 'at', label: 'On' },
      { key: 'above', label: 'Above' },
    ] as const
    return categories.map(({ label, key }) => {
      const colour = this.colours[key]
      const data = gradeData[key]
      return { label, colour, data }
    })
  }

  /**
   * Number of contributing students for left side of chart
   * converted to string if >= 10000, eg 10k
   */
  get contributingStudents() {
    const studentCount = this.args.data.student_count
    const countToStartRounding = 10000
    if (studentCount >= countToStartRounding) {
      return formatNumber(studentCount, 1)
    }
    return studentCount.toString()
  }

  <template>
    <div data-test-working-at-grade-level-totals class="relative" ...attributes>
      <LeftSplitChart>
        <:left>
          <SummaryBox
            class="h-full"
            @grade={{@grade}}
            @stats={{Array (hash label="Contributing students" value=this.contributingStudents)}}
            @colorScheme={{if (eq @product "maths") SummaryBoxColorScheme.DarkGreen SummaryBoxColorScheme.LightBlue}}
          />
        </:left>
        <:right>
          <div class="flex h-full flex-col">
            <TooltipInfo class="z-tooltip absolute right-3 top-3 text-xs print:hidden" @text={{@tooltip}} />
            <SeriesBar
              class="flex-grow"
              @title={{@title}}
              @data={{this.chartData}}
              @xAxisVisible={{false}}
              @yAxisVisible={{false}}
              @yAxis={{this.yAxis}}
              @groupPadding={{0}}
              @barPadding={{0}}
              @stackingStyle="normal"
              @maxPointWidth={{65}}
              @plotBackgroundColour="transparent"
              @borderWidth={{1}}
            />
            <ChartLegend class="mb-3 flex-shrink-0" @data={{this.legendData}} @labelsBelow={{true}} />
          </div>
        </:right>
      </LeftSplitChart>
    </div>
  </template>
}

export default ChartsWorkingAtGradeLevelTotals
