import Component from '@glimmer/component'
import { service } from '@ember/service'
import { camelize } from '@ember/string'
import { reportingLegend as legend } from 'district-ui-client/utils/giraffe/highcharts-config'
import { basicAverage } from 'district-ui-client/utils/giraffe/average-calculator'
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter'
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format'
import type { IntlService } from 'ember-intl'
import type Features from 'district-ui-client/services/features'
import SeriesColumn from 'district-ui-client/components/primitives/series-column/component'
import { StatBox, type themes } from 'district-ui-client/components/stat-box'
import { on } from '@ember/modifier'
import { fn } from '@ember/helper'
import type { LegendOptions, SeriesColumnOptions } from 'highcharts'
import { isPresent } from '@ember/utils'
import blakeColors from '@blakeelearning/blake-colours/colours'
import type { UIScopeName } from 'district-ui-client/domain/ui-scope'

const colorMap: Record<CamelizedProduct, Record<string, string>> = {
  fastPhonics: {
    lessons: blakeColors.oceanyBlue300,
    quizzes: blakeColors.juicyOrange300,
    words: blakeColors.stormBlue300,
    sounds: blakeColors.purplyPink300,
    books: blakeColors.grapeyGreen300,
  },
  maths: {
    lessons: blakeColors.forestGreen300,
    assessments: blakeColors.juicyOrange300,
    drivingTests: blakeColors.oceanyBlue300,
    quizzes: blakeColors.grapeyGreen300,
    mentalMinute: blakeColors.midOrange300,
  },
  re: {
    lessons: blakeColors.oceanyBlue300,
    assessments: blakeColors.juicyOrange300,
    drivingTests: blakeColors.stormBlue300,
    books: blakeColors.purplyPink300,
    spelling: blakeColors.grapeyGreen300,
  },
  rex: {
    lessons: blakeColors.oceanyBlue300,
    quizzes: blakeColors.stormBlue300,
    assessments: blakeColors.juicyOrange300,
    books: blakeColors.purplyPink300,
    spelling: blakeColors.grapeyGreen300,
  },
}

type CamelizedProduct = 'fastPhonics' | 'maths' | 're' | 'rex'

export function isCamelizedProduct(maybeProduct: unknown): maybeProduct is CamelizedProduct {
  const camelizedProducts: CamelizedProduct[] = ['fastPhonics', 'maths', 're', 'rex']
  return camelizedProducts.includes(maybeProduct as CamelizedProduct)
}

export interface Box {
  category: string
  tooltip?: string
  theme?: keyof typeof themes
}

interface Month {
  month: string
  count: number
}

interface MonthUsage {
  count: number
  category: string
  months: Month[]
}

interface TimeSummary {
  category: string
  event_count: number
  student_count: number
}

export interface TotalUsageOverTimeData {
  total_usage_over_time_summary: TimeSummary[]
  total_usage_over_time_by_month: MonthUsage[]
}

interface ChartArgs {
  data: TotalUsageOverTimeData

  boxes: Box[]
  product: string
  tileClickAction?: (category: string) => void
  uiScope: UIScopeName
}

interface Stats {
  title: string
  category: string
  stats: {
    name: string
    value: string
  }[][]
  noClickAction?: boolean
  tooltip?: string
  theme?: keyof typeof themes
}

interface Signature {
  Args: ChartArgs
  Element: HTMLDivElement
}

export class GiraffeChartsTotalUsageOverTime extends Component<Signature> {
  @service intl!: IntlService

  @service features!: Features

  get legend(): LegendOptions {
    return legend('left', 'top', 40, 10)
  }

  tileClickAction = (statBox: Stats, category: string) => {
    if (statBox.noClickAction) return
    this.args.tileClickAction?.(category)
  }

  get summaryData() {
    return this.args.data.total_usage_over_time_summary
  }

  get monthData() {
    return this.args.data.total_usage_over_time_by_month
  }

  // This preserves the order that the stat boxes should be displayed in
  get categories() {
    return this.args.boxes.map((item) => item.category)
  }

  /**
   * Converts the category array an object like { 'lessons': { tooltip: '..', abc: .. }, 'some-other-category':, { .. } }
   * For easier access to category properties
   */

  get keyedBoxes(): Record<string, Box> {
    return this.args.boxes.reduce((keyed, box) => {
      return { ...keyed, [box.category]: box }
    }, {})
  }

  // Returns an array of stat box objects, each with the data required for a stat box, in the intended display order.
  get statBoxes(): Stats[] {
    const titlePrefix = 'reporting.totalUsageOverTime.titles'
    const statPrefix = 'reporting.statBoxes.totalUsageOverTime'

    return this.categories
      .map((category) => {
        const categorySummaryData = this.summaryData.find((summary) => summary.category === category)

        if (!categorySummaryData) return null

        const { event_count, student_count } = categorySummaryData
        const average = basicAverage(event_count, student_count)

        const camelizedCategory = camelize(category)
        const camelizedProduct = camelize(this.args.product)

        let title = this.intl.t(`${titlePrefix}.${camelizedCategory}.${camelizedProduct}`)
        if (this.intl.exists(`${titlePrefix}.${camelizedCategory}`)) {
          title = this.intl.t(`${titlePrefix}.${camelizedCategory}`)
        }

        const scopeSiteCopyKey = category === 'mental_minute' ? 'sprints' : camelize(this.args.uiScope)

        const firstStatLabel = this.intl.t(`${statPrefix}.${scopeSiteCopyKey}`)
        const secondStatLabel = this.intl.t(`${statPrefix}.perStudent`)

        return {
          title,
          stats: [
            [
              { name: firstStatLabel, value: formatNumber(event_count, 1) as number | string },
              { name: secondStatLabel, value: formatNumber(average, 1) as number | string },
            ],
          ],
          ...this.keyedBoxes[category],
        }
      })
      .filter((sb): sb is Stats => sb !== null)
  }

  // Not ideal. But we can't join class strings like `xl:w-1/{{statBoxes.length}}` or the class will not be included.
  // We probably want to come up with a better solution for XL screen sizes though
  get statBoxesXlWidthClass() {
    switch (this.statBoxes.length) {
      case 4:
        return 'xl:w-1/4'
      case 5:
        return 'xl:w-1/5'
      case 6:
        return 'xl:w-1/6'
      default:
        return ''
    }
  }

  // These will be the chart X axis categories, use the months given in the first data item.
  get chartMonths() {
    const monthData = this.monthData[0].months
    return monthData.map(({ month }) => convertDateFormat(month, 'yyyy-MM', 'MMMM'))
  }

  // Format and sort data for the series column chart
  get chartData(): SeriesColumnOptions[] {
    const { categories, monthData } = this

    const legendPrefix = 'reporting.totalUsageOverTime.legends'

    return categories
      .map((category) => {
        if (category === 'quizzes' && !this.features.isEnabled('tuiReadingSkillsMasteredFF')) return null

        const dataItem = monthData.find((item) => item.category === category)
        if (!dataItem) return null

        const byMonth = dataItem.months.map((item) => item.count)

        const camelizedCategory = camelize(category)
        const camelizedProduct = camelize(this.args.product)
        if (!isCamelizedProduct(camelizedProduct)) return

        let name = this.intl.t(`${legendPrefix}.${camelizedCategory}.${camelizedProduct}`)
        if (this.intl.exists(`${legendPrefix}.${camelizedCategory}`)) {
          name = this.intl.t(`${legendPrefix}.${camelizedCategory}`)
        }

        const color = colorMap[camelizedProduct][camelizedCategory]

        return { name, category, data: byMonth, color, type: 'column' } as const
      })
      .filter(isPresent)
  }

  <template>
    <div data-test-total-usage-over-time class="flex h-full flex-col" ...attributes>
      <div class="flex flex-wrap justify-center" data-test-stat-box-container>
        {{#each this.statBoxes as |statBox|}}
          <StatBox
            data-test-stat-box={{statBox.category}}
            class="w-1/3 min-w-[200px] {{this.statBoxesXlWidthClass}} border-b border-r border-white"
            @title={{statBox.title}}
            @tooltipText={{statBox.tooltip}}
            @theme={{statBox.theme}}
            @stats={{statBox.stats}}
            role={{unless statBox.noClickAction "button"}}
            {{on "click" (fn this.tileClickAction statBox statBox.category)}}
            {{! This lint rule doesn't work too nicely with optional interactions }}
            {{! https://github.com/ember-template-lint/ember-template-lint/issues/274 }}
            {{! template-lint-disable no-invalid-interactive }}
          />
        {{/each}}
      </div>

      <total-usage-chart class="relative block basis-96" data-test-total-usage-chart>
        <SeriesColumn
          @data={{this.chartData}}
          @categories={{this.chartMonths}}
          @stackingStyle="normal"
          @legend={{this.legend}}
          @yAxisReversedStacks={{false}}
        />
      </total-usage-chart>
    </div>
  </template>
}

export default GiraffeChartsTotalUsageOverTime
