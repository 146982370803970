import Component from '@glimmer/component'
import ExportButton, { type ExportOption } from 'district-ui-client/components/export-button/component'
import { not } from 'ember-truth-helpers'

interface Signature {
  Args: {
    viewMode: 'table' | 'chart'
    isDataPresent?: boolean
  }
  Element: HTMLDivElement
}

export class DetailedControls extends Component<Signature> {
  exportButtonWormhole = document.querySelector('#export-button-wormhole')

  get exportOptionsToInclude(): ExportOption[] {
    return [
      {
        id: 'csv',
        label: 'CSV',
        icon: 'file-excel',
        iconPrefix: 'far',
      },
      {
        id: 'print',
        label: 'Print',
        icon: 'print',
      },
    ]
  }

  <template>
    <div class="flex flex-wrap items-center justify-center gap-3 print:hidden" ...attributes>
      {{#if this.exportButtonWormhole}}
        {{#in-element this.exportButtonWormhole}}
          <ExportButton
            @viewMode={{@viewMode}}
            @exportOptions={{this.exportOptionsToInclude}}
            @disabled={{not @isDataPresent}}
          />
        {{/in-element}}
      {{/if}}
    </div>
  </template>
}

export default DetailedControls
