import type { TOC } from '@ember/component/template-only'
import { CleverUiHeader } from 'district-ui-client/components/clever-ui/header'
import type CleverIdRoute from 'district-ui-client/clever-id/route'
import { t } from 'ember-intl'
import RouteTemplate from 'ember-route-template'

interface Signature {
  Args: {
    model: ModelFor<CleverIdRoute>
  }
}

export const CleverIdRouteTemplate: TOC<Signature> = <template>
  <CleverUiHeader @title={{t "clever.matchDistrictPageTitle"}} @subscriptionType={{@model.subscriptionType}} />
  <p class="text-center text-base">
    {{t "clever.matchDistrictPageIntroduction"}}
  </p>
  {{outlet}}
</template>

export default RouteTemplate(CleverIdRouteTemplate)
