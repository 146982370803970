import type { TOC } from '@ember/component/template-only'
import { or } from 'ember-truth-helpers'

interface Signature {
  Element: HTMLButtonElement
  Args: {
    buttonType?: string
    corners?: string
    disabled?: boolean
  }
  Blocks: {
    default: []
  }
}

/**
 * Usage:
    <UiButton
      class="ui-btn-small"
      @disabled={{isDisabled}}
      @buttonType="submit"
      @corners="rounded rounded-l-none"
      {{on "click" this.myAction}}
    >
      abc
    </UiButton>
  Can use either @disabled or disabled
 */
export const UiButton: TOC<Signature> = <template>
  <button
    class="__ui-button__fd7ee {{or @corners 'rounded'}}"
    type={{or @buttonType "button"}}
    disabled={{@disabled}}
    ...attributes
  >
    {{yield}}
  </button>
</template>

export default UiButton
