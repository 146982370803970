/**
 * Use of @babel/polyfill is deprecated, directly importing core-js/stable is the replacement.
 * Since we use @babel/core > 7.18, there's no need to also import 'regenerator-runtime/runtime' for async transpilation
 *
 * More info;
 * https://github.com/babel/ember-cli-babel/blob/master/UPGRADING.md#v8
 * https://babeljs.io/docs/babel-polyfill
 * https://github.com/zloirock/core-js#babelpolyfill
 */
import 'core-js/stable'

import Application from '@ember/application'
import Resolver from 'ember-resolver'
import loadInitializers from 'ember-load-initializers'
import config from 'district-ui-client/config/environment'
import 'district-ui-client/font-awesome'
import '@fortawesome/fontawesome-svg-core/styles.css'
import '@blakeelearning/fidget/styles/addon.css'
import '@blakeelearning/dates/styles/addon.css'
import '@blakeelearning/popovers/styles/addon.css'
// Import app CSS last, so that it is higher priority when styles are equal specificity
import '@css/app.css'

/**
 * Register our internal ember-power-calendar-date-fns plugin with ember-power-calendar.
 * See: @blakeelearning/dates/utils/ember-power-calendar-date-fns/README.md
 */
import { registerDateLibrary } from 'ember-power-calendar'
// @ts-expect-error untyped plugin
import * as DateUtils from '@blakeelearning/dates/utils/ember-power-calendar-date-fns/unlocalized'
registerDateLibrary(DateUtils)

export default class App extends Application {
  modulePrefix = config.modulePrefix

  podModulePrefix = config.podModulePrefix

  Resolver = Resolver
}

loadInitializers(App, config.modulePrefix)
