
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("district-ui-client/adapters/application", function(){ return i("district-ui-client/adapters/application.ts");});
d("district-ui-client/adapters/clever", function(){ return i("district-ui-client/adapters/clever.js");});
d("district-ui-client/adapters/clever/clever-district-match", function(){ return i("district-ui-client/adapters/clever/clever-district-match.js");});
d("district-ui-client/adapters/clever/clever-district", function(){ return i("district-ui-client/adapters/clever/clever-district.ts");});
d("district-ui-client/adapters/clever/clever-school-match", function(){ return i("district-ui-client/adapters/clever/clever-school-match.js");});
d("district-ui-client/adapters/clever/clever-school", function(){ return i("district-ui-client/adapters/clever/clever-school.js");});
d("district-ui-client/adapters/clever/clever-student-match", function(){ return i("district-ui-client/adapters/clever/clever-student-match.js");});
d("district-ui-client/adapters/clever/clever-student", function(){ return i("district-ui-client/adapters/clever/clever-student.js");});
d("district-ui-client/adapters/clever/clever-teacher-match", function(){ return i("district-ui-client/adapters/clever/clever-teacher-match.js");});
d("district-ui-client/adapters/clever/clever-teacher", function(){ return i("district-ui-client/adapters/clever/clever-teacher.js");});
d("district-ui-client/adapters/clever/clever", function(){ return i("district-ui-client/adapters/clever/clever.js");});
d("district-ui-client/adapters/clever/contact-detail", function(){ return i("district-ui-client/adapters/clever/contact-detail.ts");});
d("district-ui-client/adapters/clever/gravity-bulk", function(){ return i("district-ui-client/adapters/clever/gravity-bulk.js");});
d("district-ui-client/adapters/clever/gravity", function(){ return i("district-ui-client/adapters/clever/gravity.js");});
d("district-ui-client/adapters/clever/school", function(){ return i("district-ui-client/adapters/clever/school.js");});
d("district-ui-client/adapters/clever/student", function(){ return i("district-ui-client/adapters/clever/student.ts");});
d("district-ui-client/adapters/clever/synchronisation", function(){ return i("district-ui-client/adapters/clever/synchronisation.js");});
d("district-ui-client/adapters/clever/teacher", function(){ return i("district-ui-client/adapters/clever/teacher.js");});
d("district-ui-client/adapters/contact-detail", function(){ return i("district-ui-client/adapters/contact-detail.ts");});
d("district-ui-client/adapters/csv-api", function(){ return i("district-ui-client/adapters/csv-api.js");});
d("district-ui-client/adapters/district-coordinator", function(){ return i("district-ui-client/adapters/district-coordinator.ts");});
d("district-ui-client/adapters/district", function(){ return i("district-ui-client/adapters/district.ts");});
d("district-ui-client/adapters/gravity-bulk", function(){ return i("district-ui-client/adapters/gravity-bulk.js");});
d("district-ui-client/adapters/gravity", function(){ return i("district-ui-client/adapters/gravity.js");});
d("district-ui-client/adapters/import-schedule", function(){ return i("district-ui-client/adapters/import-schedule.js");});
d("district-ui-client/adapters/import-validation", function(){ return i("district-ui-client/adapters/import-validation.js");});
d("district-ui-client/adapters/partner-contact-detail", function(){ return i("district-ui-client/adapters/partner-contact-detail.ts");});
d("district-ui-client/adapters/school-class", function(){ return i("district-ui-client/adapters/school-class.ts");});
d("district-ui-client/adapters/school-rollover", function(){ return i("district-ui-client/adapters/school-rollover.js");});
d("district-ui-client/adapters/school-statistic", function(){ return i("district-ui-client/adapters/school-statistic.js");});
d("district-ui-client/adapters/school-subscription", function(){ return i("district-ui-client/adapters/school-subscription.ts");});
d("district-ui-client/adapters/school", function(){ return i("district-ui-client/adapters/school.ts");});
d("district-ui-client/adapters/standards-set", function(){ return i("district-ui-client/adapters/standards-set.ts");});
d("district-ui-client/adapters/student", function(){ return i("district-ui-client/adapters/student.ts");});
d("district-ui-client/adapters/subscription-coordinator", function(){ return i("district-ui-client/adapters/subscription-coordinator.ts");});
d("district-ui-client/adapters/teacher-subscription", function(){ return i("district-ui-client/adapters/teacher-subscription.ts");});
d("district-ui-client/adapters/teacher", function(){ return i("district-ui-client/adapters/teacher.ts");});
d("district-ui-client/app", function(){ return i("district-ui-client/app.ts");});
d("district-ui-client/application-error/template", function(){ return i("district-ui-client/application-error/template.ts");});
d("district-ui-client/application/route", function(){ return i("district-ui-client/application/route.ts");});
d("district-ui-client/application/template", function(){ return i("district-ui-client/application/template.ts");});
d("district-ui-client/clever-id/initial-sync-in-progress/route", function(){ return i("district-ui-client/clever-id/initial-sync-in-progress/route.js");});
d("district-ui-client/clever-id/initial-sync-in-progress/template", function(){ return i("district-ui-client/clever-id/initial-sync-in-progress/template.hbs");});
d("district-ui-client/clever-id/matching-in-progress/route", function(){ return i("district-ui-client/clever-id/matching-in-progress/route.js");});
d("district-ui-client/clever-id/matching-in-progress/template", function(){ return i("district-ui-client/clever-id/matching-in-progress/template.hbs");});
d("district-ui-client/clever-id/route", function(){ return i("district-ui-client/clever-id/route.ts");});
d("district-ui-client/clever-id/start-initial-sync/controller", function(){ return i("district-ui-client/clever-id/start-initial-sync/controller.js");});
d("district-ui-client/clever-id/start-initial-sync/route", function(){ return i("district-ui-client/clever-id/start-initial-sync/route.js");});
d("district-ui-client/clever-id/start-initial-sync/template", function(){ return i("district-ui-client/clever-id/start-initial-sync/template.hbs");});
d("district-ui-client/clever-id/template", function(){ return i("district-ui-client/clever-id/template.ts");});
d("district-ui-client/clever/dashboard/controller", function(){ return i("district-ui-client/clever/dashboard/controller.js");});
d("district-ui-client/clever/dashboard/index/route", function(){ return i("district-ui-client/clever/dashboard/index/route.js");});
d("district-ui-client/clever/dashboard/route", function(){ return i("district-ui-client/clever/dashboard/route.js");});
d("district-ui-client/clever/dashboard/synced/controller", function(){ return i("district-ui-client/clever/dashboard/synced/controller.js");});
d("district-ui-client/clever/dashboard/synced/route", function(){ return i("district-ui-client/clever/dashboard/synced/route.js");});
d("district-ui-client/clever/dashboard/synced/template", function(){ return i("district-ui-client/clever/dashboard/synced/template.hbs");});
d("district-ui-client/clever/dashboard/template", function(){ return i("district-ui-client/clever/dashboard/template.hbs");});
d("district-ui-client/clever/dashboard/unsynced/controller", function(){ return i("district-ui-client/clever/dashboard/unsynced/controller.js");});
d("district-ui-client/clever/dashboard/unsynced/route", function(){ return i("district-ui-client/clever/dashboard/unsynced/route.js");});
d("district-ui-client/clever/dashboard/unsynced/template", function(){ return i("district-ui-client/clever/dashboard/unsynced/template.hbs");});
d("district-ui-client/clever/match/schools/index/controller", function(){ return i("district-ui-client/clever/match/schools/index/controller.js");});
d("district-ui-client/clever/match/schools/index/template", function(){ return i("district-ui-client/clever/match/schools/index/template.hbs");});
d("district-ui-client/clever/match/schools/route", function(){ return i("district-ui-client/clever/match/schools/route.js");});
d("district-ui-client/clever/match/schools/students/controller", function(){ return i("district-ui-client/clever/match/schools/students/controller.js");});
d("district-ui-client/clever/match/schools/students/route", function(){ return i("district-ui-client/clever/match/schools/students/route.js");});
d("district-ui-client/clever/match/schools/students/template", function(){ return i("district-ui-client/clever/match/schools/students/template.hbs");});
d("district-ui-client/clever/match/schools/teachers/controller", function(){ return i("district-ui-client/clever/match/schools/teachers/controller.js");});
d("district-ui-client/clever/match/schools/teachers/route", function(){ return i("district-ui-client/clever/match/schools/teachers/route.js");});
d("district-ui-client/clever/match/schools/teachers/template", function(){ return i("district-ui-client/clever/match/schools/teachers/template.hbs");});
d("district-ui-client/clever/match/schools/template", function(){ return i("district-ui-client/clever/match/schools/template.hbs");});
d("district-ui-client/clever/missing-fields/controller", function(){ return i("district-ui-client/clever/missing-fields/controller.js");});
d("district-ui-client/clever/missing-fields/route", function(){ return i("district-ui-client/clever/missing-fields/route.js");});
d("district-ui-client/clever/missing-fields/template", function(){ return i("district-ui-client/clever/missing-fields/template.hbs");});
d("district-ui-client/clever/route", function(){ return i("district-ui-client/clever/route.js");});
d("district-ui-client/clever/template", function(){ return i("district-ui-client/clever/template.ts");});
d("district-ui-client/config/environment", function(){ return i("district-ui-client/config/environment.js");});
d("district-ui-client/decorators/query-param", function(){ return i("district-ui-client/decorators/query-param.ts");});
d("district-ui-client/domain/marketing-regions", function(){ return i("district-ui-client/domain/marketing-regions.ts");});
d("district-ui-client/domain/precinct", function(){ return i("district-ui-client/domain/precinct.ts");});
d("district-ui-client/domain/product", function(){ return i("district-ui-client/domain/product.ts");});
d("district-ui-client/domain/subscription-type", function(){ return i("district-ui-client/domain/subscription-type.ts");});
d("district-ui-client/domain/ui-scope", function(){ return i("district-ui-client/domain/ui-scope.ts");});
d("district-ui-client/errors/email-already-exists-error", function(){ return i("district-ui-client/errors/email-already-exists-error.ts");});
d("district-ui-client/errors/http-error", function(){ return i("district-ui-client/errors/http-error.ts");});
d("district-ui-client/errors/json-api-errors", function(){ return i("district-ui-client/errors/json-api-errors.ts");});
d("district-ui-client/errors/login-already-exists-error", function(){ return i("district-ui-client/errors/login-already-exists-error.ts");});
d("district-ui-client/errors/password-incorrect-error", function(){ return i("district-ui-client/errors/password-incorrect-error.ts");});
d("district-ui-client/font-awesome", function(){ return i("district-ui-client/font-awesome.ts");});
d("district-ui-client/instance-initializers/rsvp-error-handler", function(){ return i("district-ui-client/instance-initializers/rsvp-error-handler.js");});
d("district-ui-client/instance-initializers/start-log", function(){ return i("district-ui-client/instance-initializers/start-log.ts");});
d("district-ui-client/landing/route", function(){ return i("district-ui-client/landing/route.ts");});
d("district-ui-client/loading/template", function(){ return i("district-ui-client/loading/template.ts");});
d("district-ui-client/log-adapters/application", function(){ return i("district-ui-client/log-adapters/application.js");});
d("district-ui-client/models/clever/clever-district-match", function(){ return i("district-ui-client/models/clever/clever-district-match.ts");});
d("district-ui-client/models/clever/clever-district", function(){ return i("district-ui-client/models/clever/clever-district.ts");});
d("district-ui-client/models/clever/clever-school-match", function(){ return i("district-ui-client/models/clever/clever-school-match.ts");});
d("district-ui-client/models/clever/clever-school", function(){ return i("district-ui-client/models/clever/clever-school.ts");});
d("district-ui-client/models/clever/clever-student-match", function(){ return i("district-ui-client/models/clever/clever-student-match.ts");});
d("district-ui-client/models/clever/clever-student", function(){ return i("district-ui-client/models/clever/clever-student.ts");});
d("district-ui-client/models/clever/clever-teacher-match", function(){ return i("district-ui-client/models/clever/clever-teacher-match.ts");});
d("district-ui-client/models/clever/clever-teacher", function(){ return i("district-ui-client/models/clever/clever-teacher.ts");});
d("district-ui-client/models/clever/contact-detail", function(){ return i("district-ui-client/models/clever/contact-detail.ts");});
d("district-ui-client/models/clever/school-subscription-utilisation", function(){ return i("district-ui-client/models/clever/school-subscription-utilisation.ts");});
d("district-ui-client/models/clever/school", function(){ return i("district-ui-client/models/clever/school.ts");});
d("district-ui-client/models/clever/student", function(){ return i("district-ui-client/models/clever/student.ts");});
d("district-ui-client/models/clever/synchronisation", function(){ return i("district-ui-client/models/clever/synchronisation.ts");});
d("district-ui-client/models/clever/teacher", function(){ return i("district-ui-client/models/clever/teacher.ts");});
d("district-ui-client/models/contact-detail", function(){ return i("district-ui-client/models/contact-detail.ts");});
d("district-ui-client/models/district-coordinator", function(){ return i("district-ui-client/models/district-coordinator.ts");});
d("district-ui-client/models/district", function(){ return i("district-ui-client/models/district.ts");});
d("district-ui-client/models/import-schedule", function(){ return i("district-ui-client/models/import-schedule.ts");});
d("district-ui-client/models/import-validation", function(){ return i("district-ui-client/models/import-validation.ts");});
d("district-ui-client/models/partner-contact-detail", function(){ return i("district-ui-client/models/partner-contact-detail.ts");});
d("district-ui-client/models/school-class", function(){ return i("district-ui-client/models/school-class.ts");});
d("district-ui-client/models/school-rollover", function(){ return i("district-ui-client/models/school-rollover.ts");});
d("district-ui-client/models/school-statistic", function(){ return i("district-ui-client/models/school-statistic.ts");});
d("district-ui-client/models/school-subscription-utilisation", function(){ return i("district-ui-client/models/school-subscription-utilisation.ts");});
d("district-ui-client/models/school-subscription", function(){ return i("district-ui-client/models/school-subscription.ts");});
d("district-ui-client/models/school", function(){ return i("district-ui-client/models/school.ts");});
d("district-ui-client/models/standards-set", function(){ return i("district-ui-client/models/standards-set.ts");});
d("district-ui-client/models/student", function(){ return i("district-ui-client/models/student.ts");});
d("district-ui-client/models/subscription-coordinator", function(){ return i("district-ui-client/models/subscription-coordinator.ts");});
d("district-ui-client/models/teacher-subscription", function(){ return i("district-ui-client/models/teacher-subscription.ts");});
d("district-ui-client/models/teacher", function(){ return i("district-ui-client/models/teacher.ts");});
d("district-ui-client/reporting/route", function(){ return i("district-ui-client/reporting/route.ts");});
d("district-ui-client/reporting/ui-scope/controller", function(){ return i("district-ui-client/reporting/ui-scope/controller.ts");});
d("district-ui-client/reporting/ui-scope/fast-phonics/controller", function(){ return i("district-ui-client/reporting/ui-scope/fast-phonics/controller.ts");});
d("district-ui-client/reporting/ui-scope/fast-phonics/lesson-performance/controller", function(){ return i("district-ui-client/reporting/ui-scope/fast-phonics/lesson-performance/controller.ts");});
d("district-ui-client/reporting/ui-scope/fast-phonics/lesson-performance/route", function(){ return i("district-ui-client/reporting/ui-scope/fast-phonics/lesson-performance/route.ts");});
d("district-ui-client/reporting/ui-scope/fast-phonics/lesson-performance/template", function(){ return i("district-ui-client/reporting/ui-scope/fast-phonics/lesson-performance/template.ts");});
d("district-ui-client/reporting/ui-scope/fast-phonics/route", function(){ return i("district-ui-client/reporting/ui-scope/fast-phonics/route.ts");});
d("district-ui-client/reporting/ui-scope/fast-phonics/standards-performance/controller", function(){ return i("district-ui-client/reporting/ui-scope/fast-phonics/standards-performance/controller.ts");});
d("district-ui-client/reporting/ui-scope/fast-phonics/standards-performance/route", function(){ return i("district-ui-client/reporting/ui-scope/fast-phonics/standards-performance/route.ts");});
d("district-ui-client/reporting/ui-scope/fast-phonics/standards-performance/template", function(){ return i("district-ui-client/reporting/ui-scope/fast-phonics/standards-performance/template.ts");});
d("district-ui-client/reporting/ui-scope/maths/awards-earned/route", function(){ return i("district-ui-client/reporting/ui-scope/maths/awards-earned/route.ts");});
d("district-ui-client/reporting/ui-scope/maths/awards-earned/template", function(){ return i("district-ui-client/reporting/ui-scope/maths/awards-earned/template.ts");});
d("district-ui-client/reporting/ui-scope/maths/controller", function(){ return i("district-ui-client/reporting/ui-scope/maths/controller.ts");});
d("district-ui-client/reporting/ui-scope/maths/course-progress/averages/route", function(){ return i("district-ui-client/reporting/ui-scope/maths/course-progress/averages/route.ts");});
d("district-ui-client/reporting/ui-scope/maths/course-progress/averages/template", function(){ return i("district-ui-client/reporting/ui-scope/maths/course-progress/averages/template.ts");});
d("district-ui-client/reporting/ui-scope/maths/course-progress/totals/route", function(){ return i("district-ui-client/reporting/ui-scope/maths/course-progress/totals/route.ts");});
d("district-ui-client/reporting/ui-scope/maths/course-progress/totals/template", function(){ return i("district-ui-client/reporting/ui-scope/maths/course-progress/totals/template.ts");});
d("district-ui-client/reporting/ui-scope/maths/dashboard/index/route", function(){ return i("district-ui-client/reporting/ui-scope/maths/dashboard/index/route.ts");});
d("district-ui-client/reporting/ui-scope/maths/dashboard/index/template", function(){ return i("district-ui-client/reporting/ui-scope/maths/dashboard/index/template.ts");});
d("district-ui-client/reporting/ui-scope/maths/dashboard/table/route", function(){ return i("district-ui-client/reporting/ui-scope/maths/dashboard/table/route.ts");});
d("district-ui-client/reporting/ui-scope/maths/dashboard/table/template", function(){ return i("district-ui-client/reporting/ui-scope/maths/dashboard/table/template.ts");});
d("district-ui-client/reporting/ui-scope/maths/driving-tests/route", function(){ return i("district-ui-client/reporting/ui-scope/maths/driving-tests/route.ts");});
d("district-ui-client/reporting/ui-scope/maths/driving-tests/template", function(){ return i("district-ui-client/reporting/ui-scope/maths/driving-tests/template.ts");});
d("district-ui-client/reporting/ui-scope/maths/fluency-sprints/table/controller", function(){ return i("district-ui-client/reporting/ui-scope/maths/fluency-sprints/table/controller.ts");});
d("district-ui-client/reporting/ui-scope/maths/fluency-sprints/table/route", function(){ return i("district-ui-client/reporting/ui-scope/maths/fluency-sprints/table/route.ts");});
d("district-ui-client/reporting/ui-scope/maths/fluency-sprints/table/template", function(){ return i("district-ui-client/reporting/ui-scope/maths/fluency-sprints/table/template.ts");});
d("district-ui-client/reporting/ui-scope/maths/lesson-performance/controller", function(){ return i("district-ui-client/reporting/ui-scope/maths/lesson-performance/controller.ts");});
d("district-ui-client/reporting/ui-scope/maths/lesson-performance/route", function(){ return i("district-ui-client/reporting/ui-scope/maths/lesson-performance/route.ts");});
d("district-ui-client/reporting/ui-scope/maths/lesson-performance/template", function(){ return i("district-ui-client/reporting/ui-scope/maths/lesson-performance/template.ts");});
d("district-ui-client/reporting/ui-scope/maths/quizzes-completed/route", function(){ return i("district-ui-client/reporting/ui-scope/maths/quizzes-completed/route.ts");});
d("district-ui-client/reporting/ui-scope/maths/quizzes-completed/template", function(){ return i("district-ui-client/reporting/ui-scope/maths/quizzes-completed/template.ts");});
d("district-ui-client/reporting/ui-scope/maths/route", function(){ return i("district-ui-client/reporting/ui-scope/maths/route.ts");});
d("district-ui-client/reporting/ui-scope/maths/standards-performance/controller", function(){ return i("district-ui-client/reporting/ui-scope/maths/standards-performance/controller.ts");});
d("district-ui-client/reporting/ui-scope/maths/standards-performance/route", function(){ return i("district-ui-client/reporting/ui-scope/maths/standards-performance/route.ts");});
d("district-ui-client/reporting/ui-scope/maths/standards-performance/template", function(){ return i("district-ui-client/reporting/ui-scope/maths/standards-performance/template.ts");});
d("district-ui-client/reporting/ui-scope/maths/summary/table/route", function(){ return i("district-ui-client/reporting/ui-scope/maths/summary/table/route.ts");});
d("district-ui-client/reporting/ui-scope/maths/summary/table/template", function(){ return i("district-ui-client/reporting/ui-scope/maths/summary/table/template.ts");});
d("district-ui-client/reporting/ui-scope/maths/time-on-task/route", function(){ return i("district-ui-client/reporting/ui-scope/maths/time-on-task/route.ts");});
d("district-ui-client/reporting/ui-scope/maths/time-on-task/template", function(){ return i("district-ui-client/reporting/ui-scope/maths/time-on-task/template.ts");});
d("district-ui-client/reporting/ui-scope/maths/working-at-grade-level/route", function(){ return i("district-ui-client/reporting/ui-scope/maths/working-at-grade-level/route.ts");});
d("district-ui-client/reporting/ui-scope/maths/working-at-grade-level/template", function(){ return i("district-ui-client/reporting/ui-scope/maths/working-at-grade-level/template.ts");});
d("district-ui-client/reporting/ui-scope/re/awards-earned/route", function(){ return i("district-ui-client/reporting/ui-scope/re/awards-earned/route.ts");});
d("district-ui-client/reporting/ui-scope/re/awards-earned/template", function(){ return i("district-ui-client/reporting/ui-scope/re/awards-earned/template.ts");});
d("district-ui-client/reporting/ui-scope/re/books-read/route", function(){ return i("district-ui-client/reporting/ui-scope/re/books-read/route.ts");});
d("district-ui-client/reporting/ui-scope/re/books-read/template", function(){ return i("district-ui-client/reporting/ui-scope/re/books-read/template.ts");});
d("district-ui-client/reporting/ui-scope/re/controller", function(){ return i("district-ui-client/reporting/ui-scope/re/controller.ts");});
d("district-ui-client/reporting/ui-scope/re/course-progress/averages/route", function(){ return i("district-ui-client/reporting/ui-scope/re/course-progress/averages/route.ts");});
d("district-ui-client/reporting/ui-scope/re/course-progress/averages/template", function(){ return i("district-ui-client/reporting/ui-scope/re/course-progress/averages/template.ts");});
d("district-ui-client/reporting/ui-scope/re/course-progress/totals/route", function(){ return i("district-ui-client/reporting/ui-scope/re/course-progress/totals/route.ts");});
d("district-ui-client/reporting/ui-scope/re/course-progress/totals/template", function(){ return i("district-ui-client/reporting/ui-scope/re/course-progress/totals/template.ts");});
d("district-ui-client/reporting/ui-scope/re/dashboard/index/route", function(){ return i("district-ui-client/reporting/ui-scope/re/dashboard/index/route.ts");});
d("district-ui-client/reporting/ui-scope/re/dashboard/index/template", function(){ return i("district-ui-client/reporting/ui-scope/re/dashboard/index/template.ts");});
d("district-ui-client/reporting/ui-scope/re/dashboard/table/route", function(){ return i("district-ui-client/reporting/ui-scope/re/dashboard/table/route.ts");});
d("district-ui-client/reporting/ui-scope/re/dashboard/table/template", function(){ return i("district-ui-client/reporting/ui-scope/re/dashboard/table/template.ts");});
d("district-ui-client/reporting/ui-scope/re/driving-tests/route", function(){ return i("district-ui-client/reporting/ui-scope/re/driving-tests/route.ts");});
d("district-ui-client/reporting/ui-scope/re/driving-tests/template", function(){ return i("district-ui-client/reporting/ui-scope/re/driving-tests/template.ts");});
d("district-ui-client/reporting/ui-scope/re/lesson-performance/controller", function(){ return i("district-ui-client/reporting/ui-scope/re/lesson-performance/controller.ts");});
d("district-ui-client/reporting/ui-scope/re/lesson-performance/route", function(){ return i("district-ui-client/reporting/ui-scope/re/lesson-performance/route.ts");});
d("district-ui-client/reporting/ui-scope/re/lesson-performance/template", function(){ return i("district-ui-client/reporting/ui-scope/re/lesson-performance/template.ts");});
d("district-ui-client/reporting/ui-scope/re/lexile-details/route", function(){ return i("district-ui-client/reporting/ui-scope/re/lexile-details/route.ts");});
d("district-ui-client/reporting/ui-scope/re/lexile-details/template", function(){ return i("district-ui-client/reporting/ui-scope/re/lexile-details/template.ts");});
d("district-ui-client/reporting/ui-scope/re/quizzes-completed/route", function(){ return i("district-ui-client/reporting/ui-scope/re/quizzes-completed/route.ts");});
d("district-ui-client/reporting/ui-scope/re/quizzes-completed/template", function(){ return i("district-ui-client/reporting/ui-scope/re/quizzes-completed/template.ts");});
d("district-ui-client/reporting/ui-scope/re/route", function(){ return i("district-ui-client/reporting/ui-scope/re/route.ts");});
d("district-ui-client/reporting/ui-scope/re/spelling-progress/averages/route", function(){ return i("district-ui-client/reporting/ui-scope/re/spelling-progress/averages/route.ts");});
d("district-ui-client/reporting/ui-scope/re/spelling-progress/averages/template", function(){ return i("district-ui-client/reporting/ui-scope/re/spelling-progress/averages/template.ts");});
d("district-ui-client/reporting/ui-scope/re/spelling-progress/totals/route", function(){ return i("district-ui-client/reporting/ui-scope/re/spelling-progress/totals/route.ts");});
d("district-ui-client/reporting/ui-scope/re/spelling-progress/totals/template", function(){ return i("district-ui-client/reporting/ui-scope/re/spelling-progress/totals/template.ts");});
d("district-ui-client/reporting/ui-scope/re/standards-performance/controller", function(){ return i("district-ui-client/reporting/ui-scope/re/standards-performance/controller.ts");});
d("district-ui-client/reporting/ui-scope/re/standards-performance/route", function(){ return i("district-ui-client/reporting/ui-scope/re/standards-performance/route.ts");});
d("district-ui-client/reporting/ui-scope/re/standards-performance/template", function(){ return i("district-ui-client/reporting/ui-scope/re/standards-performance/template.ts");});
d("district-ui-client/reporting/ui-scope/re/stories-written/route", function(){ return i("district-ui-client/reporting/ui-scope/re/stories-written/route.ts");});
d("district-ui-client/reporting/ui-scope/re/stories-written/template", function(){ return i("district-ui-client/reporting/ui-scope/re/stories-written/template.ts");});
d("district-ui-client/reporting/ui-scope/re/storylands/route", function(){ return i("district-ui-client/reporting/ui-scope/re/storylands/route.ts");});
d("district-ui-client/reporting/ui-scope/re/storylands/template", function(){ return i("district-ui-client/reporting/ui-scope/re/storylands/template.ts");});
d("district-ui-client/reporting/ui-scope/re/summary/table/route", function(){ return i("district-ui-client/reporting/ui-scope/re/summary/table/route.ts");});
d("district-ui-client/reporting/ui-scope/re/summary/table/template", function(){ return i("district-ui-client/reporting/ui-scope/re/summary/table/template.ts");});
d("district-ui-client/reporting/ui-scope/re/time-on-task/route", function(){ return i("district-ui-client/reporting/ui-scope/re/time-on-task/route.ts");});
d("district-ui-client/reporting/ui-scope/re/time-on-task/template", function(){ return i("district-ui-client/reporting/ui-scope/re/time-on-task/template.ts");});
d("district-ui-client/reporting/ui-scope/re/working-at-grade-level/route", function(){ return i("district-ui-client/reporting/ui-scope/re/working-at-grade-level/route.ts");});
d("district-ui-client/reporting/ui-scope/re/working-at-grade-level/template", function(){ return i("district-ui-client/reporting/ui-scope/re/working-at-grade-level/template.ts");});
d("district-ui-client/reporting/ui-scope/rex/assessment-scores/route", function(){ return i("district-ui-client/reporting/ui-scope/rex/assessment-scores/route.ts");});
d("district-ui-client/reporting/ui-scope/rex/assessment-scores/template", function(){ return i("district-ui-client/reporting/ui-scope/rex/assessment-scores/template.ts");});
d("district-ui-client/reporting/ui-scope/rex/awards-earned/route", function(){ return i("district-ui-client/reporting/ui-scope/rex/awards-earned/route.ts");});
d("district-ui-client/reporting/ui-scope/rex/awards-earned/template", function(){ return i("district-ui-client/reporting/ui-scope/rex/awards-earned/template.ts");});
d("district-ui-client/reporting/ui-scope/rex/books-read/by-level/route", function(){ return i("district-ui-client/reporting/ui-scope/rex/books-read/by-level/route.ts");});
d("district-ui-client/reporting/ui-scope/rex/books-read/by-level/template", function(){ return i("district-ui-client/reporting/ui-scope/rex/books-read/by-level/template.ts");});
d("district-ui-client/reporting/ui-scope/rex/books-read/index/route", function(){ return i("district-ui-client/reporting/ui-scope/rex/books-read/index/route.ts");});
d("district-ui-client/reporting/ui-scope/rex/books-read/index/template", function(){ return i("district-ui-client/reporting/ui-scope/rex/books-read/index/template.ts");});
d("district-ui-client/reporting/ui-scope/rex/course-progress/averages/route", function(){ return i("district-ui-client/reporting/ui-scope/rex/course-progress/averages/route.ts");});
d("district-ui-client/reporting/ui-scope/rex/course-progress/averages/template", function(){ return i("district-ui-client/reporting/ui-scope/rex/course-progress/averages/template.ts");});
d("district-ui-client/reporting/ui-scope/rex/course-progress/totals/route", function(){ return i("district-ui-client/reporting/ui-scope/rex/course-progress/totals/route.ts");});
d("district-ui-client/reporting/ui-scope/rex/course-progress/totals/template", function(){ return i("district-ui-client/reporting/ui-scope/rex/course-progress/totals/template.ts");});
d("district-ui-client/reporting/ui-scope/rex/dashboard/index/route", function(){ return i("district-ui-client/reporting/ui-scope/rex/dashboard/index/route.ts");});
d("district-ui-client/reporting/ui-scope/rex/dashboard/index/template", function(){ return i("district-ui-client/reporting/ui-scope/rex/dashboard/index/template.ts");});
d("district-ui-client/reporting/ui-scope/rex/dashboard/table/controller", function(){ return i("district-ui-client/reporting/ui-scope/rex/dashboard/table/controller.ts");});
d("district-ui-client/reporting/ui-scope/rex/dashboard/table/route", function(){ return i("district-ui-client/reporting/ui-scope/rex/dashboard/table/route.ts");});
d("district-ui-client/reporting/ui-scope/rex/dashboard/table/template", function(){ return i("district-ui-client/reporting/ui-scope/rex/dashboard/table/template.ts");});
d("district-ui-client/reporting/ui-scope/rex/lexile-details/route", function(){ return i("district-ui-client/reporting/ui-scope/rex/lexile-details/route.ts");});
d("district-ui-client/reporting/ui-scope/rex/lexile-details/template", function(){ return i("district-ui-client/reporting/ui-scope/rex/lexile-details/template.ts");});
d("district-ui-client/reporting/ui-scope/rex/quiz-scores/route", function(){ return i("district-ui-client/reporting/ui-scope/rex/quiz-scores/route.ts");});
d("district-ui-client/reporting/ui-scope/rex/quiz-scores/template", function(){ return i("district-ui-client/reporting/ui-scope/rex/quiz-scores/template.ts");});
d("district-ui-client/reporting/ui-scope/rex/route", function(){ return i("district-ui-client/reporting/ui-scope/rex/route.ts");});
d("district-ui-client/reporting/ui-scope/rex/spelling-progress/averages/route", function(){ return i("district-ui-client/reporting/ui-scope/rex/spelling-progress/averages/route.ts");});
d("district-ui-client/reporting/ui-scope/rex/spelling-progress/averages/template", function(){ return i("district-ui-client/reporting/ui-scope/rex/spelling-progress/averages/template.ts");});
d("district-ui-client/reporting/ui-scope/rex/spelling-progress/totals/route", function(){ return i("district-ui-client/reporting/ui-scope/rex/spelling-progress/totals/route.ts");});
d("district-ui-client/reporting/ui-scope/rex/spelling-progress/totals/template", function(){ return i("district-ui-client/reporting/ui-scope/rex/spelling-progress/totals/template.ts");});
d("district-ui-client/reporting/ui-scope/rex/summary/table/route", function(){ return i("district-ui-client/reporting/ui-scope/rex/summary/table/route.ts");});
d("district-ui-client/reporting/ui-scope/rex/summary/table/template", function(){ return i("district-ui-client/reporting/ui-scope/rex/summary/table/template.ts");});
d("district-ui-client/reporting/ui-scope/rex/time-on-task/route", function(){ return i("district-ui-client/reporting/ui-scope/rex/time-on-task/route.ts");});
d("district-ui-client/reporting/ui-scope/rex/time-on-task/template", function(){ return i("district-ui-client/reporting/ui-scope/rex/time-on-task/template.ts");});
d("district-ui-client/reporting/ui-scope/rex/working-at-grade-level/route", function(){ return i("district-ui-client/reporting/ui-scope/rex/working-at-grade-level/route.ts");});
d("district-ui-client/reporting/ui-scope/rex/working-at-grade-level/template", function(){ return i("district-ui-client/reporting/ui-scope/rex/working-at-grade-level/template.ts");});
d("district-ui-client/reporting/ui-scope/route", function(){ return i("district-ui-client/reporting/ui-scope/route.ts");});
d("district-ui-client/reporting/ui-scope/template", function(){ return i("district-ui-client/reporting/ui-scope/template.ts");});
d("district-ui-client/router", function(){ return i("district-ui-client/router.js");});
d("district-ui-client/serializers/application", function(){ return i("district-ui-client/serializers/application.ts");});
d("district-ui-client/serializers/clever", function(){ return i("district-ui-client/serializers/clever.js");});
d("district-ui-client/serializers/clever/clever-district-match", function(){ return i("district-ui-client/serializers/clever/clever-district-match.js");});
d("district-ui-client/serializers/clever/clever-district", function(){ return i("district-ui-client/serializers/clever/clever-district.js");});
d("district-ui-client/serializers/clever/clever-school-match", function(){ return i("district-ui-client/serializers/clever/clever-school-match.js");});
d("district-ui-client/serializers/clever/clever-school", function(){ return i("district-ui-client/serializers/clever/clever-school.js");});
d("district-ui-client/serializers/clever/clever-student-match", function(){ return i("district-ui-client/serializers/clever/clever-student-match.js");});
d("district-ui-client/serializers/clever/clever-student", function(){ return i("district-ui-client/serializers/clever/clever-student.js");});
d("district-ui-client/serializers/clever/clever-teacher-match", function(){ return i("district-ui-client/serializers/clever/clever-teacher-match.js");});
d("district-ui-client/serializers/clever/clever-teacher", function(){ return i("district-ui-client/serializers/clever/clever-teacher.js");});
d("district-ui-client/serializers/clever/contact-detail", function(){ return i("district-ui-client/serializers/clever/contact-detail.ts");});
d("district-ui-client/serializers/clever/school-subscription-utilisation", function(){ return i("district-ui-client/serializers/clever/school-subscription-utilisation.ts");});
d("district-ui-client/serializers/clever/school", function(){ return i("district-ui-client/serializers/clever/school.js");});
d("district-ui-client/serializers/clever/student", function(){ return i("district-ui-client/serializers/clever/student.ts");});
d("district-ui-client/serializers/clever/synchronisation", function(){ return i("district-ui-client/serializers/clever/synchronisation.js");});
d("district-ui-client/serializers/clever/teacher", function(){ return i("district-ui-client/serializers/clever/teacher.js");});
d("district-ui-client/serializers/contact-detail", function(){ return i("district-ui-client/serializers/contact-detail.ts");});
d("district-ui-client/serializers/district-coordinator", function(){ return i("district-ui-client/serializers/district-coordinator.ts");});
d("district-ui-client/serializers/district", function(){ return i("district-ui-client/serializers/district.js");});
d("district-ui-client/serializers/import-schedule", function(){ return i("district-ui-client/serializers/import-schedule.js");});
d("district-ui-client/serializers/import-validation", function(){ return i("district-ui-client/serializers/import-validation.js");});
d("district-ui-client/serializers/school-class", function(){ return i("district-ui-client/serializers/school-class.ts");});
d("district-ui-client/serializers/school-statistic", function(){ return i("district-ui-client/serializers/school-statistic.ts");});
d("district-ui-client/serializers/school-subscription-utilisation", function(){ return i("district-ui-client/serializers/school-subscription-utilisation.ts");});
d("district-ui-client/serializers/school-subscription", function(){ return i("district-ui-client/serializers/school-subscription.ts");});
d("district-ui-client/serializers/school", function(){ return i("district-ui-client/serializers/school.ts");});
d("district-ui-client/serializers/standards-set", function(){ return i("district-ui-client/serializers/standards-set.ts");});
d("district-ui-client/serializers/student", function(){ return i("district-ui-client/serializers/student.ts");});
d("district-ui-client/serializers/subscription-coordinator", function(){ return i("district-ui-client/serializers/subscription-coordinator.ts");});
d("district-ui-client/serializers/teacher-subscription", function(){ return i("district-ui-client/serializers/teacher-subscription.ts");});
d("district-ui-client/serializers/teacher", function(){ return i("district-ui-client/serializers/teacher.ts");});
d("district-ui-client/services/active-route", function(){ return i("district-ui-client/services/active-route.ts");});
d("district-ui-client/services/add-existing-teachers", function(){ return i("district-ui-client/services/add-existing-teachers.ts");});
d("district-ui-client/services/alert", function(){ return i("district-ui-client/services/alert.ts");});
d("district-ui-client/services/clever", function(){ return i("district-ui-client/services/clever.js");});
d("district-ui-client/services/clever/teacher-match-error", function(){ return i("district-ui-client/services/clever/teacher-match-error.ts");});
d("district-ui-client/services/contentful-client", function(){ return i("district-ui-client/services/contentful-client.ts");});
d("district-ui-client/services/email-all-students-csv", function(){ return i("district-ui-client/services/email-all-students-csv.ts");});
d("district-ui-client/services/feature-control", function(){ return i("district-ui-client/services/feature-control.ts");});
d("district-ui-client/services/feature-notification", function(){ return i("district-ui-client/services/feature-notification.ts");});
d("district-ui-client/services/features", function(){ return i("district-ui-client/services/features.ts");});
d("district-ui-client/services/file-download", function(){ return i("district-ui-client/services/file-download.ts");});
d("district-ui-client/services/flash-queue", function(){ return i("district-ui-client/services/flash-queue.ts");});
d("district-ui-client/services/grade-sets", function(){ return i("district-ui-client/services/grade-sets.ts");});
d("district-ui-client/services/impression", function(){ return i("district-ui-client/services/impression.ts");});
d("district-ui-client/services/lesson-info", function(){ return i("district-ui-client/services/lesson-info.ts");});
d("district-ui-client/services/logout", function(){ return i("district-ui-client/services/logout.ts");});
d("district-ui-client/services/report-registry", function(){ return i("district-ui-client/services/report-registry.ts");});
d("district-ui-client/services/reporting", function(){ return i("district-ui-client/services/reporting.ts");});
d("district-ui-client/services/school-rollover", function(){ return i("district-ui-client/services/school-rollover.ts");});
d("district-ui-client/services/session", function(){ return i("district-ui-client/services/session.ts");});
d("district-ui-client/services/student-events", function(){ return i("district-ui-client/services/student-events.js");});
d("district-ui-client/services/student-import", function(){ return i("district-ui-client/services/student-import.js");});
d("district-ui-client/services/student-operations", function(){ return i("district-ui-client/services/student-operations.ts");});
d("district-ui-client/services/table-to-csv", function(){ return i("district-ui-client/services/table-to-csv.ts");});
d("district-ui-client/services/teacher-operations", function(){ return i("district-ui-client/services/teacher-operations.ts");});
d("district-ui-client/settings/district-coordinator/template", function(){ return i("district-ui-client/settings/district-coordinator/template.ts");});
d("district-ui-client/settings/district-settings/template", function(){ return i("district-ui-client/settings/district-settings/template.ts");});
d("district-ui-client/settings/template", function(){ return i("district-ui-client/settings/template.ts");});
d("district-ui-client/student-import/error-codes", function(){ return i("district-ui-client/student-import/error-codes.js");});
d("district-ui-client/student-import/import-upload/task", function(){ return i("district-ui-client/student-import/import-upload/task.js");});
d("district-ui-client/student-import/import/task", function(){ return i("district-ui-client/student-import/import/task.js");});
d("district-ui-client/student-import/task", function(){ return i("district-ui-client/student-import/task.js");});
d("district-ui-client/student-import/urls", function(){ return i("district-ui-client/student-import/urls.js");});
d("district-ui-client/student-import/utils", function(){ return i("district-ui-client/student-import/utils.js");});
d("district-ui-client/student-import/validate-upload/task", function(){ return i("district-ui-client/student-import/validate-upload/task.js");});
d("district-ui-client/student-import/validate/task", function(){ return i("district-ui-client/student-import/validate/task.js");});
d("district-ui-client/subscription-type/error/route", function(){ return i("district-ui-client/subscription-type/error/route.ts");});
d("district-ui-client/subscription-type/error/template", function(){ return i("district-ui-client/subscription-type/error/template.ts");});
d("district-ui-client/subscription-type/error404/route", function(){ return i("district-ui-client/subscription-type/error404/route.ts");});
d("district-ui-client/subscription-type/error404/template", function(){ return i("district-ui-client/subscription-type/error404/template.ts");});
d("district-ui-client/subscription-type/index/route", function(){ return i("district-ui-client/subscription-type/index/route.ts");});
d("district-ui-client/subscription-type/index/template", function(){ return i("district-ui-client/subscription-type/index/template.ts");});
d("district-ui-client/subscription-type/loading/template", function(){ return i("district-ui-client/subscription-type/loading/template.ts");});
d("district-ui-client/subscription-type/manage/schools-loading/template", function(){ return i("district-ui-client/subscription-type/manage/schools-loading/template.ts");});
d("district-ui-client/subscription-type/manage/schools/edit/route", function(){ return i("district-ui-client/subscription-type/manage/schools/edit/route.ts");});
d("district-ui-client/subscription-type/manage/schools/edit/template", function(){ return i("district-ui-client/subscription-type/manage/schools/edit/template.ts");});
d("district-ui-client/subscription-type/manage/schools/index/route", function(){ return i("district-ui-client/subscription-type/manage/schools/index/route.ts");});
d("district-ui-client/subscription-type/manage/schools/index/template", function(){ return i("district-ui-client/subscription-type/manage/schools/index/template.ts");});
d("district-ui-client/subscription-type/manage/schools/route", function(){ return i("district-ui-client/subscription-type/manage/schools/route.ts");});
d("district-ui-client/subscription-type/manage/students-loading/template", function(){ return i("district-ui-client/subscription-type/manage/students-loading/template.ts");});
d("district-ui-client/subscription-type/manage/students/edit/route", function(){ return i("district-ui-client/subscription-type/manage/students/edit/route.ts");});
d("district-ui-client/subscription-type/manage/students/edit/template", function(){ return i("district-ui-client/subscription-type/manage/students/edit/template.ts");});
d("district-ui-client/subscription-type/manage/students/import-students/controller", function(){ return i("district-ui-client/subscription-type/manage/students/import-students/controller.ts");});
d("district-ui-client/subscription-type/manage/students/import-students/route", function(){ return i("district-ui-client/subscription-type/manage/students/import-students/route.ts");});
d("district-ui-client/subscription-type/manage/students/import-students/template", function(){ return i("district-ui-client/subscription-type/manage/students/import-students/template.ts");});
d("district-ui-client/subscription-type/manage/students/index/controller", function(){ return i("district-ui-client/subscription-type/manage/students/index/controller.ts");});
d("district-ui-client/subscription-type/manage/students/index/route", function(){ return i("district-ui-client/subscription-type/manage/students/index/route.ts");});
d("district-ui-client/subscription-type/manage/students/index/template", function(){ return i("district-ui-client/subscription-type/manage/students/index/template.ts");});
d("district-ui-client/subscription-type/manage/students/new/controller", function(){ return i("district-ui-client/subscription-type/manage/students/new/controller.ts");});
d("district-ui-client/subscription-type/manage/students/new/route", function(){ return i("district-ui-client/subscription-type/manage/students/new/route.ts");});
d("district-ui-client/subscription-type/manage/students/new/template", function(){ return i("district-ui-client/subscription-type/manage/students/new/template.ts");});
d("district-ui-client/subscription-type/manage/teachers-loading/template", function(){ return i("district-ui-client/subscription-type/manage/teachers-loading/template.ts");});
d("district-ui-client/subscription-type/manage/teachers/add-existing-teachers/route", function(){ return i("district-ui-client/subscription-type/manage/teachers/add-existing-teachers/route.ts");});
d("district-ui-client/subscription-type/manage/teachers/add-existing-teachers/template", function(){ return i("district-ui-client/subscription-type/manage/teachers/add-existing-teachers/template.ts");});
d("district-ui-client/subscription-type/manage/teachers/edit/route", function(){ return i("district-ui-client/subscription-type/manage/teachers/edit/route.ts");});
d("district-ui-client/subscription-type/manage/teachers/edit/template", function(){ return i("district-ui-client/subscription-type/manage/teachers/edit/template.ts");});
d("district-ui-client/subscription-type/manage/teachers/import-teachers/template", function(){ return i("district-ui-client/subscription-type/manage/teachers/import-teachers/template.ts");});
d("district-ui-client/subscription-type/manage/teachers/index/controller", function(){ return i("district-ui-client/subscription-type/manage/teachers/index/controller.ts");});
d("district-ui-client/subscription-type/manage/teachers/index/route", function(){ return i("district-ui-client/subscription-type/manage/teachers/index/route.ts");});
d("district-ui-client/subscription-type/manage/teachers/index/template", function(){ return i("district-ui-client/subscription-type/manage/teachers/index/template.ts");});
d("district-ui-client/subscription-type/manage/teachers/new/controller", function(){ return i("district-ui-client/subscription-type/manage/teachers/new/controller.ts");});
d("district-ui-client/subscription-type/manage/teachers/new/route", function(){ return i("district-ui-client/subscription-type/manage/teachers/new/route.ts");});
d("district-ui-client/subscription-type/manage/teachers/new/template", function(){ return i("district-ui-client/subscription-type/manage/teachers/new/template.ts");});
d("district-ui-client/subscription-type/manage/template", function(){ return i("district-ui-client/subscription-type/manage/template.ts");});
d("district-ui-client/subscription-type/route", function(){ return i("district-ui-client/subscription-type/route.ts");});
d("district-ui-client/subscription-type/support/route", function(){ return i("district-ui-client/subscription-type/support/route.ts");});
d("district-ui-client/subscription-type/support/template", function(){ return i("district-ui-client/subscription-type/support/template.ts");});
d("district-ui-client/subscription-type/template", function(){ return i("district-ui-client/subscription-type/template.ts");});
d("district-ui-client/transforms/boolean", function(){ return i("district-ui-client/transforms/boolean.ts");});
d("district-ui-client/transforms/date", function(){ return i("district-ui-client/transforms/date.ts");});
d("district-ui-client/transforms/number", function(){ return i("district-ui-client/transforms/number.ts");});
d("district-ui-client/transforms/string", function(){ return i("district-ui-client/transforms/string.ts");});
d("district-ui-client/utils/bulk-adapter-utils", function(){ return i("district-ui-client/utils/bulk-adapter-utils.ts");});
d("district-ui-client/utils/bulk-manager", function(){ return i("district-ui-client/utils/bulk-manager.ts");});
d("district-ui-client/utils/clever/clever-school/sync-process-states", function(){ return i("district-ui-client/utils/clever/clever-school/sync-process-states.ts");});
d("district-ui-client/utils/contentful", function(){ return i("district-ui-client/utils/contentful.ts");});
d("district-ui-client/utils/format-duration", function(){ return i("district-ui-client/utils/format-duration.ts");});
d("district-ui-client/utils/format-humanized-duration", function(){ return i("district-ui-client/utils/format-humanized-duration.ts");});
d("district-ui-client/utils/format-value", function(){ return i("district-ui-client/utils/format-value.ts");});
d("district-ui-client/utils/giraffe/average-calculator", function(){ return i("district-ui-client/utils/giraffe/average-calculator.ts");});
d("district-ui-client/utils/giraffe/colour-picker", function(){ return i("district-ui-client/utils/giraffe/colour-picker.js");});
d("district-ui-client/utils/giraffe/convert-date-format", function(){ return i("district-ui-client/utils/giraffe/convert-date-format.ts");});
d("district-ui-client/utils/giraffe/formatter", function(){ return i("district-ui-client/utils/giraffe/formatter.ts");});
d("district-ui-client/utils/giraffe/highcharts-config", function(){ return i("district-ui-client/utils/giraffe/highcharts-config.ts");});
d("district-ui-client/utils/giraffe/highcharts-selectors", function(){ return i("district-ui-client/utils/giraffe/highcharts-selectors.ts");});
d("district-ui-client/utils/giraffe/lexile-grade-map", function(){ return i("district-ui-client/utils/giraffe/lexile-grade-map.ts");});
d("district-ui-client/utils/giraffe/lexile-y-range", function(){ return i("district-ui-client/utils/giraffe/lexile-y-range.ts");});
d("district-ui-client/utils/giraffe/stateful-sort", function(){ return i("district-ui-client/utils/giraffe/stateful-sort.js");});
d("district-ui-client/utils/round", function(){ return i("district-ui-client/utils/round.ts");});
d("district-ui-client/utils/same-content", function(){ return i("district-ui-client/utils/same-content.ts");});
d("district-ui-client/utils/sorting", function(){ return i("district-ui-client/utils/sorting.ts");});
d("district-ui-client/utils/student-events/fetchers/assessment-scores-totals", function(){ return i("district-ui-client/utils/student-events/fetchers/assessment-scores-totals.js");});
d("district-ui-client/utils/student-events/fetchers/assessment-scores", function(){ return i("district-ui-client/utils/student-events/fetchers/assessment-scores.js");});
d("district-ui-client/utils/student-events/fetchers/awards-earned-totals", function(){ return i("district-ui-client/utils/student-events/fetchers/awards-earned-totals.js");});
d("district-ui-client/utils/student-events/fetchers/awards-earned", function(){ return i("district-ui-client/utils/student-events/fetchers/awards-earned.js");});
d("district-ui-client/utils/student-events/fetchers/books-read-by-genre-totals", function(){ return i("district-ui-client/utils/student-events/fetchers/books-read-by-genre-totals.js");});
d("district-ui-client/utils/student-events/fetchers/books-read-by-genre", function(){ return i("district-ui-client/utils/student-events/fetchers/books-read-by-genre.js");});
d("district-ui-client/utils/student-events/fetchers/books-read-by-level-totals", function(){ return i("district-ui-client/utils/student-events/fetchers/books-read-by-level-totals.js");});
d("district-ui-client/utils/student-events/fetchers/books-read-by-level", function(){ return i("district-ui-client/utils/student-events/fetchers/books-read-by-level.js");});
d("district-ui-client/utils/student-events/fetchers/course-progress-averages", function(){ return i("district-ui-client/utils/student-events/fetchers/course-progress-averages.js");});
d("district-ui-client/utils/student-events/fetchers/course-progress-by-grade", function(){ return i("district-ui-client/utils/student-events/fetchers/course-progress-by-grade.js");});
d("district-ui-client/utils/student-events/fetchers/course-progress-totals", function(){ return i("district-ui-client/utils/student-events/fetchers/course-progress-totals.js");});
d("district-ui-client/utils/student-events/fetchers/driving-tests-totals-by-grade-by-month", function(){ return i("district-ui-client/utils/student-events/fetchers/driving-tests-totals-by-grade-by-month.js");});
d("district-ui-client/utils/student-events/fetchers/driving-tests", function(){ return i("district-ui-client/utils/student-events/fetchers/driving-tests.js");});
d("district-ui-client/utils/student-events/fetchers/lexile-details-averages", function(){ return i("district-ui-client/utils/student-events/fetchers/lexile-details-averages.js");});
d("district-ui-client/utils/student-events/fetchers/lexile-growth", function(){ return i("district-ui-client/utils/student-events/fetchers/lexile-growth.js");});
d("district-ui-client/utils/student-events/fetchers/mental-minute-school-overview", function(){ return i("district-ui-client/utils/student-events/fetchers/mental-minute-school-overview.js");});
d("district-ui-client/utils/student-events/fetchers/mental-minute-summary", function(){ return i("district-ui-client/utils/student-events/fetchers/mental-minute-summary.js");});
d("district-ui-client/utils/student-events/fetchers/quiz-scores-totals", function(){ return i("district-ui-client/utils/student-events/fetchers/quiz-scores-totals.js");});
d("district-ui-client/utils/student-events/fetchers/simple-summary", function(){ return i("district-ui-client/utils/student-events/fetchers/simple-summary.js");});
d("district-ui-client/utils/student-events/fetchers/skills-mastered", function(){ return i("district-ui-client/utils/student-events/fetchers/skills-mastered.js");});
d("district-ui-client/utils/student-events/fetchers/stadium-events", function(){ return i("district-ui-client/utils/student-events/fetchers/stadium-events.js");});
d("district-ui-client/utils/student-events/fetchers/stories-written-by-grade", function(){ return i("district-ui-client/utils/student-events/fetchers/stories-written-by-grade.js");});
d("district-ui-client/utils/student-events/fetchers/stories-written-totals-by-grade-by-month", function(){ return i("district-ui-client/utils/student-events/fetchers/stories-written-totals-by-grade-by-month.js");});
d("district-ui-client/utils/student-events/fetchers/storylands-totals-by-grade-by-month", function(){ return i("district-ui-client/utils/student-events/fetchers/storylands-totals-by-grade-by-month.js");});
d("district-ui-client/utils/student-events/fetchers/summary", function(){ return i("district-ui-client/utils/student-events/fetchers/summary.js");});
d("district-ui-client/utils/student-events/fetchers/time-on-task-history", function(){ return i("district-ui-client/utils/student-events/fetchers/time-on-task-history.js");});
d("district-ui-client/utils/student-events/fetchers/time-on-task-usage", function(){ return i("district-ui-client/utils/student-events/fetchers/time-on-task-usage.js");});
d("district-ui-client/utils/student-events/fetchers/total-usage-over-time", function(){ return i("district-ui-client/utils/student-events/fetchers/total-usage-over-time.js");});
d("district-ui-client/utils/student-events/fetchers/working-at-grade-level-totals-by-grade", function(){ return i("district-ui-client/utils/student-events/fetchers/working-at-grade-level-totals-by-grade.js");});
d("district-ui-client/utils/student-events/fetchers/working-at-grade-level", function(){ return i("district-ui-client/utils/student-events/fetchers/working-at-grade-level.js");});
d("district-ui-client/utils/student-events/fill-months", function(){ return i("district-ui-client/utils/student-events/fill-months.js");});
d("district-ui-client/utils/student-events/group-by-grade-position", function(){ return i("district-ui-client/utils/student-events/group-by-grade-position.js");});
d("district-ui-client/utils/student-events/stats-scope", function(){ return i("district-ui-client/utils/student-events/stats-scope.ts");});
d("district-ui-client/utils/themes", function(){ return i("district-ui-client/utils/themes.ts");});
d("district-ui-client/utils/type-guards", function(){ return i("district-ui-client/utils/type-guards.ts");});
d("district-ui-client/utils/uri", function(){ return i("district-ui-client/utils/uri.ts");});
d("district-ui-client/device/bind-viewport-height/modifier", function(){ return i("district-ui-client/device/bind-viewport-height/modifier.js");});
d("district-ui-client/device/detection-classes/modifier", function(){ return i("district-ui-client/device/detection-classes/modifier.js");});
d("district-ui-client/device/detection/service", function(){ return i("district-ui-client/device/detection/service.js");});
d("district-ui-client/device/network/service", function(){ return i("district-ui-client/device/network/service.js");});
d("district-ui-client/device/viewport/service", function(){ return i("district-ui-client/device/viewport/service.js");});
d("district-ui-client/log-adapters/base", function(){ return i("district-ui-client/log-adapters/base.js");});
d("district-ui-client/log-adapters/console", function(){ return i("district-ui-client/log-adapters/console.js");});
d("district-ui-client/log-adapters/rollbar", function(){ return i("district-ui-client/log-adapters/rollbar.js");});
d("district-ui-client/services/log", function(){ return i("district-ui-client/services/log.js");});
d("district-ui-client/event-count-checker/service", function(){ return i("district-ui-client/event-count-checker/service.js");});
d("district-ui-client/long-session-killer/service", function(){ return i("district-ui-client/long-session-killer/service.js");});
d("district-ui-client/refresher/service", function(){ return i("district-ui-client/refresher/service.js");});
d("district-ui-client/release-checker/service", function(){ return i("district-ui-client/release-checker/service.js");});
d("district-ui-client/session-tracker/service", function(){ return i("district-ui-client/session-tracker/service.js");});
d("district-ui-client/services/auth-token", function(){ return i("district-ui-client/services/auth-token.js");});
d("district-ui-client/services/fetcher", function(){ return i("district-ui-client/services/fetcher.js");});
d("district-ui-client/services/data-transformer", function(){ return i("district-ui-client/services/data-transformer.js");});
d("district-ui-client/services/power-calendar", function(){ return i("district-ui-client/services/power-calendar.js");});
d("district-ui-client/test-support/helpers", function(){ return i("district-ui-client/test-support/helpers.js");});
d("district-ui-client/services/date-filter", function(){ return i("district-ui-client/services/date-filter.js");});
d("district-ui-client/index", function(){ return i("district-ui-client/index.js");});
d("district-ui-client/state/base-state", function(){ return i("district-ui-client/state/base-state.js");});
d("district-ui-client/state/multi-select-state", function(){ return i("district-ui-client/state/multi-select-state.js");});
d("district-ui-client/state/select-box-state", function(){ return i("district-ui-client/state/select-box-state.js");});
d("district-ui-client/services/flash-messages", function(){ return i("district-ui-client/services/flash-messages.js");});
d("district-ui-client/services/keyboard", function(){ return i("district-ui-client/services/keyboard.js");});
d("district-ui-client/services/-ensure-registered", function(){ return i("district-ui-client/services/-ensure-registered.js");});
d("district-ui-client/initializers/app-version", function(){ return i("district-ui-client/initializers/app-version.js");});
d("district-ui-client/data-adapter", function(){ return i("district-ui-client/data-adapter.js");});
d("district-ui-client/initializers/ember-data", function(){ return i("district-ui-client/initializers/ember-data.js");});
d("district-ui-client/services/store", function(){ return i("district-ui-client/services/store.js");});
d("district-ui-client/transforms/boolean", function(){ return i("district-ui-client/transforms/boolean.js");});
d("district-ui-client/transforms/date", function(){ return i("district-ui-client/transforms/date.js");});
d("district-ui-client/transforms/number", function(){ return i("district-ui-client/transforms/number.js");});
d("district-ui-client/transforms/string", function(){ return i("district-ui-client/transforms/string.js");});
d("district-ui-client/services/intl", function(){ return i("district-ui-client/services/intl.js");});
d("district-ui-client/metrics-adapters/base", function(){ return i("district-ui-client/metrics-adapters/base.js");});
d("district-ui-client/metrics-adapters/google-tag-manager", function(){ return i("district-ui-client/metrics-adapters/google-tag-manager.js");});
d("district-ui-client/services/metrics", function(){ return i("district-ui-client/services/metrics.js");});
d("district-ui-client/instance-initializers/add-modals-container", function(){ return i("district-ui-client/instance-initializers/add-modals-container.js");});
d("district-ui-client/services/modal-dialog", function(){ return i("district-ui-client/services/modal-dialog.js");});





if (!runningTests) {
  i("../app").default.create({"emberGitSha":"abc","name":"district-ui-client","version":"0.23.5+3798d11c"});
}

