import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { service } from '@ember/service'
import { hash, concat } from '@ember/helper'
import { eq, notEq } from 'ember-truth-helpers'
import { t } from 'ember-intl'
import { trackedFunction } from 'reactiveweb/function'
import type { IntlService } from 'ember-intl'
import { SortableCell } from 'district-ui-client/components/tables/sortable-cell'
import { DurationCell, Interval } from 'district-ui-client/components/tables/duration-cell'
import { formatValue } from 'district-ui-client/utils/format-value'
import colspanMax from 'district-ui-client/modifiers/colspan-max'
import type { ReportingProductSlug } from 'district-ui-client/domain/product'
import type School from 'district-ui-client/models/school'
import {
  serialize as serializeSort,
  deserialize as deserializeSort,
  type SortingConfig,
  type SortComparerKey,
  injectComparerKeys,
} from '@blakeelearning/data-tables/utils/sorting'
import type DataTransformerService from '@blakeelearning/data-tables/services/data-transformer'

export interface SchoolsData {
  school_id: number
  student_count: number
  total_time: number
  lessons_completed: number
  average_total_time_per_student: number
  average_lessons_completed_per_student: number
  average_lexile_growth: number
}

export interface AveragesAndTotalsData {
  average_total_time_per_school: number
  average_lessons_completed_per_school: number
  average_total_time_per_student: number
  average_lessons_completed_per_student: number
  average_lexile_growth: number
  student_count: number
  lessons_completed: number
  total_time: number
}

interface DataItem {
  schoolName: string
  studentCount?: number
  totalTime?: number
  lessonsCompleted?: number
  averageTotalTimePerStudent?: number
  averageLessonsCompletedPerStudent?: number
  averageLexileGrowth?: number
}

interface Signature {
  Args: {
    product: ReportingProductSlug
    schools: School[]
    schoolsData?: SchoolsData[]
    averagesAndTotalsData?: AveragesAndTotalsData
  }
}

export class SummaryTableSchools extends Component<Signature> {
  @service intl!: IntlService

  @service dataTransformer!: DataTransformerService

  @tracked sort = ''

  get sortingConfig() {
    return deserializeSort(this.sort)
  }

  set sortingConfig(newSortConfig) {
    this.sort = serializeSort(newSortConfig)
  }

  onSortClick = (sortingConfig: SortingConfig) => {
    this.sortingConfig = sortingConfig
  }

  get inputRows(): DataItem[] {
    const schools = this.args.schools || []
    const schoolsData = this.args.schoolsData || []

    return schools.map(({ id, name: schoolName }) => {
      const data = schoolsData.find(({ school_id }) => school_id === Number(id))

      if (!data) {
        return { schoolName }
      }

      return {
        schoolName,
        studentCount: data.student_count,
        totalTime: data.total_time,
        lessonsCompleted: data.lessons_completed,
        averageTotalTimePerStudent: data.average_total_time_per_student,
        averageLessonsCompletedPerStudent: data.average_lessons_completed_per_student,
        averageLexileGrowth: data.average_lexile_growth,
      }
    })
  }

  get sortingDescriptor() {
    const comparerKeyMapping: Partial<Record<keyof DataItem, SortComparerKey>> = {
      schoolName: 'sortByValue',
      studentCount: 'sortByNumber',
      totalTime: 'sortByNumber',
      lessonsCompleted: 'sortByNumber',
      averageTotalTimePerStudent: 'sortByNumber',
      averageLessonsCompletedPerStudent: 'sortByNumber',
      averageLexileGrowth: 'sortByNumber',
    }
    const sortingConfigWithComparerKeys = injectComparerKeys(this.sortingConfig, comparerKeyMapping)
    const sortDescriptor = { type: 'sort' as const, config: sortingConfigWithComparerKeys }

    return sortDescriptor
  }

  get pipelineDescriptors() {
    return [this.sortingDescriptor]
  }

  transformedItems = trackedFunction(this, async () => {
    const transformedData = await this.dataTransformer.buildAndTransform<DataItem, DataItem>(
      this.inputRows,
      this.pipelineDescriptors,
    )
    return transformedData.items
  })

  get rows() {
    return this.transformedItems.value ?? []
  }

  get durationConfig() {
    const splitDurationOptions = {
      max: Interval.Hours,
      min: Interval.Minutes,
      maxIntervals: 2,
    }
    const dataInterval = 'seconds'
    return { dataInterval, splitDurationOptions }
  }

  get footerBorder() {
    return this.args.product === 'maths' ? 'border-ms-green-100' : 'border-oceany-blue-100'
  }

  <template>
    <div class="print:text-sm">
      <div class="max-h-[44rem] overflow-auto">
        <table exportable data-test-summary-table class="w-full border-separate border-spacing-0">
          <thead class="print:table-row-group">
            <tr class="[&>th]:sticky [&>th]:top-0">
              <SortableCell
                @sortingConfig={{this.sortingConfig}}
                @onSortClick={{this.onSortClick}}
                @sortKey="schoolName"
                class="table-box table-box-header {{if (eq @product 'maths') 'bg-ms-green-100' 'bg-oceany-blue-100'}}"
              >
                {{t (concat "reporting.district.summaryReportTitles." @product ".schoolName")}}
              </SortableCell>
              <SortableCell
                @sortingConfig={{this.sortingConfig}}
                @onSortClick={{this.onSortClick}}
                @sortKey="studentCount"
                class="table-box table-box-header {{if (eq @product 'maths') 'bg-ms-green-100' 'bg-oceany-blue-100'}}"
              >
                {{t (concat "reporting.district.summaryReportTitles." @product ".studentCount")}}
              </SortableCell>
              <SortableCell
                @sortingConfig={{this.sortingConfig}}
                @onSortClick={{this.onSortClick}}
                @sortKey="totalTime"
                class="table-box table-box-header bg-purply-pink-350 w-[15%] text-white"
              >
                {{t (concat "reporting.district.summaryReportTitles." @product ".totalTime")}}
              </SortableCell>
              <SortableCell
                @sortingConfig={{this.sortingConfig}}
                @onSortClick={{this.onSortClick}}
                @sortKey="lessonsCompleted"
                class="table-box table-box-header bg-juicy-orange-350 w-[15%] text-white"
              >
                {{t (concat "reporting.district.summaryReportTitles." @product ".lessonsCompleted")}}
              </SortableCell>
              <SortableCell
                @sortingConfig={{this.sortingConfig}}
                @onSortClick={{this.onSortClick}}
                @sortKey="averageTotalTimePerStudent"
                class="table-box table-box-header bg-oceany-blue-300 w-[15%] text-white"
              >
                {{t (concat "reporting.district.summaryReportTitles." @product ".averageTotalTimePerStudent")}}
              </SortableCell>
              <SortableCell
                @sortingConfig={{this.sortingConfig}}
                @onSortClick={{this.onSortClick}}
                @sortKey="averageLessonsCompletedPerStudent"
                class="table-box table-box-header bg-ms-green-350 w-[15%] text-white"
              >
                {{t (concat "reporting.district.summaryReportTitles." @product ".averageLessonsCompletedPerStudent")}}
              </SortableCell>
              {{#if (notEq @product "maths")}}
                <SortableCell
                  @sortingConfig={{this.sortingConfig}}
                  @onSortClick={{this.onSortClick}}
                  @sortKey="averageLexileGrowth"
                  class="table-box table-box-header bg-storm-blue-350 w-[15%] text-white"
                >
                  {{t (concat "reporting.district.summaryReportTitles." @product ".averageLexileGrowth")}}
                </SortableCell>
              {{/if}}
            </tr>
          </thead>
          <tbody>
            {{#each this.rows as |row|}}
              <tr
                class="bg-white print:even:bg-white
                  {{if
                    (eq @product 'maths')
                    'even:bg-ms-green-50 hover:bg-ms-green-100'
                    'even:bg-oceany-blue-50 hover:bg-oceany-blue-100'
                  }}"
              >
                <td class="table-box table-box-cell">{{formatValue row.schoolName}}</td>
                <td class="table-box table-box-cell">{{formatValue row.studentCount (hash replaceZero=true)}}</td>
                <DurationCell
                  class="table-box table-box-cell text-center"
                  @durationConfig={{this.durationConfig}}
                  @value={{row.totalTime}}
                />
                <td class="table-box table-box-cell text-center">
                  {{formatValue row.lessonsCompleted (hash replaceZero=true)}}
                </td>
                <DurationCell
                  class="table-box table-box-cell text-center"
                  @durationConfig={{this.durationConfig}}
                  @value={{row.averageTotalTimePerStudent}}
                />
                <td class="table-box table-box-cell text-center">
                  {{formatValue row.averageLessonsCompletedPerStudent (hash replaceZero=true)}}
                </td>
                {{#if (notEq @product "maths")}}
                  <td class="table-box table-box-cell text-center">
                    {{formatValue row.averageLexileGrowth (hash replaceZero=true)}}
                  </td>
                {{/if}}
              </tr>
            {{else}}
              <tr>
                <td class="bg-white p-3 text-center italic" {{colspanMax}}>
                  {{t "tables.fluencySprints.emptyTableMessage"}}
                </td>
              </tr>
            {{/each}}
          </tbody>
          {{#if @averagesAndTotalsData}}
            <tfoot class="print:table-row-group print:border-t-2 print:border-black">
              {{! averages row }}
              <tr data-test-summary-table-averages-row class="[&>td]:sticky [&>td]:bottom-[41px] [&>td]:bg-white">
                <td class="table-footer-cell border-t print:border-black {{this.footerBorder}}">
                  {{t (concat "reporting.district.summaryReportTitles." @product ".average")}}
                </td>
                <td class="table-footer-cell border-t print:border-black {{this.footerBorder}}">
                  {{t "reporting.district.summaryReportTitles.na"}}
                </td>
                <DurationCell
                  class="table-footer-cell border-t text-center print:border-black {{this.footerBorder}}"
                  @durationConfig={{this.durationConfig}}
                  @value={{@averagesAndTotalsData.average_total_time_per_school}}
                />
                <td class="table-footer-cell border-t text-center print:border-black {{this.footerBorder}}">
                  {{formatValue @averagesAndTotalsData.average_lessons_completed_per_school}}
                </td>
                <DurationCell
                  class="table-footer-cell border-t text-center print:border-black {{this.footerBorder}}"
                  @durationConfig={{this.durationConfig}}
                  @value={{@averagesAndTotalsData.average_total_time_per_student}}
                />
                <td class="table-footer-cell border-t text-center print:border-black {{this.footerBorder}}">
                  {{formatValue @averagesAndTotalsData.average_lessons_completed_per_student}}
                </td>
                {{#if (notEq @product "maths")}}
                  <td class="table-footer-cell border-t text-center print:border-black {{this.footerBorder}}">
                    {{formatValue @averagesAndTotalsData.average_lexile_growth}}
                  </td>
                {{/if}}
              </tr>
              {{! totals row }}
              <tr data-test-summary-table-totals-row class="[&>td]:sticky [&>td]:bottom-0 [&>td]:bg-white">
                <td class="table-footer-cell border-t print:border-black {{this.footerBorder}}">
                  {{t (concat "reporting.district.summaryReportTitles." @product ".totals")}}
                </td>
                <td class="table-footer-cell border-t print:border-black {{this.footerBorder}}">
                  {{formatValue @averagesAndTotalsData.student_count}}
                </td>
                <DurationCell
                  class="table-footer-cell border-t text-center print:border-black {{this.footerBorder}}"
                  @durationConfig={{this.durationConfig}}
                  @value={{@averagesAndTotalsData.total_time}}
                />
                <td class="table-footer-cell border-t text-center print:border-black {{this.footerBorder}}">
                  {{formatValue @averagesAndTotalsData.lessons_completed}}
                </td>
                <td class="table-footer-cell border-t text-center print:border-black {{this.footerBorder}}">
                  {{t "reporting.district.summaryReportTitles.na"}}
                </td>
                <td class="table-footer-cell border-t text-center print:border-black {{this.footerBorder}}">
                  {{t "reporting.district.summaryReportTitles.na"}}
                </td>
                {{#if (notEq @product "maths")}}
                  <td class="table-footer-cell border-t text-center print:border-black {{this.footerBorder}}">
                    {{t "reporting.district.summaryReportTitles.na"}}
                  </td>
                {{/if}}
              </tr>
            </tfoot>
          {{/if}}
        </table>
      </div>
      <div
        class="border-t bg-white p-2 text-sm font-bold
          {{if (eq @product 'maths') 'border-ms-green-100' 'border-oceany-blue-100'}}
          print:border-none"
      >
        {{t "reporting.district.summaryReportTitles.footerMessage"}}
      </div>
    </div>
  </template>
}

export default SummaryTableSchools
