import { service } from '@ember/service'
import Component from '@glimmer/component'
import type ActiveRouteService from 'district-ui-client/services/active-route'
import type AlertService from 'district-ui-client/services/alert'
import type GradeSetsService from 'district-ui-client/services/grade-sets'
import type SessionService from 'district-ui-client/services/session'
import type ManageStudentsEditRoute from 'district-ui-client/subscription-type/manage/students/edit/route'
import { tracked } from '@glimmer/tracking'
import { t } from 'ember-intl'
import { ManageHeading } from 'district-ui-client/components/manage-heading'
import { RolloverAlert } from 'district-ui-client/components/rollover-alert'
import Panel from 'district-ui-client/components/panel'
import themeKey from 'district-ui-client/helpers/theme-key'
import eventValue from 'district-ui-client/helpers/event-value'
import { on } from '@ember/modifier'
import { eq } from 'ember-truth-helpers'
import UiButton from 'district-ui-client/components/ui-button'
import RouteTemplate from 'ember-route-template'
import { hasLoginExistsError } from 'district-ui-client/errors/login-already-exists-error'
import { InputText } from 'district-ui-client/components/input-text'
import { BaseSelect } from 'district-ui-client/components/base/select'

interface Signature {
  Args: {
    model: ModelFor<ManageStudentsEditRoute>
  }
}

export class ManageStudentsEditRouteTemplate extends Component<Signature> {
  @service session!: SessionService

  @service alert!: AlertService

  @service gradeSets!: GradeSetsService

  @service activeRoute!: ActiveRouteService

  @tracked updatingStudent = false

  get isCleverDisabled() {
    return this.session.currentDistrict.cleverDisabledManagementFor(this.subscriptionType)
  }

  get subscriptionType() {
    return this.activeRoute.subscriptionType
  }

  get student() {
    return this.args.model.student
  }

  cancel = () => {
    this.student.rollbackAttributes()
    this.backToPrevious()
  }

  updateGrade = (position: string) => {
    this.student.gradePosition = parseInt(position, 10)
  }

  updateStudent = async () => {
    const passwordLength = 4
    const { student } = this

    const plainPassword = student.plainPassword || ''
    const firstName = student.firstName || ''
    const lastName = student.lastName || ''
    const login = student.login || ''

    if (firstName.trim().length === 0) return this.formError('First Name is required')
    if (lastName.trim().length === 0) return this.formError('Last Name is required')
    if (login.trim().length === 0) return this.formError('Login is required')
    if (plainPassword.length < passwordLength) {
      return this.formError(`Password must be at least ${passwordLength} characters`)
    }
    try {
      this.updatingStudent = true
      await student.save()
      this.updatingStudent = false
      this.backToPrevious()
      this.alert.showWithDismiss({
        type: 'positive',
        message: `Successfully updated student ${student.fullName}`,
      })
    } catch (errorPayload: unknown) {
      student.rollbackAttributes()
      this.updatingStudent = false

      if (hasLoginExistsError(errorPayload)) {
        this.formError('Login already exists')
      } else {
        this.formError('An unknown error has occurred')
      }
    }
  }

  formError = (message: string) => {
    this.alert.showWithDismiss({
      type: 'critical',
      message,
    })
  }

  backToPrevious = () => {
    window.history.back()
  }

  setFirstName = (firstName: string) => {
    this.student.firstName = firstName
  }
  setLastName = (lastName: string) => {
    this.student.lastName = lastName
  }
  setLogin = (login: string) => {
    this.student.login = login
  }
  setPassword = (password: string) => {
    this.student.plainPassword = password
  }
  setExternalId = (value: string) => {
    this.student.externalId = value
  }

  <template>
    <ManageHeading>{{t "subscriptionType.manageStudents.edit.manageStudents"}}</ManageHeading>
    <RolloverAlert />
    <div class="mx-auto mt-6 md:w-full lg:w-2/3">
      <Panel
        @theme={{themeKey this.subscriptionType}}
        @title={{t "subscriptionType.manageStudents.edit.editStudentDetails"}}
      >
        <form class="space-y-4">
          <div class="flex items-center justify-between">
            <label for="first-name" class="w-1/4">{{t "subscriptionType.manageStudents.edit.firstName"}}</label>
            <InputText
              id="first-name"
              class="w-3/4"
              disabled={{this.isCleverDisabled}}
              value={{this.student.firstName}}
              {{on "input" (eventValue this.setFirstName)}}
            />
          </div>
          <div class="flex items-center justify-between">
            <label for="last-name" class="w-1/4">{{t "subscriptionType.manageStudents.edit.lastName"}}</label>
            <InputText
              id="last-name"
              class="w-3/4"
              disabled={{this.isCleverDisabled}}
              value={{this.student.lastName}}
              {{on "input" (eventValue this.setLastName)}}
            />
          </div>
          <div class="flex items-center justify-between">
            <label for="grade-selector" class="w-1/4">{{t "subscriptionType.manageStudents.edit.grade"}}</label>
            <BaseSelect
              id="grade-selector"
              class="w-3/4"
              value={{this.student.gradePosition}}
              @themeKey={{themeKey this.subscriptionType}}
              disabled={{this.isCleverDisabled}}
              {{on "input" (eventValue this.updateGrade)}}
            >
              {{#each this.gradeSets.grades as |grade|}}
                <option value={{grade.position}} selected={{eq this.student.gradePosition grade.position}}>
                  {{grade.name}}
                </option>
              {{/each}}
            </BaseSelect>
          </div>
          <div class="flex items-center justify-between">
            <label for="login" class="w-1/4">{{t "subscriptionType.manageStudents.edit.login"}}</label>
            <InputText
              id="login"
              class="w-3/4"
              disabled={{this.isCleverDisabled}}
              value={{this.student.login}}
              {{on "input" (eventValue this.setLogin)}}
            />
          </div>
          <div class="flex items-center justify-between">
            <label for="password" class="w-1/4">{{t "subscriptionType.manageStudents.edit.password"}}</label>
            <InputText
              id="password"
              class="w-3/4"
              disabled={{this.isCleverDisabled}}
              value={{this.student.plainPassword}}
              {{on "input" (eventValue this.setPassword)}}
            />
          </div>
          <div class="flex items-center justify-between">
            <label for="external-id" class="w-1/4">{{t
                "subscriptionType.manageStudents.edit.externalStudentId"
              }}</label>
            <InputText
              id="external-id"
              class="w-3/4"
              value={{this.student.externalId}}
              {{on "input" (eventValue this.setExternalId)}}
            />
          </div>
          <div class="text-right">
            <UiButton id="form-cancel" class="muted" {{on "click" this.cancel}}>{{t
                "subscriptionType.manageStudents.edit.cancel"
              }}</UiButton>
            <UiButton
              id="update-student"
              class="regular"
              disabled={{this.updatingStudent}}
              {{on "click" this.updateStudent}}
            >{{t "subscriptionType.manageStudents.edit.updateStudent"}}</UiButton>
          </div>
        </form>
      </Panel>
    </div>
  </template>
}

export default RouteTemplate(ManageStudentsEditRouteTemplate)
