import type { TOC } from '@ember/component/template-only'
import { eq } from 'ember-truth-helpers'

interface Args {
  themeKey?: string
}

interface Signature {
  Args: Args
  Element: HTMLSelectElement
  Blocks: {
    default: []
  }
}

// {{! A themed select component }}
// {{! Uses native select hence does not support styling of options }}

export const BaseSelect: TOC<Signature> = <template>
  <select
    class="select-caret-icon border-dusty-black-100 text-dusty-black-300 disabled:bg-dusty-black-100 cursor-pointer appearance-none rounded-md border bg-white bg-[length:0.7rem] bg-[right_-1.5rem_center] bg-no-repeat bg-origin-content py-1 pl-1 pr-7 outline-none outline-offset-0 transition-all duration-200 disabled:cursor-not-allowed
      {{unless @themeKey 'focus:outline-dusty-black-300/[0.75]'}}
      {{if (eq @themeKey 'blue') 'focus:border-oceany-blue-400 focus:outline-oceany-blue-300/[0.75]'}}
      {{if (eq @themeKey 'green') 'focus:border-ms-green-400 focus:outline-ms-green-300/[0.75]'}}
      {{if (eq @themeKey 'dark-blue') 'focus:border-wl-blue-250 focus:outline-wl-blue-300/[0.75]'}}
      "
    ...attributes
  >
    {{yield}}
  </select>
</template>

export default BaseSelect
