import type { TOC } from '@ember/component/template-only'

interface Signature {
  Element: HTMLDivElement
  Blocks: {
    default: []
  }
}

export const Lozenge: TOC<Signature> = <template>
  <div
    class="text-pink-350 inline-flex h-6 items-center rounded bg-pink-100 px-1 text-xs font-medium uppercase"
    ...attributes
  >
    {{yield}}
  </div>
</template>
