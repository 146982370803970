import Component from '@glimmer/component'
import { service } from '@ember/service'
import { isEmpty } from '@ember/utils'
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format'
import lexileYRange from 'district-ui-client/utils/giraffe/lexile-y-range'
import {
  gradePositionRangeFromLexiles,
  lexileRangeFromGradePosition,
} from 'district-ui-client/utils/giraffe/lexile-grade-map'
import { reportingPlotLine, reportingLegend } from 'district-ui-client/utils/giraffe/highcharts-config'
import colors from '@blakeelearning/blake-colours/colours'
import type GradeSetsService from 'district-ui-client/services/grade-sets'
import type { IntlService } from 'ember-intl'
import LeftSplitChart from 'district-ui-client/components/left-split-chart'
import { TooltipInfo } from 'district-ui-client/components/tooltip'
import { SummaryBox, SummaryBoxColorScheme } from 'district-ui-client/components/summary-box'
import SeriesLine from 'district-ui-client/components/primitives/series-line/component'
import { t } from 'ember-intl'
import type { Point, SeriesLineOptions, YAxisPlotLinesOptions } from 'highcharts'
import { formatValue } from 'district-ui-client/utils/format-value'
import { isPresent } from '@ember/utils'

/**
 * Puts together all the pieces for the lexile details averages chart.
 */

export interface LexileDetailsAveragesData {
  grade_position: number
  summary: {
    category: string
    count: number
  }[]
  months: {
    lexile_average: Nullable<number>
    month: string
  }[]
}

interface Signature {
  Args: {
    data?: LexileDetailsAveragesData
    product: 're' | 'rex'
    grade: string
    tooltip?: string
  }
  Element: HTMLDivElement
}

export class ChartsLexileDetailsAverages extends Component<Signature> {
  @service gradeSets!: GradeSetsService

  @service intl!: IntlService

  /**
   * Computed list of summary statistics, extracted from the input data.
   * Display lexile growth and average lexile level for the class.
   */
  get summaryStats() {
    const summaryData = this.args.data?.summary ?? []
    const gain = summaryData.find((d) => d.category === 'lexile_gain')?.count ?? 0
    const average = summaryData.find((d) => d.category === 'class_average')?.count

    return [
      { label: 'Class Average Lexile Growth', value: formatValue(gain, { prepend: '+', append: 'L' }) },
      { label: 'Class Average Lexile Level', value: formatValue(average, { append: 'L' }) },
    ]
  }

  /**
   * Computed list of column data, extracted from the input data.
   * Formats month data for Highcharts.
   */
  get chartData(): SeriesLineOptions[] {
    const monthsData = this.args.data?.months ?? []

    const color = colors.oceanyBlue300

    const data = monthsData.map((monthData, index) => ({ x: index, y: monthData.lexile_average, monthData }))
    return [{ name: 'lexile_average', color, data, type: 'line' }]
  }

  /**
   * Computed list of category data, extracted from the input data.
   * Formats month data for Highcharts for use as column labels.
   * Converts month dates into short names.
   */
  get categories() {
    return this.args.data?.months?.map((monthData) => convertDateFormat(monthData.month)) ?? []
  }

  get legend() {
    return reportingLegend('left', 'top', 50, 10)
  }

  /**
   * Plot lines to mark the grade bands for the lexile values. Only applies to YAxis in Series Line chart
   */
  get plotLines(): YAxisPlotLinesOptions[] {
    const { product } = this.args
    const monthsData = this.args.data?.months ?? []
    const unfilteredLexiles = monthsData.map((monthData) => monthData.lexile_average)
    const lexiles = unfilteredLexiles.filter(isPresent)
    if (isEmpty(lexiles)) return []

    const positionPadding = 1 // add extra plotline to chart
    const gradePositionRange = gradePositionRangeFromLexiles(product, lexiles, positionPadding)

    if (isEmpty(gradePositionRange) || !gradePositionRange) return []

    const plotLines = gradePositionRange
      .map((gradePosition) => {
        const lexileRange = lexileRangeFromGradePosition(product, gradePosition)
        if (!lexileRange) return null
        const label = this.gradeSets.findByPosition(gradePosition)?.fullName ?? '-'

        return reportingPlotLine(lexileRange.min, label)
      })
      .filter(isPresent)

    return plotLines
  }

  /**
   * Set the yRange of the chart to include all lessons for all grades in the data
   */
  get yRange(): [number, number] {
    const { product } = this.args
    const monthsData = this.args.data?.months ?? []
    const lexiles = monthsData.map((m) => m.lexile_average).filter(isPresent)
    const [min, max] = lexileYRange(product, lexiles)
    return [min, max]
  }

  /**
   * Set the tooltip of the points in the chart to include the student count
   */
  get toolTipFormatter() {
    const studentCountLabel = this.intl.t('reporting.statBoxes.assessmentScoresTotals.studentCount')

    return function (this: Point) {
      if (this.color !== 'transparent') {
        const { monthData } = this as Point & { monthData: { student_count: number } }
        const studentCount = monthData.student_count

        return `<strong>${this.y}L<br>${studentCountLabel}: ${studentCount}</strong>`
      }
    }
  }

  get chartSpacing(): [number, number, number, number] {
    return [10, 10, 10, 10]
  }

  <template>
    <div data-test-lexile-details-averages class="relative" ...attributes>

      <TooltipInfo class="z-tooltip absolute right-3 top-3 text-xs print:hidden" @text={{@tooltip}} />
      <LeftSplitChart>
        <:left>
          <SummaryBox
            class="h-full"
            @grade={{@grade}}
            @stats={{this.summaryStats}}
            @colorScheme={{SummaryBoxColorScheme.LightBlue}}
          />
        </:left>
        <:right>
          <SeriesLine
            @title={{t "reporting.chartTitles.lexileDetailsAverages"}}
            @data={{this.chartData}}
            @categories={{this.categories}}
            @chartSpacing={{this.chartSpacing}}
            @yAxisLabel="Lexile level"
            @toolTipUnit="L"
            @toolTipFormatter={{this.toolTipFormatter}}
            @plotLines={{this.plotLines}}
            @yRange={{this.yRange}}
          />
        </:right>
      </LeftSplitChart>
    </div>
  </template>
}
export default ChartsLexileDetailsAverages
