import type { TOC } from '@ember/component/template-only'
import { LinkTo } from '@ember/routing'
import { or } from 'ember-truth-helpers'
import { array, hash } from '@ember/helper'

// Usage:
//   <UiButton::LinkTo
//     class="ui-btn-small"
//     @route="route.name"
//     @model={{teacher}}
//     @disabled={{isDisabled}}
//     @corners="rounded rounded-l-none"
//   >
//     abc
//   </UiButton::LinkTo>

// Must define route. model or models is optional.

interface Signature {
  Element: HTMLAnchorElement
  Args: {
    model?: unknown
    models?: unknown[]
    route: string
    corners?: string
    query?: Record<string, unknown>
    disabled?: boolean
  }
  Blocks: {
    default: []
  }
}
export const UiButtonLinkTo: TOC<Signature> = <template>
  {{#if (or (Boolean @model) @models)}}
    <LinkTo
      class="__ui-button__fd7ee {{or @corners 'rounded'}}"
      @route={{@route}}
      @models={{if @models @models (array @model)}}
      @query={{if @query @query (hash)}}
      @disabled={{@disabled}}
      ...attributes
    >
      {{yield}}
    </LinkTo>
  {{else}}
    <LinkTo
      class="__ui-button__fd7ee {{or @corners 'rounded'}}"
      @route={{@route}}
      @query={{if @query @query (hash)}}
      @disabled={{@disabled}}
      ...attributes
    >
      {{yield}}
    </LinkTo>
  {{/if}}
</template>

export default UiButtonLinkTo
