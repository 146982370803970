export const Precinct = {
  RE_READING: 'reading-eggs-reading',
  RE_SPELLING: 'reading-eggs-spelling',
  RE_STORYLANDS: 'reading-eggs-storylands',
  RE_DRIVING_TESTS: 'reading-eggs-driving-tests',
  REX_READING: 'reading-eggspress-reading',
  REX_SPELLING: 'reading-eggspress-spelling',
  REX_LIBRARY: 'reading-eggspress-library',
  FP_PEAKS: 'fast-phonics-peaks',
  MS_LESSONS: 'mathseeds-lessons',
  MS_MENTAL_MINUTE: 'mathseeds-mental-minute',
  MS_DRIVING_TESTS: 'mathseeds-driving-tests',
  MSP_LESSONS: 'mathseeds-prime-lessons',
  WL_WRITING: 'writing-legends-writing',
  WL_EXCITING_WRITING_IDEAS: 'writing-legends-exciting-writing-ideas',
} as const

export type Precinct = ValuesOf<typeof Precinct>

export function isPrecinct(maybePrecinct: unknown): maybePrecinct is Precinct {
  const valid = Object.values<string>(Precinct)
  return typeof maybePrecinct === 'string' && valid.includes(maybePrecinct)
}
