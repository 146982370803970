import Component from '@glimmer/component'
import { service } from '@ember/service'
import { isPresent } from '@ember/utils'
import { themeKeys as statBoxThemes } from 'district-ui-client/components/stat-box'
import type { IntlService } from 'ember-intl'
import type GradeSetsService from 'district-ui-client/services/grade-sets'
import { ReportingDistrictHeader } from 'district-ui-client/components/reporting/district-header'
import { DashboardControls } from 'district-ui-client/components/reporting/dashboard-controls'
import { DynamicDashboardChartWrapper } from 'district-ui-client/components/reporting/dashboard-chart-wrapper/dynamic'
import { StaticDashboardChartWrapper } from 'district-ui-client/components/reporting/dashboard-chart-wrapper/static'
import { not } from 'ember-truth-helpers'
import { t } from 'ember-intl'
import { GiraffeChartsTotalUsageOverTime } from 'district-ui-client/components/charts/dashboard/total-usage-over-time/component'
import { ChartsDashboardCourseProgress } from 'district-ui-client/components/charts/dashboard/course-progress/component'
import { DrivingTestsDashboardChart } from 'district-ui-client/components/charts/dashboard/driving-tests/component'
import { WorkingAtGradeLevelDashboardChart } from 'district-ui-client/components/charts/dashboard/working-at-grade-level/component'
import { SkillsMasteredDashboardChart } from 'district-ui-client/components/charts/dashboard/skills-mastered/component'
import { MentalMinuteDashboardChart } from 'district-ui-client/components/charts/dashboard/mental-minute/component'
import { AwardsEarnedRibbons } from 'district-ui-client/components/charts/dashboard/awards-earned/ribbons'
import RouteTemplate from 'ember-route-template'
import type RouterService from '@ember/routing/router-service'
import type ReportingMathsDashboardRoute from 'district-ui-client/reporting/ui-scope/maths/dashboard/index/route'

interface Signature {
  Args: {
    model: ModelFor<ReportingMathsDashboardRoute>
  }
}

export class DistrictMathsCharts extends Component<Signature> {
  @service intl!: IntlService

  @service gradeSets!: GradeSetsService

  @service router!: RouterService

  goToCategory = (category: string) => {
    const path = {
      lessons: 'course-progress.totals',
      // quizzes: '', // no link/report for this tile yet
      assessments: 'quizzes-completed',
      driving_tests: 'driving-tests',
      mental_minute: 'fluency-sprints.table',
    }[category]

    const { scope: scopeName, id: scopeId } = this.args.model.scope
    if (path) this.router.transitionTo(`reporting.ui-scope.maths.${path}`, scopeName, scopeId)
  }

  get totalUsageBoxes() {
    const { intl } = this
    return [
      {
        category: 'lessons',
        theme: statBoxThemes.THEME_FOREST_GREEN,
      },
      {
        category: 'quizzes',
        tooltip: intl.t('reporting.totalUsageOverTime.tooltips.quizzes'),
        noClickAction: true,
        theme: statBoxThemes.THEME_GRAPEY_GREEN,
      },
      {
        category: 'driving_tests',
        tooltip: intl.t('reporting.totalUsageOverTime.tooltips.drivingTests'),
        theme: statBoxThemes.THEME_OCEANY_BLUE,
      },
      {
        category: 'assessments',
        tooltip: intl.t('reporting.totalUsageOverTime.tooltips.assessments'),
        theme: statBoxThemes.THEME_JUICY_ORANGE,
      },
      {
        category: 'mental_minute',
        tooltip: intl.t('reporting.totalUsageOverTime.tooltips.mentalMinute'),
        theme: statBoxThemes.THEME_MID_ORANGE,
      },
    ]
  }

  get isSummaryDataPresent() {
    return isPresent(this.args.model.totalUsageOverTime)
  }

  /**
   * A mapping of driving test grades (0,1,2) to equivalent grade position names
   */
  get drivingTestGradeNames() {
    return {
      0: this.gradeSets.nameFromGradeSetByPosition(1),
      1: this.gradeSets.nameFromGradeSetByPosition(2),
      2: this.gradeSets.nameFromGradeSetByPosition(3),
    }
  }

  <template>
    <ReportingDistrictHeader />

    <DashboardControls class="my-3" @viewMode="chart" @isDataPresent={{this.isSummaryDataPresent}} />

    <DynamicDashboardChartWrapper
      class="mb-6"
      @empty={{not this.isSummaryDataPresent}}
      @title={{t "reporting.chartTitles.dashboard.totalUsageOverTime"}}
      @tooltip={{t "reporting.tooltips.dashboard.totalUsageOverTime"}}
    >
      {{#if @model.totalUsageOverTime}}
        <GiraffeChartsTotalUsageOverTime
          @product={{@model.product}}
          @boxes={{this.totalUsageBoxes}}
          @data={{@model.totalUsageOverTime}}
          @tileClickAction={{this.goToCategory}}
          @uiScope={{@model.scope.scope}}
        />
      {{/if}}
    </DynamicDashboardChartWrapper>

    <div class="grid w-full auto-rows-[26rem] grid-cols-2 gap-x-7 gap-y-6 xl:grid-cols-3 print:grid-cols-3">

      <StaticDashboardChartWrapper
        @title={{t "reporting.chartTitles.dashboard.courseProgressByGrade.maths"}}
        @tooltip={{t "reporting.tooltips.dashboard.courseProgressByGrade.maths"}}
        @linkPath="reporting.ui-scope.maths.course-progress.averages"
        @empty={{not @model.courseProgressByGrade}}
      >
        {{#if @model.courseProgressByGrade}}
          <ChartsDashboardCourseProgress
            @data={{@model.courseProgressByGrade}}
            @product={{@model.product}}
            @precinct="lessons"
          />
        {{/if}}
      </StaticDashboardChartWrapper>

      <StaticDashboardChartWrapper
        @title={{t "reporting.chartTitles.dashboard.drivingTests"}}
        @tooltip={{t "reporting.tooltips.dashboard.drivingTests"}}
        @linkPath="reporting.ui-scope.maths.driving-tests"
        @empty={{not @model.drivingTests}}
      >
        {{#if @model.drivingTests}}
          <DrivingTestsDashboardChart
            data-test-driving-tests
            @data={{@model.drivingTests}}
            @gradeToShortNameMap={{this.drivingTestGradeNames}}
          />
        {{/if}}

      </StaticDashboardChartWrapper>

      <StaticDashboardChartWrapper
        @title={{t "reporting.chartTitles.dashboard.workingAtGradeLevel"}}
        @tooltip={{t "reporting.tooltips.dashboard.workingAtGradeLevel"}}
        @linkPath="reporting.ui-scope.maths.working-at-grade-level"
        @empty={{not @model.workingAtGradeLevel}}
      >
        {{#if @model.workingAtGradeLevel}}
          <WorkingAtGradeLevelDashboardChart @data={{@model.workingAtGradeLevel}} @product={{@model.product}} />
        {{/if}}
      </StaticDashboardChartWrapper>

      <StaticDashboardChartWrapper
        @title={{t "reporting.chartTitles.dashboard.skillsMastered"}}
        @tooltip={{t "reporting.tooltips.dashboard.skillsMastered"}}
        @empty={{not @model.skillsMastered}}
      >
        {{#if @model.skillsMastered}}
          <SkillsMasteredDashboardChart @data={{@model.skillsMastered}} />
        {{/if}}
      </StaticDashboardChartWrapper>

      <StaticDashboardChartWrapper
        @title={{t "reporting.chartTitles.dashboard.mentalMinuteSummary"}}
        @tooltip={{t "reporting.tooltips.dashboard.mentalMinuteSummary"}}
        @linkPath="reporting.ui-scope.maths.fluency-sprints.table"
        @empty={{not @model.mentalMinuteSummary}}
      >
        {{#if @model.mentalMinuteSummary}}
          <MentalMinuteDashboardChart @data={{@model.mentalMinuteSummary}} />
        {{/if}}
      </StaticDashboardChartWrapper>

      <StaticDashboardChartWrapper
        @title={{t "reporting.chartTitles.dashboard.awardsEarned.maths"}}
        @tooltip={{t "reporting.tooltips.dashboard.awardsEarned.maths" htmlSafe=true}}
        @linkPath="reporting.ui-scope.maths.awards-earned"
        @empty={{not @model.awardsEarned}}
      >
        {{#if @model.awardsEarned}}
          <AwardsEarnedRibbons @data={{@model.awardsEarned}} @withLegend={{true}} />
        {{/if}}
      </StaticDashboardChartWrapper>
    </div>
  </template>
}
export default RouteTemplate(DistrictMathsCharts)
