import type { TOC } from '@ember/component/template-only'
import { t } from 'ember-intl'
import { on } from '@ember/modifier'
import { eventValue } from 'district-ui-client/helpers/event-value'
import { InputText } from 'district-ui-client/components/input-text'

interface Signature {
  Element: HTMLElement
  Args: {
    value?: string
    updateValue(value: string): void
  }
}

export const InputSearch: TOC<Signature> = <template>
  <InputText
    data-test-input-search
    type="search"
    role="searchbox"
    value={{@value}}
    placeholder={{t "search"}}
    aria-label={{t "search"}}
    @icon="search"
    @iconEnd={{true}}
    {{on "input" (eventValue @updateValue)}}
    ...attributes
  />
</template>

export default InputSearch
