import Component from '@glimmer/component'
import ModalDefault from 'district-ui-client/components/modal-default'
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'
import type School from 'district-ui-client/models/school'
import FidgetSpinnerWaveComponent from '@blakeelearning/fidget/components/fidget/spinner/wave'
import { UiButton } from 'district-ui-client/components/ui-button'
import type Teacher from 'district-ui-client/models/teacher'
import { fn } from '@ember/helper'
import { on } from '@ember/modifier'
import { t } from 'ember-intl'

interface Args {
  school: School
  subscriptionType: SubscriptionType
  onClose: () => void
  isSubmitting?: boolean
  onTeacherSelected: (teacher: Teacher) => void
}

interface Signature {
  Args: Args
}

export class ChangeTeacherModal extends Component<Signature> {
  get teachersForSubscriptionType() {
    return this.args.school.teachersForSubscriptionType(this.args.subscriptionType)
  }

  <template>
    <ModalDefault data-test-modal-content="change-teacher" @onClose={{@onClose}} as |modal|>
      <modal.header>
        {{t "manage.students.groupActions.changeTeacher.title"}}
        {{#if @isSubmitting}}
          <div class="ml-1 inline-block align-middle">
            <FidgetSpinnerWaveComponent @small={{true}} />
          </div>
        {{/if}}
      </modal.header>
      <modal.body class="max-h-1/2-screen">
        <div class="stacked-buttons">
          {{#each this.teachersForSubscriptionType as |teacher|}}
            <UiButton
              data-test-teacher-option={{teacher.id}}
              class="muted"
              disabled={{@isSubmitting}}
              {{on "click" (fn @onTeacherSelected teacher)}}
            >
              {{teacher.fullNameReversed}}
            </UiButton>
          {{/each}}
        </div>
      </modal.body>
    </ModalDefault>
  </template>
}

export default ChangeTeacherModal
