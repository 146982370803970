import Component from '@glimmer/component'
import { reportingTitle } from 'district-ui-client/utils/giraffe/highcharts-config'
import BlakeColors from '@blakeelearning/blake-colours/colours'
import BaseHighcharterComponent from 'district-ui-client/components/base/highcharter'
import type {
  PlotOptions,
  DataLabelsOptions,
  SeriesColumnrangeOptions,
  TooltipOptions,
  Options,
  YAxisOptions,
  DataLabelsFormatterCallbackFunction,
} from 'highcharts'

interface Args {
  categories?: string[]
  data: SeriesColumnrangeOptions[]
  dataLabelsEnabled?: boolean
  dataLabelsFormatter?: DataLabelsFormatterCallbackFunction
  toolTip: TooltipOptions
  xAxisLabel?: string
  yAxisFloor?: number
}

interface Signature {
  Element: HTMLDivElement
  Args: Args
}

const disabled = { enabled: false }

export class SeriesColumnRange extends Component<Signature> {
  plotBackgroundColor = BlakeColors.dustyBlack50

  yAxisGridLineColor = BlakeColors.dustyBlack100

  get chartOptions(): Options {
    return {
      chart: {
        type: 'columnrange',
        plotBackgroundColor: this.plotBackgroundColor,
        backgroundColor: 'transparent',
      },
      credits: disabled,
      title: reportingTitle(''),
      yAxis: this.yAxis,
      xAxis: this.xAxis,
      plotOptions: this.plotOptions,
      tooltip: this.args.toolTip,
      legend: this.legend,
    }
  }

  get dataLabelsEnabled() {
    return this.args.dataLabelsEnabled ?? false
  }

  get dataLabelsFormatter() {
    return this.args.dataLabelsFormatter
  }

  get highchartOptions(): Options {
    return { ...this.chartOptions, series: this.args.data }
  }

  get legend() {
    return disabled
  }

  get plotOptions(): PlotOptions {
    const options: PlotOptions = {
      series: {
        dataLabels: this.dataLabels,
      },
    }

    return options
  }

  get dataLabels(): DataLabelsOptions {
    // Only set formatter if it's defined, else it will override highcharts' default and error for charts that enable it
    if (this.args.dataLabelsFormatter) {
      return {
        enabled: this.dataLabelsEnabled,
        formatter: this.args.dataLabelsFormatter,
        style: {
          // Explicitly define this, because otherwise I encountered a highcharts bug where occasionally, some numbers
          // will have a different outline for no reason
          textOutline: 'none',
        },
        /* Allow datalabels to overflow the chart (and don't crop them). Normally, a datalabel when too close to the
         * edge of a chart, would wrap to the next line. This is usually fine, except in the case of a very small
         * column range, where it can look a bit odd - so instead just let it display as slightly outside of chart
         */
        overflow: 'allow',
        crop: false,
      }
    }
    return {
      enabled: this.dataLabelsEnabled,
      style: {
        textOutline: 'none',
      },

      overflow: 'allow',
      crop: false,
    }
  }

  get xAxis() {
    return {
      title: reportingTitle(this.xAxisLabel),
      categories: this.args.categories,
      allowDecimals: false,
      lineColor: BlakeColors.dustyBlack200,
      tickColor: BlakeColors.dustyBlack200,
    }
  }

  get xAxisLabel() {
    return this.args.xAxisLabel ?? ''
  }

  get yAxis(): YAxisOptions {
    return {
      floor: this.yAxisFloor,
      gridLineColor: this.yAxisGridLineColor,
      title: undefined,
    }
  }

  get yAxisFloor() {
    return this.args.yAxisFloor ?? 0
  }

  <template><BaseHighcharterComponent class="h-full" @options={{this.highchartOptions}} ...attributes /></template>
}

export default SeriesColumnRange
