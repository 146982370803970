import { modifier } from 'ember-modifier'

interface Signature {
  Args: {
    Named: unknown
    Positional: [close: () => void]
  }
  Element: HTMLElement
}

export const popoverDropdownKeyboard = modifier<Signature>(
  function popoverDropdownKeyboard(element, [close], _hash) {
    element.addEventListener('keydown', (event: KeyboardEvent) => {
      if (!(event.target instanceof HTMLElement)) return

      switch (event.key) {
        case 'Escape':
          close()
      }
    })
  },
)

export default popoverDropdownKeyboard
