import type { TOC } from '@ember/component/template-only'
interface Signature {
  Element: HTMLDivElement
  Blocks: {
    left: []
    right: []
  }
}
export const LeftSplitChart: TOC<Signature> = <template>
  <div data-test-left-split-chart class="h-full overflow-visible" ...attributes>
    <div
      data-test-left-split-chart="left"
      class="print:border-dusty-black-200 inline-block h-full w-[250px] print:border-r"
    >
      {{yield to="left"}}
    </div>
    <div data-test-left-split-chart="right" class="float-right block h-full w-[calc(100%-250px)] rounded-md bg-white">
      {{yield to="right"}}
    </div>
  </div>
</template>

export default LeftSplitChart
