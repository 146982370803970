import Component from '@glimmer/component'
import BlakeColours from '@blakeelearning/blake-colours/colours'
import PieChart from 'district-ui-client/components/primitives/pie-chart/component'
import { t } from 'ember-intl'
import type { SeriesPieOptions, Point } from 'highcharts'

export interface DrivingTestsData {
  driving_tests_by_grade: {
    grade_level: number
    quiz_completed_percentage: number
  }[]
}

interface Signature {
  Args: {
    data: DrivingTestsData
    gradeToShortNameMap?: Record<number, string | undefined>
  }
  Element: HTMLDivElement
}

export class DrivingTestsDashboardChart extends Component<Signature> {
  get colours() {
    return [BlakeColours.oceanyBlue300, BlakeColours.oceanyBlue100]
  }

  get columnsPerGraph() {
    const testsLength = this.args.data.driving_tests_by_grade.length
    if (!testsLength) {
      return 4
    }
    return 12 / testsLength
  }

  get numberOfPiesClass() {
    const numberOfPies = this.args.data.driving_tests_by_grade.length

    const pieClasses = ['', 'w-full', 'w-1/2', 'w-1/3']

    return pieClasses[numberOfPies]
  }

  get chartInputData(): {
    series: SeriesPieOptions[]
    innerSize: string
    size: string
    subtitle: string
    label: number
  }[] {
    const gradeToShortNameMap = this.args.gradeToShortNameMap || {}
    const mapped = this.args.data.driving_tests_by_grade.map((drivingTest) => {
      const grade = drivingTest.grade_level
      const shortGradeName = gradeToShortNameMap[grade] || '?'
      const completed = drivingTest.quiz_completed_percentage
      const notCompleted = 100 - completed
      const size = this.pieSize
      const series: SeriesPieOptions[] = [
        {
          data: [
            ['completed', completed],
            ['notCompleted', notCompleted],
          ],
          dataLabels: {
            enabled: false,
          },
          type: 'pie',
        },
      ]
      const inputData = {
        series: series,
        innerSize: '80%',
        size,
        subtitle: `<h1>${shortGradeName}</h1>`,
        label: completed,
      }
      return inputData
    })
    return mapped
  }

  get pieSize() {
    const testsLength = this.args.data.driving_tests_by_grade.length
    if (testsLength === 1) {
      return '80%'
    }
    return '100%'
  }

  get tooltip() {
    return {
      enabled: true,
      backgroundColor: BlakeColours.dustyBlack400,
      borderWidth: 0,
      borderRadius: 5,
      formatter: this.tooltipFormatter,
      shadow: false,
      style: { color: BlakeColours.white },
    }
  }

  get tooltipFormatter() {
    return function (this: Point) {
      return `<b>${Math.round(this.y ?? 0)}%</b>`
    }
  }

  <template>
    <div class="flex h-full flex-col" data-test-dashboard-driving-tests ...attributes>
      <div data-test-dashboard-driving-tests-labels class="flex flex-grow items-center">
        {{#each this.chartInputData as |chartData|}}
          <div class="relative top-3 mx-auto mb-1">
            <p
              class="bg-dusty-black-400 text-dusty-black-100 print:!bg-dusty-black-400 print:!text-dusty-black-100 mb-0 flex h-[40px] w-[60px] items-center justify-center rounded print:!rounded"
            >
              {{chartData.label}}
              %
            </p>
            <div
              class="border-y-dusty-black-500 absolute bottom-[-10%] left-[25px] border-x-[5px] border-b-0 border-t-[5px] border-x-transparent"
            ></div>
          </div>
        {{/each}}
      </div>

      <div
        data-test-dashboard-driving-tests-charts
        class="relative flex flex-grow-[5] flex-row items-center justify-center"
      >
        <div class="absolute inset-0 flex">
          {{! Using display inline block so the below ~ is to avoid whitespace }}
          {{#each this.chartInputData as |chartData|}}
            <div class="{{this.numberOfPiesClass}} relative inline-block h-full">
              <PieChart
                @data={{chartData.series}}
                @subtitle={{chartData.subtitle}}
                @colours={{this.colours}}
                @size={{chartData.size}}
                @innerSize={{chartData.innerSize}}
                @tooltip={{this.tooltip}}
              />
            </div>
          {{/each}}
        </div>
      </div>

      <div data-test-dashboard-driving-tests-legend class="flex flex-grow items-center justify-around">
        <div data-test-dashboard-driving-tests-legend-item="completed" class="flex flex-row items-center">
          <div
            class="bg-oceany-blue-300 print:!bg-oceany-blue-300 m-0.5 h-[12px] w-[12px] rounded-full print:!rounded-full"
          >
          </div>
          <div class="text-dusty-black-500">
            {{t "reporting.charts.dashboard.drivingTests.completed"}}
          </div>
        </div>

        <div data-test-dashboard-driving-tests-legend-item="not-completed" class="flex flex-row items-center">
          <div
            class="bg-oceany-blue-200 print:!bg-oceany-blue-200 m-0.5 h-[12px] w-[12px] rounded-full print:!rounded-full"
          >
          </div>
          <div class="text-dusty-black-500">
            {{t "reporting.charts.dashboard.drivingTests.notCompleted"}}
          </div>
        </div>
      </div>
    </div>
  </template>
}

export default DrivingTestsDashboardChart
