import { Panel } from 'district-ui-client/components/panel'
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'
import themeKey from 'district-ui-client/helpers/theme-key'
import { t } from 'ember-intl'
import { Uploader } from 'district-ui-client/components/csv/uploader'
import { ImportPanelStudentUnexpectedError } from 'district-ui-client/components/import-panel-student/unexpected-error/component'
import FidgetLoadingIndicatorComponent from '@blakeelearning/fidget/components/fidget/loading-indicator'
import {
  ImportPanelStudentConfirm,
  type CandidateStudent,
} from 'district-ui-client/components/import-panel-student/confirm/component'
import {
  ImportPanelStudentErrors,
  type StudentImportError,
} from 'district-ui-client/components/import-panel-student/errors/component'
import { and } from 'ember-truth-helpers'
import type { TOC } from '@ember/component/template-only'

export interface TaskInstanceState {
  isUploading?: boolean
  uploadComplete?: boolean
  uploadProgress?: number
  isImportSuccessful: boolean
  unexpectedError: boolean
  unexpectedErrorData: { code: unknown; status: unknown; jobId: unknown }
  isValidating?: boolean
  isValid?: boolean
  filename?: string
  isSubmitting?: boolean
  candidateStudents?: CandidateStudent[]
  validationErrors?: StudentImportError[]
  submitCsv: () => void
  cancelTask: () => void
}

interface Signature {
  Element: HTMLDivElement
  Args: {
    subscriptionType: SubscriptionType
    uploadAction: (file: File) => unknown
    state: Nullable<TaskInstanceState>
    cancelAction: () => void
    submitAction: () => void
  }
}

export const ImportPanelStudent: TOC<Signature> = <template>
  <div class="w-full" ...attributes>
    <Panel
      data-test-import-panel
      @theme={{themeKey @subscriptionType}}
      @title={{t "fileImporter.headerTitle" uploadType="CSV"}}
    >
      <Uploader
        @uploadAction={{@uploadAction}}
        @uploading={{@state.isUploading}}
        @uploadComplete={{@state.uploadComplete}}
        @uploadProgress={{@state.uploadProgress}}
      />
      <div data-test-import-complete={{@state.isImportSuccessful}}>
        {{#if (and @state.unexpectedError @state.unexpectedErrorData)}}
          {{#if @state.unexpectedErrorData}}
            <ImportPanelStudentUnexpectedError @error={{@state.unexpectedErrorData}} />
          {{/if}}
        {{else}}
          {{#if @state.isValidating}}
            <div class="mt-5">
              <FidgetLoadingIndicatorComponent
                @show={{true}}
                @overlay={{false}}
                @centered={{true}}
                @loadingText={{t "fileImporter.loadingText"}}
              />
            </div>
          {{else if @state.isValid}}
            <div class="mt-5" data-test-valid-upload>
              {{#unless @state.isImportSuccessful}}
                {{#if @state.candidateStudents}}
                  {{#if @state.filename}}
                    <ImportPanelStudentConfirm
                      @candidateStudents={{@state.candidateStudents}}
                      @filename={{@state.filename}}
                      @isSubmitting={{@state.isSubmitting}}
                      @cancelAction={{@cancelAction}}
                      @submitAction={{@submitAction}}
                    />
                  {{/if}}
                {{/if}}
              {{/unless}}
            </div>
          {{/if}}
          {{#if @state.validationErrors}}
            <ImportPanelStudentErrors @messages={{@state.validationErrors}} />
          {{/if}}
        {{/if}}
      </div>
    </Panel>
  </div>
</template>

export default ImportPanelStudent
