import { service } from '@ember/service'
import Component from '@glimmer/component'
import type FlashQueueService from 'district-ui-client/services/flash-queue'
import { Toast } from 'district-ui-client/components/toast'

/**
 * Uses the data from the FlashQueue service to render a list of Toast components.
 */
interface Signature {
  Element: Element
}

export class Toaster extends Component<Signature> {
  @service flashQueue!: FlashQueueService

  <template>
    <flash-queue class="z-flash pointer-events-none space-y-3 print:hidden" ...attributes>
      {{#each this.flashQueue.queue as |flashObject|}}
        <Toast @flash={{flashObject}} />
      {{/each}}
    </flash-queue>
  </template>
}

export default Toaster

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Toaster: typeof Toaster
  }
}
