import templateOnly from '@ember/component/template-only'
import type { ComponentLike } from '@glint/template'
import type { DropdownActions } from 'ember-basic-dropdown/components/basic-dropdown'
import type { BasicDropdownContentSignature } from 'ember-basic-dropdown/components/basic-dropdown-content'

export interface Signature {
  Element: HTMLElement
  Args: {
    dropContent: ComponentLike<BasicDropdownContentSignature>
    close: DropdownActions['close']
  }
  Blocks: {
    default: []
  }
}
export default templateOnly<Signature>()
