import { service } from '@ember/service'
import Component from '@glimmer/component'
import { StatBox, themeKeys as statBoxThemes } from 'district-ui-client/components/stat-box'
import type { IntlService } from 'ember-intl'
import { formatValue } from 'district-ui-client/utils/format-value'

export interface MentalMinuteData {
  summary?: {
    addition: {
      sprints_mastered: number
      improvement: number
    }
    subtraction: {
      sprints_mastered: number
      improvement: number
    }
    multiplication: {
      sprints_mastered: number
      improvement: number
    }
    division: {
      sprints_mastered: number
      improvement: number
    }
  }
}

interface Signature {
  Args: {
    data: MentalMinuteData
  }
  Element: HTMLDivElement
}

const themes = {
  addition: statBoxThemes.THEME_OCEANY_BLUE,
  subtraction: statBoxThemes.THEME_JUICY_ORANGE,
  multiplication: statBoxThemes.THEME_MS_GREEN,
  division: statBoxThemes.THEME_PURPLY_PINK,
}

export class MentalMinuteDashboardChart extends Component<Signature> {
  get summaryData() {
    return this.args.data.summary
  }

  @service intl!: IntlService

  get categorySummaries() {
    const categories = ['addition', 'subtraction', 'multiplication', 'division'] as const

    return categories.map((category) => {
      const categoryData = this.summaryData?.[category]
      const title = this.intl.t(`reporting.charts.dashboard.mentalMinute.categories.${category}`)

      const sprintsStatName = this.intl.t(`reporting.charts.dashboard.mentalMinute.stats.sprintsMastered`)
      const sprintsStatValue = formatValue(categoryData?.sprints_mastered)
      const sprintsStat = { name: sprintsStatName, value: sprintsStatValue }

      const improvementStatName = this.intl.t(`reporting.charts.dashboard.mentalMinute.stats.improvement`)
      const improvementStatValue = formatValue(categoryData?.improvement, { append: '%' })
      const improvementStat = { name: improvementStatName, value: improvementStatValue }

      const stats = [[sprintsStat], [improvementStat]]

      const theme = themes[category]

      return { title, theme, category, stats }
    })
  }

  <template>
    <div class="flex h-full flex-wrap items-stretch" data-test-mental-minute ...attributes>
      {{#each this.categorySummaries as |categorySummary|}}
        <StatBox
          data-test-stat-box={{categorySummary.category}}
          class="w-1/2 [&_.header]:basis-[30px] [&_.stat]:p-0 [&_.stats]:min-h-[calc(100%-30px)]"
          @title={{categorySummary.title}}
          @theme={{categorySummary.theme}}
          @stats={{categorySummary.stats}}
        />
      {{/each}}
    </div>
  </template>
}

export default MentalMinuteDashboardChart
