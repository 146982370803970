import type { TOC } from '@ember/component/template-only'
import { on } from '@ember/modifier'
import { UiButton } from 'district-ui-client/components/ui-button'
import { UiButtonState } from 'district-ui-client/components/ui-button/state'
import { or } from 'ember-truth-helpers'

interface Signature {
  Args: {
    negativeAction?: () => void
    negativeButtonText?: string
    positiveAction?: () => void
    positiveButtonText: string
    isTaskLoading?: boolean
    positiveButtonAppearance?: string
    positiveButtonDisabled?: boolean
  }
  Element: HTMLDivElement
  Blocks: {
    default: []
  }
}

export const CleverUiConfirmDialog: TOC<Signature> = <template>
  <div ...attributes>
    <div class="mx-auto my-0 w-full max-w-[32rem] p-7 text-center">
      {{yield}}
    </div>
    <div class="border-t-dusty-black-50 border-t border-solid px-4 pb-0.5 pt-3 text-center">
      {{#if @negativeAction}}
        <UiButton
          data-test-confirm-dialog-negative
          class="muted"
          disabled={{@isTaskLoading}}
          {{on "click" @negativeAction}}
        >
          {{@negativeButtonText}}
        </UiButton>
      {{/if}}
      {{#if @positiveAction}}
        <UiButton
          data-test-confirm-dialog-positive
          class="{{or @positiveButtonAppearance 'regular'}}"
          disabled={{or @isTaskLoading @positiveButtonDisabled}}
          {{on "click" @positiveAction}}
        >
          <UiButtonState @isLoading={{@isTaskLoading}}>{{@positiveButtonText}}</UiButtonState>
        </UiButton>
      {{/if}}
    </div>
  </div>
</template>

export default CleverUiConfirmDialog
