import type { TOC } from '@ember/component/template-only'

interface TabItemSignature {
  Element: HTMLButtonElement
  Args: {
    active?: boolean
  }
  Blocks: {
    default: []
  }
}

export const TabItem: TOC<TabItemSignature> = <template>
  <button
    role="menuitem"
    type="button"
    class="group inline-block min-w-[100px] text-center"
    data-test-tab-item={{if @active "active" "inactive"}}
    ...attributes
  >
    <div class="h-1 w-full" />
    <div
      class="{{if @active 'text-blue-350' 'text-neutral-250'}}
        group-hover:bg-neutral-75 my-3 inline-block rounded-lg px-4 py-2 text-base font-semibold group-focus:bg-neutral-100"
    >{{yield}}</div>
    <div
      class="{{if @active 'bg-blue-350 rounded-t-sm' 'bg-neutral-75'}}
        h-1 w-full transition-colors duration-200 ease-in-out"
    ></div>
  </button>
</template>

export interface TabGroupSignature {
  Element: HTMLElement
  Blocks: {
    default: []
  }
}

export const TabGroup: TOC<TabGroupSignature> = <template>
  <nav role="menubar" class="flex" ...attributes>
    {{yield}}
    <div class="border-neutral-75 grow border-b-4" />
  </nav>
</template>
