import { service } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { HomePage } from 'district-ui-client/components/home-page'
import { RolloverModal } from 'district-ui-client/components/rollover/modal'
import type ActiveRouteService from 'district-ui-client/services/active-route'
import type SessionService from 'district-ui-client/services/session'
import RouteTemplate from 'ember-route-template'

export class SubscriptionTypeIndexRouteTemplate extends Component<unknown> {
  @service activeRoute!: ActiveRouteService

  @service session!: SessionService

  @tracked showRolloverModal = true

  get subscriptionType() {
    return this.activeRoute.subscriptionType
  }

  get currentDistrict() {
    return this.session.currentDistrict
  }

  onCloseRolloverModal = () => {
    this.showRolloverModal = false
  }

  <template>
    <HomePage class="mx-8 my-10 max-w-screen-xl" @district={{this.currentDistrict}} />
    {{#if this.showRolloverModal}}
      <RolloverModal @subscriptionType={{this.subscriptionType}} @onClose={{this.onCloseRolloverModal}} />
    {{/if}}
  </template>
}

export default RouteTemplate(SubscriptionTypeIndexRouteTemplate)
