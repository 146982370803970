import Component from '@glimmer/component'
import { set, action } from '@ember/object'
import { isEmpty } from '@ember/utils'
import { A } from '@ember/array'
import DataTableOperations, { dtoComputed } from '@blakeelearning/data-tables/components/data-table-operations'
import { statefulSort } from 'district-ui-client/utils/giraffe/stateful-sort'
import { tracked } from '@glimmer/tracking'
import type School from 'district-ui-client/models/school'
import { Panel } from 'district-ui-client/components/panel'
import { eq } from 'ember-truth-helpers'
import { on } from '@ember/modifier'
import { fn } from '@ember/helper'
import { t } from 'ember-intl'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import type { IconName } from '@fortawesome/fontawesome-svg-core'
import { formatValue } from 'district-ui-client/utils/format-value'
import formatDuration from 'district-ui-client/helpers/format-duration'
import { BasePageSelector } from 'district-ui-client/components/base/page-selector'

interface SchoolViewModel {
  id: string
  name: string
  students: number
  session_count: number
  total_seconds: number
  average_seconds_per_week: number
  average_seconds_per_session: number
}

export interface TimeOnTaskHistoryData {
  schools: {
    school_id: string
    name: string
    student_count: number
    session_count: number
    total_seconds: number
    average_seconds_per_week: number
    average_seconds_per_session: number
  }[]
  district: {
    student_count: number
    total_seconds: number
    average_seconds_per_week: number
    average_seconds_per_session: number
    session_count: number
  }
}

interface Signature {
  Args: {
    data: TimeOnTaskHistoryData
    schools: School[]
  }
}

type DTOComponentContext = any

export class TimeOnTaskTable extends Component<Signature> {
  currentSortColumn = null

  get districtTotal() {
    const districtTotal = this.args.data.district
    return districtTotal
  }

  get dataOperations() {
    return {
      doPagination: dtoComputed('rows.[]', 'currentPage', 'perPage', function (data: SchoolViewModel[]) {
        // https://github.com/ember-template-imports/ember-template-imports/issues/223
        // @ts-expect-error - transpilation issue with GTS file, cant put this: Context in the function args
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-unsafe-assignment
        const _this = this as DTOComponentContext

        if (isEmpty(_this.currentPage) || isEmpty(_this.perPage)) return data
        const upperIndex = _this.currentPage * _this.perPage
        const lowerIndex = (_this.currentPage - 1) * _this.perPage
        return A(data).slice(lowerIndex, upperIndex)
      }),
    }
  }

  @tracked currentPage: number = 1

  @tracked perPage: number = 10

  @tracked currentSortIcon: IconName = 'sort'

  get pageCount() {
    const dataLength = this.rows?.length
    const { perPage } = this
    return Math.ceil(dataLength / perPage)
  }

  operationsStack(this: DTOComponentContext) {
    return [...this.defaultOperationsStack, 'doPagination']
  }

  get rows() {
    const { schools } = this.args
    const schoolsData = this.args.data.schools
    const rows = schoolsData.reduce<SchoolViewModel[]>((acc, schoolData) => {
      const schoolId = schoolData.school_id
      const school = schools.find((school) => school.id === String(schoolId))
      if (!school) return acc

      const row = {
        id: school.id,
        name: school.name,
        students: schoolData.student_count,
        session_count: schoolData.session_count,
        total_seconds: schoolData.total_seconds,
        average_seconds_per_week: schoolData.average_seconds_per_week,
        average_seconds_per_session: schoolData.average_seconds_per_session,
      }
      return [...acc, row]
    }, [])

    return rows
  }

  statefulSort = (...args: Parameters<typeof statefulSort>) => {
    return statefulSort.apply(this, args)
  }

  @action
  changePage(newPageNumber: number) {
    set(this, 'currentPage', newPageNumber)
  }

  <template>
    <div>
      <Panel>
        <DataTableOperations
          @data={{this.rows}}
          @dataOperations={{this.dataOperations}}
          @operationsStack={{this.operationsStack}}
          @currentPage={{this.currentPage}}
          @perPage={{this.perPage}}
          as |transformedData tableAction|
        >
          <table data-test-tot-table class="disco-table w-full">
            <thead>
              <tr>
                <th
                  role="button"
                  class={{if (eq this.currentSortColumn "name") "selected"}}
                  {{on "click" (fn this.statefulSort tableAction "sortByValue" "name")}}
                >
                  <div class="flex items-center justify-between">
                    {{t "components.reporting.timeOnTask.table.allSchools"}}
                    <FaIcon @icon={{if (eq this.currentSortColumn "name") this.currentSortIcon "sort"}} />
                  </div>
                </th>
                <th
                  role="button"
                  class={{if (eq this.currentSortColumn "students") "selected"}}
                  {{on "click" (fn this.statefulSort tableAction "sortByNumber" "students")}}
                >
                  <div class="flex items-center justify-between">
                    {{t "components.reporting.timeOnTask.table.students"}}
                    <FaIcon @icon={{if (eq this.currentSortColumn "students") this.currentSortIcon "sort"}} />
                  </div>
                </th>
                <th
                  role="button"
                  class={{if (eq this.currentSortColumn "total_seconds") "selected"}}
                  {{on "click" (fn this.statefulSort tableAction "sortByNumber" "total_seconds")}}
                >
                  <div class="flex items-center justify-between">
                    {{t "components.reporting.timeOnTask.table.totalHours"}}
                    <FaIcon @icon={{if (eq this.currentSortColumn "total_seconds") this.currentSortIcon "sort"}} />
                  </div>
                </th>
                <th
                  role="button"
                  class={{if (eq this.currentSortColumn "average_seconds_per_week") "selected"}}
                  {{on "click" (fn this.statefulSort tableAction "sortByNumber" "average_seconds_per_week")}}
                >
                  <div class="flex items-center justify-between">
                    {{t "components.reporting.timeOnTask.table.avgHoursByWeek"}}
                    <FaIcon
                      @icon={{if (eq this.currentSortColumn "average_seconds_per_week") this.currentSortIcon "sort"}}
                    />
                  </div>
                </th>
                <th
                  role="button"
                  class={{if (eq this.currentSortColumn "session_count") "selected"}}
                  {{on "click" (fn this.statefulSort tableAction "sortByNumber" "session_count")}}
                >
                  <div class="flex items-center justify-between">
                    {{t "components.reporting.timeOnTask.table.totalNumberOfSessions"}}
                    <FaIcon @icon={{if (eq this.currentSortColumn "session_count") this.currentSortIcon "sort"}} />
                  </div>
                </th>
                <th
                  role="button"
                  class={{if (eq this.currentSortColumn "average_seconds_per_session") "selected"}}
                  {{on "click" (fn this.statefulSort tableAction "sortByNumber" "average_seconds_per_session")}}
                >
                  <div class="flex items-center justify-between">
                    {{t "components.reporting.timeOnTask.table.avgTimeInSession"}}
                    <FaIcon
                      @icon={{if (eq this.currentSortColumn "average_seconds_per_session") this.currentSortIcon "sort"}}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="hover:bg-blue-50">
                <td>{{t "components.reporting.timeOnTask.table.total"}}</td>
                <td>
                  {{formatValue this.districtTotal.student_count}}
                </td>
                <td>
                  {{formatDuration this.districtTotal.total_seconds}}
                </td>
                <td>
                  {{formatDuration this.districtTotal.average_seconds_per_week}}
                </td>
                <td>
                  {{formatValue this.districtTotal.session_count}}
                </td>
                <td>
                  {{formatDuration this.districtTotal.average_seconds_per_session}}
                </td>
              </tr>
              {{#each transformedData as |row|}}
                <tr class="hover:bg-blue-50">
                  <td>
                    {{formatValue row.name}}
                  </td>
                  <td>
                    {{formatValue row.students}}
                  </td>
                  <td>
                    {{formatDuration row.total_seconds}}
                  </td>
                  <td>
                    {{formatDuration row.average_seconds_per_week}}
                  </td>
                  <td>
                    {{formatValue row.session_count}}
                  </td>
                  <td>
                    {{formatDuration row.average_seconds_per_session}}
                  </td>
                </tr>
              {{/each}}
            </tbody>
          </table>
        </DataTableOperations>

        {{! Table for export }}
        <div class="hidden">
          <DataTableOperations
            @data={{this.rows}}
            @dataOperations={{this.dataOperations}}
            @operationsStack={{this.operationsStack}}
            as |transformedData|
          >
            <table exportable>
              <thead>
                <tr>
                  <th>{{t "components.reporting.timeOnTask.table.allSchools"}}</th>
                  <th>{{t "components.reporting.timeOnTask.table.students"}}</th>
                  <th>{{t "components.reporting.timeOnTask.table.totalHours"}}</th>
                  <th>{{t "components.reporting.timeOnTask.table.avgHoursByWeek"}}</th>
                  <th>{{t "components.reporting.timeOnTask.table.totalNumberOfSessions"}}</th>
                  <th>{{t "components.reporting.timeOnTask.table.avgTimeInSession"}}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{t "components.reporting.timeOnTask.table.total"}}</td>
                  <td>
                    {{formatValue this.districtTotal.student_count}}
                  </td>
                  <td>
                    {{formatDuration this.districtTotal.total_seconds}}
                  </td>
                  <td>
                    {{formatDuration this.districtTotal.average_seconds_per_week}}
                  </td>
                  <td>
                    {{formatValue this.districtTotal.session_count}}
                  </td>
                  <td>
                    {{formatDuration this.districtTotal.average_seconds_per_session}}
                  </td>
                </tr>
                {{#each transformedData as |row|}}
                  <tr>
                    <td>
                      {{formatValue row.name}}
                    </td>
                    <td>
                      {{formatValue row.students}}
                    </td>
                    <td>
                      {{formatDuration row.total_seconds}}
                    </td>
                    <td>
                      {{formatDuration row.average_seconds_per_week}}
                    </td>
                    <td>
                      {{formatValue row.session_count}}
                    </td>
                    <td>
                      {{formatDuration row.average_seconds_per_session}}
                    </td>
                  </tr>
                {{/each}}
              </tbody>
            </table>
          </DataTableOperations>
        </div>
        <BasePageSelector
          class="mt-2"
          @pageCount={{this.pageCount}}
          @currentPage={{this.currentPage}}
          @changePage={{this.changePage}}
        />
      </Panel>
    </div>
  </template>
}

export default TimeOnTaskTable
