import Component from '@glimmer/component'
import { reportingTitle, reportingToolTip as toolTipConfig } from 'district-ui-client/utils/giraffe/highcharts-config'
import BlakeColours from '@blakeelearning/blake-colours/colours'
import type {
  Options,
  SeriesLineOptions,
  TooltipFormatterCallbackFunction,
  YAxisOptions,
  YAxisPlotLinesOptions,
} from 'highcharts'
import BaseHighcharterComponent from 'district-ui-client/components/base/highcharter'
import type { AxisLabelsFormatterCallbackFunction } from 'highcharts'

/**
 * An ember component interface to a highcharts line chart.
 *
 * Data is in this format:
 *
 * ```
 * [
 *   { name: 'series-0-name', data: [1, 2, 3] },
 *   { name: 'series-1-name', data: [3, 4, 5] },
 * ]
 * ```
 *

 * @example
 *   {{primitives/series-line
 *     data=multiSeriesData
 *     colours=multiSeriesColours
 *     categories=categories
 *     legend=legend
 *  }}
 *
 */

interface Args {
  categories?: string[]
  chartSpacing?: [number, number, number, number]
  data: SeriesLineOptions[]
  plotLines?: YAxisPlotLinesOptions[]
  title?: string
  toolTipFormatter?: TooltipFormatterCallbackFunction
  toolTipUnit?: string
  yAxisLabel?: string
  yAxisLabelFormatter?: AxisLabelsFormatterCallbackFunction
  yRange?: number[]
}

interface Signature {
  Element: HTMLDivElement
  Args: Args
}

const disabled = { enabled: false }

export class SeriesLine extends Component<Signature> {
  get chartOptions(): Options {
    return {
      chart: {
        type: 'line',
        plotBackgroundColor: BlakeColours.dustyBlack50,
        backgroundColor: 'transparent',
        spacing: this.chartSpacing,
      },
      title: this.title,
      yAxis: this.yAxis,
      xAxis: this.xAxis,
      plotOptions: this.plotOptions,
      tooltip: this.toolTip,
      legend: disabled,
    }
  }

  get chartSpacing() {
    return this.args.chartSpacing ?? [10, 10, 10, 10]
  }

  get highchartsOptions(): Highcharts.Options {
    return { ...this.chartOptions, series: this.args.data }
  }

  get plotOptions(): Highcharts.PlotOptions {
    return {
      line: {
        marker: {
          radius: 8,
        },
      },
    }
  }

  get title() {
    if (typeof this.args.title === 'string') {
      return reportingTitle(this.args.title)
    }
    return this.args.title
  }

  get toolTip() {
    const formatter = this.args.toolTipFormatter

    return toolTipConfig('y', this.toolTipUnit, { formatter }) ?? disabled
  }

  get toolTipUnit() {
    return this.args.toolTipUnit ?? ''
  }

  get xAxis() {
    const allowDecimals = false

    const xAxis: Record<string, unknown> = {
      title: reportingTitle(''),
      categories: this.args.categories,
      allowDecimals,
      tickWidth: 1,
      lineColor: BlakeColours.dustyBlack200,
      tickColor: BlakeColours.dustyBlack200,
    }

    return xAxis
  }

  get plotlines() {
    return this.args.plotLines ?? []
  }

  get yAxis(): YAxisOptions {
    const [min, max] = this.yRange

    const allowDecimals = false

    const yAxis: Record<string, unknown> = {
      title: reportingTitle(this.yAxisLabel),
      min,
      max,
      plotLines: this.plotlines,
      plotBands: [],
      allowDecimals,
      gridLineColor: BlakeColours.dustyBlack100,
    }

    if (min) {
      yAxis.startOnTick = false
    }

    if (max) {
      yAxis.endOnTick = false
    }

    if (this.args.yAxisLabelFormatter) {
      yAxis.labels = {
        formatter: this.args.yAxisLabelFormatter,
      }
    }

    return yAxis
  }

  get yAxisLabel() {
    return this.args.yAxisLabel ?? ''
  }

  get yRange() {
    return this.args.yRange ?? []
  }

  <template><BaseHighcharterComponent class="h-full" @options={{this.highchartsOptions}} ...attributes /></template>
}

export default SeriesLine

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Primitives::SeriesLine': typeof SeriesLine
  }
}
