import { library } from '@fortawesome/fontawesome-svg-core'
import { faCloudQuestion, faDiamondExclamation, faSparkles } from '@fortawesome/pro-solid-svg-icons'
import { faFileExcel, faStar as faStarRegular, faStarHalfStroke } from '@fortawesome/free-regular-svg-icons'
import {
  faAngleLeft,
  faAngleRight,
  faAngleDown,
  faAngleUp,
  faSort,
  faSortUp,
  faSortDown,
  faDownload,
  faTriangleExclamation,
  faMagnifyingGlass,
  faCheck,
  faChevronLeft,
  faChevronDown,
  faCircle,
  faCircleCheck,
  faCircleInfo,
  faCircleXmark,
  faCircleNotch,
  faCircleExclamation,
  faCircleQuestion,
  faCircleUser,
  faCircleRight,
  faClock,
  faArrowsRotate,
  faArrowRight,
  faCalendar,
  faClipboard,
  faFolder,
  faFolderOpen,
  faHouse,
  faChartArea,
  faChartLine,
  faChartPie,
  faChartSimple,
  faUser,
  faUserPen,
  faUsers,
  faPowerOff,
  faCaretDown,
  faList,
  faPencil,
  faXmark,
  faRightToBracket,
  faRightFromBracket,
  faLock,
  faTrash,
  faEnvelope,
  faSliders,
  faSchool,
  faListCheck,
  faHeadset,
  faGear,
  faBook,
  faGaugeSimple,
  faStar as faStarSolid,
  faLink,
  faCommentDots,
  faArrowUpRightFromSquare,
  faChalkboardUser,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'

/**
 * If maintaining the list of icons is too tedious, it is possible to import all icons;
 * import * as freeBrandSvgIcons from '@fortawesome/free-brands-svg-icons'
 * library.add(freeBrandSvgIcons['fab']); // option to import all icons of fab
 */

library.add(
  // pro solid
  faCloudQuestion,
  faDiamondExclamation,
  faSparkles,
  // regular
  faFileExcel,
  faStarRegular,
  faStarHalfStroke,
  // solid
  faAngleLeft,
  faAngleRight,
  faAngleDown,
  faAngleUp,
  faSort,
  faSortUp,
  faSortDown,
  faDownload,
  faTriangleExclamation,
  faMagnifyingGlass,
  faCheck,
  faChevronLeft,
  faChevronDown,
  faCircle,
  faCircleCheck,
  faCircleInfo,
  faCircleXmark,
  faCircleNotch,
  faCircleExclamation,
  faCircleQuestion,
  faCircleUser,
  faCircleRight,
  faClock,
  faArrowsRotate,
  faArrowRight,
  faCalendar,
  faClipboard,
  faFolder,
  faFolderOpen,
  faHouse,
  faChartArea,
  faChartLine,
  faChartPie,
  faChartSimple,
  faUser,
  faUserPen,
  faUsers,
  faPowerOff,
  faCaretDown,
  faList,
  faPencil,
  faXmark,
  faRightToBracket,
  faRightFromBracket,
  faLock,
  faTrash,
  faEnvelope,
  faSliders,
  faSchool,
  faListCheck,
  faHeadset,
  faGear,
  faBook,
  faGaugeSimple,
  faStarSolid,
  faLink,
  faCommentDots,
  faArrowUpRightFromSquare,
  faChalkboardUser,
  faPhone,
)
