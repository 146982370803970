import RouteTemplate from 'ember-route-template'
import Component from '@glimmer/component'
import { service } from '@ember/service'
import { use } from 'ember-resources'
import { trackedFunction } from 'reactiveweb/function'
import { keepLatest } from 'reactiveweb/keep-latest'
import { task } from 'ember-concurrency'
import { and } from 'ember-truth-helpers'
import { array } from '@ember/helper'
import type ReStandardsPerformanceController from 'district-ui-client/reporting/ui-scope/re/standards-performance/controller'
import { ReportingPageHeader } from 'district-ui-client/components/reporting/page-header'
import { ReportingScopeHeader } from 'district-ui-client/components/reporting/scope-header'
import type SessionService from 'district-ui-client/services/session'
import type ReportingService from 'district-ui-client/services/reporting'
import { RequestScope, exportFilename } from 'district-ui-client/services/reporting'
import { NoStandardsMessage } from 'district-ui-client/components/no-standards-message'
import { NoReportValue } from 'district-ui-client/components/reporting/no-report-value'
import { StandardsSetUpdatedAlert } from 'district-ui-client/components/standards-set/updated-alert'
import {
  StandardsPerformanceOverview,
  type StandardsPerformanceOverviewReportData,
} from 'district-ui-client/components/reporting/standards-performance/overview'
import {
  StandardsPerformanceResults,
  type StandardsPerformanceResultsReportData,
} from 'district-ui-client/components/reporting/standards-performance/results'
import { ReadingEggsReportPath } from 'district-ui-client/services/report-registry'

interface ReStandardsPerformancePageSignature {
  Args: {
    controller: ReStandardsPerformanceController
  }
}

class ReStandardsPerformancePage extends Component<ReStandardsPerformancePageSignature> {
  @service session!: SessionService

  @service reporting!: ReportingService

  fetchOverviewReport = trackedFunction(this, async () => {
    if (!this.reporting.uiScope || !this.reporting.product) return

    const requestScope = new RequestScope(this.reporting.uiScope)
    const url = this.reporting.buildGravityUrl({
      product: this.reporting.product,
      requestScope,
      reportName: 'standards-performance/overview',
    })
    const query = this.reporting.buildQuery({
      requestScope,
      period: this.reporting.period,
      studentGrade: this.args.controller.studentGrade,
      contentLevel: this.args.controller.contentLevel,
    })
    return await this.reporting.fetchReport<StandardsPerformanceOverviewReportData>(url, query)
  })

  fetchResultsReport = trackedFunction(this, async () => {
    if (!this.reporting.uiScope || !this.reporting.product) return

    const requestScope = new RequestScope(this.reporting.uiScope)
    const url = this.reporting.buildGravityUrl({
      product: this.reporting.product,
      requestScope,
      reportName: 'standards-performance/results',
    })
    const query = this.reporting.buildQuery({
      requestScope,
      sort: this.args.controller.sort,
      page: {
        number: this.args.controller.pageNumber,
        size: this.args.controller.perPage,
      },
      period: this.reporting.period,
      studentGrade: this.args.controller.studentGrade,
      contentLevel: this.args.controller.contentLevel,
    })
    const report = await this.reporting.fetchReport<StandardsPerformanceResultsReportData>(url, query)
    return report?.results.length ? report : undefined
  })

  exportResultsCsv = task({ drop: true }, async () => {
    if (!this.reporting.uiScope || !this.reporting.product) return

    const requestScope = new RequestScope(this.reporting.uiScope)
    const url = this.reporting.buildGravityUrl({
      product: this.reporting.product,
      requestScope,
      reportName: 'standards-performance/results',
    })
    const query = this.reporting.buildQuery({
      requestScope,
      sort: this.args.controller.sort,
      period: this.reporting.period,
      studentGrade: this.args.controller.studentGrade,
      contentLevel: this.args.controller.contentLevel,
    })
    const filename = exportFilename(this.reporting.product, 'standards-performance', '.csv')

    await this.reporting.exportReportCsv(url, query, filename)
  })

  @use
  latestOverviewReport = keepLatest({
    value: () => this.fetchOverviewReport.value,
    when: () => this.fetchOverviewReport.isPending,
  })

  get latestOverviewReportData() {
    return this.latestOverviewReport?.overview.total_lessons_completed ? this.latestOverviewReport : undefined
  }

  @use
  latestResultsReport = keepLatest({
    value: () => this.fetchResultsReport.value,
    when: () => this.fetchResultsReport.isPending,
  }); /* prettier-ignore */ /* prettier-ignore-end */

  get isSingleSchool() {
    return this.reporting.uiScope?.subScopes.length === 1
  }

  <template>
    <ReportingPageHeader class="mb-8" />
    {{#if this.session.currentDistrict.standardsSet}}
      <div class="space-y-6">
        <ReportingScopeHeader />
        <StandardsSetUpdatedAlert
          @reportStandardsSetId={{this.latestOverviewReport.meta.standards_set_id}}
          @currentStandardsSet={{this.session.currentDistrict.standardsSet}}
        />
        {{#if (and this.latestOverviewReportData this.latestResultsReport)}}
          {{#if this.latestOverviewReportData}}
            <StandardsPerformanceOverview
              @reportData={{this.latestOverviewReportData}}
              @isReloading={{this.fetchOverviewReport.isPending}}
              @lessonPerformanceReport={{ReadingEggsReportPath.LessonPerformance}}
            />
          {{/if}}
          {{#if this.latestResultsReport}}
            <StandardsPerformanceResults
              @reportData={{this.latestResultsReport}}
              @isReloading={{this.fetchResultsReport.isPending}}
              @exportCsv={{this.exportResultsCsv}}
              @isSingleSchool={{this.isSingleSchool}}
            />
          {{/if}}
        {{else}}
          <NoReportValue @reportStates={{array this.fetchOverviewReport this.fetchResultsReport}} />
        {{/if}}
      </div>
    {{else}}
      <NoStandardsMessage />
    {{/if}}
  </template>
}

export default RouteTemplate(ReStandardsPerformancePage)
