import Component from '@glimmer/component'
import { get } from '@ember/helper'
import { or } from 'ember-truth-helpers'

/**
 * Figure boxes component. Takes a list of stat objects and display them as a set of boxes.
 */

export interface Stat {
  label: string
  value: string
}

export type FigureBoxTheme = 'blue' | 'green' | 'orange' | 'default'

interface Args {
  figures: Stat[]
  title?: string
  theme?: FigureBoxTheme
}

interface Signature {
  Args: Args
  Element: HTMLDivElement
}

export class FigureBoxes extends Component<Signature> {
  get themes() {
    return {
      blue: 'bg-oceany-blue-300',
      green: 'bg-grapey-green-300',
      orange: 'bg-juicy-orange-300',
      default: 'bg-black',
    }
  }

  <template>
    <div data-test-figure-boxes class="h-full" ...attributes>
      {{#if @title}}
        <div data-test-figure-boxes-title>
          <p class="mb-2 text-base font-bold">
            {{@title}}
          </p>
        </div>
      {{/if}}
      <div class="flex flex-wrap justify-between">
        {{#each @figures as |figure index|}}
          <figure-box
            data-test-figure-box={{index}}
            class="{{get this.themes (or @theme 'default')}}
              print:border-dusty-black-200 mb-4 mr-0.5 flex h-24 min-w-[200px] flex-1 items-center p-2 text-white print:border print:bg-transparent print:text-black"
          >
            <p class="label mb-0 w-full text-base" data-test-figure-label>
              {{figure.label}}
            </p>
            <p class="mb-0 ml-1 text-3xl font-bold" data-test-figure-value>
              {{figure.value}}
            </p>
          </figure-box>
        {{/each}}
      </div>
    </div>
  </template>
}

export default FigureBoxes

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    FigureBoxes: typeof FigureBoxes
  }
}
