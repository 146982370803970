import Component from '@glimmer/component'
import { service } from '@ember/service'
import { reportingLegend } from 'district-ui-client/utils/giraffe/highcharts-config'
import { basicAverage } from 'district-ui-client/utils/giraffe/average-calculator'
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter'
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format'
import colors from '@blakeelearning/blake-colours/colours'
import type { IntlService } from 'ember-intl'
import type { SeriesColumnOptions } from 'highcharts'
import { LeftSplitChart } from 'district-ui-client/components/left-split-chart'
import { SummaryBox, SummaryBoxColorScheme } from 'district-ui-client/components/summary-box'
import { SeriesColumn } from 'district-ui-client/components/primitives/series-column/component'
import { t } from 'ember-intl'
import { TooltipInfo } from 'district-ui-client/components/tooltip'

/**
 * Puts together all the pieces for the books read by level totals chart.
 *
 * Data format:
 *
 * ```
 * {
 *   grade_position: 1,
 *   summary: [
 *     { category: 'total_count', count: 120 },
 *     { category: 'student_count', count: 20 },
 *   ],
 *   months: [
 *     {
 *       '0-300': 10,
 *       '300-500': 20,
 *       '500-800': 40,
 *       '800-2000': 30,
 *       month: '2016-07',
 *     },
 *     {
 *       '0-300': 10,
 *       '300-500': 20,
 *       '500-800': 40,
 *       '800-2000': 30,
 *       month: '2016-08',
 *     },
 *   ],
 * }
 * ```
 */

export interface BooksReadByLevelData {
  grade_position: number
  summary: { category: string; count: number }[]
  months: { '0-300': number; '300-500': number; '500-800': number; '800-2000': number; month: string }[]
}

interface Signature {
  Args: {
    data?: BooksReadByLevelData
    grade?: string
    tooltip?: string
  }
  Element: HTMLDivElement
}

export class ChartsBooksReadByLevelTotals extends Component<Signature> {
  @service intl!: IntlService

  /**
   * Computed list of summary statistics, extracted from the input data.
   * Display total books read and student counts, and average books per student.
   */
  get summaryStats() {
    const summaryData = this.args.data?.summary ?? []
    const total = summaryData.find((d) => d.category === 'total_count')?.count ?? 0
    const studentCount = summaryData.find((d) => d.category === 'student_count')?.count ?? 0
    const average = basicAverage(total, studentCount)

    return [
      { label: this.intl.t('reporting.statBoxes.courseProgress.booksRead'), value: formatNumber(total, 1) },
      {
        label: this.intl.t('reporting.statBoxes.courseProgress.studentCount'),
        value: formatNumber(studentCount, 1),
      },
      {
        label: this.intl.t('reporting.statBoxes.courseProgress.perStudentCount'),
        value: formatNumber(average, 1),
      },
    ]
  }

  /**
   * Computed list of column data, extracted from the input data.
   * Formats month data for Highcharts.
   */
  get columns(): SeriesColumnOptions[] {
    const monthData = this.args.data?.months ?? []

    const levelColors = {
      '0-300': colors.oceanyBlue300,
      '300-500': colors.grapeyGreen300,
      '500-800': colors.juicyOrange300,
      '800-2000': colors.watermelonyRed300,
    }

    const levels = ['0-300', '300-500', '500-800', '800-2000'] as const

    return levels.map((name, i, list) => {
      let label
      if (i === list.length - 1) {
        label = `${name.split('-')[0]}L +`
      } else {
        label = `${name}L`
      }
      const color = levelColors[name]
      const lexileData = monthData.map((monthDatum) => monthDatum?.[name] ?? 0)
      return { name: label, data: lexileData, color, type: 'column' }
    })
  }

  /**
   * Computed list of category data, extracted from the input data.
   * Formats month data for Highcharts for use as column labels.
   * Converts month dates into short names.
   */
  get categories() {
    return this.args.data?.months?.map((m) => convertDateFormat(m.month)) ?? []
  }

  get legend() {
    return reportingLegend('left', 'top', 50, -15)
  }

  <template>
    <div data-test-books-read-by-level-totals class="relative" ...attributes>
      <LeftSplitChart>
        <:left>
          <SummaryBox
            class="h-full"
            @grade={{@grade}}
            @stats={{this.summaryStats}}
            @colorScheme={{SummaryBoxColorScheme.LightBlue}}
          />
        </:left>
        <:right>
          <TooltipInfo class="z-tooltip absolute right-3 top-3 text-xs print:hidden" @text={{@tooltip}} />
          <SeriesColumn
            @title={{t "reporting.chartTitles.booksReadByLevelTotals"}}
            @data={{this.columns}}
            @categories={{this.categories}}
            @stackingStyle="normal"
            @legend={{this.legend}}
            @yAxisReversedStacks={{false}}
            @chartSpacing={{Array 10 10 10 10}}
          />
        </:right>
      </LeftSplitChart>
    </div>
  </template>
}

export default ChartsBooksReadByLevelTotals
