/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/unbound-method */
import type { ArgsFor, PositionalArgs, NamedArgs } from 'ember-modifier'
import Modifier from 'ember-modifier'
import { registerDestructor } from '@ember/destroyable'
import { action } from '@ember/object'
import { assert } from '@ember/debug'
import type Owner from '@ember/owner'
import type { Placement } from '@floating-ui/dom'
import {
  computePosition,
  flip,
  shift,
  offset,
  arrow,
  hide,
  autoUpdate,
} from '@floating-ui/dom'

interface Signature {
  Args: {
    Named: {
      reference: string | HTMLElement
      arrow?: string | HTMLElement
      /** useful for arrow, offset on main axis */
      mainOffset?: number
      placement?: Placement
      /** use this if you want a tooltip to just render how it is, and not respond to events */
      noEvents?: boolean
      /** use this to have a tooltip show on render (but still respond to events) */
      showOnRender?: boolean
      /** provide classname for animating in. Only applied after the first interaction with the tooltip */
      animationInClass?: string | undefined
      /** provide classname for animating out. Only applied after the first interaction with the tooltip */
      animationOutClass?: string | undefined
      [named: string]: unknown
    }
    Positional: unknown[]
  }
  Element: HTMLElement
}

interface FloatOptions {
  placement: Placement
  mainOffset: number
  noEvents?: boolean | undefined
  showOnRender?: boolean | undefined
  animationInClass?: string | undefined
  animationOutClass?: string | undefined
}

const showTooltipStyles = {
  visibility: 'visible',
  opacity: '1',
}

const hideTooltipStyles = {
  visibility: 'hidden',
  opacity: '0',
}

/**
 * Usage:
 * <button id="reference-123">test</button>
 * <span {{popover-tooltip reference="#reference-123"}}>my tooltip</span>
 */
export default class PopoverTooltipModifier extends Modifier<Signature> {
  didSetup = false

  declare tooltipElement: HTMLElement

  declare referenceElement: HTMLElement

  arrowElement?: HTMLElement

  declare floatOptions: FloatOptions

  // True when the tooltip is intended to be showing. This allows the modifier to know when it should re-show the
  // tooltip as its reference element re-enters its viewport.
  shouldShow = false

  isTest = false

  cleanupUpdaters?: () => void

  constructor(owner: Owner, args: ArgsFor<Signature>) {
    super(owner, args)

    // Determine if we're running in a test environment DOM by locating the ember-testing element
    this.isTest = Boolean(document.getElementById('ember-testing'))

    registerDestructor(this, cleanup)
  }

  override modify(
    element: HTMLElement,
    _positional: PositionalArgs<Signature>,
    named: NamedArgs<Signature>,
  ) {
    // Construct the float options from the named arguments, using defaults where needed
    const {
      placement = 'top',
      mainOffset = 0,
      noEvents,
      showOnRender,
      animationInClass,
      animationOutClass,
    } = named
    this.floatOptions = {
      placement,
      mainOffset,
      noEvents,
      showOnRender,
      animationInClass,
      animationOutClass,
    }

    this.tooltipElement = element

    if (named.reference) {
      if (typeof named.reference === 'string') {
        const referenceEl = document.querySelector(named.reference)
        if (referenceEl instanceof HTMLElement)
          this.referenceElement = referenceEl
      } else {
        this.referenceElement = named.reference
      }
    }

    assert('tooltip needs an id for aria', this.tooltipElement.id)
    assert('tooltip needs a reference element', this.referenceElement)

    this.addAriaAttributes(this.tooltipElement, this.referenceElement)

    if (named.arrow) {
      if (typeof named.arrow === 'string') {
        const arrowEl = element.querySelector(named.arrow)
        if (arrowEl instanceof HTMLElement) this.arrowElement = arrowEl
      } else {
        this.arrowElement = named.arrow
      }
    }

    this.setPosition()
  }

  addAriaAttributes(
    tooltipElement: HTMLElement,
    referenceElement: HTMLElement,
  ) {
    if (!referenceElement.getAttribute('aria-describedby')) {
      referenceElement.setAttribute('aria-describedby', tooltipElement.id)
    }
  }

  removeAriaAttributes(
    tooltipElement: HTMLElement,
    referenceElement: HTMLElement,
  ) {
    if (
      referenceElement.getAttribute('aria-describedby') === tooltipElement.id
    ) {
      referenceElement.removeAttribute('aria-describedby')
    }
  }

  @action
  setPosition() {
    const { tooltipElement, referenceElement, arrowElement, floatOptions } =
      this

    // Prepare some middleware - these are extra features beyond basic positioning, that act on the position of the
    // tooltip or provide useful data to act upon. Order is important here.
    const middleware = [offset(floatOptions.mainOffset), flip(), shift()]
    if (arrowElement) middleware.push(arrow({ element: arrowElement }))

    // Qunit's DOM fixture positioning doesn't play nicely with floating-ui's hide() middleware. It thinks the reference
    // is outside of its clipping boundary and so then always hides the tooltip. It's not likely we'll be testing
    // anything to do with hiding a tooltip based on whether its reference is clipped, so we should just disable it in
    // tests.
    // Note that it seems partially(?) due to the transform: scale(0.5) that is applied to #ember-testing - removing that
    // seems to fix the positioning. There have been bugs in floating-ui around transform/scale that are supposedly
    // fixed.
    // https://github.com/floating-ui/floating-ui/issues/376
    // https://github.com/floating-ui/floating-ui/pull/1247
    // https://github.com/floating-ui/floating-ui/pull/1322
    // A different solution is to place the tooltip container element on the body element (outside of ember-testing)
    // but then the tooltip zoom does not match the testing zoom, and is not part of the DOM sandbox that is cleaned up
    if (!this.isTest) middleware.push(hide())

    // Compute the position, then apply it to the tooltip
    void computePosition(referenceElement, tooltipElement, {
      placement: floatOptions.placement,
      middleware,
    }).then(({ x, y, placement, strategy, middlewareData }) => {
      Object.assign(tooltipElement.style, {
        position: strategy,
        left: `${x.toFixed()}px`,
        top: `${y.toFixed()}px`,
      })

      if (arrowElement && middlewareData.arrow) {
        // Use the arrow data made available by the arrow middleware
        const { x: arrowX, y: arrowY } = middlewareData.arrow

        const [side] = placement.split('-')
        const staticSide = {
          top: 'bottom',
          right: 'left',
          bottom: 'top',
          left: 'right',
        }[side ?? 'top']

        if (staticSide) {
          Object.assign(arrowElement.style, {
            left: typeof arrowX === 'number' ? `${arrowX.toFixed()}px` : '',
            top: typeof arrowY === 'number' ? `${arrowY.toFixed()}px` : '',
            right: '',
            bottom: '',
            [staticSide]: '-4px',
          })
        }
      }

      // Hide the tooltip if the reference element is outside its bounding box (eg a scroll) (and reshow when it's back)
      if (middlewareData.hide) {
        if (middlewareData.hide.referenceHidden) {
          Object.assign(tooltipElement.style, hideTooltipStyles)
        } else if (this.shouldShow) {
          // only re-show the item if it _should_ be showing (eg is focused), based on user events. don't want to show
          // tooltip just whenever its reference is scrolled back into view, unless it is meant to be showing.
          Object.assign(tooltipElement.style, showTooltipStyles)
        }
      }

      if (!this.didSetup) {
        if (floatOptions.showOnRender) {
          this.showTooltip()
        } else {
          this.hideTooltip()
        }
        this.applyListeners()
        this.didSetup = true
      }
    })
  }

  applyListeners() {
    // autoUpdate will ensure the tooltips' position is updated on events like scroll, etc.
    // Should only be called _once_, on install. Returns a function to be called on cleanup
    this.cleanupUpdaters = autoUpdate(
      this.referenceElement,
      this.tooltipElement,
      this.setPosition,
    )

    if (!this.floatOptions.noEvents) {
      this.referenceElement.addEventListener(
        'mouseenter',
        this.showTooltipHandler,
      )
      this.referenceElement.addEventListener(
        'mouseleave',
        this.hideTooltipHandler,
      )
      this.referenceElement.addEventListener('focus', this.showTooltipHandler)
      this.referenceElement.addEventListener('blur', this.hideTooltipHandler)
    }
  }

  @action
  showTooltip() {
    this.shouldShow = true
    Object.assign(this.tooltipElement.style, showTooltipStyles)
  }

  @action
  hideTooltip() {
    this.shouldShow = false
    Object.assign(this.tooltipElement.style, hideTooltipStyles)
  }

  // When invokved by a listener, we should both show the tooltip and set/update its position
  @action
  showTooltipHandler() {
    /* Only apply animation classes once user has interacted with the element. This stops animation from occurring for
     * tooltips that are showOnRender */
    if (this.floatOptions.animationInClass) {
      this.tooltipElement.classList.add(this.floatOptions.animationInClass)
    }
    if (this.floatOptions.animationOutClass) {
      this.tooltipElement.classList.remove(this.floatOptions.animationOutClass)
    }

    this.showTooltip()
    this.setPosition()
  }

  @action
  hideTooltipHandler() {
    /* Only apply animation classes once user has interacted with the element. This stops animation from occurring for
     * tooltips that are showOnRender */
    if (this.floatOptions.animationInClass) {
      this.tooltipElement.classList.remove(this.floatOptions.animationInClass)
    }
    if (this.floatOptions.animationOutClass) {
      this.tooltipElement.classList.add(this.floatOptions.animationOutClass)
    }

    this.hideTooltip()
  }
}

function cleanup(instance: PopoverTooltipModifier) {
  if (instance) {
    instance.cleanupUpdaters?.()

    if (instance.referenceElement) {
      instance.removeAriaAttributes(
        instance.tooltipElement,
        instance.referenceElement,
      )

      if (!instance.floatOptions.noEvents) {
        instance.referenceElement?.removeEventListener(
          'mouseenter',
          instance.showTooltipHandler,
        )
        instance.referenceElement?.removeEventListener(
          'mouseleave',
          instance.hideTooltipHandler,
        )
        instance.referenceElement?.removeEventListener(
          'focus',
          instance.showTooltipHandler,
        )
        instance.referenceElement?.removeEventListener(
          'blur',
          instance.hideTooltipHandler,
        )
      }
    }
  }
}
