import Component from '@glimmer/component'
import { service } from '@ember/service'
import type ActiveRouteService from 'district-ui-client/services/active-route'
import { BaseModal } from 'district-ui-client/components/base-modal'
import { or, eq } from 'ember-truth-helpers'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'

interface Signature {
  Args: {
    isShowingModal?: boolean
    onClose: () => void
    size?: string
    type?: string
    title?: string
  }
  Blocks: {
    default: []
  }
}

export class CleverUiModal extends Component<Signature> {
  @service activeRoute!: ActiveRouteService

  <template>
    {{#if @isShowingModal}}
      <BaseModal
        @close={{@onClose}}
        @containerClass="
      __clever-ui__modal__8e4af
      custom-modal
      {{or @size 'default'}}
      {{or @type 'normal'}}
      {{this.activeRoute.subscriptionType}}"
      >
        <div class="header">
          <h2>
            {{@title}}
            {{#if (eq @type "warning")}}
              <FaIcon @icon="circle-info" />
            {{/if}}
          </h2>
        </div>
        {{yield}}
      </BaseModal>
    {{/if}}
  </template>
}

export default CleverUiModal
