// This error page will be rendered inside the application / subscription-type template, it should have a navbar and sidenav
import type { TOC } from '@ember/component/template-only'
import type Controller from '@ember/controller'
import ErrorMessage from 'district-ui-client/components/error-message'
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'
import RouteTemplate from 'ember-route-template'

interface Signature {
  Args: {
    controller: Controller & { subscriptionType: SubscriptionType }
  }
}

export const SubscriptionTypeErrorRouteTemplate: TOC<Signature> = <template>
  <ErrorMessage data-test-subscription-type-error @subscriptionType={{@controller.subscriptionType}} />
</template>

export default RouteTemplate(SubscriptionTypeErrorRouteTemplate)
