import type { TOC } from '@ember/component/template-only'
import { hash } from '@ember/helper'
import { BaseModal } from 'district-ui-client/components/base-modal'
import ModalDefaultHeader from 'district-ui-client/components/modal-default/header'
import ModalDefaultBody from 'district-ui-client/components/modal-default/body'
import { ModalDefaultFooter } from 'district-ui-client/components/modal-default/footer'

interface Signature {
  Element: HTMLDivElement
  Args: {
    onClose: () => unknown
  }
  Blocks: {
    default: [
      {
        header: any
        body: any
        footer: any
      },
    ]
  }
}

export const ModalDefault: TOC<Signature> = <template>
  <BaseModal @close={{@onClose}}>
    <div
      class="border-dusty-black-200 relative rounded-md border border-solid bg-clip-padding outline-0"
      data-test-modal-content
      ...attributes
    >
      {{yield
        (hash
          header=(component ModalDefaultHeader onClose=@onClose)
          body=(component ModalDefaultBody)
          footer=(component ModalDefaultFooter)
        )
      }}
    </div>
  </BaseModal>
</template>

export default ModalDefault
