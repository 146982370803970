import type { TOC } from '@ember/component/template-only'

interface Signature {
  Element: HTMLDivElement
  Blocks: {
    default: []
  }
}

export const PaneHeader: TOC<Signature> = <template>
  <div class="flex content-center items-center justify-between py-2.5 text-sm font-semibold uppercase" ...attributes>
    {{yield}}
  </div>
</template>
