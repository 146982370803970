import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { BaseModal } from 'district-ui-client/components/base-modal'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import { t } from 'ember-intl'
import { on } from '@ember/modifier'
import { get } from '@ember/helper'
import { service } from '@ember/service'
import type FeatureControlService from 'district-ui-client/services/feature-control'
import { type FeatureAccessId } from 'district-ui-client/services/feature-control'
import type FeaturesService from 'district-ui-client/services/features'
import type ImpressionService from 'district-ui-client/services/impression'
import { ThemedButton } from 'district-ui-client/components/themed-button'
import { isBefore } from 'date-fns'
import autoFocus from 'district-ui-client/modifiers/auto-focus'
import { type SafeString } from '@ember/template'
import type SessionService from 'district-ui-client/services/session'

interface FeatureModalSlide {
  title: SafeString | string
  summary: SafeString | string
  image: {
    src: string
    width: number
    height: number
  }
}

export interface FeatureModalItem {
  /** A unique id to use in localstorage to mark if modal has been seen */
  id: string
  /** A feature flag to limit display by */
  featureFlag?: string
  /** A feature access id to limit display by */
  featureAccess?: FeatureAccessId
  /** Some other condition to limit display by */
  conditional?: boolean
  slides: FeatureModalSlide[]
  expiresAt: Date
}

export const impressionId = (item: { id: string }) => `feature-modal:${item.id}`

export class FeatureModal extends Component<unknown> {
  @service featureControl!: FeatureControlService

  @service features!: FeaturesService

  @service impression!: ImpressionService

  @service session!: SessionService

  @tracked slideIndex = 0

  get visibleItem(): FeatureModalItem | undefined {
    const firstActiveItem = this.featureModalItems.find((item) => {
      const isActive = isBefore(new Date(), item.expiresAt)
      const isEnabled = typeof item.featureFlag === 'undefined' || this.features.isEnabled(item.featureFlag)
      const canAccess = typeof item.featureAccess === 'undefined' || this.featureControl.canAccess(item.featureAccess)
      const otherCondition = typeof item.conditional === 'undefined' || item.conditional
      return isActive && isEnabled && canAccess && otherCondition
    })

    /* If the latest feature that meets criteria has been seen, dont show any.
     * We don't want to be showing multiple modals in a row, or older features to the user, each time they login */
    if (!firstActiveItem || this.impression.wasMade(impressionId(firstActiveItem))) return undefined

    return firstActiveItem
  }

  close = () => {
    if (this.visibleItem) this.impression.make(impressionId(this.visibleItem))
  }

  // top-most has priority if user satisfies criteria for multiple modal items
  get featureModalItems(): FeatureModalItem[] {
    return [
      /* recent history / examples */
      // {
      //   id: 'non-amer-standards',
      //   featureAccess: FeatureAccess.Standards,
      //   conditional: !AMER.includes(this.session.currentDisco.countryCode), // AMER have already seen a feature modal for standards
      //   expiresAt: new Date(2025, 1, 1), // feb 1st
      //   slides: [
      //     {
      //       image: { src: image, width: 512, height: 320 },
      //       title: 'New Curriculum and Reports',
      //       summary:
      //         "Select your curriculum to track your districts' performance in our new Curriculum Performance and Lesson Performance reports.",
      //     },
      //   ],
      // },
      // {
      //   id: 'layout-2024-with-standards',
      //   featureAccess: FeatureAccess.Standards,
      //   expiresAt: new Date(2024, 9, 1), // oct 1st
      //   slides: [
      //     {
      //       image: { src: image, width: 512, height: 320 },
      //       title: htmlSafe('New District dashboard now with<br>State Standards settings'),
      //       summary:
      //         'Our new look district dashboard provides you with an improved interface to track district, school and student growth and performance.',
      //     },
      //   ],
      // },
      // {
      //   id: 'layout-2024',
      //   expiresAt: new Date(2024, 9, 1), // oct 1st
      //   slides: [
      //     {
      //       image: { src: image, width: 512, height: 320 },
      //       title: 'Welcome to our new look District dashboard',
      //       summary:
      //         'Our new look district dashboard provides you with an improved interface to track district, school and student growth and performance.',
      //     },
      //   ],
      // },
    ]
  }

  <template>
    {{#if this.visibleItem}}
      <BaseModal @close={{this.close}} @containerClass="w-[32rem]">
        {{! Autofocus modal, rather than submit button, to have it appear unfocussed by default }}
        <div class="relative" data-test-feature-modal={{this.visibleItem.id}} {{autoFocus}}>
          <div
            class="text-pink-350 absolute left-6 top-6 inline-flex cursor-default select-none space-x-1 rounded bg-white/50 px-2 py-1 backdrop-blur-sm"
          >
            <FaIcon @icon="sparkles" class="h-3 w-3 p-0.5" />
            <span class="text-xs font-bold uppercase">{{t "new"}}</span>
          </div>
          <div
            role="button"
            class="absolute right-6 top-6 inline-flex rounded-full bg-white/50 p-1 backdrop-blur-sm hover:bg-white/75 focus:bg-white/75"
            aria-label={{t "close"}}
            {{on "click" this.close}}
            data-test-modal-close
          >
            <FaIcon class="h-4 w-4" @icon="xmark" />
          </div>
          {{#let (get this.visibleItem.slides this.slideIndex) as |currentSlide|}}
            <img
              class="w-full"
              height={{currentSlide.image.height}}
              width={{currentSlide.image.width}}
              src={{currentSlide.image.src}}
              alt={{currentSlide.title}}
            />
            <div class="space-y-4 px-6 pb-2 pt-6 text-center">
              <div class="text-xl font-medium text-neutral-400" data-test-feature-modal-title>
                {{currentSlide.title}}
              </div>
              <div class="text-base" data-test-feature-modal-summary>{{currentSlide.summary}}</div>
            </div>
          {{/let}}
          <div class="px-6 pb-6 pt-4 text-center">
            <ThemedButton data-test-feature-modal-submit @style="discovery" {{on "click" this.close}}>
              {{t "gotIt"}}
            </ThemedButton>
          </div>
        </div>
      </BaseModal>
    {{/if}}
  </template>
}
