import templateOnly from '@ember/component/template-only'
import type { ComponentLike, ModifierLike } from '@glint/template'
import type {
  DropdownContentX,
  DropdownContentY,
} from '@blakeelearning/popovers/components/popover/dropdowns/base'
import type {
  Dropdown,
  DropdownActions,
} from 'ember-basic-dropdown/components/basic-dropdown'
import type { Signature as ContentSignature } from '@blakeelearning/popovers/components/popover/dropdowns/standard/-content'

export interface Signature {
  Args: {
    contentX?: DropdownContentX
    contentY?: DropdownContentY
    disabled?: boolean
    onClose?(): void
    matchTriggerWidth?: boolean
  }
  Blocks: {
    default: [
      {
        makeTrigger: ModifierLike
        content: ComponentLike<Omit<ContentSignature, 'Args'>>
      },
      { isOpen: Dropdown['isOpen'] },
      { close: DropdownActions['close'] },
    ]
  }
}

export default templateOnly<Signature>()
