import type { TOC } from '@ember/component/template-only'
import FidgetLoadingIndicatorComponent from '@blakeelearning/fidget/components/fidget/loading-indicator'
import RouteTemplate from 'ember-route-template'
import { t } from 'ember-intl'

export const ManageTeachersLoadingRouteTemplate: TOC<unknown> = <template>
  <FidgetLoadingIndicatorComponent @show={{true}} @overlay={{false}} @loadingText={{t "loading"}} @centered={{true}} />
</template>

export default RouteTemplate(ManageTeachersLoadingRouteTemplate)
