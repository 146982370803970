import type { TOC } from '@ember/component/template-only'
import { NoDataMessage } from 'district-ui-client/components/no-data-message'

interface Args {
  on: boolean
}

interface Signature {
  Element: HTMLDivElement
  Args: Args
  Blocks: {
    default: unknown[]
  }
}

export const ReportingNoDataMessageIf: TOC<Signature> = <template>
  <div class="block h-full" ...attributes>
    {{#if @on}}
      <NoDataMessage class="m-4" />
    {{else}}
      {{yield}}
    {{/if}}
  </div>
</template>

export default ReportingNoDataMessageIf

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::NoDataMessageIf': typeof ReportingNoDataMessageIf
  }
}
