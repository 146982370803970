import type { TOC } from '@ember/component/template-only'

interface Signature {
  Element: HTMLDivElement
  Blocks: {
    default: []
  }
}

export const ModalDefaultBody: TOC<Signature> = <template>
  <div class="relative overflow-y-auto p-3 text-center" ...attributes>
    {{yield}}
  </div>
</template>

export default ModalDefaultBody
