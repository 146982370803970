import type FileDownload from 'district-ui-client/services/file-download'
import type Store from '@ember-data/store'
import { on } from '@ember/modifier'
import { service } from '@ember/service'
import Component from '@glimmer/component'
import { ImportPanelTeacher } from 'district-ui-client/components/import-panel-teacher'
import { ManageHeading } from 'district-ui-client/components/manage-heading'
import Panel from 'district-ui-client/components/panel'
import { RolloverAlert } from 'district-ui-client/components/rollover-alert'
import UiButton from 'district-ui-client/components/ui-button'
import themeKey from 'district-ui-client/helpers/theme-key'
import type ActiveRouteService from 'district-ui-client/services/active-route'
import { t, type IntlService } from 'ember-intl'
import RouteTemplate from 'ember-route-template'

export class ManageTeachersImportTeachersRouteTemplate extends Component<unknown> {
  @service store!: Store

  @service activeRoute!: ActiveRouteService

  @service fileDownload!: FileDownload

  @service intl!: IntlService

  get subscriptionType() {
    return this.activeRoute.subscriptionType
  }

  /**
   * Generates a sample teacher Import CSV array
   */
  get generateTeacherSampleImportData() {
    const fieldPrefix = 'manage.teachers.sampleCsv.fields'

    const row = [
      this.intl.t(`${fieldPrefix}.firstName`),
      this.intl.t(`${fieldPrefix}.lastName`),
      this.intl.t(`${fieldPrefix}.teacherEmail`),
      this.intl.t(`${fieldPrefix}.trialIndicator`),
      this.intl.t(`${fieldPrefix}.schoolCode`),
    ]

    return Array.from({ length: 4 }, () => row)
  }

  downloadSampleCsv = () => {
    const data = this.generateTeacherSampleImportData
    const filename = this.intl.t('manage.teachers.sampleCsv.filename')
    this.fileDownload.downloadAsCsv(data, { filename })
  }
  <template>
    <ManageHeading>{{t "subscriptionType.manageTeachers.importTeachers.importTeachers"}}</ManageHeading>
    <RolloverAlert />

    <ImportPanelTeacher
      class="my-6"
      @subscriptionType={{this.subscriptionType}}
      @validateCommand="validate_teachers_csv"
      @successCommand="proceed_with_teachers_import"
      @successMessage={{t "fileImporter.successTeacher"}}
    />

    <Panel class="mt-6" @theme={{themeKey this.subscriptionType}}>
      <h2 class="pb-3">{{t "subscriptionType.manageTeachers.importTeachers.howDoIImportTeachers"}}</h2>
      <p>{{t "subscriptionType.manageTeachers.importTeachers.addOneOrMoreNewTeachersTo"}}</p>
      <p>{{t "subscriptionType.manageTeachers.importTeachers.theCsvFileMustBeFormatted"}}</p>

      <div class="pb-3 pt-7">
        <table class="w-full">
          <thead>
            <tr>
              <th>{{t "subscriptionType.manageTeachers.importTeachers.columnA"}}<sup>*</sup>
              </th>
              <th>{{t "subscriptionType.manageTeachers.importTeachers.columnB"}}<sup>*</sup>
              </th>
              <th>{{t "subscriptionType.manageTeachers.importTeachers.columnC"}}<sup>*</sup>
              </th>
              <th>{{t "subscriptionType.manageTeachers.importTeachers.columnD"}}<sup>*</sup>
              </th>
              <th>{{t "subscriptionType.manageTeachers.importTeachers.columnE"}}<sup>*</sup>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{t "subscriptionType.manageTeachers.importTeachers.teacherFirstName"}}</td>
              <td>{{t "subscriptionType.manageTeachers.importTeachers.teacherLastName"}}</td>
              <td>{{t "subscriptionType.manageTeachers.importTeachers.teacherEmail"}}</td>
              <td>{{t "subscriptionType.manageTeachers.importTeachers.trialIndicatoryn"}}</td>
              <td>{{t "subscriptionType.manageTeachers.importTeachers.schoolCode"}}</td>
            </tr>
            <tr>
              <td>{{t "subscriptionType.manageTeachers.importTeachers.teacherFirstName"}}</td>
              <td>{{t "subscriptionType.manageTeachers.importTeachers.teacherLastName"}}</td>
              <td>{{t "subscriptionType.manageTeachers.importTeachers.teacherEmail"}}</td>
              <td>{{t "subscriptionType.manageTeachers.importTeachers.trialIndicatoryn"}}</td>
              <td>{{t "subscriptionType.manageTeachers.importTeachers.schoolCode"}}</td>
            </tr>
            <tr>
              <td>{{t "subscriptionType.manageTeachers.importTeachers.teacherFirstName"}}</td>
              <td>{{t "subscriptionType.manageTeachers.importTeachers.teacherLastName"}}</td>
              <td>{{t "subscriptionType.manageTeachers.importTeachers.teacherEmail"}}</td>
              <td>{{t "subscriptionType.manageTeachers.importTeachers.trialIndicatoryn"}}</td>
              <td>{{t "subscriptionType.manageTeachers.importTeachers.schoolCode"}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <p class="pb-7">
        <small><sup>*</sup>{{t "subscriptionType.manageTeachers.importTeachers.allFieldsAreRequired"}}</small>
      </p>
      <p>
        <UiButton class="regular" {{on "click" this.downloadSampleCsv}} data-test-teacher-sample-csv>
          {{t "subscriptionType.manageTeachers.importTeachers.downloadASampleCsvFile"}}
        </UiButton>
      </p>
    </Panel>
  </template>
}

export default RouteTemplate(ManageTeachersImportTeachersRouteTemplate)
