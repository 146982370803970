import Component from '@glimmer/component'
import { reportingTitle } from 'district-ui-client/utils/giraffe/highcharts-config'
import BlakeColors from '@blakeelearning/blake-colours/colours'
import BaseHighcharterComponent from 'district-ui-client/components/base/highcharter'
import type {
  XAxisOptions,
  Options,
  YAxisOptions,
  PlotOptions,
  SeriesBarOptions,
  OptionsStackingValue,
  ColorString,
} from 'highcharts'

/**
 * An ember component interface to a highcharts bar chart.
 *
 * Data is in this format:
 *
 * ```
 * [
 *   { name: 'series-0-name', data: [1, 2, 3] },
 *   { name: 'series-1-name', data: [3, 4, 5] },
 * ]
 * ```
 *
 *
 * Use 1 series for a bar chart, multiple series for a stacked or grouped
 * bar chart.
 * @example
 *   {{primitives/series-bar
 *     data=multiSeriesData
 *     colours=multiSeriesColours
 *     categories=categories
 *     legend=legend
 *   }}
 */
interface Args {
  borderWidth?: number
  categories?: string[]
  barPadding?: number
  chartMargin?: (number | null)[]
  colorByPoint?: boolean
  colours?: ColorString[]
  data: SeriesBarOptions[]
  groupPadding?: number
  maxPointWidth?: number
  plotBackgroundColour?: string
  stackingStyle?: OptionsStackingValue
  title?: string
  xAxisLabel?: string
  xAxisVisible?: boolean
  yAxis?: YAxisOptions | YAxisOptions[]
  yAxisGridLineWidth?: number
  yAxisLabel?: string
  yAxisLineWidth?: number
  yAxisOffset?: number
  yAxisVisible?: boolean
}

interface Signature {
  Element: HTMLDivElement
  Args: Args
}

export class SeriesBar extends Component<Signature> {
  get chartMargins() {
    if (!this.args.chartMargin) return { margin: undefined }

    const [marginTop, marginRight, marginBottom, marginLeft] = this.args.chartMargin

    return {
      marginTop: marginTop ?? undefined,
      marginRight: marginRight ?? undefined,
      marginBottom: marginBottom ?? undefined,
      marginLeft: marginLeft ?? undefined,
    }
  }

  get chartOptions(): Options {
    const plotBackgroundColor = this.args.plotBackgroundColour ?? BlakeColors.dustyBlack50

    return {
      chart: {
        type: 'bar',
        plotBackgroundColor: plotBackgroundColor,
        backgroundColor: 'transparent',
        spacing: [10, 10, 10, 10],
        ...this.chartMargins,
      },
      credits: { enabled: false },
      title: this.title,
      yAxis: this.yAxis,
      xAxis: this.xAxis,
      colors: this.args.colours,
      plotOptions: this.plotOptions,
      legend: { enabled: false },
    }
  }

  get highchartsOptions(): Options {
    return { ...this.chartOptions, series: this.args.data }
  }

  get plotOptions(): PlotOptions {
    const colorByPoint = this.args.colorByPoint ?? false
    const pointPadding = this.args.barPadding ?? 0.1
    const groupPadding = this.args.groupPadding ?? 0.2
    const borderWidth = this.args.borderWidth ?? 0

    return {
      bar: {
        borderWidth: borderWidth,
        stacking: this.args.stackingStyle,
        groupPadding: groupPadding,
        pointPadding: pointPadding,
        colorByPoint: colorByPoint,
        maxPointWidth: this.args.maxPointWidth,
      },
    }
  }

  get title() {
    const title = this.args.title === undefined ? '' : this.args.title
    if (typeof title === 'string') {
      return reportingTitle(title)
    }
    return title
  }

  get xAxis() {
    const xAxisVisible = this.args.xAxisVisible ?? true

    const xAxis: XAxisOptions = {
      title: this.xAxisLabel,
      categories: this.args.categories,
      visible: xAxisVisible,
      tickLength: 10,
      allowDecimals: false,
      lineColor: BlakeColors.dustyBlack200,
    }

    return xAxis
  }

  get xAxisLabel() {
    if (typeof this.args.xAxisLabel === 'string') return reportingTitle(this.args.xAxisLabel)

    return this.args.xAxisLabel
  }

  get yAxis() {
    if (this.args.yAxis) return this.args.yAxis

    const yAxisVisible = this.args.yAxisVisible ?? true
    const yAxisOffset = this.args.yAxisOffset ?? 0
    const yAxisLabel = this.args.yAxisLabel ?? ''
    const yAxisGridLineWidth = this.args.yAxisGridLineWidth ?? 1
    const yAxisLineWidth = this.args.yAxisLineWidth ?? 0

    const yAxis: YAxisOptions = {
      title: reportingTitle(yAxisLabel),
      visible: yAxisVisible,
      allowDecimals: false,
      offset: yAxisOffset,
      reversedStacks: true,
      lineWidth: yAxisLineWidth,
      gridLineWidth: yAxisGridLineWidth,
      gridLineColor: BlakeColors.dustyBlack100,
    }

    return yAxis
  }

  <template><BaseHighcharterComponent class="h-full" @options={{this.highchartsOptions}} ...attributes /></template>
}

export default SeriesBar
