import type { TOC } from '@ember/component/template-only'
import type SchoolSubscriptionUtilisation from 'district-ui-client/models/school-subscription-utilisation'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import { Tooltip } from 'district-ui-client/components/tooltip'
import { t } from 'ember-intl'

interface Signature {
  Element: HTMLSpanElement
  Args: {
    utilisation?: SchoolSubscriptionUtilisation
  }
}
export const CapacityIndicator: TOC<Signature> = <template>
  <span data-test-table-capacity ...attributes>
    {{#if @utilisation}}
      <span data-test-capacity-label class={{if @utilisation.isOverCapacity "text-juicy-orange-300 font-bold"}}>
        {{@utilisation.formattedCapacity}}
        {{#if @utilisation.isOverCapacity}}
          <span data-test-over-capacity-icon>
            <FaIcon @icon="circle-info" class="text-dusty-black-400" />
            <Tooltip @text={{t "clever.overCapacityTooltip"}} data-test-capacity-tooltip />
          </span>
        {{/if}}
      </span>
    {{else}}
      -
    {{/if}}
  </span>
</template>

export default CapacityIndicator
