import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { t } from 'ember-intl'
import { ModalDefault } from 'district-ui-client/components/modal-default'
import FidgetSpinnerWaveComponent from '@blakeelearning/fidget/components/fidget/spinner/wave'
import { UiButton } from 'district-ui-client/components/ui-button'
import { Tooltip } from 'district-ui-client/components/tooltip'
import { InputText } from 'district-ui-client/components/input-text'
import { on } from '@ember/modifier'
import { fn } from '@ember/helper'

interface Args {
  isSubmitting?: boolean
  onClose: () => void
  onPasswordUpdated: (password: string) => void
}

interface Signature {
  Args: Args
}

export class EditPasswordModal extends Component<Signature> {
  @tracked password = ''

  readonly minLength = 4

  get passwordEmpty() {
    return !this.password
  }

  get passwordInvalid() {
    return this.passwordEmpty || this.password.length < this.minLength
  }

  get submitDisabled() {
    return this.args.isSubmitting || this.passwordEmpty || this.passwordInvalid
  }

  setPassword = (event: Event) => {
    if (!(event.target instanceof HTMLInputElement)) return
    this.password = event.target.value
  }

  <template>
    <ModalDefault data-test-modal-content="edit-password" @onClose={{@onClose}} as |modal|>
      <modal.header>
        {{t "manage.students.groupActions.editPassword.title"}}
        {{#if @isSubmitting}}
          <div class="ml-1 inline-block align-middle">
            <FidgetSpinnerWaveComponent @small={{true}} />
          </div>
        {{/if}}
      </modal.header>
      <modal.body>
        <div class="flex items-stretch justify-around">
          <InputText
            data-test-edit-password-input
            class="w-4/6"
            aria-label={{t "manage.students.groupActions.editPassword.newPassword"}}
            placeholder={{t "manage.students.groupActions.editPassword.placeholder"}}
            disabled={{@isSubmitting}}
            value={{this.password}}
            {{on "input" this.setPassword}}
          />
          <div class="w-1/6">
            <span>
              <UiButton
                class="cyan"
                disabled={{this.submitDisabled}}
                {{on "click" (fn @onPasswordUpdated this.password)}}
                data-test-submit-password
              >
                {{t "manage.students.groupActions.editPassword.submitButton"}}
              </UiButton>
              {{#if this.passwordEmpty}}
                <Tooltip @text={{t "manage.students.groupActions.editPassword.tooltip.passwordEmpty"}} />
              {{else if this.passwordInvalid}}
                <Tooltip
                  @text={{t "manage.students.groupActions.editPassword.tooltip.passwordInvalid" number=this.minLength}}
                />
              {{/if}}
            </span>
          </div>
        </div>
      </modal.body>
    </ModalDefault>
  </template>
}

export default EditPasswordModal

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Modals::EditPassword': typeof EditPasswordModal
  }
}
