import Component from '@glimmer/component'
import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper'
import { FluencySprintsSchoolOverview } from 'district-ui-client/components/tables/fluency-sprints/school-overview/component'
import type ReportingMsFluencySprintsTableRoute from 'district-ui-client/reporting/ui-scope/maths/fluency-sprints/table/route'
import { not } from 'ember-truth-helpers'
import type ReportingMsFluencySprintsTableController from 'district-ui-client/reporting/ui-scope/maths/fluency-sprints/table/controller'
import RouteTemplate from 'ember-route-template'
import { isPresent } from '@ember/utils'

interface Signature {
  Args: {
    model: ModelFor<ReportingMsFluencySprintsTableRoute>
    controller: ReportingMsFluencySprintsTableController
  }
}

export class ReportingMsFluencySprintsTableRouteTemplate extends Component<Signature> {
  get isDataPresent() {
    return isPresent(this.args.model.mentalMinuteSchoolOverview)
  }

  <template>
    <DetailedPageWrapper @empty={{not this.isDataPresent}} @viewMode="table">

      <FluencySprintsSchoolOverview
        @schools={{@model.mentalMinuteSchoolOverview.schools}}
        @aggregate={{@model.mentalMinuteSchoolOverview.aggregate}}
        @onSortClick={{@controller.updateSorts}}
        @sortingConfig={{@controller.sortingConfig}}
      />
    </DetailedPageWrapper>
  </template>
}

export default RouteTemplate(ReportingMsFluencySprintsTableRouteTemplate)
