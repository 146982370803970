import Component from '@glimmer/component'
import { service } from '@ember/service'
import type StudentOperationsService from 'district-ui-client/services/student-operations'
import type { Log } from '@blakeelearning/log'
import type School from 'district-ui-client/models/school'
import type { IntlService } from 'ember-intl'
import UiButton from 'district-ui-client/components/ui-button'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import { t } from 'ember-intl'
import { on } from '@ember/modifier'
import type ActiveRouteService from 'district-ui-client/services/active-route'
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'
import type FlashQueueService from 'district-ui-client/services/flash-queue'

interface Signature {
  Args: {
    school?: School
    showAsButton?: boolean
    disableButton?: boolean
  }
}

export class ExportStudents extends Component<Signature> {
  @service studentOperations!: StudentOperationsService

  @service log!: Log

  @service activeRoute!: ActiveRouteService

  @service intl!: IntlService

  @service flashQueue!: FlashQueueService

  get subscriptionType(): SubscriptionType {
    return this.activeRoute.subscriptionType
  }

  exportStudents = async () => {
    const response = await this.studentOperations.exportStudents(this.subscriptionType, this.args.school)
    if (response.ok) {
      this.flashQueue.addSuccess({
        subtitle: this.intl.t('subscriptionType.manageStudents.exportStudents.exportCsvSuccess'),
      })
    } else {
      this.log.error('student-operations failed', await response.text())
      this.flashQueue.addFail({
        subtitle: this.intl.t('subscriptionType.manageStudents.exportStudents.exportCsvError'),
      })
    }
  }

  <template>
    {{#if @showAsButton}}
      <UiButton
        class="muted"
        @corners="rounded rounded-l-none"
        disabled={{@disableButton}}
        {{on "click" this.exportStudents}}
        data-test-export-students-button
      >
        <FaIcon @icon="download" class="mr-0.5" />
        {{t "exportAllStudents"}}
      </UiButton>
    {{else}}
      {{! Still a button, since it has a click action, but looks link a link. }}
      <button
        data-test-export-students-link
        type="button"
        class="text-oceany-blue-300 hover:text-oceany-blue-350 focus:text-oceany-blue-350 print:text-black"
        {{on "click" this.exportStudents}}
      >
        <FaIcon @icon="download" class="mr-0.5 text-black" />
        {{t "exportAllStudents"}}
      </button>
    {{/if}}
  </template>
}

export default ExportStudents

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ExportStudents: typeof ExportStudents
  }
}
