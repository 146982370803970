import Component from '@glimmer/component'
import type { SeriesBarOptions } from 'highcharts'
import blakeColors from '@blakeelearning/blake-colours/colours'
import SeriesBar from 'district-ui-client/components/primitives/series-bar/component'
import ChartLegend from 'district-ui-client/components/chart-legend'

type GradeLevelKey = 'below' | 'at' | 'above'

export interface WorkingAtGradeLevelData {
  working_at_grade_level: {
    below: number
    at: number
    above: number
  }
}

interface Signature {
  Args: {
    data: WorkingAtGradeLevelData
    product: Product
    title?: string
  }
}

const colorMap: Record<Product, Record<string, string>> = {
  maths: {
    below: blakeColors.msGreen150,
    at: blakeColors.msGreen300,
    above: blakeColors.msGreen400,
  },
  re: {
    below: blakeColors.oceanyBlue100,
    at: blakeColors.oceanyBlue300,
    above: blakeColors.oceanyBlue400,
  },
  rex: {
    below: blakeColors.oceanyBlue100,
    at: blakeColors.oceanyBlue300,
    above: blakeColors.oceanyBlue400,
  },
}

type Product = 'maths' | 'rex' | 're'

export class WorkingAtGradeLevelDashboardChart extends Component<Signature> {
  get workingAtGradeLevelData() {
    return this.args.data.working_at_grade_level
  }

  get chartData(): SeriesBarOptions[] {
    const { below, at, above } = this.workingAtGradeLevelData
    const { below: belowColor, at: atColor, above: aboveColor } = this.colours

    return [
      { name: 'Below', data: [{ y: below, color: belowColor }], type: 'bar' },
      { name: 'On', data: [{ y: at, color: atColor }], type: 'bar' },
      { name: 'Above', data: [{ y: above, color: aboveColor }], type: 'bar' },
    ]
  }

  get maxChartValue() {
    const { below, at, above } = this.workingAtGradeLevelData
    return below + at + above
  }

  get yAxis() {
    const max = this.maxChartValue

    return {
      startOnTick: false,
      endOnTick: false,
      gridLineWidth: 0,
      max,
      labels: {
        enabled: false,
      },
      title: {
        text: null,
      },
      reversedStacks: false,
    }
  }

  get colours() {
    const { product } = this.args
    return colorMap[product]
  }

  get legendData() {
    const { colours } = this
    const studentNumbersByKey = this.workingAtGradeLevelData
    const categories: { key: GradeLevelKey; label: string }[] = [
      { key: 'below', label: 'Below' },
      { key: 'at', label: 'On' },
      { key: 'above', label: 'Above' },
    ]
    const legendData = categories.map((category) => {
      const { label, key } = category
      const colour = colours[key]
      const data = Math.round(studentNumbersByKey[key])
      return { label, colour, data }
    })
    return legendData
  }

  <template>
    <div class="flex h-full flex-col" data-test-working-at-grade-level>
      <SeriesBar
        @title={{@title}}
        @data={{this.chartData}}
        @xAxisVisible={{false}}
        @yAxisVisible={{false}}
        @yAxis={{this.yAxis}}
        @groupPadding={{0}}
        @barPadding={{0}}
        @chartMargin={{Array 60 10 160 10}}
        @stackingStyle="normal"
        @maxPointWidth={{120}}
        @plotBackgroundColour="transparent"
        @borderWidth={{1}}
      />
      <ChartLegend
        {{! 80px to match highcharts chartMargin top }}
        class="absolute inset-0 top-auto h-[80px] px-5"
        @data={{this.legendData}}
        @labelsBelow={{true}}
      />
    </div>
  </template>
}

export default WorkingAtGradeLevelDashboardChart
