import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { service } from '@ember/service'
import { not, or } from 'ember-truth-helpers'
import { on } from '@ember/modifier'
import { t, type IntlService } from 'ember-intl'
import type Store from '@ember-data/store'
import { hash, fn, array } from '@ember/helper'
import { isEmpty, isPresent } from '@ember/utils'
import { trackedFunction } from 'reactiveweb/function'
import type { ThemeKeys } from 'district-ui-client/utils/themes'
import { THEME_KEYS } from 'district-ui-client/utils/themes'
import { TooltipInfo } from 'district-ui-client/components/tooltip'
import { InputSearch } from 'district-ui-client/components/input-search'
import { UiButton } from 'district-ui-client/components/ui-button'
import { CleverMatchStateIndicator } from 'district-ui-client/components/clever-ui/match-state-indicator'
import CapacityIndicator from 'district-ui-client/components/clever-ui/capacity-indicator'
import { CleverModalsSyncConfirm } from 'district-ui-client/components/clever-ui/modals/sync-confirm-modal'
import { CleverModalsResetConfirm } from 'district-ui-client/components/clever-ui/modals/reset-confirm-modal'
import colspanMax from 'district-ui-client/modifiers/colspan-max'
import type CleverSchool from 'district-ui-client/models/clever/clever-school'
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import FidgetSpinnerWaveComponent from '@blakeelearning/fidget/components/fidget/spinner/wave'
import { type SortingConfig } from '@blakeelearning/data-tables/utils/sorting'
import {
  textFilterPredicate,
  type FilterConfig,
  type FilterDataItem,
} from '@blakeelearning/data-tables/utils/filtering'
import type DataTransformerService from '@blakeelearning/data-tables/services/data-transformer'
import type CleverSchoolSubscriptionUtilisation from 'district-ui-client/models/clever/school-subscription-utilisation'
import type { PipelineDescriptor } from '@blakeelearning/data-tables/utils/data-transformer'
import { SortableCell } from 'district-ui-client/components/tables/sortable-cell'
import { formatValue } from 'district-ui-client/utils/format-value'

function arrayIncludes(arr: unknown[], item: unknown) {
  return arr.includes(item)
}

interface DataItem {
  id: CleverSchool['id']
  name: CleverSchool['name']
  record: CleverSchool
  matchSchoolState: CleverSchool['matchSchoolState']
  matchTeachersState: CleverSchool['matchTeachersState']
  matchStudentsState: CleverSchool['matchStudentsState']
  studentCount: number | undefined
  sectionCount: number | undefined
  capacityDiff: number
  utilisation?: CleverSchoolSubscriptionUtilisation
  isInitializing: CleverSchool['isInitializing']
  isMatchingInProgress: CleverSchool['isMatchingInProgress']
  isSyncInProgress: CleverSchool['isSyncInProgress']
  isAwaitingSync: CleverSchool['isAwaitingSync']
}

interface Signature {
  Element: HTMLTableElement
  Args: {
    cleverSchools: CleverSchool[]
    subscriptionType: SubscriptionType.Reading | SubscriptionType.Maths
    theme?: ThemeKeys
    /** if true, the table will be displayed in a 'simpler' mode, with unneeded columns and
     * actions hidden. Most data and actions in this table are only relevant to unsynced clever schools. */
    useSyncedView?: boolean
    textFilter?: string
    sortingConfig: SortingConfig
    selectedIds?: string[]
    onTextInput?: ({ value }: { value: string }) => unknown
    onSortClick: (sortingConfig: SortingConfig) => unknown
    onSelectClick?: (newIds: string[]) => unknown
    schoolSetupAction?: (cleverSchoolId: string) => void
    syncSchoolsAction?: ({ ids }: { ids: string[] }) => void
    resetSchoolsAction?: ({ ids }: { ids: string[] }) => void
    isSyncInProgress?: boolean
    isResetInProgress?: boolean
  }
}

/**
 * @property {Boolean} useSyncedView if true, the table will be displayed in a 'simpler' mode, with unneeded columns and
 * actions hidden. Most data and actions in this table are only relevant to unsynced clever schools.
 */
export class CleverDashboardSchoolsTable extends Component<Signature> {
  @service intl!: IntlService

  @service store!: Store

  @service dataTransformer!: DataTransformerService

  get selectedTheme() {
    const themes = {
      [THEME_KEYS.blue]: { header: 'bg-oceany-blue-100' },
      [THEME_KEYS.green]: { header: 'bg-ms-green-100' },
      [THEME_KEYS.default]: { header: 'bg-dusty-black-100' },
    }
    const themeKey = this.args.theme || ''
    return themes[themeKey] || themes[THEME_KEYS.default]
  }

  get selectedIds() {
    return this.args.selectedIds || []
  }

  /**
   * This returns a subset of the selected ids array, where only ids for schools in the awaiting sync state are returned
   * It is the list of selected school ids that can have the sync action performed on them
   */
  get syncableSelectedIds() {
    return this.selectedIds.filter(
      (selectedId) => this.args.cleverSchools.find((cs) => cs.id === selectedId)?.isAwaitingSync,
    )
  }

  get pipelineDescriptors(): Nullable<PipelineDescriptor>[] {
    return [this.sortingDescriptor, this.textFilterDescriptor]
  }

  get inputRows(): DataItem[] {
    const { subscriptionType } = this.args
    return this.args.cleverSchools.map((cleverSchool) => {
      const {
        id,
        name,
        blakeSchoolId,
        matchSchoolState,
        matchTeachersState,
        matchStudentsState,
        studentCounts,
        sectionCounts,
        isInitializing,
        isMatchingInProgress,
        isSyncInProgress,
        isAwaitingSync,
      } = cleverSchool
      // This is the number of students that have been shared via clever for this clever school record
      const studentCountForSubscriptionType = studentCounts?.[subscriptionType]
      // This is the number of sections (classes) that have been shared via clever for this clever school record
      const sectionCountForSubscriptionType = sectionCounts?.[subscriptionType]

      // Get the utilisation, used for the capacity column
      const school = isPresent(blakeSchoolId) ? this.store.peekRecord('clever/school', blakeSchoolId) : null
      const utilisation = school?.utilisationFor(subscriptionType)
      // Calculate the capacity diff for sorting.
      // If utilisation, use zero for the value, so that it shows up towards the middle when sorted
      let capacityDiff = 0
      if (utilisation) {
        capacityDiff = utilisation.isUnlimitedCapacity
          ? Number.POSITIVE_INFINITY
          : utilisation.licenceCount - utilisation.studentCount
      }

      return {
        id, // for checkbox selection
        name,
        record: cleverSchool, // access to fields that aren't needed in transform, that cause unnecessary re-processing
        matchSchoolState,
        matchTeachersState,
        matchStudentsState,
        studentCount: studentCountForSubscriptionType,
        sectionCount: sectionCountForSubscriptionType,
        capacityDiff,
        utilisation,
        isInitializing,
        isMatchingInProgress,
        isSyncInProgress,
        isAwaitingSync,
      }
    })
  }

  get sortingDescriptor() {
    const { sortingConfig } = this.args
    if (!sortingConfig) return null

    const sortDescriptor = { type: 'sort' as const, config: sortingConfig }
    return sortDescriptor
  }

  get textFilterDescriptor() {
    const { textFilter } = this.args
    const dataKeysToFilterOn = ['name']

    // Only need to apply a text filter if there is text
    if (!isPresent(textFilter)) return null

    // Create an item filter function that keeps only selected items and items meeting the text filter query
    const selectedFilter = (item: FilterDataItem) => typeof item.id === 'string' && this.selectedIds.includes(item.id)
    const searchFilter = textFilterPredicate(textFilter, dataKeysToFilterOn)
    const itemFilter = (item: FilterDataItem) => selectedFilter(item) || searchFilter(item)
    const filterConfig: FilterConfig<FilterDataItem> = { itemFilter }

    return { type: 'filter' as const, config: filterConfig }
  }

  get disableActions() {
    return this.args.isSyncInProgress || this.args.isResetInProgress
  }

  transformedItems = trackedFunction(this, async () => {
    const transformedData = await this.dataTransformer.buildAndTransform<DataItem, DataItem>(
      this.inputRows,
      this.pipelineDescriptors.filter(isPresent),
    )
    return transformedData.items
  })

  get rows() {
    return this.transformedItems.value ?? []
  }

  get allSelectableIds() {
    return this.rows.map((item) => item.id)
  }

  get isTransforming() {
    return this.transformedItems.isPending
  }

  get hasNoData() {
    return this.transformedItems.isFinished && isEmpty(this.rows)
  }

  get noDataMessage() {
    return this.intl.t('components.cleverUi.tables.dashboardSchoolsTable.emptyTableMessage')
  }

  get isAllSelected() {
    const set1 = new Set(this.selectedIds)
    const set2 = new Set(this.allSelectableIds)

    if (set1.size !== set2.size) return false

    const mergedSet = new Set([...set1, ...set2])

    return mergedSet.size === set1.size && mergedSet.size === set2.size
  }

  onSelectAll = () => {
    this.args.onSelectClick?.(this.isAllSelected ? [] : this.allSelectableIds)
  }

  onSelect = (id: string) => {
    let newSelectedIds = []
    const isSelected = this.selectedIds.includes(id)
    if (isSelected) {
      // deselect
      newSelectedIds = [...this.selectedIds.filter((selectedId) => selectedId !== id)]
    } else {
      // select
      newSelectedIds = [...this.selectedIds, id]
    }

    this.args.onSelectClick?.(newSelectedIds)
  }

  onTextInput = (value: string) => {
    return this.args.onTextInput?.({ value })
  }

  schoolSetupAction = (cleverSchoolId: string) => {
    return this.args.schoolSetupAction?.(cleverSchoolId)
  }

  @tracked resetConfirmModal = false

  @tracked syncConfirmModal = false

  syncSchoolsAction = ({ ids }: { ids: string[] }) => {
    this.showSyncModal(false)
    return this.args.syncSchoolsAction?.({ ids })
  }

  resetSchoolsAction = ({ ids }: { ids: string[] }) => {
    this.showResetModal(false)
    return this.args.resetSchoolsAction?.({ ids })
  }

  showResetModal = (value: boolean) => {
    this.resetConfirmModal = value
  }

  showSyncModal = (value: boolean) => {
    this.syncConfirmModal = value
  }

  <template>
    <table data-test-dashboard-schools-table class="relative w-full bg-white" ...attributes>
      <thead data-test-table-controls-header>
        <tr>
          <th class="table-box table-box-header {{this.selectedTheme.header}}" {{colspanMax}}>
            <div class="flex items-center gap-1">
              <InputSearch
                class="capitalize"
                @value={{@textFilter}}
                @updateValue={{this.onTextInput}}
                placeholder={{t "components.cleverUi.tables.dashboardSchoolsTable.textFilterPlaceholder"}}
                aria-label={{t "components.cleverUi.tables.dashboardSchoolsTable.textFilterPlaceholder"}}
              />
              {{#let (or this.disableActions (not this.selectedIds)) as |disabled|}}
                <UiButton
                  data-test-reset-selected-schools
                  class="muted"
                  disabled={{disabled}}
                  {{on "click" (fn this.showResetModal true)}}
                >
                  {{t "components.cleverUi.tables.dashboardSchoolsTable.buttons.resetSelectedSchools"}}
                </UiButton>
              {{/let}}
              {{#unless @useSyncedView}}
                <div class="flex-grow"></div>
                {{#let (or this.disableActions (not this.syncableSelectedIds)) as |disabled|}}
                  <UiButton
                    data-test-sync-selected-schools
                    class={{if disabled "muted" "green"}}
                    disabled={{disabled}}
                    {{on "click" (fn this.showSyncModal true)}}
                  >
                    <FaIcon @icon="arrows-rotate" @spin={{@isSyncInProgress}} />
                    {{t "components.cleverUi.tables.dashboardSchoolsTable.buttons.syncSelectedSchools"}}
                  </UiButton>
                {{/let}}
              {{/unless}}
            </div>
          </th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th class="table-box bg-dusty-black-300 w-px p-0 text-white">
            <label class="table-box-header m-0 inline-block cursor-pointer text-center">
              {{t "components.cleverUi.tables.dashboardSchoolsTable.headers.selectAll"}}
              <input
                data-test-select-all-checkbox
                class="cursor-pointer"
                type="checkbox"
                checked={{this.isAllSelected}}
                {{on "click" this.onSelectAll}}
              />
            </label>
          </th>
          <SortableCell
            @sortingConfig={{@sortingConfig}}
            @onSortClick={{@onSortClick}}
            @sortKey="name"
            class="table-box table-box-header bg-dusty-black-300 text-white"
          >
            {{t "components.cleverUi.tables.dashboardSchoolsTable.headers.schoolName"}}
          </SortableCell>
          {{#unless @useSyncedView}}
            <SortableCell
              @sortingConfig={{@sortingConfig}}
              @onSortClick={{@onSortClick}}
              @sortKey="matchSchoolState"
              class="table-box table-box-header bg-dusty-black-300 text-center text-white"
            >
              {{t "components.cleverUi.tables.dashboardSchoolsTable.headers.matchSchool"}}
            </SortableCell>
            <SortableCell
              @sortingConfig={{@sortingConfig}}
              @onSortClick={{@onSortClick}}
              @sortKey="matchTeachersState"
              class="table-box table-box-header bg-dusty-black-300 text-center text-white"
            >
              {{t "components.cleverUi.tables.dashboardSchoolsTable.headers.matchTeachers"}}
            </SortableCell>
            <SortableCell
              @sortingConfig={{@sortingConfig}}
              @onSortClick={{@onSortClick}}
              @sortKey="matchStudentsState"
              class="table-box table-box-header bg-dusty-black-300 text-center text-white"
            >
              {{t "components.cleverUi.tables.dashboardSchoolsTable.headers.matchStudents"}}
            </SortableCell>
          {{/unless}}
          <SortableCell
            @sortingConfig={{@sortingConfig}}
            @onSortClick={{@onSortClick}}
            @sortKey="studentCount"
            class="table-box table-box-header bg-dusty-black-300 text-center text-white"
          >
            {{t "components.cleverUi.tables.dashboardSchoolsTable.headers.sharedStudents"}}
            <TooltipInfo @text={{t "components.cleverUi.tables.dashboardSchoolsTable.tooltips.sharedStudents"}} />
          </SortableCell>
          <SortableCell
            @sortingConfig={{@sortingConfig}}
            @onSortClick={{@onSortClick}}
            @sortKey="sectionCount"
            class="table-box table-box-header bg-dusty-black-300 text-center text-white"
          >
            {{t "components.cleverUi.tables.dashboardSchoolsTable.headers.sharedSections"}}
            <TooltipInfo @text={{t "components.cleverUi.tables.dashboardSchoolsTable.tooltips.sharedSections"}} />
          </SortableCell>
          <SortableCell
            @sortingConfig={{@sortingConfig}}
            @onSortClick={{@onSortClick}}
            {{! this sortKey sorts by capacity _issues_ (ie those going over, or close to it) }}
            @sortKey="capacityDiff"
            class="table-box table-box-header bg-dusty-black-300 text-center text-white"
          >
            {{t "components.cleverUi.tables.dashboardSchoolsTable.headers.capacity" htmlSafe=true}}
            <TooltipInfo @text={{t "components.cleverUi.tables.dashboardSchoolsTable.tooltips.capacity"}} />
          </SortableCell>
          {{#if @useSyncedView}}
            <th class="table-box table-box-header bg-dusty-black-300 w-px text-center text-white"></th>
          {{else}}
            <th class="table-box table-box-header bg-dusty-black-300 w-px text-center text-white"></th>
            <th class="table-box table-box-header bg-dusty-black-300 w-px text-center text-white"></th>
          {{/if}}
        </tr>
      </thead>
      {{! If not transforming and no rows to show, always only display the empty message }}
      {{#if this.hasNoData}}
        <tbody data-test-empty-message-body>
          <tr>
            <td class="p-2 text-center italic" {{colspanMax}}>{{this.noDataMessage}}</td>
          </tr>
        </tbody>
      {{else}}
        {{! If transforming, always show a loading spinner }}
        {{! When no items, show it normally. }}
        {{! If there are items, place it on top of the existing table body (absolute) }}
        {{#if this.isTransforming}}
          <tbody>
            <tr>
              <td
                class="table-box table-box-cell border-transparent
                  {{if this.rows 'absolute left-1/2 -translate-x-1/2 transform'}}"
              >
                <FidgetSpinnerWaveComponent @small={{true}} @centered={{true}} />
              </td>
            </tr>
          </tbody>
        {{/if}}
        <tbody class={{if this.isTransforming "opacity-50"}}>
          {{#each this.rows as |row|}}
            <tr>
              <td class="table-box border-dusty-black-50">
                <label class="table-box-cell m-0 inline-block cursor-pointer text-center">
                  <input
                    data-test-select-checkbox={{row.id}}
                    type="checkbox"
                    checked={{arrayIncludes this.selectedIds row.id}}
                    {{on "click" (fn this.onSelect row.id)}}
                  />
                </label>
              </td>
              <td class="table-box table-box-cell border-dusty-black-50">
                <div>{{row.name}}</div>
                {{#if row.record.cityStateJoined}}
                  <div class="text-sm">{{row.record.cityStateJoined}}</div>
                {{/if}}
              </td>
              {{#unless @useSyncedView}}
                <td class="table-box table-box-cell border-dusty-black-50 text-center">
                  <CleverMatchStateIndicator
                    @subscriptionType={{@subscriptionType}}
                    @matchState={{row.matchSchoolState}}
                  />
                </td>
                <td class="table-box table-box-cell border-dusty-black-50 text-center">
                  <CleverMatchStateIndicator
                    @subscriptionType={{@subscriptionType}}
                    @matchState={{row.matchTeachersState}}
                  />
                </td>
                <td class="table-box table-box-cell border-dusty-black-50 text-center">
                  <CleverMatchStateIndicator
                    @subscriptionType={{@subscriptionType}}
                    @matchState={{row.matchStudentsState}}
                  />
                </td>
              {{/unless}}
              <td class="table-box table-box-cell border-dusty-black-50 text-center">{{formatValue
                  row.studentCount
                }}</td>
              <td class="table-box table-box-cell border-dusty-black-50 text-center">{{formatValue
                  row.sectionCount
                }}</td>
              <td class="table-box table-box-cell border-dusty-black-50 text-center">
                <CapacityIndicator @utilisation={{row.utilisation}} />
              </td>
              {{#let (or row.isMatchingInProgress row.isSyncInProgress this.disableActions) as |disableButtons|}}
                {{#if @useSyncedView}}
                  {{! RESET }}
                  <td class="table-box table-box-cell border-dusty-black-50 text-center">
                    <UiButton
                      data-test-reset-school={{row.id}}
                      class="muted"
                      disabled={{disableButtons}}
                      {{on "click" (fn this.resetSchoolsAction (hash ids=(array row.id)))}}
                    >
                      {{#if row.isInitializing}}
                        <FidgetSpinnerWaveComponent @small={{true}} @centered={{true}} data-test-button-spinner />
                      {{else}}
                        {{t "components.cleverUi.tables.dashboardSchoolsTable.buttons.resetSchool"}}
                      {{/if}}
                    </UiButton>
                  </td>
                {{else}}
                  {{! SETUP }}
                  <td class="table-box table-box-cell border-dusty-black-50 text-center">
                    <UiButton
                      data-test-setup-school={{row.id}}
                      class={{if disableButtons "muted" "regular"}}
                      disabled={{disableButtons}}
                      {{on "click" (fn this.schoolSetupAction row.id)}}
                    >
                      {{#if row.isMatchingInProgress}}
                        <FidgetSpinnerWaveComponent @small={{true}} @centered={{true}} data-test-button-spinner />
                      {{else}}
                        {{t "components.cleverUi.tables.dashboardSchoolsTable.buttons.setupSchool"}}
                      {{/if}}
                    </UiButton>
                  </td>
                  {{! SYNC }}
                  <td class="table-box table-box-cell border-dusty-black-50 text-center">
                    <UiButton
                      data-test-sync-school={{row.id}}
                      class={{if (or disableButtons (not row.isAwaitingSync)) "muted" "green"}}
                      disabled={{or disableButtons (not row.isAwaitingSync)}}
                      {{on "click" (fn this.syncSchoolsAction (hash ids=(array row.id)))}}
                    >
                      {{#if row.isSyncInProgress}}
                        <FidgetSpinnerWaveComponent @small={{true}} @centered={{true}} data-test-button-spinner />
                      {{else}}
                        <FaIcon @icon="arrows-rotate" />
                        {{t "components.cleverUi.tables.dashboardSchoolsTable.buttons.syncSchool"}}
                      {{/if}}
                    </UiButton>
                  </td>
                {{/if}}
              {{/let}}
            </tr>
          {{/each}}
        </tbody>
      {{/if}}
    </table>
    {{#if this.resetConfirmModal}}
      <CleverModalsResetConfirm
        @selectedCount={{this.selectedIds.length}}
        @onClose={{fn this.showResetModal false}}
        @onSubmit={{fn this.resetSchoolsAction (hash ids=this.selectedIds)}}
      />
    {{/if}}
    {{#if this.syncConfirmModal}}
      <CleverModalsSyncConfirm
        @selectedCount={{this.selectedIds.length}}
        @syncableSelectedCount={{this.syncableSelectedIds.length}}
        @onClose={{fn this.showSyncModal false}}
        @onSubmit={{fn this.syncSchoolsAction (hash ids=this.syncableSelectedIds)}}
      />
    {{/if}}
  </template>
}

export default CleverDashboardSchoolsTable

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CleverUi::Tables::DashboardSchoolsTable': typeof CleverDashboardSchoolsTable
  }
}
