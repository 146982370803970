import type { TOC } from '@ember/component/template-only'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import { t } from 'ember-intl'
import { ThemedLinkTo } from 'district-ui-client/components/themed-button'

interface Signature {
  Element: HTMLDivElement
}

/**
 * Intended for use in the reporting area to tell the user to set their state standards.
 */
export const NoStandardsMessage: TOC<Signature> = <template>
  <div data-test-no-standards-message class="text-center" ...attributes>
    <FaIcon @icon="triangle-exclamation" class="mx-auto h-[200px] w-[200px] text-neutral-100" />
    <div class="space-y-3">
      <h2 class="mt-0 font-semibold" data-test-no-standards-title>
        {{t "components.noStandardsMessage.title"}}
      </h2>
      <p class="text-base" data-test-no-standards-body>
        {{t "components.noStandardsMessage.body" htmlSafe=true}}
      </p>
      <div class="p-3">
        <ThemedLinkTo @style="theme" @route="settings.district-settings">
          <span>{{t "components.noStandardsMessage.button"}}</span>
          <FaIcon @icon="arrow-right" />
        </ThemedLinkTo>
      </div>
    </div>
  </div>
</template>
