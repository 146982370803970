import { on } from '@ember/modifier'
import { Panel } from 'district-ui-client/components/panel'
import { RolloverAlert } from 'district-ui-client/components/rollover-alert'
import themeKey from 'district-ui-client/helpers/theme-key'
import RouteTemplate from 'ember-route-template'
import { BaseSelect } from 'district-ui-client/components/base/select'
import Alert from 'district-ui-client/components/alert'
import UiButton from 'district-ui-client/components/ui-button'
import { t, type IntlService } from 'ember-intl'
import { eventValue } from 'district-ui-client/helpers/event-value'
import Component from '@glimmer/component'
import { service } from '@ember/service'
import type Store from '@ember-data/store'
import type ActiveRouteService from 'district-ui-client/services/active-route'
import type RouterService from '@ember/routing/router-service'
import type AlertService from 'district-ui-client/services/alert'
import type StudentOperationsService from 'district-ui-client/services/student-operations'
import type GradeSetsService from 'district-ui-client/services/grade-sets'
import type { Log } from '@blakeelearning/log'
import { tracked } from '@glimmer/tracking'
import type ManageStudentsNewRoute from 'district-ui-client/subscription-type/manage/students/new/route'
import { uniqBy } from 'lodash'
import { didCancel, task } from 'ember-concurrency'
import type Student from 'district-ui-client/models/student'
import type ManageStudentsNewController from 'district-ui-client/subscription-type/manage/students/new/controller'
import { not, eq } from 'ember-truth-helpers'
import { isEmpty, isPresent } from '@ember/utils'
import { trackedFunction } from 'reactiveweb/function'
import type SessionService from 'district-ui-client/services/session'
import { InputText } from 'district-ui-client/components/input-text'

interface Signature {
  Args: {
    model: ModelFor<ManageStudentsNewRoute>
    controller: ManageStudentsNewController
  }
}

export class ManageStudentsNewRouteTemplate extends Component<Signature> {
  @service studentOperations!: StudentOperationsService

  @service alert!: AlertService

  @service store!: Store

  @service router!: RouterService

  @service activeRoute!: ActiveRouteService

  @service intl!: IntlService

  @service log!: Log

  @service gradeSets!: GradeSetsService

  @service session!: SessionService

  @tracked firstName = ''

  @tracked lastName = ''

  @tracked teacherId = ''

  @tracked gradePosition: Nullable<number> = null

  loadSchools = trackedFunction(this, async () => {
    const schools = await this.store.query('school', {
      scope: `districts/${this.session.currentDistrict.id}`,
      include: 'school-subscriptions',
    })

    return schools
  })

  loadClasses = trackedFunction(this, async () => {
    if (!this.school) {
      return undefined
    }

    const schoolClasses = await this.store.query('school-class', {
      filter: { product: this.subscriptionType }, // schools scope only has product filter
      scope: `schools/${this.school.id}`,
      // used for the teacher options
      include: 'teachers',
    })

    return schoolClasses
  })

  loadStudents = trackedFunction(this, async () => {
    if (!this.school) {
      return undefined
    }
    const students = await this.store.query('student', { scope: `schools/${this.school.id}`, include: 'school' })

    return students
  })

  get subscriptionType() {
    return this.activeRoute.subscriptionType
  }

  get students() {
    return this.loadStudents.value?.slice() ?? []
  }

  get schoolClasses() {
    return this.loadClasses.value?.slice() ?? []
  }

  get schools() {
    return (
      this.loadSchools.value?.slice().filter((school) => {
        return school.hasActiveSchoolSubscriptionFor(this.subscriptionType)
      }) ?? []
    )
  }

  get school() {
    return this.schools.find((school) => school.id === this.args.controller.schoolId)
  }

  // this generates a list of teachers from all the product school clases for the school
  get teachersForSchool() {
    const teachersFromSchoolClasses = this.schoolClasses
      .flatMap((schoolClass) => schoolClass.teachers?.slice())
      .filter(isPresent)

    return uniqBy(teachersFromSchoolClasses, 'id')
  }

  get studentExists() {
    const students = this.students
    if (!students) return false
    return (
      students.filter((student) => {
        const studentName = student.fullName.toLowerCase()
        const searchingFor = `${this.firstName} ${this.lastName}`.toLowerCase()
        return !student.isNew && studentName === searchingFor
      })?.length > 0
    )
  }

  get disableSubmit() {
    return (
      isEmpty(this.firstName) || isEmpty(this.lastName) || isEmpty(this.teacherId) || !isPresent(this.gradePosition)
    )
  }

  cancel = () => {
    this.backToStudents()
  }

  setSchoolId = (schoolId: string) => {
    this.args.controller.schoolId = schoolId
  }

  setFirstName = (firstName: string) => {
    this.firstName = firstName
  }

  setLastName = (lastName: string) => {
    this.lastName = lastName
  }

  setGradePosition = (gradePosition: string) => {
    const gradePos = Number.parseInt(gradePosition, 10)
    this.gradePosition = Number.isNaN(gradePos) ? null : gradePos
  }

  setTeacherId = (teacherId: string) => {
    const foundTeacher = this.teachersForSchool.find((teacher) => teacher.id === teacherId)
    this.teacherId = foundTeacher ? teacherId : ''
  }

  createStudentTask = task({ drop: true }, async (newStudent: Student) => {
    await newStudent.save()
  })

  createStudent = async (event: Event) => {
    event.preventDefault()

    // just use teachers' first class for this subtype for now
    const teacher = this.teachersForSchool.find((teacher) => teacher?.id === this.teacherId)
    const firstClass = teacher?.schoolClasses?.find(
      (schoolClass) => schoolClass.subscriptionType === this.subscriptionType,
    )
    const schoolClasses = firstClass ? [firstClass] : []

    const newStudent = this.store.createRecord('student', {
      firstName: this.firstName,
      lastName: this.lastName,
      gradePosition: this.gradePosition,
      school: this.school,
      schoolClasses,
    })

    try {
      await this.createStudentTask.perform(newStudent)

      this.backToStudents()
      this.alert.show({ type: 'positive', message: 'Successfully created a student' })
    } catch (e: any) {
      newStudent.rollbackAttributes()
      if (didCancel(e)) return
      this.log.error('failed to create student', e)
      this.alert.showWithDismiss({
        type: 'critical',
        message: this.intl.t('errorMessages.createStudent'),
      })
    }
  }

  backToStudents = () => {
    this.router.transitionTo('subscription-type.manage.students', {
      queryParams: { schoolId: this.args.controller.schoolId },
    })
  }

  <template>
    <RolloverAlert />
    <div class="mx-auto mt-6 md:w-full lg:w-2/3">
      <Panel
        @theme={{themeKey this.subscriptionType}}
        @title={{t "subscriptionType.manageStudents.new.addANewStudent"}}
      >
        <form class="space-y-4" {{on "submit" this.createStudent}}>
          <div class="flex items-center justify-between">
            <label for="school-dropdown" class="w-1/3">{{t "school"}}</label>

            <BaseSelect
              data-test-select-school
              id="school-dropdown"
              class="w-2/3"
              value={{@controller.schoolId}}
              required={{true}}
              @themeKey={{themeKey this.subscriptionType}}
              {{on "input" (eventValue this.setSchoolId)}}
            >
              <option value="">{{t "clever.selectSchoolText"}}</option>
              {{#each this.schools as |school|}}
                <option value={{school.id}} selected={{eq @controller.schoolId school.id}}>{{school.name}}
                </option>
              {{/each}}
            </BaseSelect>
          </div>

          <div class="flex items-center justify-between">
            <label for="first-name" class="w-1/3">{{t "firstName"}}</label>
            <InputText
              data-test-first-name
              id="first-name"
              class="w-2/3"
              required={{true}}
              disabled={{not this.school}}
              value={{this.firstName}}
              {{on "input" (eventValue this.setFirstName)}}
            />
          </div>

          <div class="flex items-center justify-between">
            <label for="last-name" class="w-1/3">{{t "lastName"}}</label>
            <InputText
              data-test-last-name
              id="last-name"
              class="w-2/3"
              required={{true}}
              disabled={{not this.school}}
              value={{this.lastName}}
              {{on "input" (eventValue this.setLastName)}}
            />
          </div>

          <div class="flex items-center justify-between">
            <label for="grade-dropdown" class="w-1/3">{{t "subscriptionType.manageStudents.new.grade"}}</label>

            <BaseSelect
              data-test-select-grade
              id="grade-dropdown"
              class="w-2/3"
              value={{this.gradePosition}}
              required={{true}}
              disabled={{not this.school}}
              @themeKey={{themeKey this.subscriptionType}}
              {{on "input" (eventValue this.setGradePosition)}}
            >
              <option value="">{{t "subscriptionType.manageStudents.new.gradePlaceHolder"}}</option>
              {{#each this.gradeSets.grades as |grade|}}
                <option value={{grade.position}}>{{grade.name}}</option>
              {{/each}}
            </BaseSelect>
          </div>

          <div class="flex items-center justify-between">
            <label for="teacher-dropdown" class="w-1/3">{{t "teacher"}}</label>

            <BaseSelect
              data-test-select-teacher
              id="teacher-dropdown"
              class="w-2/3"
              value={{this.teacherId}}
              required={{true}}
              disabled={{not this.school}}
              @themeKey={{themeKey this.subscriptionType}}
              {{on "input" (eventValue this.setTeacherId)}}
            >
              <option value="">{{t "selectTeacher"}}</option>
              {{#each this.teachersForSchool as |teacher|}}
                <option value={{teacher.id}}>{{teacher.fullNameReversed}}</option>
              {{/each}}
            </BaseSelect>
          </div>

          {{#if this.studentExists}}
            <Alert @showIcon={{false}} @tone="caution" @role="alertdialog">
              {{t
                "subscriptionType.manageStudents.new.exists"
                firstName=this.firstName
                lastName=this.lastName
                schoolName=this.school.name
              }}
            </Alert>
          {{/if}}

          <div class="text-right">
            <UiButton data-test-cancel-new-student class="muted" {{on "click" this.cancel}}>{{t "cancel"}}</UiButton>
            <UiButton
              data-test-submit-new-student
              id="create-student"
              @buttonType="submit"
              @disabled={{this.disableSubmit}}
              class={{if this.studentExists "orange" "regular"}}
            >
              {{#if this.studentExists}}
                {{t "subscriptionType.manageStudents.new.addAnyway"}}
              {{else}}
                {{t "subscriptionType.manageStudents.new.createStudent"}}
              {{/if}}
            </UiButton>
          </div>
        </form>
      </Panel>
    </div>
  </template>
}

export default RouteTemplate(ManageStudentsNewRouteTemplate)
