import { service } from '@ember/service'
import Component from '@glimmer/component'
import isFinite from 'lodash/isFinite'
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter'
import { reportingToolTip as toolTipConfig } from 'district-ui-client/utils/giraffe/highcharts-config'
import BlakeColors from '@blakeelearning/blake-colours/colours'
import SeriesColumnRange from 'district-ui-client/components/primitives/series-column-range/component'
import type { IntlService } from 'ember-intl'
import type GradeSetsService from 'district-ui-client/services/grade-sets'
import { t } from 'ember-intl'
import type { SeriesColumnrangeOptions, Point } from 'highcharts'

export interface LexileGrowthData {
  lexile_growth_by_grade: {
    grades: {
      grade_position: number
      from_lexile_growth: number
      to_lexile_growth: number
      student_count: number
    }[]
    student_count: number
  }
}

interface Signature {
  Args: {
    data: LexileGrowthData
  }
}

export class LexileGrowthDashboardChart extends Component<Signature> {
  @service gradeSets!: GradeSetsService

  @service intl!: IntlService

  get grades() {
    return this.args.data.lexile_growth_by_grade.grades
  }

  get sortedGrades() {
    return [...this.grades].sort((a, b) => a.grade_position - b.grade_position)
  }

  get colour() {
    return BlakeColors.oceanyBlue300
  }

  get chartData(): SeriesColumnrangeOptions[] {
    const color = this.colour
    const data = this.sortedGrades.map((gradeInfo) => {
      const { from_lexile_growth: lexileFrom, to_lexile_growth: lexileTo, student_count: studentCount } = gradeInfo

      if ([studentCount, lexileFrom, lexileTo].every(isFinite)) {
        const avgMinlexile = Math.round(lexileFrom)
        const avgMaxlexile = Math.round(lexileTo)

        return [avgMinlexile, avgMaxlexile]
      }
      return [0, 0]
    })

    return [{ data, color, type: 'columnrange' }]
  }

  get subtitleKey() {
    if (this.isSingleGrade) return 'reporting.dashboardSubtitles.singleGrade.lexileGrowth'
    return 'reporting.dashboardSubtitles.multiGrade'
  }

  get isSingleGrade() {
    return this.grades.length === 1
  }

  get categories() {
    const { sortedGrades } = this

    return sortedGrades.map((grade) => this.gradeSets.findByPosition(grade.grade_position)?.name ?? '-')
  }

  get studentCount() {
    const count = this.args.data?.lexile_growth_by_grade?.student_count
    return formatNumber(count, 2)
  }

  get tooltipFormatter() {
    return function (this: Point) {
      const formatPoint = this as Point & { low: number; high: number }

      if (formatPoint.low === formatPoint.high) {
        return `<b>${formatPoint.high}L</b>`
      }
      return `<b>From ${formatPoint.low}L to ${formatPoint.high}L</b>`
    }
  }

  get toolTip() {
    return toolTipConfig('y', '', {
      formatter: this.tooltipFormatter,
    })
  }

  /**
   * Returns a useful datalabel string, if the point is a high point. If low point, returns an empty string.
   * 'below' is not added by us, nor is it in the Point type, but it appears in the data.
   */
  dataLabelsFormatter(this: Point) {
    const { high, low, below } = this as Point & { low: number; high: number; below: boolean }
    const growth = high - low
    return below ? '' : `+${growth}`
  }

  <template>
    <div class="flex h-full flex-col" data-test-lexile-growth>
      <div data-test-dashboard-subtitle class="flex justify-between gap-4 text-xs">
        <span>
          {{t this.subtitleKey}}
        </span>
        <span>
          {{t "reporting.dashboardSubtitles.studentCount" count=this.studentCount}}
        </span>
      </div>

      <SeriesColumnRange
        @categories={{this.categories}}
        @data={{this.chartData}}
        @toolTip={{this.toolTip}}
        @xAxisLabel={{t "grade.label"}}
        @yAxisFloor={{0}}
        @dataLabelsEnabled={{true}}
        @dataLabelsFormatter={{this.dataLabelsFormatter}}
      />

    </div>
  </template>
}

export default LexileGrowthDashboardChart
