import type { TOC } from '@ember/component/template-only'
import { on } from '@ember/modifier'
import UiButton from 'district-ui-client/components/ui-button'
import { or } from 'ember-truth-helpers'
import FidgetSpinnerWaveComponent from '@blakeelearning/fidget/components/fidget/spinner/wave'
import { t } from 'ember-intl'

interface Signature {
  Args: {
    disabled?: boolean
    matchErrorCode: string
    isPerformingRemedy?: boolean
    showModal: () => void
  }
}

export const TeacherMatchErrorMoreInfo: TOC<Signature> = <template>
  <UiButton
    data-test-more-info={{@matchErrorCode}}
    class="regular {{if (or @disabled @isPerformingRemedy) 'muted'}}"
    @buttonType="submit"
    @corners="rounded"
    @disabled={{or @disabled @isPerformingRemedy}}
    {{on "click" @showModal}}
  >
    {{#if @isPerformingRemedy}}
      <FidgetSpinnerWaveComponent @small={{true}} @centered={{true}} />
    {{else}}
      {{t "clever.teacherMatcher.showErrorModalButton"}}
    {{/if}}
  </UiButton>
</template>

export default TeacherMatchErrorMoreInfo
