import Component from '@glimmer/component'
import { guidFor } from '@ember/object/internals'
import { createContainerElement } from '../../utils/create-container-element.ts'
import type { SafeString } from '@ember/template'
import type Owner from '@ember/owner'

interface Args {
  container?: string
  noEvents?: boolean
  showOnRender?: boolean
  showArrow?: boolean
  text?: string | SafeString
}

interface Signature {
  Element: HTMLDivElement
  Args: Args
}

/**
 * A standard tooltip component.
 *
 * This component is mostly provided to be backwards-compatible. If you need styles customised, you should probably
 * create a tooltip component in your application that uses the same modifier, and apply the styles you need in your
 * application.
 *
 * <Popover::Tooltip @text="my tooltip" />
 *
 * @param {string} text
 * The text you would like in the tooltip
 *
 * @param {boolean} showOnRender
 * Enable to show the tooltip on initial render. It will return to normal operation when the reference element is
 * hovered and then unhovered.
 *
 * @param {boolean} noEvents
 * Enable to not have the tooltip react to any events (like hover, focus, etc)
 *
 * @param {HTMLElement | string} container
 * The container that the tooltip will try to position itself within. You shouldn't need to use this option.
 */
export class PopoverTooltipComponent extends Component<Signature> {
  elementId: string

  get containerId() {
    return this.args.container ?? `popover-tooltip-container`
  }

  containerElement?: HTMLElement

  get parentElement() {
    return document.querySelector(
      `#addon-popover-tooltip-placeholder-${this.elementId}`,
    )?.parentElement
  }

  get renderTooltip() {
    return this.args.text && this.containerElement
  }

  constructor(owner: Owner, args: Args) {
    super(owner, args)

    this.elementId = guidFor(this)
    this.containerElement = createContainerElement(this.containerId)
  }
}

export default PopoverTooltipComponent
