import type { TOC } from '@ember/component/template-only'
import { array, hash } from '@ember/helper'
import { LinkTo } from '@ember/routing'

const sectionClassNames = 'block border-neutral-75 rounded-xl border-2 bg-white overflow-hidden'
const tileClassNames = 'transform-gpu px-6 py-4 transition duration-300 ease-in-out'

/**
 * For simpler content.
 */
export const Tile: TOC<{
  Element: HTMLElement
  Args: {
    /**
     * Provide a route, and this will be rendered as a LinkTo <a> element
     */
    route?: string
    href?: string
    models?: unknown[]
    query?: Record<string, unknown>
  }
  Blocks: {
    default: []
  }
}> = <template>
  {{#if @route}}
    <LinkTo
      class="{{sectionClassNames}}
        {{tileClassNames}}
        hover:shadow-tile focus:shadow-tile focus-visible:outline-blue-325 cursor-pointer focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
      @route={{@route}}
      @models={{if @models @models (array)}}
      @query={{if @query @query (hash)}}
      ...attributes
    >
      {{yield}}
    </LinkTo>

  {{else if @href}}
    <a
      class="{{sectionClassNames}}
        {{tileClassNames}}
        hover:shadow-tile focus:shadow-tile focus-visible:outline-blue-325 cursor-pointer focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
      href={{@href}}
      ...attributes
    >
      {{yield}}
    </a>
  {{else}}
    <section class="{{sectionClassNames}} {{tileClassNames}}" ...attributes>
      {{yield}}
    </section>
  {{/if}}
</template>

/**
 * Intended for use with larger content, using PanelHeader, PanelSubHeader and PanelBody components to provide the
 * padding.
 */
export const Panel: TOC<{ Element: HTMLElement; Blocks: { default: [] } }> = <template>
  <section class={{sectionClassNames}} ...attributes>
    {{yield}}
  </section>
</template>

export const PanelHeader: TOC<{ Element: HTMLDivElement; Blocks: { default: [] } }> = <template>
  <div class="px-6 pb-3 pt-6" ...attributes>
    {{yield}}
  </div>
</template>

/**
 * Used for Panel and Tile headers
 */
export const PanelTitle: TOC<{ Element: HTMLDivElement; Blocks: { default: [] } }> = <template>
  <div class="text-xl font-medium" ...attributes>
    {{yield}}
  </div>
</template>

export const PanelSubHeader: TOC<{ Element: HTMLDivElement; Blocks: { default: [] } }> = <template>
  <div class="px-6 py-2" ...attributes>
    {{yield}}
  </div>
</template>

interface PanelBodySignature {
  Element: HTMLDivElement
  Args: {
    /**
     * true will not add side or bottom padding. Intended for use when rendering tables in the panel body.
     */
    tight?: boolean
  }
  Blocks: { default: [] }
}

export const PanelBody: TOC<PanelBodySignature> = <template>
  <div class="{{unless @tight 'px-6 pb-6'}} pt-2" ...attributes>
    {{yield}}
  </div>
</template>
