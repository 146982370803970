import Component from '@glimmer/component'
import { service } from '@ember/service'
import { tracked } from '@glimmer/tracking'
import type { IntlService } from 'ember-intl'
import { on } from '@ember/modifier'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import { UploadProgress } from 'district-ui-client/components/csv/upload-progress'
import { pick } from 'district-ui-client/helpers/pick'

interface Args {
  uploading?: boolean
  uploadComplete?: boolean
  uploadProgress?: number
  uploadAction?: (file: File) => unknown
}

interface Signature {
  Args: Args
  Element: HTMLDivElement
}

export class Uploader extends Component<Signature> {
  @service intl!: IntlService

  @tracked file?: File

  @tracked isDragging = false

  get statusMessage() {
    if (this.args.uploading) return this.intl.t('fileImporter.fileDrop.status.uploading', { fileName: this.file?.name })
    if (this.args.uploadComplete) return this.intl.t('fileImporter.fileDrop.status.uploadComplete')
    return this.intl.t('fileImporter.fileDrop.status.ready')
  }

  get backgroundClassName() {
    if (this.isDragging) return 'bg-dusty-black-100'
    if (this.args.uploadComplete) return 'bg-forest-green-300'
    return 'bg-dusty-black-50'
  }

  dragOver = (event: Event) => {
    event.preventDefault()
    this.isDragging = true
  }

  dragEnter = (event: Event) => {
    event.preventDefault()
  }

  dragLeave = (event: Event) => {
    event?.preventDefault()
    this.isDragging = false
  }

  /**
   * When the "drop" event is fired, the event is of type DragEvent
   */
  drop = (event: DragEvent) => {
    event.preventDefault()
    const file = event.dataTransfer?.files?.[0]
    if (file) {
      this.file = file
      this.args.uploadAction?.(file)
    }
    this.isDragging = false
  }

  fileAdded = (files: FileList) => {
    const [file] = files
    if (file) {
      this.file = file
      this.args.uploadAction?.(file)
    }
  }

  <template>
    <div
      class="text-center"
      {{on "dragover" this.dragOver}}
      {{on "dragenter" this.dragEnter}}
      {{on "dragleave" this.dragLeave}}
      {{on "drop" this.drop}}
      data-test-file-drop-area
      ...attributes
    >
      <label
        class="border-dusty-black-200 m-0 inline-block w-48 break-words rounded-md border-2 border-dashed p-3 font-normal
          {{this.backgroundClassName}}"
        data-test-file-drop-label
      >
        <div data-test-status-message>
          {{this.statusMessage}}
        </div>
        <div class="my-3">
          {{#if @uploadComplete}}
            <FaIcon @icon="folder-open" @size="3x" />
          {{else}}
            <FaIcon @icon="folder" @size="3x" />
          {{/if}}
        </div>
        <input
          class="hidden"
          type="file"
          accept="text/csv, .csv"
          {{on "change" (pick "target.files" this.fileAdded)}}
        />
      </label>
      {{#if @uploadProgress}}
        <UploadProgress class="mx-auto mt-0.5 w-48" @progressPercent={{@uploadProgress}} />
      {{/if}}
    </div>
  </template>
}

export default Uploader

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Csv::Uploader': typeof Uploader
  }
}
