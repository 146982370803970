// This error page will be rendered outside of the application template, it'll be pretty bare

import { on } from '@ember/modifier'
import { service } from '@ember/service'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import Component from '@glimmer/component'
import type LogoutService from 'district-ui-client/services/logout'
import { t } from 'ember-intl'
import RouteTemplate from 'ember-route-template'
import { ErrorMessage } from 'district-ui-client/components/error-message'
import { Toaster } from 'district-ui-client/components/toaster'

export class ApplicationErrorRouteTemplate extends Component<unknown> {
  @service logoutService!: LogoutService

  logout = () => {
    this.logoutService.logoutAndRedirect()
  }

  <template>
    <div data-test-application-error class="ml-7">
      <ErrorMessage />

      {{! Should at least give them the ability to log out, if needed. }}
      <button
        type="button"
        class="text-oceany-blue-300 hover:text-oceany-blue-350 focus:text-oceany-blue-350 mt-7"
        {{on "click" this.logout}}
      >
        <FaIcon @icon="power-off" @size="xs" />
        {{t "errorPages.logout"}}
      </button>
    </div>

    {{! Render a toaster here, so that any error toasts will still appear }}
    <Toaster />
  </template>
}

export default RouteTemplate(ApplicationErrorRouteTemplate)
