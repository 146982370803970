import Controller from '@ember/controller'
import { service } from '@ember/service'
import { action } from '@ember/object'

export default class CleverMissingFieldsController extends Controller {
  @service clever

  @service fileDownload

  get blakeTeachers() {
    return this.model.teacherMatches.map((match) => match.teacher)
  }

  get blakeStudents() {
    return this.model.studentMatches.map((match) => match.student)
  }

  @action
  downloadStudents() {
    const csvData = this.clever.blakeStudentsToCSVArray(this.blakeStudents, true)
    this.fileDownload.downloadAsCsv(csvData)
  }

  @action
  downloadTeachers() {
    const csvData = this.clever.blakeTeachersToCSVArray(this.blakeTeachers, true)
    this.fileDownload.downloadAsCsv(csvData)
  }
}
