import Component from '@glimmer/component'
import { service } from '@ember/service'
import type RouterService from '@ember/routing/router-service'
import { BasePageSelectorTraverser } from 'district-ui-client/components/base/page-selector/traverser'
import { gt, not, eq } from 'ember-truth-helpers'
import { t } from 'ember-intl'
import { fn } from '@ember/helper'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import { optional } from 'district-ui-client/helpers/optional'

/**
 * Query Param based paging (recommended) - most values read from the URL.
 * <Base::PageSelector @pageCount={{this.pageCount}} />
 *
 * Action based paging - all values need to be supplied.
 * <Base::PageSelector @pageCount={{this.pageCount}} @currentPage={{this.currentPage}} @changePage={{this.updatePage}} />
 */
interface Args {
  /**
   * The total number of pages. This changes per request and can't be retrieved via the URL.
   */
  pageCount: number
  /**
   * Provide this if your Query Param key for page number is not `pageNumber`
   */
  pageNumberKey?: string
  /**
   * Provide the currentPage if it is not available via Query Params.
   */
  currentPage?: number | string
  /**
   * Provide this if you don't want to use pagination via Query Params.
   */
  changePage?: (pageNumber: number) => unknown
}

interface Signature {
  Element: HTMLElement
  Args: Args
}

interface PageLink {
  pageNumber: number
  query: Record<string, unknown>
}

function toInt(maybeString: string | number) {
  return typeof maybeString === 'string' ? parseInt(maybeString, 10) : maybeString
}

export class BasePageSelector extends Component<Signature> {
  @service router!: RouterService

  get pageNumberKey() {
    return this.args.pageNumberKey ?? 'pageNumber'
  }

  /**
   * Note that the "raw" page query param when obtained via the router service will always come through as a string, even if
   * the default value in the controller is a number - it does not cast the value to a number when obtained this way, as.
   * opposed to accessing it via the controller.
   *
   * However, this component will always deal with pages as numbers.
   */
  get currentPage(): number {
    // Current Page via arguments
    if (this.args.currentPage) {
      return toInt(this.args.currentPage)
    }

    // Current Page via Query Params
    /**
     * When transitioning to a page with pagination, often the query param will not be set in the URL. The default value
     * to use when that happens is defined in the controller.
     *
     * When obtaining the pageNumber via the router service, we do not have access to that value - the router service will
     * provide the page query param as "undefined". We could overengineer something to find the matching controller and
     * find the "correct" value, but pragmatism says it's OK to assume the default page will always be 1. If that ever
     * changes, consider providing the default page number as an optional component argument.
     */
    const rawPage = this.router.currentRoute?.queryParams[this.pageNumberKey]
    return toInt(typeof rawPage === 'string' ? rawPage : '1')
  }

  get prevPageQuery() {
    if (this.currentPage <= 1) {
      return undefined
    } else {
      return { [this.pageNumberKey]: this.currentPage - 1 }
    }
  }

  get nextPageQuery() {
    if (this.currentPage >= this.args.pageCount) {
      return undefined
    } else {
      return { [this.pageNumberKey]: this.currentPage + 1 }
    }
  }

  get pageLinks(): (PageLink | null)[] {
    return this.generatePageLinks()
  }

  /**
   * Generates a stable array of page link items, that should return the same number of pages (desiredPageCount) as the
   * user traverses the pager. Extra pages are ellipsed (indicated by nulls)
   */
  generatePageLinks(): (PageLink | null)[] {
    const firstPage = 1
    const desiredPageCount = 9
    const { pageCount: lastPage } = this.args
    const { currentPage, pageNumberKey } = this

    // [1, 2, 3, ... pageCount]
    const pageRange = Array.from({ length: this.args.pageCount }, (_v, index) => index + 1)

    const buildPageLink = (pageNumber: number): PageLink => ({ pageNumber, query: { [pageNumberKey]: pageNumber } })

    // If number of pages is under the max allowed, just return links for all those pages.
    if (pageRange.length <= desiredPageCount) return pageRange.map(buildPageLink)

    // Else, we need to determine which pages should be hidden by ellipses, and which are safe.
    const safePageNumbers = new Set<number>()
    safePageNumbers.add(firstPage)
    safePageNumbers.add(lastPage)
    safePageNumbers.add(currentPage)

    // With maxPages 9, the border zone is 1,2,3,4 vs 8,9,10,11
    // If the current page is within these pages, only 1 ellipsis will be shown.
    const borderZoneSize = Math.floor(desiredPageCount / 2)
    const currentPageInBorderZone = currentPage < firstPage + borderZoneSize || currentPage > lastPage - borderZoneSize
    const expectedEllipsisCount = currentPageInBorderZone ? 1 : 2

    // keep adding safe numbers either side of currentpage until reaching maxpages (minus expected count of ellipses)
    for (let i = 1; safePageNumbers.size < desiredPageCount - expectedEllipsisCount; i++) {
      const leftAdjacent = currentPage - i
      const rightAdjacent = currentPage + i
      if (pageRange.includes(leftAdjacent)) safePageNumbers.add(leftAdjacent)
      if (safePageNumbers.size < desiredPageCount - expectedEllipsisCount) {
        if (pageRange.includes(rightAdjacent)) safePageNumbers.add(rightAdjacent)
      }
    }

    // Iterate over sorted safe page numbers, to find any gaps of 1 (diff between two adjacent numbers is 2)
    // Add those to the set as well, this way there are no ellipses used to hide a single page number (no point)
    // This shouldn't affect the final pages count.
    ;[...safePageNumbers]
      .sort((a, b) => a - b)
      .forEach((value, index, arr) => {
        if (arr[index + 1] - value === 2) safePageNumbers.add(value + 1)
      })

    return pageRange.reduce<(PageLink | null)[]>((pageLinks: (PageLink | null)[], pageNumber: number) => {
      if (safePageNumbers.has(pageNumber)) {
        return [...pageLinks, buildPageLink(pageNumber)]
      } else {
        return pageLinks[pageLinks.length - 1] === null ? pageLinks : [...pageLinks, null]
      }
    }, [])
  }

  /**
   * Returns true if pageNumber is or adjacent to the comparePageNumber
   *
   * For example, if the pageNumber were 4, then 3, 4 and 5 are adjacent if the distance is 1.
   */
  isAdjacentToPage(pageNumber: number, comparePageNumber: number, distance: number) {
    return pageNumber >= comparePageNumber - distance && pageNumber <= comparePageNumber + distance
  }

  /* It's important these getters return a nullish value if no changePage function is provided, so that the traverser
   * component only receives a function if changePage was given. A noop function is not suitable here
   */

  get onPrevClick(): (() => void) | undefined {
    if (this.args.changePage) {
      return () => {
        const newPage = this.prevPageQuery?.[this.pageNumberKey]
        if (newPage) this.args.changePage?.(newPage)
      }
    }
  }

  get onNextClick(): (() => void) | undefined {
    if (this.args.changePage) {
      return () => {
        const newPage = this.nextPageQuery?.[this.pageNumberKey]
        if (newPage) this.args.changePage?.(newPage)
      }
    }
  }

  get onPageClick(): ((pageNumber: number) => void) | undefined {
    if (this.args.changePage) {
      return (pageNumber: number) => this.args.changePage?.(pageNumber)
    }
  }

  <template>
    {{#if (gt @pageCount 1)}}
      <nav class="flex flex-wrap justify-center gap-1 print:hidden" data-test-page-selector ...attributes>
        <BasePageSelectorTraverser
          aria-label={{t "base.pageSelector.prev"}}
          @isDisabled={{not this.prevPageQuery}}
          @route={{if this.router.currentRouteName this.router.currentRouteName}}
          @query={{this.prevPageQuery}}
          @onClick={{this.onPrevClick}}
          @buttonStyle="icon-only"
        >
          <FaIcon @icon="angle-left" />
        </BasePageSelectorTraverser>
        {{#each this.pageLinks as |pageLink|}}
          {{#if pageLink}}
            <BasePageSelectorTraverser
              aria-label={{pageLink.pageNumber}}
              @isActive={{eq this.currentPage pageLink.pageNumber}}
              @route={{if this.router.currentRouteName this.router.currentRouteName}}
              @query={{pageLink.query}}
              {{! The optional is required, despite the if guard, because all branches of an in-template if block are
              evaluated at runtime regardless of conditional value, and the fn helper errors if the fn is undefined }}
              @onClick={{if this.onPageClick (fn (optional this.onPageClick) pageLink.pageNumber)}}
              @buttonStyle="text"
            >
              {{pageLink.pageNumber}}
            </BasePageSelectorTraverser>
          {{else}}
            <BasePageSelectorTraverser @isDisabled={{true}} @buttonStyle="text" aria-label="...">
              ...
            </BasePageSelectorTraverser>
          {{/if}}
        {{/each}}
        <BasePageSelectorTraverser
          aria-label={{t "base.pageSelector.next"}}
          @isDisabled={{not this.nextPageQuery}}
          @route={{if this.router.currentRouteName this.router.currentRouteName}}
          @query={{this.nextPageQuery}}
          @onClick={{this.onNextClick}}
          @buttonStyle="icon-only"
        >
          <FaIcon @icon="angle-right" />
        </BasePageSelectorTraverser>
      </nav>
    {{/if}}
  </template>
}

export default BasePageSelector

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Base::PageSelector': typeof BasePageSelector
  }
}
