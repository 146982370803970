import type { TOC } from '@ember/component/template-only'
import { ReportingPageHeader } from 'district-ui-client/components/reporting/page-header'
import { ReportingScopeHeader } from 'district-ui-client/components/reporting/scope-header'

interface Signature {
  Element: HTMLDivElement
}

export const ReportingDistrictHeader: TOC<Signature> = <template>
  <ReportingPageHeader class="mb-8" />
  <ReportingScopeHeader />
</template>

export default ReportingDistrictHeader

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::DistrictHeader': typeof ReportingDistrictHeader
  }
}
