import type { TOC } from '@ember/component/template-only'
import type { Grade } from 'district-ui-client/services/grade-sets'
import { ModalDefault } from 'district-ui-client/components/modal-default'
import FidgetSpinnerWaveComponent from '@blakeelearning/fidget/components/fidget/spinner/wave'
import { UiButton } from 'district-ui-client/components/ui-button'
import { on } from '@ember/modifier'
import { fn } from '@ember/helper'
import { t } from 'ember-intl'

interface Args {
  isSubmitting?: boolean
  onClose: () => void
  grades: Grade[]
  onGradeSelected: (grade: Grade) => void
}

interface Signature {
  Args: Args
}

export const EditGradeModal: TOC<Signature> = <template>
  <ModalDefault data-test-modal-content="edit-grade" @onClose={{@onClose}} as |modal|>
    <modal.header>
      {{t "manage.students.groupActions.editGrade.title"}}
      {{#if @isSubmitting}}
        <div class="ml-1 inline-block align-middle">
          <FidgetSpinnerWaveComponent @small={{true}} />
        </div>
      {{/if}}
    </modal.header>
    <modal.body class="max-h-1/2-screen">
      <div class="stacked-buttons">
        {{#each @grades as |grade|}}
          <UiButton
            class="muted"
            disabled={{@isSubmitting}}
            {{on "click" (fn @onGradeSelected grade)}}
            data-test-grade-option={{grade.name}}
          >
            {{grade.name}}
          </UiButton>
        {{/each}}
      </div>
    </modal.body>
  </ModalDefault>
</template>

export default EditGradeModal

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Modals::EditGrade': typeof EditGradeModal
  }
}
