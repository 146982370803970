import Component from '@glimmer/component'
import { service } from '@ember/service'
import { on } from '@ember/modifier'
import { isEmpty } from '@ember/utils'
import { t } from 'ember-intl'
import { ThemedDropdownButton, ThemedDropdownContent } from 'district-ui-client/components/themed-dropdown'
import { ThemedMenu, ThemedMenuGroup, ThemedMenuItem } from 'district-ui-client/components/themed-menu'
import PopoverStandardDropdown from '@blakeelearning/popovers/components/popover/dropdowns/standard'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import type TableToCsv from 'district-ui-client/services/table-to-csv'
import type { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'

export interface ExportOption {
  id: string
  label: string
  icon: IconName
  iconPrefix?: IconPrefix
}

interface Signature {
  Args: {
    viewMode: 'table' | 'chart'
    exportOptions: ExportOption[]
    fileName?: string
    disabled?: boolean
    emailAllStudentsCSV?: () => void
  }
}

export class ExportButton extends Component<Signature> {
  @service tableToCsv!: TableToCsv

  get defaultOptions(): Record<string, { viewModes: string[]; action: () => void }> {
    return {
      csv: {
        action: this.exportAsCsv,
        viewModes: ['table'],
      },
      allStudentsCsv: {
        action: this.args.emailAllStudentsCSV ?? (() => {}),
        viewModes: ['table'],
      },
      print: {
        action: this.print,
        viewModes: ['table', 'chart'],
      },
    }
  }

  get isDisabled() {
    return this.args.disabled || isEmpty(this.args.exportOptions)
  }

  get fileName() {
    return this.args.fileName || 'export.csv'
  }

  get exportOptions() {
    const {
      defaultOptions,
      args: { exportOptions, viewMode },
    } = this

    return exportOptions.reduce<(ExportOption & { action: () => void })[]>((acc, option) => {
      const defaultOption = defaultOptions[option.id]

      if (defaultOption.viewModes.includes(viewMode)) {
        acc.push({ ...defaultOption, ...option })
      }

      return acc
    }, [])
  }

  /* Standard export actions */

  exportAsCsv = () => {
    const { fileName: filename } = this
    const exportTable = document.querySelectorAll('[exportable]:not([exportable=false])')
    if (exportTable.length > 0) {
      this.tableToCsv.downloadCsvFrom('[exportable]:not([exportable=false])', { filename })
    }
  }

  print = () => {
    window.print()
  }

  <template>
    <PopoverStandardDropdown @disabled={{@disabled}} as |dropdown _status actions|>
      <div {{dropdown.makeTrigger}}>
        <ThemedDropdownButton data-test-export-button class="print:hidden" @style="theme" @disabled={{@disabled}}>
          <span>{{t "reporting.exportButton.title"}}</span>
          <FaIcon @icon="caret-down" @size="sm" class="ml-0.5" />
        </ThemedDropdownButton>
      </div>
      <dropdown.content>
        <ThemedDropdownContent @constrainWidth={{true}}>
          <ThemedMenu>
            <ThemedMenuGroup>
              {{#each this.exportOptions as |option|}}
                <ThemedMenuItem
                  data-test-export-option={{option.id}}
                  class="cursor-pointer"
                  {{on "click" option.action}}
                  {{on "click" actions.close}}
                >
                  <FaIcon @icon={{option.icon}} @prefix={{option.iconPrefix}} @fixedWidth={{true}} />
                  {{option.label}}
                </ThemedMenuItem>
              {{/each}}
            </ThemedMenuGroup>
          </ThemedMenu>
        </ThemedDropdownContent>
      </dropdown.content>
    </PopoverStandardDropdown>
  </template>
}

export default ExportButton

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ExportButton: typeof ExportButton
  }
}
