import type { TOC } from '@ember/component/template-only'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'

interface Signature {
  Args: {
    matchErrorCode: string
    summaryText: string
  }
}

export const TeacherMatchErrorSummary: TOC<Signature> = <template>
  <div data-test-teacher-match-error={{@matchErrorCode}}>
    <FaIcon class="icon text-watermelony-red-300 mr-1" @icon="triangle-exclamation" @size="lg" @fixedWidth={{true}} />
    {{@summaryText}}
  </div>
</template>

export default TeacherMatchErrorSummary
