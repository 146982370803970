import type { TOC } from '@ember/component/template-only'

interface Signature {
  Element: HTMLDivElement
  Blocks: {
    default: []
  }
}

export const ModalDefaultFooter: TOC<Signature> = <template>
  <div ...attributes>
    {{yield}}
  </div>
</template>

export default ModalDefaultFooter
