import { service } from '@ember/service'
import { didCancel, task } from 'ember-concurrency'
import Component from '@glimmer/component'
import type AlertService from 'district-ui-client/services/alert'
import type StudentOperationsService from 'district-ui-client/services/student-operations'
import type { Log } from '@blakeelearning/log'
import type ActiveRouteService from 'district-ui-client/services/active-route'
import type { IntlService } from 'ember-intl'
import type GradeSetsService from 'district-ui-client/services/grade-sets'
import { tracked } from '@glimmer/tracking'
import type Student from 'district-ui-client/models/student'
import type { Grade } from 'district-ui-client/services/grade-sets'
import type Teacher from 'district-ui-client/models/teacher'
import type School from 'district-ui-client/models/school'
import PopoverStandardDropdown from '@blakeelearning/popovers/components/popover/dropdowns/standard'
import { UiButton } from 'district-ui-client/components/ui-button'
import { or, eq } from 'ember-truth-helpers'
import { t } from 'ember-intl'
import FidgetSpinnerWaveComponent from '@blakeelearning/fidget/components/fidget/spinner/wave'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import ActionMenu from 'district-ui-client/components/base/action-menu'
import ActionMenuItem from 'district-ui-client/components/base/action-menu/item'
import { on } from '@ember/modifier'
import { fn } from '@ember/helper'
import { ChangeSchoolModal } from 'district-ui-client/components/modals/change-school'
import { ChangeTeacherModal } from 'district-ui-client/components/modals/change-teacher'
import { EditPasswordModal } from 'district-ui-client/components/modals/edit-password'
import { EditGradeModal } from 'district-ui-client/components/modals/edit-grade'

interface Signature {
  Args: {
    selectedStudents: Student[]
    updateSelectedStudents: (selectedIds: string[]) => void
    isDisabled?: boolean
    currentSchool?: School
    schools?: School[]
  }
  Element: HTMLDivElement
}

type Modals = 'change-school' | 'change-teacher' | 'edit-password' | 'edit-grade'

export class ManageStudentActions extends Component<Signature> {
  @service alert!: AlertService

  @service studentOperations!: StudentOperationsService

  @service log!: Log

  @service activeRoute!: ActiveRouteService

  @service intl!: IntlService

  @service gradeSets!: GradeSetsService

  @tracked currentModal?: Modals = undefined

  setCurrentModal = (value?: Modals) => {
    this.currentModal = value
  }

  get showChangeTeacherOption() {
    return Boolean(this.args.currentSchool)
  }

  get showChangeSchoolOption() {
    return Boolean(this.args.schools)
  }

  get subscriptionType() {
    return this.activeRoute.subscriptionType
  }

  get selectedStudentsEmpty() {
    return !this.args.selectedStudents.length
  }

  handleStudentOp = task({ drop: true }, async (studentOpTaskInstance) => {
    let completed = false
    let error = undefined
    try {
      await studentOpTaskInstance
      completed = true
    } catch (e) {
      if (!didCancel(e)) error = e
    } finally {
      this.closeModalAndUncheckAll()
    }
    return completed ? { completed } : { completed, error }
  })

  private closeModalAndUncheckAll() {
    this.args.updateSelectedStudents?.([])
    this.currentModal = undefined
  }

  private studentOpsFailure(error: any) {
    const defaultMessage = this.intl.t('manage.students.groupActions.messages.error.defaultMsg')
    const errors = error?.payload?.errors ?? [{ detail: defaultMessage }]
    const errorMessages = errors.map((errorItem: any) => errorItem.detail)
    this.log.error('student-operations failed', error)
    this.alert.show({
      type: 'critical',
      message: errorMessages.join(', '),
    })
  }

  onGradeSelected = async (grade: Grade) => {
    this.args.selectedStudents.forEach((student) => (student.gradePosition = grade.position))

    const savePromises = this.args.selectedStudents.map((student) => student.save({ adapterOptions: { bulk: true } }))
    const { completed, error } = await this.handleStudentOp.perform(Promise.all(savePromises))
    if (completed) {
      const count = this.args.selectedStudents.length
      const message = this.intl.t('manage.students.groupActions.messages.success.editGrade', { count })
      this.alert.showWithDismiss({ message })
      return
    }
    if (error) this.studentOpsFailure(error)
    this.args.selectedStudents.forEach((student) => student.rollbackAttributes())
  }

  onPasswordUpdated = async (password: string) => {
    this.args.selectedStudents.forEach((student) => (student.plainPassword = password))

    const savePromises = this.args.selectedStudents.map((student) => student.save({ adapterOptions: { bulk: true } }))
    const { completed, error } = await this.handleStudentOp.perform(Promise.all(savePromises))
    if (completed) {
      const count = this.args.selectedStudents.length
      const message = this.intl.t('manage.students.groupActions.messages.success.editPassword', { count })
      this.alert.showWithDismiss({ message })
      return
    }
    if (error) this.studentOpsFailure(error)
    this.args.selectedStudents.forEach((student) => student.rollbackAttributes())
  }

  /**
   * Remove all school classes from students for the given subscription type
   */

  removeStudentsFromClass = async () => {
    this.args.selectedStudents.map((student) => {
      student.set(
        'schoolClasses',
        student.schoolClasses.filter((schoolClass) => schoolClass.subscriptionType !== this.subscriptionType),
      )
    })

    const savePromises = this.args.selectedStudents.map((student) => student.save({ adapterOptions: { bulk: true } }))
    const { completed, error } = await this.handleStudentOp.perform(Promise.all(savePromises))
    if (completed) {
      const count = this.args.selectedStudents.length
      const message = this.intl.t('manage.students.groupActions.messages.success.removeFromClasses', { count })
      this.alert.showWithDismiss({ message })
      return
    }
    if (error) this.studentOpsFailure(error)
  }

  onTeacherSelected = async (teacher: Teacher) => {
    // replace all classes for student with whatever the teachers' first class for this subtype is
    this.args.selectedStudents.forEach((student) => {
      const firstClass = teacher.schoolClasses?.find(
        (schoolClass) => schoolClass.subscriptionType === this.subscriptionType,
      )

      student.set('schoolClasses', firstClass ? [firstClass] : [])
    })

    const savePromises = this.args.selectedStudents.map((student) => student.save({ adapterOptions: { bulk: true } }))
    const { completed, error } = await this.handleStudentOp.perform(Promise.all(savePromises))
    if (completed) {
      const count = this.args.selectedStudents.length
      const message = this.intl.t('manage.students.groupActions.messages.success.changeTeacher', { count })
      this.alert.showWithDismiss({ message })
      return
    }
    if (error) this.studentOpsFailure(error)
  }

  onSchoolAndTeacherSelected = async ({ school, teacher }: { school: School; teacher?: Teacher }) => {
    // remember existing school / classes in case of rollback
    const existingRelationships = this.args.selectedStudents.map((student) => ({
      student,
      school: student.school,
      schoolClasses: student.schoolClasses.slice(), // a shallow copy of the array to avoid it changing
    }))
    // replace all classes for student with whatever the teachers' first class for this subtype is
    // if no teacher given, will just move the student to the school with no classes
    this.args.selectedStudents.forEach((student) => {
      student.school = school
      const firstClass = teacher?.schoolClasses?.find(
        (schoolClass) => schoolClass.subscriptionType === this.subscriptionType,
      )
      student.set('schoolClasses', firstClass ? [firstClass] : [])
    })

    const savePromises = this.args.selectedStudents.map((student) => student.save({ adapterOptions: { bulk: true } }))
    const { completed, error } = await this.handleStudentOp.perform(Promise.all(savePromises))
    if (completed) {
      const message = this.intl.t('manage.students.groupActions.messages.success.changeSchool', {
        count: this.args.selectedStudents.length,
        schoolName: school.name,
      })
      this.alert.showWithDismiss({ message })
      return
    }

    // rollback relationships
    existingRelationships.forEach((existing) => {
      existing.student.school = existing.school
      existing.student.set('schoolClasses', existing.schoolClasses)
    })

    if (error) this.studentOpsFailure(error)
  }

  openConfirmDeleteModal = async () => {
    const count = this.args.selectedStudents.length

    const confirmMessage = this.intl.t('manage.students.groupActions.messages.confirm.delete', { count })
    const isConfirmed = window.confirm(confirmMessage)

    if (isConfirmed) {
      this.args.selectedStudents.forEach((student) => student.deleteRecord())
      const savePromises = this.args.selectedStudents.map((student) => student.save({ adapterOptions: { bulk: true } }))
      const { completed, error }: { completed: boolean; error?: any } = await this.handleStudentOp.perform(
        Promise.all(savePromises),
      )

      if (completed) {
        const message = this.intl.t('manage.students.groupActions.messages.success.delete', { count })
        this.alert.showWithDismiss({ message })
        return
      }
      this.args.selectedStudents.forEach((student) => student.rollbackAttributes())
      // handle if student in other classes
      const studentInClassesError = error.errors?.find(
        (e: any) => e?.status === '422' && e?.code.startsWith('student_enrolled_in_'),
      )
      if (studentInClassesError) {
        // no need to log to rollbar, we are handling this error
        const studentIdsInClasses = studentInClassesError.meta?.student_ids ?? []
        const studentsInClasses = this.args.selectedStudents.reduce<Student[]>((acc, student) => {
          if (studentIdsInClasses.includes(student.id)) {
            return [...acc, student]
          }
          return acc
        }, [])
        if (!studentIdsInClasses.length) {
          const message = this.intl.t('manage.students.groupActions.messages.error.studentsInClasses', {
            names: this.intl.t('students'),
          })
          this.alert.show({ type: 'critical', message })
          return
        }
        const message = this.intl.t('manage.students.groupActions.messages.error.studentsInClasses', {
          names: studentsInClasses.map((s) => s.fullName).join(', '),
        })
        this.alert.show({ type: 'critical', message })
        return
      }
      this.studentOpsFailure(error)
    }
  }

  <template>
    <div class="mx-3 mb-3 inline-block align-middle" ...attributes>
      <PopoverStandardDropdown @disabled={{or @isDisabled this.handleStudentOp.isRunning}} as |dropdown status actions|>
        <UiButton
          data-test-manage-student-actions
          class="muted"
          @corners="rounded"
          aria-haspopup="menu"
          disabled={{or @isDisabled this.handleStudentOp.isRunning}}
          {{dropdown.makeTrigger}}
        >
          {{t "manage.students.groupActions.title"}}
          {{#if this.handleStudentOp.isRunning}}
            <div class="ml-1 inline-block align-middle"><FidgetSpinnerWaveComponent @small={{true}} /></div>
          {{else}}
            <FaIcon @icon="caret-down" class="ml-1" />
          {{/if}}
        </UiButton>
        <dropdown.content>
          <ActionMenu data-test-actions-menu>
            <ActionMenuItem
              data-test-edit-grade
              {{on "click" (fn this.setCurrentModal "edit-grade")}}
              {{on "click" actions.close}}
            >
              <FaIcon @icon="users" @fixedWidth={{true}} />
              {{t "manage.students.groupActions.options.editGrade"}}
            </ActionMenuItem>
            <ActionMenuItem
              data-test-edit-password
              {{on "click" (fn this.setCurrentModal "edit-password")}}
              {{on "click" actions.close}}
            >
              <FaIcon @icon="lock" @fixedWidth={{true}} />
              {{t "manage.students.groupActions.options.editPassword"}}
            </ActionMenuItem>
            {{#if this.showChangeTeacherOption}}
              <ActionMenuItem
                data-test-change-teacher
                {{on "click" (fn this.setCurrentModal "change-teacher")}}
                {{on "click" actions.close}}
              >
                <FaIcon @icon="building-columns" @fixedWidth={{true}} />
                {{t "manage.students.groupActions.options.changeTeacher"}}
              </ActionMenuItem>
            {{/if}}
            {{#if this.showChangeSchoolOption}}
              <ActionMenuItem
                data-test-change-school
                {{on "click" (fn this.setCurrentModal "change-school")}}
                {{on "click" actions.close}}
              >
                <FaIcon @icon="building-columns" @fixedWidth={{true}} />
                {{t "manage.students.groupActions.options.changeSchool"}}
              </ActionMenuItem>
            {{/if}}
            <ActionMenuItem
              data-test-remove-students-from-class
              {{on "click" this.removeStudentsFromClass}}
              {{on "click" actions.close}}
            >
              <FaIcon @icon="xmark" @fixedWidth={{true}} />
              {{#if @currentSchool}}
                {{t "manage.students.groupActions.options.removeFromClass"}}
              {{else}}
                {{t "manage.students.groupActions.options.removeFromAllClasses"}}
              {{/if}}
            </ActionMenuItem>
            <ActionMenuItem
              data-test-delete-students
              {{on "click" this.openConfirmDeleteModal}}
              {{on "click" actions.close}}
            >
              <FaIcon @icon="trash" @fixedWidth={{true}} />
              {{t "manage.students.groupActions.options.delete"}}
            </ActionMenuItem>
          </ActionMenu>
        </dropdown.content>
      </PopoverStandardDropdown>

      {{#if (eq this.currentModal "edit-grade")}}
        <EditGradeModal
          @grades={{this.gradeSets.grades}}
          @onGradeSelected={{this.onGradeSelected}}
          @isSubmitting={{this.handleStudentOp.isRunning}}
          @onClose={{fn this.setCurrentModal undefined}}
        />
      {{/if}}

      {{#if (eq this.currentModal "edit-password")}}
        <EditPasswordModal
          @onPasswordUpdated={{this.onPasswordUpdated}}
          @isSubmitting={{this.handleStudentOp.isRunning}}
          @onClose={{fn this.setCurrentModal undefined}}
        />
      {{/if}}

      {{#if (eq this.currentModal "change-teacher")}}
        {{#if @currentSchool}}
          <ChangeTeacherModal
            @school={{@currentSchool}}
            @subscriptionType={{this.subscriptionType}}
            @onTeacherSelected={{this.onTeacherSelected}}
            @isSubmitting={{this.handleStudentOp.isRunning}}
            @onClose={{fn this.setCurrentModal undefined}}
          />
        {{/if}}
      {{/if}}

      {{#if (eq this.currentModal "change-school")}}
        {{#if @schools}}
          <ChangeSchoolModal
            @schools={{@schools}}
            @subscriptionType={{this.subscriptionType}}
            @onSchoolAndTeacherSelected={{this.onSchoolAndTeacherSelected}}
            @isSubmitting={{this.handleStudentOp.isRunning}}
            @onClose={{fn this.setCurrentModal undefined}}
          />
        {{/if}}
      {{/if}}
    </div>
  </template>
}

export default ManageStudentActions

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ManageStudentActions: typeof ManageStudentActions
  }
}
