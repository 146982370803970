import type { TOC } from '@ember/component/template-only'
interface Signature {
  Element: HTMLLIElement
  Blocks: {
    default: []
  }
}

export const ActionMenuItem: TOC<Signature> = <template>
  <li
    role="menuitem"
    class="text-dusty-black-500 hover:bg-dusty-black-50 focus:bg-dusty-black-50 cursor-pointer whitespace-nowrap px-4 py-1"
    ...attributes
  >
    {{yield}}
  </li>
</template>

export default ActionMenuItem
