import type { TOC } from '@ember/component/template-only'
import { Alert } from 'district-ui-client/components/alert'
import { t } from 'ember-intl'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import type { SafeString } from '@ember/template'

export interface StudentImportError {
  csvRow?: number
  detail: string | SafeString
}

interface Signature {
  Args: {
    messages: StudentImportError[]
  }
}
export const ImportPanelStudentErrors: TOC<Signature> = <template>
  <div data-test-upload-errors>
    <Alert @tone="critical" class="mt-5">
      {{t "manage.studentCsv.errorInlineFlash" htmlSafe=true}}
    </Alert>

    <table class="mt-5 w-full bg-white">
      <thead>
        <tr class="border-dusty-black-250 border-l border-r">
          <th class="bg-dusty-black-250 w-24 border-r border-white p-5 font-normal text-white"></th>
          <th class="bg-dusty-black-250 w-64 border-r border-white p-5 text-center font-normal text-white">{{t
              "manage.csv.lineNo"
            }}</th>
          <th class="bg-dusty-black-250 border-0 border-r border-white p-5 font-normal text-white">{{t
              "manage.csv.rowErrorHeading"
            }}</th>
        </tr>
      </thead>
      <tbody class="border-dusty-black-100 border-l border-r">
        {{#each @messages as |error index|}}
          <tr data-test-error-row={{index}}>
            <td class="border-dusty-black-100 bg-watermelony-red-50 border-b p-5 text-center font-normal">
              <FaIcon @icon="triangle-exclamation" @transform="grow-2" class="text-watermelony-red-300" />
            </td>
            <td
              class="border-dusty-black-100 bg-watermelony-red-50 border-b p-5 text-center font-normal"
              data-test-error-csv-line-cell
            >
              {{#if error.csvRow}}
                {{error.csvRow}}
              {{else}}
                {{t "manage.csv.na"}}
              {{/if}}
            </td>
            <td class="border-dusty-black-100 bg-watermelony-red-50 border-b p-5 font-normal" data-test-error-msg-cell>
              {{error.detail}}
            </td>
          </tr>
        {{/each}}
      </tbody>
    </table>
  </div>
</template>

export default ImportPanelStudentErrors
