import eventValue from 'district-ui-client/helpers/event-value'
import { on } from '@ember/modifier'
import { service } from '@ember/service'
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import ManageHeading from 'district-ui-client/components/manage-heading'
import Panel from 'district-ui-client/components/panel'
import RolloverAlert from 'district-ui-client/components/rollover-alert'
import UiButton from 'district-ui-client/components/ui-button'
import themeKey from 'district-ui-client/helpers/theme-key'
import type ManageTeacherEditRoute from 'district-ui-client/subscription-type/manage/teachers/edit/route'
import type ActiveRouteService from 'district-ui-client/services/active-route'
import type AlertService from 'district-ui-client/services/alert'
import type SessionService from 'district-ui-client/services/session'
import { t } from 'ember-intl'
import RouteTemplate from 'ember-route-template'
import { hasLoginExistsError } from 'district-ui-client/errors/login-already-exists-error'
import { hasEmailExistsError } from 'district-ui-client/errors/email-already-exists-error'
import { InputText } from 'district-ui-client/components/input-text'

interface Signature {
  Args: {
    model: ModelFor<ManageTeacherEditRoute>
  }
}

export class ManageTeachersEditRouteTemplate extends Component<Signature> {
  @service session!: SessionService

  @service alert!: AlertService

  @service activeRoute!: ActiveRouteService

  @tracked updatingTeacher = false

  get isCleverDisabled() {
    return this.session.currentDistrict.cleverDisabledManagementFor(this.subscriptionType)
  }

  get subscriptionType() {
    return this.activeRoute.subscriptionType
  }

  get teacher() {
    return this.args.model.teacher
  }

  cancel = () => {
    this.teacher.rollbackAttributes()
    this.backToPrevious()
  }

  updateTeacher = async () => {
    try {
      this.updatingTeacher = true
      await this.teacher.save()
      this.updatingTeacher = false
      this.backToPrevious()
      this.alert.showWithDismiss({
        type: 'positive',
        message: `Successfully updated teacher ${this.teacher.fullNameReversed}`,
      })
    } catch (errorPayload: unknown) {
      this.teacher.rollbackAttributes()
      this.updatingTeacher = false

      if (hasLoginExistsError(errorPayload)) {
        this.formError('Login already exists')
        return
      }
      if (hasEmailExistsError(errorPayload)) {
        this.formError('Email already exists')
        return
      }

      return this.formError('Failed to update details')
    }
  }

  formError = (message: string) => {
    this.alert.showWithDismiss({
      type: 'critical',
      message,
    })
  }

  backToPrevious() {
    window.history.back()
  }

  setFirstName = (firstName: string) => {
    this.teacher.firstName = firstName
  }

  setLastName = (lastName: string) => {
    this.teacher.lastName = lastName
  }

  setLogin = (login: string) => {
    this.teacher.login = login
  }

  setEmail = (email: string) => {
    this.teacher.email = email
  }

  <template>
    <ManageHeading>{{t "subscriptionType.manageTeachers.edit.manageTeachers"}}</ManageHeading>
    <RolloverAlert />

    <div class="mx-auto mt-6 md:w-full lg:w-2/3">
      <Panel
        @theme={{themeKey this.subscriptionType}}
        @title={{t "subscriptionType.manageTeachers.edit.editTeacherDetails"}}
      >
        <form class="space-y-4">
          {{#unless this.isCleverDisabled}}
            <div class="flex items-center justify-between">
              <label for="login" class="w-1/4">{{t "subscriptionType.manageTeachers.edit.login"}}</label>
              <InputText
                id="login"
                class="w-3/4"
                disabled={{this.isCleverDisabled}}
                value={{this.teacher.login}}
                {{on "input" (eventValue this.setLogin)}}
              />
            </div>
          {{/unless}}
          <div class="flex items-center justify-between">
            <label for="first-name" class="w-1/4">{{t "subscriptionType.manageTeachers.edit.firstName"}}</label>
            <InputText
              id="first-name"
              class="w-3/4"
              disabled={{this.isCleverDisabled}}
              value={{this.teacher.firstName}}
              {{on "input" (eventValue this.setFirstName)}}
            />
          </div>

          <div class="flex items-center justify-between">
            <label for="last-name" class="w-1/4">{{t "subscriptionType.manageTeachers.edit.lastName"}}</label>
            <InputText
              id="last-name"
              class="w-3/4"
              disabled={{this.isCleverDisabled}}
              value={{this.teacher.lastName}}
              {{on "input" (eventValue this.setLastName)}}
            />
          </div>
          <div class="flex items-center justify-between">
            <label for="email" class="w-1/4">{{t "subscriptionType.manageTeachers.edit.email"}}</label>
            <InputText
              id="email"
              class="w-3/4"
              disabled={{this.isCleverDisabled}}
              value={{this.teacher.email}}
              {{on "input" (eventValue this.setEmail)}}
            />
          </div>
          <div class="text-right">
            <UiButton class="muted" {{on "click" this.cancel}}>{{t
                "subscriptionType.manageTeachers.edit.cancel"
              }}</UiButton>
            <UiButton class="regular" disabled={{this.updatingTeacher}} {{on "click" this.updateTeacher}}>{{t
                "subscriptionType.manageTeachers.edit.updateTeacher"
              }}</UiButton>
          </div>
        </form>
      </Panel>
    </div>
  </template>
}

export default RouteTemplate(ManageTeachersEditRouteTemplate)
