import Controller from '@ember/controller'
import { action } from '@ember/object'
import { service } from '@ember/service'
import config from 'district-ui-client/config/environment'
import { didCancel, type TaskInstance } from 'ember-concurrency'
import { isTaskInstanceActive as isActive } from 'district-ui-client/student-import/utils'
import type ActiveRouteService from 'district-ui-client/services/active-route'
import type { IntlService } from 'ember-intl'
import type StudentImportService from 'district-ui-client/services/student-import'
import type FlashQueueService from 'district-ui-client/services/flash-queue'
import type RouterService from '@ember/routing/router-service'
import type FileDownload from 'district-ui-client/services/file-download'
import type { TaskInstanceState } from 'district-ui-client/components/import-panel-student/component'

const {
  csvImport: { redirectOnSuccessTimeout },
} = config

export default class ImportStudentsAsyncController extends Controller {
  @service activeRoute!: ActiveRouteService

  @service intl!: IntlService

  @service studentImport!: StudentImportService

  @service flashQueue!: FlashQueueService

  @service router!: RouterService

  @service fileDownload!: FileDownload

  get subscriptionType() {
    return this.activeRoute.subscriptionType
  }

  get activeImportTaskInstance() {
    const taskInstance = this.studentImport.getLastForSubscriptionType(this.subscriptionType) as TaskInstanceState
    return isActive(taskInstance) ? taskInstance : null
  }

  get manageStudentsUrl() {
    return this.router.urlFor('subscription-type.manage.students', this.subscriptionType)
  }

  _showSuccessAlert() {
    const timeout = redirectOnSuccessTimeout
    const url = this.manageStudentsUrl
    const message = this.intl.t('manage.studentCsv.importSuccessful', { url, htmlSafe: true })

    // show alert until timeout or the flash is clicked
    this.flashQueue.addSuccess({ subtitle: message }, { timeout })
  }

  /**
   * Generates a sample student Import CSV array
   *
   * @return {string[][]} data array for csv
   */
  generateStudentSampleImportData() {
    const fieldPrefix = 'manage.students.sampleCsv.fields'

    const row = [
      this.intl.t(`${fieldPrefix}.firstName`),
      this.intl.t(`${fieldPrefix}.lastName`),
      this.intl.t(`${fieldPrefix}.grade`),
      this.intl.t(`${fieldPrefix}.teacherEmail`),
      this.intl.t(`${fieldPrefix}.schoolCode`),
      this.intl.t(`${fieldPrefix}.externalId`),
    ]

    return Array.from({ length: 4 }, () => row)
  }

  /**
   * @param {File} file
   */
  @action
  async uploadCsv(file: File) {
    try {
      const importTaskInstance = this.studentImport.perform(file) as TaskInstance<{ ok?: boolean }>
      const result = await importTaskInstance
      if (result?.ok) {
        this._showSuccessAlert()
      }
    } catch (e) {
      if (!didCancel(e)) throw e // re-throw the non-cancelation error
    }
  }

  @action
  submitCsv() {
    this.activeImportTaskInstance?.submitCsv()
  }

  @action
  cancel() {
    this.activeImportTaskInstance?.cancelTask()
  }

  @action
  downloadSampleCsv() {
    const data = this.generateStudentSampleImportData()
    const filename = this.intl.t('manage.students.sampleCsv.filename')
    this.fileDownload.downloadAsCsv(data, { filename })
  }
}
