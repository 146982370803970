import Component from '@glimmer/component'
import { createContainerElement } from '../../../utils/create-container-element.ts'
import type Owner from '@ember/owner'

export type DropdownContentX =
  | 'auto'
  | 'auto-right'
  | 'left'
  | 'center'
  | 'right'
export type DropdownContentY = 'auto' | 'above' | 'below'

export interface Signature {
  Args: {
    contentX?: DropdownContentX
    contentY?: DropdownContentY
    disabled?: boolean
    onClose(): void
    matchTriggerWidth?: boolean
  }
}

/**
 * This component is intended to be a layer between the underlying dropdown library and our other dropdown components.
 * It should be used as a base for other dropdown components within this addon. While it's possible to use this
 * externally, you're likely better off using one of the other dropdown components in this addon, or creating one that
 * meets your requirements.
 */
export default class PopoverBaseDropdownComponent extends Component<Signature> {
  constructor(owner: Owner, args: Signature['Args']) {
    super(owner, args)

    createContainerElement(this.dropdownContainerId)
  }

  get contentX(): DropdownContentX {
    return this.args.contentX ?? 'auto'
  }

  get contentY(): DropdownContentY {
    return this.args.contentY ?? 'auto'
  }

  get matchTriggerWidth(): boolean {
    return this.args.matchTriggerWidth ?? false
  }

  dropdownContainerId = 'popover-dropdown-wormhole'
}
