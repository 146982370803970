import RouteTemplate from 'ember-route-template'
import type { TOC } from '@ember/component/template-only'
import { AlertMessage } from 'district-ui-client/components/alert-message'
import { SideNav } from 'district-ui-client/components/side-nav'
import type SubscriptionTypeRoute from 'district-ui-client/subscription-type/route'

interface Signature {
  Args: {
    model: ModelFor<SubscriptionTypeRoute>
  }
}

export const SubscriptionTypePage: TOC<Signature> = <template>
  <SideNav class="min-h-0 flex-shrink flex-grow" @subscriptionType={{@model.subscriptionType}}>
    <AlertMessage />
    {{outlet}}
  </SideNav>
</template>

export default RouteTemplate(SubscriptionTypePage)
