import type { TOC } from '@ember/component/template-only'
import { on } from '@ember/modifier'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import { optional } from 'district-ui-client/helpers/optional'
import { t } from 'ember-intl'

interface Signature {
  Element: HTMLDivElement
  Args: {
    onClose?: () => unknown
  }
  Blocks: {
    default: []
  }
}

export const ModalDefaultHeader: TOC<Signature> = <template>
  <div class="border-dusty-black-100 flex justify-between border-b border-solid p-4" ...attributes>
    <h4 data-test-modal-title class="leading-[1.4]">
      {{yield}}
    </h4>
    <button
      data-test-close-modal-x
      type="button"
      class="text-2xl font-bold leading-4 text-black opacity-20 [text-shadow:_0_1px_0_#FFFFFF] hover:cursor-pointer hover:text-black hover:opacity-50 focus:cursor-pointer focus:text-black focus:opacity-50"
      aria-label={{t "components.modalDefault.header.close"}}
      {{on "click" (optional @onClose)}}
    >
      <FaIcon @icon="xmark" class="w-4" />
    </button>

  </div>
</template>

export default ModalDefaultHeader
