import RouteTemplate from 'ember-route-template'
import Component from '@glimmer/component'
import { service } from '@ember/service'
import { t } from 'ember-intl'
import { StandardsPicker } from 'district-ui-client/components/standards-picker'
import { PageTitle, SectionTitle } from 'district-ui-client/components/page-title'
import type SessionService from 'district-ui-client/services/session'

class DistrictSettingsPage extends Component<unknown> {
  @service session!: SessionService

  <template>
    <div class="mx-auto w-full max-w-screen-lg space-y-8">
      <PageTitle>{{t "districtSettings"}}</PageTitle>
      <section class="flex flex-col">
        <SectionTitle class="mb-6">
          {{t "stateAcademicStandards.title"}}
        </SectionTitle>
        <StandardsPicker
          class="w-full max-w-[600px] self-center"
          @district={{this.session.currentDistrict}}
          @countryCode={{this.session.currentDisco.countryCode}}
        />
      </section>
    </div>
  </template>
}

export default RouteTemplate(DistrictSettingsPage)
