import Component from '@glimmer/component'
import { service } from '@ember/service'
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter'
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format'
import colors from '@blakeelearning/blake-colours/colours'
import type { IntlService } from 'ember-intl'
import { formatValue } from 'district-ui-client/utils/format-value'
import { LeftSplitChart } from 'district-ui-client/components/left-split-chart'
import { SummaryBox, SummaryBoxColorScheme } from 'district-ui-client/components/summary-box'
import { t } from 'ember-intl'
import { TooltipInfo } from 'district-ui-client/components/tooltip'
import SeriesColumn from 'district-ui-client/components/primitives/series-column/component'
import type { SeriesColumnOptions, Point } from 'highcharts'

/**
 * Puts together all the pieces for the storylands totals chart.
 */

interface SummaryTotalCount {
  category: 'total_count'
  count: number
}

interface SummaryAverageScore {
  category: 'average_score'
  average: Nullable<number>
}

export interface StorylandsLessonsData {
  grade_position: number
  summary: Array<SummaryTotalCount | SummaryAverageScore>
  months: { month: string; count: Nullable<number> }[]
}

interface Signature {
  Args: {
    data?: StorylandsLessonsData
    grade?: string
    tooltip?: string
    title?: string
  }
  Element: HTMLDivElement
}

export class ChartsStorylandsLessonsTotals extends Component<Signature> {
  @service intl!: IntlService

  /**
   * Computed list of summary statistics, extracted from the input data.
   * Display total lessons completed and average score per student.
   */
  get summaryStats() {
    const summaryData = this.args.data?.summary ?? []
    const total = summaryData.find((d): d is SummaryTotalCount => d.category === 'total_count')?.count ?? 0
    const averageScore = summaryData.find((d): d is SummaryAverageScore => d.category === 'average_score')?.average

    return [
      { label: this.intl.t('reporting.summaryTitles.storylandsTotals.total'), value: formatNumber(total, 0) },
      {
        label: this.intl.t('reporting.summaryTitles.storylandsTotals.average'),
        value: formatValue(averageScore, { append: '%' }),
      },
    ]
  }

  /**
   * Computed list of column data, extracted from the input data.
   * Formats month data for Highcharts.
   */
  get columns(): SeriesColumnOptions[] {
    const monthsData = this.args.data?.months ?? []
    const color = colors.juicyOrange300

    const data = monthsData.map((monthData, index) => ({ x: index, y: monthData.count, monthData }))

    return [{ name: 'count', color, data, type: 'column' }]
  }

  get months() {
    return this.args.data?.months?.map((m) => convertDateFormat(m.month)) ?? []
  }

  /**
   * A formatter function to pass to Highcharts to format the tooltip label.
   */
  toolTipFormatter(this: Point) {
    const point = this as Point & { monthData: { month: string; count: number } }
    return `${point.monthData.count}`
  }

  <template>
    <div data-test-storylands-lessons-totals class="relative" ...attributes>
      <LeftSplitChart>
        <:left>
          <SummaryBox
            class="h-full"
            @grade={{@grade}}
            @stats={{this.summaryStats}}
            @colorScheme={{SummaryBoxColorScheme.Orange}}
          />
        </:left>
        <:right>
          <TooltipInfo class="z-tooltip absolute right-3 top-3 text-xs print:hidden" @text={{@tooltip}} />
          <SeriesColumn
            @title={{@title}}
            @data={{this.columns}}
            @categories={{this.months}}
            @yAxisLabel={{t "reporting.yAxisLabels.storylandsTotals"}}
            @chartSpacing={{Array 10 10 10 10}}
            @toolTipFormatter={{this.toolTipFormatter}}
          />
        </:right>
      </LeftSplitChart>
    </div>
  </template>
}
export default ChartsStorylandsLessonsTotals
