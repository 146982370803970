import Component from '@glimmer/component'
import { service } from '@ember/service'
import {
  isSchoolInitDone,
  isSchoolMatchDone,
  areTeacherMatchesDone,
  areStudentMatchesDone,
} from 'district-ui-client/utils/clever/clever-school/sync-process-states'
import { t, type IntlService } from 'ember-intl'
import { LinkTo } from '@ember/routing'
import themeClass from 'district-ui-client/helpers/theme-class'
import type { ThemeKeys } from 'district-ui-client/utils/themes'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'

interface Signature {
  Args: {
    syncProcessState?: string
    theme: ThemeKeys
  }
}

/**
 * This component renders a bar with 3 elements.
 * The current route defines which of the steps becomes active.
 * The current sync state determines which of the steps are done or disabled.
 *
 */
export class CleverWorkflowBar extends Component<Signature> {
  @service intl!: IntlService

  /**
   * Used to determine what match steps are available
   *
   * @property currentSyncState
   * @type string
   */
  get currentSyncState() {
    return this.args.syncProcessState || null
  }

  /**
   * Computes the steps and their states to be rendered
   */
  get steps() {
    const { intl, currentSyncState } = this

    const initDone = currentSyncState ? isSchoolInitDone(currentSyncState) : false
    const schoolDone = currentSyncState ? isSchoolMatchDone(currentSyncState) : false
    const teachersDone = currentSyncState ? areTeacherMatchesDone(currentSyncState) : false
    const studentsDone = currentSyncState ? areStudentMatchesDone(currentSyncState) : false

    return [
      {
        stepId: 'school',
        title: intl.t('clever.workflowBarSchoolTitle'),
        route: 'clever.match.schools.index',
        done: schoolDone,
        disabled: !initDone,
      },
      {
        stepId: 'teachers',
        title: intl.t('clever.workflowBarTeachersTitle'),
        route: 'clever.match.schools.teachers',
        done: teachersDone,
        disabled: !schoolDone,
      },
      {
        stepId: 'students',
        title: intl.t('clever.workflowBarStudentsTitle'),
        route: 'clever.match.schools.students',
        done: studentsDone,
        disabled: !teachersDone,
      },
    ]
  }

  <template>
    <div class="__clever-ui__workflow-bar__85498" data-test-workflow-bar>
      <span class="whitespace-nowrap">
        {{t "clever.workflowLabel"}}
      </span>
      <div class="border-dusty-black-200 mx-3 flex flex-grow overflow-hidden rounded-md border">
        {{#each this.steps as |step|}}
          <LinkTo
            data-test-workflow-link={{step.stepId}}
            class="step-link border-dusty-black-200 inline-flex w-0 flex-grow items-center border-r bg-white p-2
              {{themeClass @theme}}"
            role="button"
            @route={{step.route}}
            @disabled={{step.disabled}}
            @activeClass="step-active"
          >
            <span>
              {{step.title}}
            </span>
            {{#if step.done}}
              <FaIcon @icon="circle-check" class="done-icon ml-auto" />
            {{/if}}
          </LinkTo>
        {{/each}}
      </div>
      <span class="invisible whitespace-nowrap" aria-hidden={{true}}>
        {{! A hidden label, at the end, to ensure the workflow steps are centered }}
        {{t "clever.workflowLabel"}}
      </span>
    </div>
  </template>
}

export default CleverWorkflowBar
