import Component from '@glimmer/component'
import { service } from '@ember/service'
import Alert from 'district-ui-client/components/alert'
import type AlertService from 'district-ui-client/services/alert'

export class AlertMessage extends Component {
  @service alert!: AlertService

  closeAlert = () => {
    this.alert.closeAlert()
  }

  <template>
    {{#if this.alert.alertContent}}
      <Alert
        data-test-alert-message
        @showIcon={{false}}
        @closeAlertAction={{this.closeAlert}}
        @tone={{this.alert.alertContent.type}}
      >
        {{this.alert.alertContent.message}}
      </Alert>
    {{/if}}
  </template>
}

export default AlertMessage

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AlertMessage: typeof AlertMessage
  }
}
