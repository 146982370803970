import Component from '@glimmer/component'
import { service } from '@ember/service'
import { isEmpty, isPresent } from '@ember/utils'
import { reportingLegend, reportingPlotLine } from 'district-ui-client/utils/giraffe/highcharts-config'
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format'
import BlakeColors from '@blakeelearning/blake-colours/colours'
import type GradeSetsService from 'district-ui-client/services/grade-sets'
import type { Point, SeriesLineOptions, AxisLabelsFormatterCallbackFunction, YAxisPlotLinesOptions } from 'highcharts'
import { TooltipInfo } from 'district-ui-client/components/tooltip'
import SeriesLine from 'district-ui-client/components/primitives/series-line/component'
import { Precinct } from 'district-ui-client/domain/precinct'
import type LessonInfoService from 'district-ui-client/services/lesson-info'

/**
 * Puts together all the pieces for the course progress averages chart.
 *
 * Data format:
 *
 * ```
 * {
 *   grade_position: 1,
 *   months: [
 *     {
 *       avg_max_lesson: 50,
 *       month: '2016-07',
 *     },
 *     {
 *       avg_max_lesson: 70,
 *       month: '2016-08',
 *     },
 *   ],
 * }
 * ```
 */

interface CourseProgressMonthData {
  avg_max_lesson: number
  month: string
}

export interface CourseProgressAveragesData {
  grade_position: number
  months: CourseProgressMonthData[]
}

interface Signature {
  Args: {
    title: string
    data: CourseProgressAveragesData
    precinct:
      | typeof Precinct.RE_READING
      | typeof Precinct.RE_SPELLING
      | typeof Precinct.REX_READING
      | typeof Precinct.REX_SPELLING
      | typeof Precinct.MS_LESSONS
    tooltip?: string
  }
  Element: HTMLDivElement
}

export class ChartsCourseProgressAverages extends Component<Signature> {
  @service gradeSets!: GradeSetsService

  @service lessonInfo!: LessonInfoService

  get hyphenatedTitle() {
    const mainChartTitle = this.args.title
    const gradePosition = this.args.data.grade_position
    if (typeof gradePosition !== 'number') return `${mainChartTitle}`
    const gradeSuffix = this.gradeSets.findByPosition(gradePosition)?.fullName
    return `${mainChartTitle} - ${gradeSuffix}`
  }

  /**
   * Computed list of column data, extracted from the input data.
   * Formats month data for Highcharts.
   */
  get chartData(): SeriesLineOptions[] {
    const data =
      this.args.data.months?.map((monthData, index) => ({ x: index, y: monthData.avg_max_lesson, monthData })) ?? []
    return [{ name: 'avg_max_lesson', color: this.color, data, type: 'line' }]
  }

  get color() {
    switch (this.args.precinct) {
      case Precinct.MS_LESSONS:
        return BlakeColors.forestGreen300
      case Precinct.RE_SPELLING:
      case Precinct.REX_SPELLING:
        return BlakeColors.grapeyGreen300
      case Precinct.RE_READING:
      case Precinct.REX_READING:
        return BlakeColors.oceanyBlue300
      default:
        return BlakeColors.oceanyBlue300
    }
  }

  /**
   * Computed list of category data, extracted from the input data.
   * Formats month data for Highcharts for use as column labels.
   * Converts month dates into short names.
   */
  get categories() {
    return this.args.data.months?.map((m) => convertDateFormat(m.month)) ?? []
  }

  get legend() {
    return reportingLegend('left', 'top', 50, 10)
  }

  get lessons() {
    return this.lessonInfo.lessonsFor(this.args.precinct)
  }

  /**
   * Provide the grades for the lessons in the reporting data
   */
  get grades() {
    const monthsData = this.args.data.months
    const maxLessons = monthsData.map((month) => month.avg_max_lesson).filter(isPresent)
    if (isEmpty(maxLessons)) return []
    const grades = maxLessons
      .map((lesson) => this.lessons.find((l) => l.lesson_position === lesson)?.grade_position)
      .filter(isPresent)
    return [...new Set(grades)].sort((a, b) => a - b)
  }

  /**
   * Plot lines to mark the grade bands for the lesson values.
   */
  get plotLines(): YAxisPlotLinesOptions[] {
    // Add the _next_ grade to the list, if it exists in the lesson data. This is so we show the next plotLine too.
    const nextGrade = this.grades[this.grades.length - 1] + 1
    const gradesWithNext = this.lessons.find((l) => l.grade_position === nextGrade)
      ? [...this.grades, nextGrade]
      : this.grades

    return gradesWithNext
      .map((grade) => {
        const firstLessonOfGrade = this.lessons.find((l) => l.grade_position === grade)?.lesson_position
        const label = this.gradeSets.findByPosition(grade)?.fullName ?? ''

        if (typeof firstLessonOfGrade === 'number') return reportingPlotLine(firstLessonOfGrade, label)
        return null
      })
      .filter(isPresent)
  }

  /**
   * Returns a formatter function to pass to Highcharts to format the tooltip label.
   */
  get toolTipFormatter() {
    const { formatLessonPosition } = this
    const { precinct } = this.args

    return function (this: Point) {
      return typeof this.y === 'number' ? `<strong>${formatLessonPosition(this.y, precinct)}</strong>` : ''
    }
  }

  /**
   * Returns a formatter function to pass to Highcharts to format the y axis label.
   */
  get yAxisLabelFormatter(): AxisLabelsFormatterCallbackFunction {
    const { formatLessonPosition } = this
    const { precinct } = this.args

    return function () {
      if (typeof this.value !== 'number') return ''
      return formatLessonPosition(this.value, precinct)
    }
  }

  /**
   * Set the yRange of the chart to include all lessons for all grades in the data
   */
  get yRange(): [number, number] {
    const firstGrade = Math.min(...this.grades)
    const lastGrade = Math.max(...this.grades)

    /* expand grades to lessons range for the chart
     * min: first lesson for first grade
     * max: last lesson for last grade
     */
    const firstLesson = this.lessons.find((l) => l.grade_position === firstGrade)
    const lastLesson = [...this.lessons].reverse().find((l) => l.grade_position === lastGrade)

    // 1 is added to the last lesson, so that the nextGrade plotLine is part of the range
    return [firstLesson?.lesson_position ?? -Infinity, (lastLesson?.lesson_position ?? Infinity) + 1]
  }

  get chartSpacing(): [number, number, number, number] {
    return [10, 10, 10, 10]
  }

  /**
   * Format the value based on the current precinct.
   */
  formatLessonPosition = (lessonPos: number, precinct: Precinct): string => {
    if (precinct === Precinct.REX_SPELLING) {
      const lessons = this.lessonInfo.lessonsFor(precinct)
      const lesson = lessons.find((l) => l.lesson_position === lessonPos)
      if (!lesson || !lesson.grade_position || !lesson.grade_lesson_number) return lessonPos.toString()

      // 36 lessons in a grade, returns something like 2.36, or 2.05
      return `${lesson.grade_position}.${lesson.grade_lesson_number.toString().padStart(2, '0')}`
    }
    return lessonPos.toString()
  }

  <template>
    <div data-test-course-progress-averages class="relative rounded-md bg-white" ...attributes>
      <TooltipInfo class="z-tooltip absolute right-3 top-3 text-xs print:hidden" @text={{@tooltip}} />
      <SeriesLine
        @title={{this.hyphenatedTitle}}
        @data={{this.chartData}}
        @categories={{this.categories}}
        @chartSpacing={{this.chartSpacing}}
        @yAxisLabel="Lesson number"
        @yAxisLabelFormatter={{this.yAxisLabelFormatter}}
        @toolTipFormatter={{this.toolTipFormatter}}
        @plotLines={{this.plotLines}}
        @yRange={{this.yRange}}
      />
    </div>
  </template>
}

export default ChartsCourseProgressAverages
