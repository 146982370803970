import Service, { service } from '@ember/service'
import { isPresent } from '@ember/utils'
import { zip } from 'lodash'
import type { DownloadCsvOptions } from 'district-ui-client/services/file-download'
import type FileDownload from 'district-ui-client/services/file-download'

/**
 * table-to-csv is a service that can be injected to provide the downloadCsvFrom
 * function, which takes a css query selector string to a HTML table and pulls all the data out of it,
 * downloading it as CSV file.
 */
export default class TableToCsv extends Service {
  @service() declare fileDownload: FileDownload

  /**
   * Takes a CSS query selector string pointing to a table element and downloads a CSV file of the data
   * @property tableSelector The table element selector that will have data extracted from it.
   */
  downloadCsvFrom(tableSelector: string, options: DownloadCsvOptions = {}): void {
    const dataArray = this._exportTables(tableSelector)
    this.fileDownload.downloadAsCsv(dataArray, options)
  }

  _exportTables(tableSelector: string): string[][] {
    // The new way
    const tableElements = Array.from(document.querySelectorAll<HTMLTableElement>(tableSelector))

    /* For each table, pull everything we can out of the element, and store it in an object for later. That includes
     * determining the title, building an array of rows of cells. If any tables need to be joined (usually rendered
     * side-by-side in view), do that here too.
     *
     * Rather than doing a map(), returning null for some items, then filtering with isPresent() to skip them, we use
     * flatMap() as a type-safe alternative.
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flatMap#for_adding_and_removing_items_during_a_map
     */
    const tableExportObjects = tableElements.flatMap((tableElement) => {
      // Pull everything we can out of the element, and store it in an object for later

      // Skip any join table tails, we'll handle them with the main join table.
      if (tableElement.getAttribute('exportable-join-with')) return []

      const exportTitle = tableElement.getAttribute('exportable')
      const dataArray = this._parseTable(tableElement)

      // if this was a join table, go find the tail(s) and add it to the data array
      const tableJoinId = tableElement.getAttribute('exportable-join-id')
      if (tableJoinId) {
        // Supports 0, 1 or more joins
        const tailTables = tableElements.filter((el) => el.getAttribute('exportable-join-with') === tableJoinId)
        if (tailTables.length > 0) {
          const tailDataArrays = tailTables.map((tailTable) => this._parseTable(tailTable))
          const joinedDataArray = this._joinTables([dataArray, ...tailDataArrays])
          return [{ tableElement, exportTitle, dataArray: joinedDataArray }]
        }
      }

      return [{ tableElement, exportTitle, dataArray }]
    })

    if (tableExportObjects.length === 0) return []

    /*
     * tableExportObjects is now an array of tables to be exported. Each item (table) in this array contains a dataArray
     * property that could be passed to the csv exporter individually. But we want to concat them into a single file.
      [
        {
          exportTitle: 'table 1'
          dataArray: [
            ['a','b','c'],
            [1,2,3],
            [4,5,6],
          ], // table 1 rows
        },
        {
          exportTitle: 'table 2'
          dataArray: [
            ['d','e','f'],
            [7,8,9],
            [10,11,12],
          ], // table 2 rows
        },
      ]
     */

    // Add a title and gaps between tables before flattening and returning the final data array for export
    // This handles 0, 1 or more tables.
    return tableExportObjects.flatMap((tableExport, index) => {
      const title = tableExport.exportTitle
      const dataArrayWithTitle = title ? [[title], ...tableExport.dataArray] : tableExport.dataArray
      const isLastTable = index === tableExportObjects.length - 1
      return isLastTable ? dataArrayWithTitle : [...dataArrayWithTitle, []] // gap after table
    })
    /* The final return value will look something like this
      [
        ['table 1']
        ['a','b','c'],
        [1,2,3],
        [4,5,6],
        [],
        ['table 2'],
        ['d','e','f'],
        [7,8,9],
        [10,11,12],
        [],
      ]
     */
  }

  _parseTable(table: HTMLTableElement): string[][] {
    return Array.from(table.rows, (row) => this._parseRow(row))
  }

  _parseRow(row: HTMLTableRowElement): string[] {
    const result = Array.from(row.cells, (cell) => this._parseTableCell(cell))
    return result.flat()
  }

  _parseTableCell(cell: HTMLTableCellElement): string[] {
    const emptyCellsToAdd = Math.max(cell.colSpan - 1, 0)
    // https://2ality.com/2018/12/creating-arrays.html#recommended-patterns
    const emptyCells = Array.from({ length: emptyCellsToAdd }, () => '')
    /* Use innerText, rather than textContent, as innerText represents the "rendered" text content. That means it will
     * skip elements hidden from view (alternatively, textContent returns the text content including hidden elements)
     * eg <td>export me!<span style="display: none;">hide me!</span></td>
     *
     * Exporting a rendered table doesn't typically require trim. But when exporting an unrendered table (say, one with
     * class="hidden") trim() is necessary to remove whitespace and newlines that are often present in a cell.
     */
    return [cell.innerText.trim(), ...emptyCells]
  }

  /**
   * This handles 0, 1 or more tables. Typically used when the tables are meant to be viewed and exported side-by-side.
        table1|table2
    tr  a b c | d e f
    tr  1 2 3 | 7 8 9
    tr  4 5 6 | 10 11 12

    Provide it an array of dataArrays like this;
    [
      [
        ['a','b','c'],
        [1,2,3],
        [4,5,6],
      ], // table 1 rows
      [
        ['d','e','f'],
        [7,8,9],
        [10,11,12],
      ], // table 2 rows
    ]
    With the zip & map below, it forms a single data array like this;
    [
      ['a','b','c','d','e','f'],
      [1,2,3,7,8,9],
      [4,5,6,10,11,12],
    ]
    While it shouldn't happen; in the case where tables have mismatched number of rows, the first table will be used to
    join against, if a subsequent table has extra rows they will be left out.
   */
  _joinTables(tableDataArrays: string[][][]): string[][] {
    const [firstTable, ...rest] = tableDataArrays
    return zip(firstTable, ...rest).map((row) => row.filter(isPresent).flat())
  }
}

declare module '@ember/service' {
  interface Registry {
    'table-to-csv': TableToCsv
  }
}
