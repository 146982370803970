import Component from '@glimmer/component'
import { service } from '@ember/service'
import CleverUiModal from 'district-ui-client/components/clever-ui/modal'
import CleverUiConfirmDialog from 'district-ui-client/components/clever-ui/confirm-dialog/component'
import type RouterService from '@ember/routing/router-service'
import { t } from 'ember-intl'
import type CleverTeacher from 'district-ui-client/models/clever/clever-teacher'
/**
 * Unknown teacher match error, user is prompted to contact support.
 */

interface Signature {
  Args: {
    isShowingModal?: boolean
    cleverTeacher: CleverTeacher
    positiveAction: () => void
    negativeAction: () => void
  }
}

export class UnknownErrorTeacherMatcherModal extends Component<Signature> {
  @service router!: RouterService

  get supportUrl() {
    return this.router.urlFor('subscription-type.support')
  }

  <template>
    <CleverUiModal
      @isShowingModal={{@isShowingModal}}
      @onClose={{@negativeAction}}
      @title={{t "clever.teacherMatcher.errors.unknown.summary"}}
      @type="warning"
    >
      <CleverUiConfirmDialog
        @positiveAction={{@positiveAction}}
        @positiveButtonText={{t "close"}}
        @positiveButtonAppearance="orange"
      >
        <p class="text-base">
          {{t "clever.teacherMatcher.errors.unknown.explanation" htmlSafe=true teacherId=@cleverTeacher.id}}
        </p>
        <p class="mb-0 text-base">
          {{t "clever.teacherMatcher.errors.unknown.solution" htmlSafe=true supportUrl=this.supportUrl}}
        </p>
      </CleverUiConfirmDialog>
    </CleverUiModal>
  </template>
}

export default UnknownErrorTeacherMatcherModal
