import Component from '@glimmer/component'
import { service } from '@ember/service'
import { hash } from '@ember/helper'
import { t } from 'ember-intl'
import type { IntlService } from 'ember-intl'
import type Store from '@ember-data/store'
import { assert } from '@ember/debug'
import { trackedFunction } from 'reactiveweb/function'
import { formatValue } from 'district-ui-client/utils/format-value'
import { SortableCell } from 'district-ui-client/components/tables/sortable-cell'
import { DurationCell, Interval } from 'district-ui-client/components/tables/duration-cell'
import colspanMax from 'district-ui-client/modifiers/colspan-max'
import { injectComparerKeys, type SortComparerKey, type SortingConfig } from '@blakeelearning/data-tables/utils/sorting'
import type DataTransformerService from '@blakeelearning/data-tables/services/data-transformer'

export interface Aggregate {
  students: { average: Nullable<number>; total: number }
  time_on_task: { average: Nullable<number>; total: number }
  sprints_mastered: { average: Nullable<number>; total: number }
  sprints_attempted: { average: Nullable<number> }
  correct_answers: { average: Nullable<number>; total: number }
  improvement: { average: Nullable<number> }
}

export interface SchoolDataItem {
  school_id: number
  number_of_students: number
  sprints_mastered: number
  time_on_task: number
  correct_answers: number
  average_sprints_attempted: Nullable<number>
  average_improvement: Nullable<number>
}

interface DataItem extends SchoolDataItem {
  school_name: string
}

interface Signature {
  schools?: SchoolDataItem[]
  aggregate?: Aggregate
  sortingConfig: SortingConfig
  onSortClick: (sortingConfig: SortingConfig) => void
}

export class FluencySprintsSchoolOverview extends Component<Signature> {
  @service intl!: IntlService
  @service store!: Store

  @service dataTransformer!: DataTransformerService

  get inputRows(): DataItem[] {
    return (
      this.args.schools?.map((school) => {
        const schoolName = this.store.peekRecord('school', String(school.school_id))?.name
        assert('cannot find school', schoolName)
        return { ...school, school_name: schoolName }
      }) ?? []
    )
  }

  get sortingDescriptor() {
    const comparerKeyMapping: Partial<Record<keyof DataItem, SortComparerKey>> = {
      school_name: 'sortByValue',
      number_of_students: 'sortByNumber',
      sprints_mastered: 'sortByNumber',
      time_on_task: 'sortByNumber',
      correct_answers: 'sortByNumber',
      average_sprints_attempted: 'sortByNumber',
      average_improvement: 'sortByNumber',
    }
    const sortingConfigWithComparerKeys = injectComparerKeys(this.args.sortingConfig, comparerKeyMapping)
    const sortDescriptor = { type: 'sort' as const, config: sortingConfigWithComparerKeys }

    return sortDescriptor
  }

  get pipelineDescriptors() {
    return [this.sortingDescriptor]
  }

  transformedItems = trackedFunction(this, async () => {
    const transformedData = await this.dataTransformer.buildAndTransform<DataItem, DataItem>(
      this.inputRows,
      this.pipelineDescriptors,
    )
    return transformedData.items
  })

  get rows() {
    return this.transformedItems.value ?? []
  }

  get durationConfig() {
    const splitDurationOptions = {
      max: Interval.Days,
      min: Interval.Minutes,
      maxIntervals: 2,
    }
    const dataInterval = 'seconds'
    return { dataInterval, splitDurationOptions }
  }

  <template>
    <div class="overflow-x-auto">
      <table exportable data-test-fluency-school-overview-table class="w-full">
        <thead>
          <tr data-test-grouped-header-row>
            <th class="table-box table-box-header bg-ms-green-150 text-center" colspan="5"></th>
            <th class="table-box table-box-header bg-ms-green-150 text-center" colspan="2">
              {{t "tables.fluencySprints.schoolOverview.headers.numberFactsPerMinute"}}
            </th>
          </tr>
          <tr data-test-header-row>
            <SortableCell
              @sortingConfig={{@sortingConfig}}
              @onSortClick={{@onSortClick}}
              @sortKey="school_name"
              class="table-box table-box-header bg-ms-green-100"
            >
              {{t "tables.fluencySprints.schoolOverview.headers.schoolName"}}
            </SortableCell>
            <SortableCell
              @sortingConfig={{@sortingConfig}}
              @onSortClick={{@onSortClick}}
              @sortKey="number_of_students"
              class="table-box table-box-header bg-ms-green-100"
            >
              {{t "tables.fluencySprints.schoolOverview.headers.numberOfStudents"}}
            </SortableCell>
            <SortableCell
              @sortingConfig={{@sortingConfig}}
              @onSortClick={{@onSortClick}}
              @sortKey="sprints_mastered"
              class="table-box table-box-header bg-ms-green-100"
            >
              {{t "tables.fluencySprints.schoolOverview.headers.sprintsMastered"}}
            </SortableCell>
            <SortableCell
              @sortingConfig={{@sortingConfig}}
              @onSortClick={{@onSortClick}}
              @sortKey="time_on_task"
              class="table-box table-box-header bg-ms-green-100"
            >
              {{t "tables.fluencySprints.schoolOverview.headers.timeOnTask"}}
            </SortableCell>
            <SortableCell
              @sortingConfig={{@sortingConfig}}
              @onSortClick={{@onSortClick}}
              @sortKey="correct_answers"
              class="table-box table-box-header bg-ms-green-100"
            >
              {{t "tables.fluencySprints.schoolOverview.headers.questionsAnswered"}}
            </SortableCell>
            <SortableCell
              @sortingConfig={{@sortingConfig}}
              @onSortClick={{@onSortClick}}
              @sortKey="average_sprints_attempted"
              class="table-box table-box-header bg-ms-green-100"
            >
              {{t "tables.fluencySprints.schoolOverview.headers.averageSprintsAttempted"}}
            </SortableCell>
            <SortableCell
              @sortingConfig={{@sortingConfig}}
              @onSortClick={{@onSortClick}}
              @sortKey="average_improvement"
              class="table-box table-box-header bg-ms-green-100"
            >
              {{t "tables.fluencySprints.schoolOverview.headers.averageImprovement"}}
            </SortableCell>
          </tr>
        </thead>
        <tbody>
          {{#each this.rows as |row|}}
            <tr class="even:bg-ms-green-50 hover:bg-ms-green-100 bg-white print:even:bg-white">
              <td class="table-box table-box-cell">
                {{formatValue row.school_name}}
              </td>
              <td class="table-box table-box-cell">
                {{formatValue row.number_of_students}}
              </td>
              <td class="table-box table-box-cell">
                {{formatValue row.sprints_mastered}}
              </td>
              <DurationCell
                @durationConfig={{this.durationConfig}}
                @value={{row.time_on_task}}
                class="table-box table-box-cell"
              />
              <td class="table-box table-box-cell">
                {{formatValue row.correct_answers}}
              </td>
              <td class="table-box table-box-cell">
                {{formatValue row.average_sprints_attempted}}
              </td>
              <td class="table-box table-box-cell">
                {{formatValue row.average_improvement (hash append="%")}}
              </td>
            </tr>
          {{else}}
            <tr>
              <td class="bg-white p-3 text-center italic" {{colspanMax}}>
                {{t "tables.fluencySprints.emptyTableMessage"}}
              </td>
            </tr>
          {{/each}}
        </tbody>
        {{#if @aggregate}}
          <tfoot class="bg-white print:border-t-2 print:border-black">
            {{! average row }}
            <tr>
              <td class="table-footer-cell border-ms-green-100 border-t print:border-black">
                {{t "tables.fluencySprints.schoolOverview.footers.average"}}
              </td>
              <td class="table-footer-cell border-ms-green-100 border-t print:border-black"></td>
              <td class="table-footer-cell border-ms-green-100 border-t print:border-black">
                {{formatValue @aggregate.sprints_mastered.average}}
              </td>
              <DurationCell
                @durationConfig={{this.durationConfig}}
                @value={{@aggregate.time_on_task.average}}
                class="table-footer-cell border-ms-green-100 border-t print:border-black"
              />
              <td class="table-footer-cell border-ms-green-100 border-t print:border-black">
                {{formatValue @aggregate.correct_answers.average}}
              </td>
              <td class="table-footer-cell border-ms-green-100 border-t print:border-black">
                {{formatValue @aggregate.sprints_attempted.average}}
              </td>
              <td class="table-footer-cell border-ms-green-100 border-t print:border-black">
                {{formatValue @aggregate.improvement.average (hash append="%")}}
              </td>
            </tr>
            {{! totals row }}
            <tr>
              <td class="table-footer-cell border-ms-green-100 border-t print:border-black">
                {{t "tables.fluencySprints.schoolOverview.footers.totals"}}
              </td>
              <td class="table-footer-cell border-ms-green-100 border-t print:border-black"></td>
              <td class="table-footer-cell border-ms-green-100 border-t print:border-black">
                {{formatValue @aggregate.sprints_mastered.total}}
              </td>
              <DurationCell
                @durationConfig={{this.durationConfig}}
                @value={{@aggregate.time_on_task.total}}
                class="table-footer-cell border-ms-green-100 border-t print:border-black"
              />
              <td class="table-footer-cell border-ms-green-100 border-t print:border-black">
                {{formatValue @aggregate.correct_answers.total}}
              </td>
              <td class="table-footer-cell border-ms-green-100 border-t print:border-black"></td>
              <td class="table-footer-cell border-ms-green-100 border-t print:border-black"></td>
            </tr>
          </tfoot>
        {{/if}}
      </table>
    </div>
  </template>
}

export default FluencySprintsSchoolOverview
