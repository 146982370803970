import RouteTemplate from 'ember-route-template'
import type { TOC } from '@ember/component/template-only'
import FidgetLoadingIndicatorComponent from '@blakeelearning/fidget/components/fidget/loading-indicator'
import { t } from 'ember-intl'

/**
 * Display a loading spinner on child pages of subscription-type route while their model loads
 */
const SubscriptionTypeLoading: TOC<unknown> = <template>
  <div class="px-8 py-10">
    <FidgetLoadingIndicatorComponent
      @show={{true}}
      @overlay={{false}}
      @loadingText={{t "loading"}}
      @centered={{true}}
    />
  </div>
</template>

export default RouteTemplate(SubscriptionTypeLoading)
