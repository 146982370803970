import Component from '@glimmer/component'
import { colspanMax } from 'district-ui-client/modifiers/colspan-max'
import { t } from 'ember-intl'

interface Signature {
  Element: HTMLTableElement
  Args: {
    /** GravityStudent[] | GravityTeacher[] */
    blakeRecords: { sisId: string; firstName: string; lastName: string }[]
  }
}

export class CleverMissingFieldsTable extends Component<Signature> {
  get rows() {
    return [...this.args.blakeRecords].sort((a, b) => a.lastName.localeCompare(b.lastName))
  }

  <template>
    <table class="relative w-full" data-test-missing-fields-table ...attributes>
      <thead>
        <tr>
          <th class="table-box table-box-header bg-dusty-black-300 text-white">
            {{t "components.cleverUi.tables.missingFields.headers.sisId"}}
          </th>
          <th class="table-box table-box-header bg-dusty-black-300 text-white">
            {{t "components.cleverUi.tables.missingFields.headers.firstName"}}
          </th>
          <th class="table-box table-box-header bg-dusty-black-300 text-white">
            {{t "components.cleverUi.tables.missingFields.headers.lastName"}}
          </th>
        </tr>
      </thead>
      <tbody>
        {{#each this.rows as |row|}}
          <tr>
            <td class="table-box table-box-cell border-dusty-black-50">{{row.sisId}}</td>
            <td class="table-box table-box-cell border-dusty-black-50">{{row.firstName}}</td>
            <td class="table-box table-box-cell border-dusty-black-50">{{row.lastName}}</td>
          </tr>
        {{else}}
          <tr>
            <td class="p-2 text-center italic" {{colspanMax}}>
              {{t "components.cleverUi.tables.missingFields.emptyTableMessage"}}
            </td>
          </tr>
        {{/each}}
      </tbody>
    </table>
  </template>
}

export default CleverMissingFieldsTable

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CleverUi::Tables::MissingFields': typeof CleverMissingFieldsTable
  }
}
