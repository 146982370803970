import Controller from '@ember/controller'
import { tracked } from '@glimmer/tracking'
import { service } from '@ember/service'
import { isEmpty } from '@ember/utils'
import type { Log } from '@blakeelearning/log'
import type ActiveRouteService from 'district-ui-client/services/active-route'
import type Store from '@ember-data/store'
import type ManageStudentsIndexRoute from 'district-ui-client/subscription-type/manage/students/index/route'
import type SessionService from 'district-ui-client/services/session'
import { trackedFunction } from 'reactiveweb/function'
import type { SelectOption } from 'district-ui-client/components/form-components/form-select/option'

interface SchoolOption extends SelectOption {
  uid: string
  id: string
  text: string
  value: string
  schoolCode: string
}

interface SchoolClassOption {
  uid: string
  id: string
  type: 'school_class'
  value: string
  text: string
}

interface TeacherOption {
  uid: string
  id: string
  type: 'teacher'
  text: string
  value: string | SchoolClassOption[]
  icon: 'fa-user'
}

interface StudentsQuery {
  scope?: string
  filter?: {
    'first-name-contains'?: string
    'last-name-contains'?: string
    'teacher-ids'?: string // if multiple needed, should be comma-joined id strings 123,456
    'school-class-ids'?: string
    'subscription-type'?: string
  }
  include?: string
}

export default class ManageStudentsIndexController extends Controller {
  @service log!: Log

  @service activeRoute!: ActiveRouteService

  @service session!: SessionService

  @service store!: Store

  queryParams = ['schoolId', 'teacherId', 'schoolClassId', 'studentFirstName', 'studentLastName']

  get studentsQuery(): StudentsQuery | undefined {
    if (this.schoolId) {
      return {
        scope: `schools/${this.schoolId}`,
        include: 'school,school-classes,school-classes.teachers',
      }
    }
    if (this.teacherId) {
      return {
        scope: `districts/${this.session.currentDistrict.id}`,
        filter: { 'teacher-ids': this.teacherId, 'subscription-type': this.subscriptionType },
        include: 'school,school-classes,school-classes.teachers',
      }
    }
    if (this.schoolClassId) {
      return {
        scope: `districts/${this.session.currentDistrict.id}`,
        filter: { 'school-class-ids': this.schoolClassId },
        include: 'school,school-classes,school-classes.teachers',
      }
    }
    if (this.studentFirstName || this.studentLastName) {
      return {
        scope: `districts/${this.session.currentDistrict.id}`,
        filter: { 'first-name-contains': this.queryFirstName, 'last-name-contains': this.queryLastName },
        include: 'school,school-classes,school-classes.teachers',
      }
    }
    return
  }

  queryStudents = trackedFunction(this, async () => {
    if (!this.studentsQuery) return []
    return (await this.store.query('student', this.studentsQuery)).slice()
  })

  @tracked selectedIds: string[] = []

  get searchInFlight() {
    return this.queryStudents.isPending
  }

  get searchResult() {
    return this.queryStudents.value
  }

  get showResultCount() {
    return this.queryStudents.isResolved
  }

  importStudentsRouteName = 'subscription-type.manage.students.import-students'
  addStudentRouteName = 'subscription-type.manage.students.new'

  model!: ModelFor<ManageStudentsIndexRoute>

  @tracked schoolId = ''

  @tracked teacherId = ''

  @tracked schoolClassId = ''

  // these are acted on by the queryStudents tracked func. After submit of name search, the input value is copied here
  @tracked studentFirstName = ''

  @tracked studentLastName = ''

  // for user typing, input is tracked via these
  @tracked queryFirstName = ''

  @tracked queryLastName = ''

  get isCleverDisabled() {
    return this.session.currentDistrict.cleverDisabledManagementFor(this.subscriptionType)
  }

  get is3EDisabled() {
    return this.model.has3ESchool
  }

  get groupActionsDisabled() {
    return this.noSelectedStudents || this.isCleverDisabled || this.is3EDisabled
  }

  get noSelectedStudents() {
    return isEmpty(this.selectedStudents)
  }

  get subscriptionType() {
    return this.activeRoute.subscriptionType
  }

  get productStudents() {
    return (
      this.searchResult?.filter(
        (student) => !student.isDeleted && student.school?.hasActiveSchoolSubscriptionFor(this.subscriptionType),
      ) ?? []
    )
  }

  get selectedStudents() {
    return this.productStudents.filter((student) => this.selectedIds.includes(student.id))
  }

  get sortedSchools() {
    return this.model.schoolsForSubType.sort((a, b) => a.name.localeCompare(b.name))
  }

  get sortedTeachers() {
    return this.model.teachersForSubType.sort((a, b) => a.fullNameReversed.localeCompare(b.fullNameReversed))
  }

  get sortedSchoolsOptions(): SchoolOption[] {
    return this.sortedSchools.map((school) => {
      const { id, name, schoolCode } = school
      return {
        uid: id,
        id,
        text: name,
        value: id,
        schoolCode,
      }
    })
  }

  get selectedSchool() {
    return this.model.schoolsForSubType.find((s) => s.id === this.schoolId)
  }

  get selectedSchoolOption() {
    return this.sortedSchoolsOptions.find((opt) => opt.id === this.schoolId)
  }

  set selectedSchoolOption(value) {
    // don't allow form-select to set this
  }

  get sortedTeachersOptions() {
    const { subscriptionType, sortedTeachers = [] } = this
    return sortedTeachers.map((teacher) => {
      const { id, fullName, email } = teacher
      const schoolClasses = teacher.get('schoolClasses')
      const classes = schoolClasses?.filter((sc) => sc.subscriptionType === subscriptionType) ?? []
      return {
        uid: id,
        id,
        type: 'teacher',
        text: `${fullName} - ${email}`,
        value:
          classes.length > 1
            ? classes.map((schoolClass) => {
                const classId = schoolClass.id
                const className = schoolClass.name
                return {
                  uid: `${id}_${classId}`,
                  id: classId,
                  type: 'school_class',
                  value: classId,
                  text: className,
                }
              })
            : id,
        icon: 'fa-user',
      }
    })
  }

  get selectedTeacherClassOption() {
    return this.sortedTeachersOptions
      .flatMap((opt) => [opt, ...(typeof opt.value === 'string' ? [] : opt.value)])
      .find(
        (opt) =>
          (opt.type === 'teacher' && opt.id === this.teacherId) ||
          (opt.type === 'school_class' && opt.id === this.schoolClassId),
      )
  }

  // disable if both dont have minimum chars (OK for just one input to be filled in)
  get byNameDisabled() {
    return !(this.queryFirstName.length > 1) && !(this.queryLastName.length > 1)
  }

  get currentSchoolForExport() {
    const { selectedSchool, sortedSchools } = this
    const schoolCode = selectedSchool?.schoolCode
    return schoolCode ? sortedSchools.find((school) => school.schoolCode === schoolCode) : undefined
  }

  setQueryFirstName = (value: string) => {
    this.queryFirstName = value
  }

  setQueryLastName = (value: string) => {
    this.queryLastName = value
  }

  selectTeacherOrSchoolClass = (option: TeacherOption | SchoolClassOption) => {
    if (option.type === 'teacher') {
      this.teacherId = option.id
      this.schoolClassId = ''
    } else if (option.type === 'school_class') {
      this.teacherId = ''
      this.schoolClassId = option.id
    }
    // clear the _other_ search fields
    this.resetSchoolSearch()
    this.resetNameSearch()
  }

  resetSchoolSearch() {
    this.schoolId = ''
  }

  resetTeacherSchoolClassSearch() {
    this.teacherId = ''
    this.schoolClassId = ''
  }

  resetNameSearch() {
    this.studentFirstName = ''
    this.studentLastName = ''
    this.queryFirstName = ''
    this.queryLastName = ''
  }

  selectSchool = (option: SchoolOption) => {
    this.schoolId = option.id

    // clear the _other_ search fields
    this.resetTeacherSchoolClassSearch()
    this.resetNameSearch()
  }

  searchByStudent = (event: Event) => {
    event.preventDefault()

    if (this.byNameDisabled) return

    this.studentFirstName = this.queryFirstName
    this.studentLastName = this.queryLastName

    // clear the _other_ search fields
    this.resetSchoolSearch()
    this.resetTeacherSchoolClassSearch()
  }

  updateSelectedIds = (selectedIds: string[]) => {
    this.selectedIds = selectedIds
  }
}

declare module '@ember/controller' {
  interface Registry {
    'subscription-type/manage/students/index': ManageStudentsIndexController
  }
}
