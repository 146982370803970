import type { TOC } from '@ember/component/template-only'
import { t } from 'ember-intl'
import type { RibbonViewModel } from '../ribbons'

/**
 * Display a medal badge
 * awards that have been earned by students.
 **/

interface Signature {
  Args: {
    ribbon: RibbonViewModel
  }
  Element: HTMLDivElement
}

export const AwardsEarnedRibbon: TOC<Signature> = <template>
  <div ...attributes>
    <div class="relative">
      <img src={{@ribbon.src}} class="drop-shadow-[7px_7px_1px_#E0E0E0]" alt={{t "awards.ribbon"}} />
      <div class="absolute left-1/2 top-[20%] -translate-x-1/2 transform text-xl font-bold leading-none text-black">
        {{@ribbon.value}}
      </div>
      <div class="absolute left-1/2 top-[40%] -translate-x-1/2 transform font-bold leading-none text-white">
        {{@ribbon.label}}
      </div>
    </div>
    {{#if @ribbon.legend}}
      <div class="text-center font-bold">
        <div class="mx-auto h-4 w-4 rounded-full {{@ribbon.legendClassNames}}"></div>
        {{@ribbon.label}}
        <br />
        {{@ribbon.legend}}
      </div>
    {{/if}}
  </div>
</template>

export default AwardsEarnedRibbon
