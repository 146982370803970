import type { TOC } from '@ember/component/template-only'
import { hash } from '@ember/helper'
import { LinkTo } from '@ember/routing'

export type ButtonStyle = 'theme' | 'discovery' | 'neutral' | 'text' | 'icon-only' | 'link'

/**
 * default; resting state
 * hover; while mouse hovers element (:hover)
 * press; while mouse clicks down on element (:active)
 * focus; user tabs onto element (:focus)
 * selected; resting state, while representing current selection (eg, in a button group) or the currently displayed page
 */
function classNames(style: ButtonStyle): string {
  const standard = `inline-block cursor-pointer align-middle space-x-2 py-2 text-sm ${
    style === 'icon-only' ? 'px-2' : 'px-4'
  } font-medium transition-colors duration-75 ease-in-out whitespace-nowrap focus-visible:outline focus-visible:outline-2 focus-visible:outline-blue-325 focus-visible:outline-offset-2`

  switch (style) {
    case 'theme':
      return `${standard} selected:bg-blue-100 rounded-lg selected:text-blue-350 bg-blue-325 hover:bg-blue-350 text-white active:bg-blue-400 disabled:bg-neutral-50 disabled:text-neutral-250`
    case 'discovery':
      return `${standard} selected:bg-pink-100 rounded-lg selected:text-pink-350 bg-pink-300 hover:bg-pink-350 text-white active:bg-pink-400 disabled:bg-neutral-50 disabled:text-neutral-250`
    case 'neutral':
      return `${standard} selected:bg-blue-100 rounded-lg selected:text-blue-350 selected:border-transparent border-neutral-75 hover:bg-neutral-75 hover:border-transparent border-2 bg-white text-neutral-400 active:border-transparent active:bg-neutral-100 disabled:border-transparent disabled:bg-neutral-50 disabled:text-neutral-250`
    case 'text':
    case 'icon-only':
      // icon same as text, it just has less padding (applied above)
      return `${standard} selected:bg-blue-100 rounded-lg selected:text-blue-350 hover:bg-neutral-75 bg-transparent text-neutral-400 active:bg-neutral-100 disabled:bg-transparent disabled:text-neutral-250`
    case 'link':
      return `${standard} text-blue-325 selected:text-blue-350 hover:text-blue-350 active:text-blue-400 hover:underline active:underline disabled:text-neutral-250`
  }
}

interface ButtonSignature {
  Element: HTMLButtonElement
  Args: {
    style: ButtonStyle
    disabled?: boolean
  }
  Blocks: {
    default: []
  }
}

export const ThemedButton: TOC<ButtonSignature> = <template>
  <button
    type="button"
    class={{classNames @style}}
    ...attributes
    {{! After splattributes forces use of @disabled for disabling, to keep consistent usage }}
    disabled={{@disabled}}
  >
    {{yield}}
  </button>
</template>

interface LinkSignature {
  Element: HTMLElement
  Args: {
    style: ButtonStyle
    disabled?: boolean
  }
  Blocks: {
    default: []
  }
}

export const ThemedLink: TOC<LinkSignature> = <template>
  {{! Links can't have the :disabled pseudo-style, and aren't really meant to be "disabled" }}
  {{! To allow us to disable a link, we instead switch it out for a disabled button with no action }}
  {{! There should be no visual difference, other than the disabled styles }}
  {{#if @disabled}}
    <ThemedButton @style={{@style}} @disabled={{@disabled}} ...attributes>
      {{yield}}
    </ThemedButton>
  {{else}}
    <a href class={{classNames @style}} ...attributes>
      {{yield}}
    </a>
  {{/if}}
</template>

interface LinkToSignature {
  Element: HTMLElement
  Args: {
    style: ButtonStyle
    disabled?: boolean
    route?: string
    query?: Record<string, unknown>
    activeClass?: string
    'current-when'?: boolean | string
  }
  Blocks: {
    default: []
  }
}

export const ThemedLinkTo: TOC<LinkToSignature> = <template>
  {{! Links can't have the :disabled pseudo-style, and aren't really meant to be "disabled" }}
  {{! To allow us to disable a link, we instead switch it out for a disabled button with no action }}
  {{! There should be no visual difference, other than the disabled styles }}
  {{#if @disabled}}
    <ThemedButton @style={{@style}} @disabled={{@disabled}} ...attributes>
      {{yield}}
    </ThemedButton>
  {{else}}
    <LinkTo
      @route={{if @route @route}}
      @query={{if @query @query (hash)}}
      @activeClass={{@activeClass}}
      @current-when={{@current-when}}
      class={{classNames @style}}
      ...attributes
    >
      {{yield}}
    </LinkTo>
  {{/if}}
</template>

/**
 * Currently, we only have button groups of neutral style buttons, so this is built for that style.
 *
 * To ensure correct border thickness between buttons (2px not 4px), since we can't collapse borders, the easiest way to
 * handle that is by removing all button borders, applying a border to the container, with gap between the buttons and a
 * background matching the border color.
 *
 * Usage:
 * <ThemedButtonGroup>
 *   <ThemedButton @style="neutral">1</ThemedButton
 *   <ThemedButton @style="neutral">2</ThemedButton
 *   <ThemedButton @style="neutral">3</ThemedButton
 * </ThemedButtonGroup>
 */
export const ThemedButtonGroup: TOC<{ Blocks: { default: [] } }> = <template>
  <div
    class="border-neutral-75 bg-neutral-75 inline-flex gap-[2px] rounded-l-md rounded-r-md border-2 [&_button:not(first-child)]:rounded-l-none [&_button:not(last-child)]:rounded-r-none [&_button]:border-none"
  >
    {{yield}}
  </div>
</template>

export default ThemedButton

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ThemedButton: typeof ThemedButton
  }
}
