import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import { eq } from 'ember-truth-helpers'
import { tracked } from '@glimmer/tracking'
import Component from '@glimmer/component'
import type { TOC } from '@ember/component/template-only'
import { on } from '@ember/modifier'
import { t } from 'ember-intl'

export enum AlertType {
  Information = 'information',
  Warning = 'warning',
  Critical = 'critical',
  Success = 'success',
  NewFeature = 'new-feature',
}

export interface InlineAlertSignature {
  Element: HTMLDivElement
  Args: {
    type: AlertType
    hideIcon?: boolean
    showClose?: boolean
    flat?: boolean
  }
  Blocks: {
    default: []
  }
}

/**
 * @example
 *
 * <InlineAlert @type={{AlertType.Information}}>
 *   This is an informative example.
 * </InlineAlert>
 *
 * This component applies role="status", which is an approximation of how we use our alert component (more of a
 * non-urgent notification). If you need, role="alert" can be used for the alert to be treated as urgent, which will
 * interrupt screen readers.
 *
 * Typically, urgent "alert" would be a notification responding to user input; like form validation failed, or
 * "your changes will not be saved".
 *
 * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/status_role
 * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/alert_role
 */
export const InlineAlert: TOC<InlineAlertSignature> = <template>
  {{#if (eq @type AlertType.Information)}}
    <AlertContainer
      data-test-alert={{@type}}
      @showClose={{@showClose}}
      @flat={{@flat}}
      class="border-l-blue-325"
      ...attributes
    >
      <FaIcon @icon="circle-info" class="text-blue-325 h-5 w-5 flex-shrink-0 p-0.5 {{if @hideIcon 'hidden'}}" />
      {{yield}}
    </AlertContainer>
  {{else if (eq @type AlertType.Warning)}}
    <AlertContainer
      data-test-alert={{@type}}
      @showClose={{@showClose}}
      @flat={{@flat}}
      class="border-l-orange-350"
      ...attributes
    >
      <FaIcon
        @icon="triangle-exclamation"
        class="text-orange-350 h-5 w-5 flex-shrink-0 p-0.5 {{if @hideIcon 'hidden'}}"
      />
      {{yield}}
    </AlertContainer>
  {{else if (eq @type AlertType.Critical)}}
    <AlertContainer
      data-test-alert={{@type}}
      @showClose={{@showClose}}
      @flat={{@flat}}
      class="border-l-red-300"
      ...attributes
    >
      <FaIcon @icon="diamond-exclamation" class="h-5 w-5 flex-shrink-0 p-0.5 text-red-300 {{if @hideIcon 'hidden'}}" />
      {{yield}}
    </AlertContainer>
  {{else if (eq @type AlertType.Success)}}
    <AlertContainer
      data-test-alert={{@type}}
      @showClose={{@showClose}}
      @flat={{@flat}}
      class="border-l-green-300"
      ...attributes
    >
      <FaIcon @icon="circle-check" class="h-5 w-5 flex-shrink-0 p-0.5 text-green-300 {{if @hideIcon 'hidden'}}" />
      {{yield}}
    </AlertContainer>
  {{else if (eq @type AlertType.NewFeature)}}
    <AlertContainer
      data-test-alert={{@type}}
      @showClose={{@showClose}}
      @flat={{@flat}}
      class="border-l-pink-300"
      ...attributes
    >
      <FaIcon @icon="sparkles" class="h-5 w-5 flex-shrink-0 p-0.5 text-pink-300 {{if @hideIcon 'hidden'}}" />
      {{yield}}
    </AlertContainer>
  {{/if}}
</template>

interface AlertContainerSignature {
  Element: HTMLDivElement
  Args: {
    showClose?: boolean
    flat?: boolean
  }
  Blocks: {
    default: []
  }
}

class AlertContainer extends Component<AlertContainerSignature> {
  @tracked dismissed = false

  onClose = () => {
    this.dismissed = true
  }

  <template>
    {{#unless this.dismissed}}
      <div
        class="{{if
            @flat
            ''
            'shadow-inline-alert border-y-neutral-75 border-r-neutral-75 rounded border-y border-l-4 border-r p-4'
          }}
          flex gap-4 bg-white print:hidden"
        role="status"
        {{! off, we don't intend for this to be updated with new information }}
        aria-live="off"
        ...attributes
      >
        {{yield}}
        {{#if @showClose}}
          <div class="ml-auto">
            <button type="button" aria-label={{t "close"}} {{on "click" this.onClose}} data-test-alert-close>
              <FaIcon class="text-neutral-250 h-4 w-4" @icon="xmark" />
            </button>
          </div>
        {{/if}}
      </div>
    {{/unless}}
  </template>
}

interface InlineMessageSignature {
  Element: HTMLDivElement
  Args: {
    type: AlertType
  }
  Blocks: {
    default: []
  }
}

export const InlineMessage: TOC<InlineMessageSignature> = <template>
  <InlineAlert @type={{@type}} @flat={{true}} @showClose={{false}} ...attributes>
    <div class="space-y-1">
      {{yield}}
    </div>
  </InlineAlert>
</template>

interface InlineTitleSignature {
  Blocks: {
    default: []
  }
}

export const InlineTitle: TOC<InlineTitleSignature> = <template>
  <div data-test-inline-message-heading class="text-sm font-semibold">{{yield}}</div>
</template>

interface InlineSubtitleSignature {
  Blocks: {
    default: []
  }
}
export const InlineSubtitle: TOC<InlineSubtitleSignature> = <template>
  <div data-test-inline-message-subtitle class="text-neutral-250 text-sm">{{yield}}</div>
</template>

export default InlineAlert

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    InlineAlert: typeof InlineAlert
  }
}
