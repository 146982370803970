import Component from '@glimmer/component'
import { service } from '@ember/service'
import type ReportingService from 'district-ui-client/services/reporting'
import { t, type IntlService } from 'ember-intl'
import { isKeyOf } from 'district-ui-client/utils/type-guards'
import { formatDate } from 'district-ui-client/services/reporting'
import { TooltipInfo } from 'district-ui-client/components/tooltip'
import type GradeSetsService from 'district-ui-client/services/grade-sets'

interface Signature {
  Element: HTMLDivElement
}

export class ReportingScopeHeader extends Component<Signature> {
  @service reporting!: ReportingService

  @service intl!: IntlService

  @service gradeSets!: GradeSetsService

  get uiScopeFormatted() {
    const { uiScope } = this.reporting
    if (uiScope?.scope === 'school') return this.intl.t('reporting.scopes.someSchools', { count: 1 })
    if (uiScope?.scope === 'district' && uiScope.subScopes.length) {
      return this.intl.t('reporting.scopes.someSchools', { count: uiScope.subScopes.length })
    }
    return this.intl.t('reporting.scopes.allSchools')
  }

  get gradeScopeFormatted() {
    const { studentGrade } = this.reporting
    if (studentGrade === 'all') return this.intl.t('reporting.scopes.allGrades')
    return this.gradeSets.findByPosition(parseInt(studentGrade, 10))?.fullName ?? ''
  }

  get contentLevelScopeFormatted() {
    const { contentLevel } = this.reporting
    if (contentLevel === 'all') return this.intl.t('reporting.scopes.allContentLevels')
    const gradeName = (this.gradeSets.findByPosition(parseInt(contentLevel, 10))?.fullName ?? '').toLowerCase()
    return this.intl.t('reporting.scopes.contentLevel', { gradeName })
  }

  get periodFormatted() {
    const { period, periodNames, periodNameKey } = this.reporting
    if (periodNameKey && isKeyOf(periodNames, periodNameKey)) return periodNames[periodNameKey]

    return `${formatDate(period.start)} - ${formatDate(period.end)}`
  }

  <template>
    <div data-test-scope-header class="flex flex-wrap items-center gap-4 text-xl text-neutral-400" ...attributes>
      <span class="font-medium">{{this.uiScopeFormatted}}</span>
      <span class="border-neutral-75 h-6 border-x" />
      <span class="font-medium">{{this.gradeScopeFormatted}}</span>
      <span class="border-neutral-75 h-6 border-x" />
      <span class="font-medium">{{this.contentLevelScopeFormatted}}</span>
      <span class="border-neutral-75 h-6 border-x" />
      <span class="font-medium">{{this.periodFormatted}}</span>
      <TooltipInfo class="text-base" @text={{t "reporting.scopeHeaderTooltip"}} />
      <div id="export-button-wormhole" class="ml-auto" />
    </div>
  </template>
}
