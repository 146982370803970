import type { TOC } from '@ember/component/template-only'
interface Signature {
  Element: HTMLUListElement
  Blocks: {
    default: []
  }
}
export const ActionMenu: TOC<Signature> = <template>
  <ul
    role="menu"
    class="divide-dusty-black-100 border-dusty-black-100 my-px min-w-[10rem] divide-y rounded-md border bg-white shadow-xl print:hidden"
    ...attributes
  >
    {{yield}}
  </ul>
</template>

export default ActionMenu
