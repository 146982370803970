import type { TOC } from '@ember/component/template-only'

interface Signature {
  Element: HTMLHeadingElement
  Blocks: {
    default: []
  }
}

export const ManageHeading: TOC<Signature> = <template>
  <h1 class="text-dusty-black-400 m-[30px] text-center text-[42px] font-light leading-[1.1]" ...attributes>
    {{yield}}
  </h1>
</template>

export default ManageHeading

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ManageHeading: typeof ManageHeading
  }
}
