import Component from '@glimmer/component'
import type { TOC } from '@ember/component/template-only'
import type RouterService from '@ember/routing/router-service'
import { service } from '@ember/service'
import { on } from '@ember/modifier'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import {
  serialize as serializeSort,
  deserialize as deserializeSort,
  updateSortColumns,
} from '@blakeelearning/data-tables/utils/sorting'
import { getSortViewModel } from 'district-ui-client/utils/sorting'
import { gt, or } from 'ember-truth-helpers'
import { colspanMax } from 'district-ui-client/modifiers/colspan-max'
import { BasePageSelector } from 'district-ui-client/components/base/page-selector'

interface TableContainerSignature {
  Element: HTMLDivElement
  Blocks: {
    default: []
  }
}

/**
 * Provides a scrollable container for a table
 */
export const TableContainer: TOC<TableContainerSignature> = <template>
  <div class="overflow-x-auto overflow-y-hidden print:overflow-visible" ...attributes>{{yield}}</div>
</template>

interface TableSignature {
  Element: HTMLTableElement
  Args: {
    /* can be helpful to inform container when to begin scroll */
    minWidth?: string
  }
  Blocks: {
    default: []
  }
}

/**
 * Provides a fixed-layout table with rounded top.
 *
 * You may want to provide some width classes on your TH cells (though not required)
 */
export const Table: TOC<TableSignature> = <template>
  <table class="w-full table-fixed {{@minWidth}}" ...attributes>{{yield}}</table>
</template>

interface TrHeadSignature {
  Element: HTMLTableRowElement
  Blocks: {
    default: []
  }
}

export const TRHead: TOC<TrHeadSignature> = <template>
  <tr class="divide-x divide-white bg-neutral-50" ...attributes>{{yield}}</tr>
</template>

interface TBodySignature {
  Element: HTMLTableSectionElement
  Blocks: {
    default: []
  }
}

export const TBody: TOC<TBodySignature> = <template>
  <tbody class="divide-neutral-75 divide-y" ...attributes>{{yield}}</tbody>
</template>

interface ThSignature {
  Element: HTMLTableCellElement
  Args: {
    /** If provided, will get/set sort query param & render a sort icon, and make the cell clickable */
    sortKey?: string
    /* Otherwise, use this to indicate the header will be clickable for some purpose - will apply pointer etc. */
    clickable?: boolean
  }
  Blocks: { default: [] }
}

/**
 * Provides a standard `<th>` to use, that applies the standard styles we expect from header cells. It can accept a
 * sortKey (which will display an icon), and other items (like tooltip icons) are also permitted.
 *
 * @example
 * <TH @sortKey="optional_key_to_sort_by">
 *   My text
 *   <OptionalTooltipIcon />
 * </TH>
 * </code>
 */
export const TH: TOC<ThSignature> = <template>
  {{#if @sortKey}}
    <ThSorter class="p-3 text-sm font-medium" @sortKey={{@sortKey}} ...attributes>{{yield}}</ThSorter>
  {{else}}
    <ThIcons class="p-3 text-sm font-medium" @clickable={{@clickable}} ...attributes>{{yield}}</ThIcons>
  {{/if}}
</template>

interface TdSignature {
  Element: HTMLTableCellElement
  Args: { textColor?: string }
  Blocks: { default: [] }
}

/**
 * Provides a standard `<td>` to use, that applies the standard styles we expect from body cells.
 * break-words will wrap nicely at first (breaking on spaces), when even less room will begin to break on words.
 */
export const TD: TOC<TdSignature> = <template>
  <td class="break-words p-3 text-sm {{or @textColor ''}}" ...attributes>{{yield}}</td>
</template>

interface ThIconsSignature {
  Element: HTMLTableCellElement
  Args: {
    clickable?: boolean
  }
  Blocks: {
    default: []
  }
}

/**
 * Use this to add zero or more icons to a th cell in a standard way
 *
 * @example
 * <ThIcons>
 *   My Text
 *   <TooltipIcon />
 *   <SortIcon />
 * </ThIcons>
 */
export const ThIcons: TOC<ThIconsSignature> = <template>
  <th class={{if @clickable "cursor-pointer select-none"}} ...attributes>
    <div class="inline-flex items-center justify-start gap-2 whitespace-nowrap">
      {{yield}}
    </div>
  </th>
</template>

interface ThSorterSignature {
  Element: HTMLTableCellElement
  Args: {
    sortKey: string
  }
  Blocks: {
    default: []
  }
}

/**
 * Get & sets sort query param to display sort icon & handle click, makes use of <ThIcons /> component to display icon.
 *
 * @example
 * <ThSorter @sortKey="key_to_sort">
 *   My Text
 *   <OptionalTooltipIcon />
 * </ThSorter>
 */
export class ThSorter extends Component<ThSorterSignature> {
  @service router!: RouterService

  get sort(): string | undefined {
    /**
     * When transitioning to a page with sorting, often the query param will not be set in the URL. The default value
     * to use when that happens is defined in the controller.
     *
     * When obtaining the sort via the router service, we do not have access to that value - the router service will
     * provide the sort query param as "undefined". In practice, for this component it just means that the sort icon
     * will not be we shown on initial load if there is no sort QP in the URL. It will just be the generic up+down icon.
     *
     * The alternative is to locate the correct controller that should have the sort, and get/set that tracked property,
     * but as of writing this cannot be done in a generic page-agnostic way.
     */
    const sortParam = this.router.currentRoute?.find((rInfo) => Boolean(rInfo.queryParams.sort))?.queryParams.sort
    return typeof sortParam === 'string' ? sortParam : undefined
  }

  get sortingConfig() {
    return deserializeSort(this.sort)
  }

  updateSort = () => {
    const newSort = serializeSort(updateSortColumns(this.sortingConfig, this.args.sortKey))

    /* If within the reporting area, we need to also provide the same schoolIds query param when doing this
     * query-param-only transition.
     * Otherwise, without it, the ui-scope model hooks seem to get triggered for update, and also the setupController
     * there, which then opens the drawer.
     * I believe this is an ember routing bug. Currently Ember 5.8
     */
    const schoolIds = this.router.currentRoute?.find((rInfo) => Boolean(rInfo.queryParams.schoolIds))?.queryParams
      .schoolIds
    void this.router.replaceWith({
      queryParams: { sort: newSort, schoolIds: typeof schoolIds === 'string' ? JSON.parse(schoolIds) : undefined },
    })
  }

  get sortViewModel() {
    return getSortViewModel(this.sortingConfig, this.args.sortKey)
  }

  <template>
    <ThIcons
      data-test-sort-key={{@sortKey}}
      aria-sort={{this.sortViewModel.ariaDirection}}
      @clickable={{true}}
      {{! template-lint-disable no-invalid-interactive }}
      {{on "click" this.updateSort}}
      ...attributes
    >
      {{yield}}
      <FaIcon @icon={{this.sortViewModel.icon}} class="print:hidden" />
    </ThIcons>
  </template>
}

export const TFootPageSelector: TOC<{ Element: HTMLTableSectionElement; Args: { pageCount: number } }> = <template>
  {{#if (gt @pageCount 1)}}
    <tfoot class="border-neutral-75 border-t" ...attributes>
      <tr>
        <td {{colspanMax}}>
          <BasePageSelector class="mb-2 mt-4" @pageCount={{@pageCount}} />
        </td>
      </tr>
    </tfoot>
  {{/if}}
</template>
