import RouteTemplate from 'ember-route-template'
import type { TOC } from '@ember/component/template-only'

export const ManagePage: TOC<unknown> = <template>
  <div class="px-8 py-10">
    {{outlet}}
  </div>
</template>

export default RouteTemplate(ManagePage)
