import Model, { attr, hasMany, belongsTo } from '@ember-data/model'
import { isPresent } from '@ember/utils'
import type CleverDistrict from 'district-ui-client/models/clever/clever-district'
import type CleverSchoolMatch from 'district-ui-client/models/clever/clever-school-match'
import type CleverStudent from 'district-ui-client/models/clever/clever-student'
import type CleverTeacher from 'district-ui-client/models/clever/clever-teacher'
import {
  SYNC_STEPS,
  isSchoolMatchDone,
  areTeacherMatchesDone,
  areStudentMatchesDone,
  schoolMatchStateForStep,
  teacherMatchStateForStep,
  studentMatchStateForStep,
  hasProcessingState,
  type SyncSteps,
} from 'district-ui-client/utils/clever/clever-school/sync-process-states'
import type { SyncHasMany } from '@ember-data/model'

interface Location {
  state: string
  city: string
}

export default class CleverSchool extends Model {
  @attr('string') name!: string

  @attr('string') sisId!: string

  /**
   * { state: string, city: string }
   */
  @attr() location?: Nullable<Location>

  /* counts */

  @attr('number') readingStudentCount!: number

  @attr('number') readingTeacherCount!: number

  @attr('number') readingSectionCount!: number

  @attr('number') mathsStudentCount!: number

  @attr('number') mathsTeacherCount!: number

  @attr('number') mathsSectionCount!: number

  /* booleans */

  @attr('boolean') confirmedMatch!: boolean

  @attr('boolean') matched!: boolean

  @attr('boolean') readingCleverApp!: boolean

  @attr('boolean') mathsCleverApp!: boolean

  /* state */

  @attr('string') syncProcessState!: SyncSteps

  /* relations */

  @belongsTo('clever/clever-district', { async: false, inverse: 'cleverSchools' })
  cleverDistrict?: CleverDistrict

  @hasMany('clever/clever-teacher', { async: false, inverse: 'cleverSchool' })
  cleverTeachers?: SyncHasMany<CleverTeacher>

  @hasMany('clever/clever-student', { async: false, inverse: 'cleverSchool' })
  cleverStudents?: SyncHasMany<CleverStudent>

  @belongsTo('clever/clever-school-match', { async: false, inverse: 'cleverSchool' })
  cleverSchoolMatch?: CleverSchoolMatch

  /** model properties */

  // NOTE: Usage can result in unnecessary tracking updates, because this is based on an attr field without a transform
  get cityStateJoined() {
    return isPresent(this.location?.city) && isPresent(this.location?.state)
      ? `${this.location.city}, ${this.location.state}`
      : null
  }

  get cleverDistrictId() {
    return this.cleverDistrict?.id
  }

  get blakeSchool() {
    return this.cleverSchoolMatch?.school
  }

  get blakeSchoolId() {
    return this.blakeSchool?.id
  }

  get studentCounts() {
    return { reading: this.readingStudentCount, maths: this.mathsStudentCount }
  }

  get sectionCounts() {
    return { reading: this.readingSectionCount, maths: this.mathsSectionCount }
  }

  get isInitializing() {
    return this.syncProcessState === SYNC_STEPS.INIT
  }

  get isMatchingInProgress() {
    return hasProcessingState(this.syncProcessState)
  }

  get isAwaitingSchoolMatch() {
    return this.syncProcessState === SYNC_STEPS.UNMATCHED_SCHOOL
  }

  get isAwaitingSchoolMatchConfirm() {
    return this.syncProcessState === SYNC_STEPS.UNCONFIRMED_SCHOOL_MATCH
  }

  // school is at any state after the school matching stage
  get isSchoolMatchDone() {
    return isSchoolMatchDone(this.syncProcessState)
  }

  get isAwaitingTeacherMatch() {
    return this.syncProcessState === SYNC_STEPS.AWAITING_TEACHER_MATCH
  }

  get isTeacherMatchingInProgress() {
    return this.syncProcessState === SYNC_STEPS.MATCHING_TEACHERS
  }

  // school is at any state after the teacher matching stage
  get areTeacherMatchesDone() {
    return areTeacherMatchesDone(this.syncProcessState)
  }

  get isAwaitingStudentMatch() {
    return this.syncProcessState === SYNC_STEPS.AWAITING_STUDENT_MATCH
  }

  get isStudentMatchingInProgress() {
    return this.syncProcessState === SYNC_STEPS.MATCHING_STUDENTS
  }

  // school is at any state after the student matching stage
  get areStudentMatchesDone() {
    return areStudentMatchesDone(this.syncProcessState)
  }

  get isAwaitingSync() {
    return this.syncProcessState === SYNC_STEPS.AWAITING_SYNC
  }

  get isSyncInProgress() {
    return this.syncProcessState === SYNC_STEPS.SYNC_IN_PROGRESS
  }

  get hasCompletedSync() {
    return this.syncProcessState === SYNC_STEPS.COMPLETE
  }

  get matchSchoolState() {
    return schoolMatchStateForStep(this.syncProcessState)
  }

  get matchTeachersState() {
    return teacherMatchStateForStep(this.syncProcessState)
  }

  get matchStudentsState() {
    return studentMatchStateForStep(this.syncProcessState)
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'clever/clever-school': CleverSchool
  }
}
