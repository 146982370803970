import FlashMessage from 'ember-cli-flash/components/flash-message'
import { FlashType, type CustomFlashMessageObject } from 'district-ui-client/services/flash-queue'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import { eq } from 'ember-truth-helpers'
import type { TOC } from '@ember/component/template-only'
import { on } from '@ember/modifier'
import optional from 'district-ui-client/helpers/optional'
import { t } from 'ember-intl'

interface Signature {
  Element: Element
  Args: {
    flash: CustomFlashMessageObject
  }
}

export const Toast: TOC<Signature> = <template>
  {{! Note: some styles provided in _flash.css file }}
  <FlashMessage
    data-test-flash-message={{@flash.flashType}}
    @flash={{@flash}}
    class="flash-box-animate-in-out pointer-events-auto w-[32rem] translate-x-full pr-7 transition-transform duration-500
      {{if @flash.destroyOnClick 'cursor-pointer'}}"
    ...attributes
    as |_flash_message _flash_object closeAction|
  >
    <div
      data-test-toast={{@flash.flashType}}
      class="border-neutral-75 flex space-x-4 overflow-hidden rounded-lg border-2 bg-white p-4 shadow-xl"
    >
      {{#if @flash.icon}}
        <FaIcon
          data-test-toast-icon={{@flash.icon}}
          @icon={{@flash.icon}}
          class="{{if (eq @flash.flashType FlashType.Default) 'text-neutral-300'}}
            {{if (eq @flash.flashType FlashType.Info) 'text-blue-325'}}
            {{if (eq @flash.flashType FlashType.Caution) 'text-juicy-orange-300'}}
            {{if (eq @flash.flashType FlashType.Positive) 'text-forest-green-300'}}
            {{if (eq @flash.flashType FlashType.Critical) 'text-watermelony-red-300'}}
            h-5 w-5 flex-shrink-0 p-0.5
            {{unless @flash.icon 'hidden'}}"
        />
      {{/if}}
      <div class="flex-grow space-y-2">
        {{#if @flash.title}}
          <div data-test-toast-title class="font-medium text-neutral-400">{{@flash.title}}</div>
        {{/if}}
        {{#if @flash.subtitle}}
          <div data-test-toast-subtitle class="flash-box-style-links">{{@flash.subtitle}}</div>
        {{/if}}
      </div>
      {{#if @flash.showClose}}
        <div class="flex-shrink-0">
          <button data-test-toast-close type="button" aria-label={{t "close"}} {{on "click" (optional closeAction)}}>
            <FaIcon class="text-neutral-250 h-4 w-4" @icon="xmark" />
          </button>
        </div>
      {{/if}}
    </div>
  </FlashMessage>
</template>
