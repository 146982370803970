import { reportingTitle, reportingToolTip as toolTipConfig } from 'district-ui-client/utils/giraffe/highcharts-config'
import BlakeColours from '@blakeelearning/blake-colours/colours'
import Component from '@glimmer/component'
import BaseHighcharterComponent from 'district-ui-client/components/base/highcharter'
import type {
  LegendOptions,
  TooltipOptions,
  XAxisOptions,
  YAxisOptions,
  XAxisPlotBandsOptions,
  YAxisPlotLinesOptions,
  XAxisPlotLinesOptions,
  YAxisPlotBandsOptions,
  Options,
  DataLabelsFormatterCallbackFunction,
  TooltipFormatterCallbackFunction,
  ColorString,
  PlotOptions,
  OptionsStackingValue,
  AxisLabelsFormatterCallbackFunction,
  TitleOptions,
  SeriesColumnOptions,
} from 'highcharts'

/**
 * An ember component interface to a highcharts column chart.
 *
 * Data is in this format:
 *
 * ```
 * [
 *   { name: 'series-0-name', data: [1, 2, 3] },
 *   { name: 'series-1-name', data: [3, 4, 5] },
 * ]
 * ```
 *
 * Use 1 series for a column chart, multiple series for a stacked or grouped
 * column chart.
 *
 *
 * @example
 *   {{primitives/series-column
 *     data=multiSeriesData
 *     colours=multiSeriesColours
 *     categories=categories
 *     legend=legend
 *   }}
 *
 */

interface Args {
  data: SeriesColumnOptions[]
  title?: TitleOptions | string
  chartSpacing?: number[]
  chartMargin?: (number | null)[]
  legend?: LegendOptions
  toolTip?: TooltipOptions
  categories?: string[]
  xAxisLabel?: string
  xAxisVisible?: boolean
  xAxisTickWidth?: number
  xAxisPlotBands?: XAxisPlotBandsOptions[]
  xAxisPlotLines?: XAxisPlotLinesOptions[]
  xAxisLabelFormatter?: AxisLabelsFormatterCallbackFunction
  yAxisLabel?: string
  yAxisVisible?: boolean
  yRange?: [number, number]
  yAxisPlotLines?: YAxisPlotLinesOptions[]
  yAxisPlotBands?: YAxisPlotBandsOptions[]
  yAxisReversedStacks?: boolean
  yAxisEndOnTick?: boolean
  yAxisTickInterval?: number
  yAxisGridLineColor?: string
  yAxisLabelFormatter?: AxisLabelsFormatterCallbackFunction
  plotOptions?: PlotOptions
  groupPadding?: number
  columnPadding?: number
  colorByPoint?: boolean
  dataLabelsEnabled?: boolean
  dataLabelsFormatter?: DataLabelsFormatterCallbackFunction
  colours?: ColorString[]
  toolTipFormatter?: TooltipFormatterCallbackFunction
  toolTipUnit?: string
  stackingStyle?: OptionsStackingValue
  animation?: boolean
}

interface Signature {
  Args: Args
  Element: HTMLDivElement
}

const disabled = { enabled: false }

export class SeriesColumn extends Component<Signature> {
  get colors() {
    return this.args.colours ?? undefined
  }

  readonly plotBackgroundColor = BlakeColours.dustyBlack50

  readonly yAxisGridLineColor = BlakeColours.dustyBlack100

  get chartMargins() {
    if (!this.args.chartMargin) return { margin: undefined }

    const [marginTop, marginRight, marginBottom, marginLeft] = this.args.chartMargin

    return {
      marginTop: marginTop ?? undefined,
      marginRight: marginRight ?? undefined,
      marginBottom: marginBottom ?? undefined,
      marginLeft: marginLeft ?? undefined,
    }
  }

  get chartSpacing() {
    return this.args.chartSpacing ?? [10, 10, 10, 10]
  }

  get chartOptions(): Options {
    const tooltip = this.toolTip ?? disabled

    const options: Options = {
      chart: {
        type: 'column',
        plotBackgroundColor: this.plotBackgroundColor,
        backgroundColor: 'transparent',
        spacing: this.chartSpacing,
        ...this.chartMargins,
      },
      credits: disabled,
      title: this.chartTitle,
      yAxis: this.yAxis,
      xAxis: this.xAxis,
      plotOptions: this.plotOptions,
      tooltip,
      legend: this.args.legend ?? disabled,
    }
    if (this.colors) options.colors = this.colors
    return options
  }

  /**
   * the private computed xAxis property
   */

  get xAxis(): XAxisOptions {
    const xAxisLabel = this.args.xAxisLabel ?? ''
    const visible = this.args.xAxisVisible ?? true
    const tickWidth = this.args.xAxisTickWidth ?? 1
    const plotBands = this.args.xAxisPlotBands ?? []
    const plotLines = this.args.xAxisPlotLines ?? []
    const { xAxisLabelFormatter } = this.args

    const xAxis: XAxisOptions = {
      title: reportingTitle(xAxisLabel),
      categories: this.args.categories,
      visible,
      allowDecimals: false,
      tickWidth,
      plotLines,
      plotBands,
      lineColor: BlakeColours.dustyBlack200,
      tickColor: BlakeColours.dustyBlack200,
    }

    if (xAxisLabelFormatter) {
      xAxis.labels = {
        formatter: xAxisLabelFormatter,
      }
    }

    return xAxis
  }

  /**
   * the private computed yAxis property
   */

  get yAxis(): YAxisOptions {
    const yAxisLabel = this.args.yAxisLabel ?? ''
    const visible = this.args.yAxisVisible ?? true
    const [min, max] = this.args.yRange ?? []
    const plotLines = this.args.yAxisPlotLines ?? []
    const plotBands = this.args.yAxisPlotBands ?? []
    const reversedStacks = this.args.yAxisReversedStacks ?? true
    const endOnTick = this.args.yAxisEndOnTick ?? true

    const { yAxisLabelFormatter } = this.args

    const yAxis: YAxisOptions = {
      title: reportingTitle(yAxisLabel),
      visible,
      min,
      max,
      plotLines,
      plotBands,
      reversedStacks,
      allowDecimals: false,
      gridLineColor: this.args.yAxisGridLineColor,
      endOnTick,
      tickInterval: this.args.yAxisTickInterval,
    }

    if (yAxisLabelFormatter) {
      yAxis.labels = {
        formatter: yAxisLabelFormatter,
      }
    }

    return yAxis
  }

  get dataLabelsEnabled() {
    return this.args.dataLabelsEnabled ?? false
  }

  get dataLabels(): Highcharts.DataLabelsOptions {
    // Only set formatter if it's defined, else it will override highcharts' default and error for charts that enable it
    if (this.args.dataLabelsFormatter) {
      return {
        enabled: this.dataLabelsEnabled,
        formatter: this.args.dataLabelsFormatter,
      }
    }
    return { enabled: this.dataLabelsEnabled }
  }

  /**
   * the private computed plotOptions property
   */

  get plotOptions(): PlotOptions {
    if (this.args.plotOptions) {
      return this.args.plotOptions
    }
    const groupPadding = this.args.groupPadding ?? 0.2
    const pointPadding = this.args.columnPadding ?? 0.1
    const colorByPoint = this.args.colorByPoint ?? false

    const options = {
      series: {
        dataLabels: this.dataLabels,
      },
      column: {
        borderWidth: 0,
        stacking: this.args.stackingStyle,
        animation: this.args.animation,
        groupPadding,
        pointPadding,
        colorByPoint,
      },
    }

    return options
  }

  get chartTitle() {
    const title = this.args.title === undefined ? '' : this.args.title
    if (typeof title === 'string') {
      return reportingTitle(title)
    }
    return title
  }

  get toolTip() {
    if (this.args.toolTip) {
      return this.args.toolTip
    }
    const unit = this.args.toolTipUnit === undefined ? '' : this.args.toolTipUnit
    return toolTipConfig('y', unit, { formatter: this.args.toolTipFormatter })
  }

  get highchartOptions(): Options {
    return { ...this.chartOptions, series: this.args.data }
  }

  <template><BaseHighcharterComponent class="h-full" @options={{this.highchartOptions}} ...attributes /></template>
}

export default SeriesColumn

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Primitives::SeriesColumn': typeof SeriesColumn
  }
}
