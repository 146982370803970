import Component from '@glimmer/component'
import { service } from '@ember/service'
import { action } from '@ember/object'
import { guidFor } from '@ember/object/internals'
import { isHTMLSafe, htmlSafe, type SafeString } from '@ember/template'
import { isEmpty, isNone } from '@ember/utils'
import { keyResponder, onKey } from 'ember-keyboard'
import templateCompiler from 'lodash/template'
import cloneDeep from 'lodash/cloneDeep'
import type ActiveRouteService from 'district-ui-client/services/active-route'
import FormSelectOption, {
  type OptionValue,
  type SelectOption,
  type ValueKey,
} from 'district-ui-client/components/form-components/form-select/option'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import { on } from '@ember/modifier'
import { t } from 'ember-intl'
import { eq } from 'ember-truth-helpers'
import { tracked } from '@glimmer/tracking'
import autoFocus from 'district-ui-client/modifiers/auto-focus'

const { isArray } = Array

interface Args {
  closeAction?: () => void
  defaultText?: string
  disabled?: boolean
  labelKey?: string
  openAction?: () => void
  optionClick?: (value: any) => void
  options: SelectOption[]
  optionTemplate?: SafeString | string
  optionTemplateSubLevel?: SafeString | string
  resetAction?: () => void
  search?: boolean
  searchPlaceholder?: string
  secondLevelOnly?: boolean
  selectedTemplate?: string
  selectedTemplateSubLevel?: string
  staticAfterOptions?: SelectOption[]
  staticBeforeOptions?: SelectOption[]
  value?: SelectOption | string | number
  valueLookupKey?: ValueKey
}

interface Signature {
  Element: HTMLDivElement
  Args: Args
}

function flattenArray(acc: SelectOption[], option: SelectOption) {
  if (isArray(option.value)) {
    acc.push(option) // add the parent option
    option.value.forEach((child) => {
      child.parentId = option.uid
    })
    return option.value.reduce<SelectOption[]>(flattenArray, acc)
  }
  acc.push(option)
  return acc
}

/**
 * Renders a template with lodash. It uses standard js templates so a template could looks like
 *
 * 'hello ${planet}'
 *
 */
export function renderTemplateToString(optionTemplateString: string | SafeString, optionData: SelectOption) {
  const wasSafe = isHTMLSafe(optionTemplateString)

  const template = templateCompiler(wasSafe ? optionTemplateString.toString() : optionTemplateString)

  try {
    const compiled = template(optionData)
    return wasSafe ? htmlSafe(compiled) : compiled
  } catch {
    // fallback to default text
    return optionData.text
  }
}

/**
 * The formSelect component creates a form controls which implements search, option groups, keyboard
 * controls, custom look and feel.
 *
 * @class FormSelectComponent
 *
 * @property {String}  defaultText              - shows up when no option is selected or value set.
 * @property {Object}  value                    - this holds the currently selected option
 * @property {Array}   options                  - Is a array of options which populate the dropdown.
 * @property {Array}   staticBeforeOptions      - Is a array of options which will be shown before
 *                                                the searchable options
 * @property {Array}   staticAfterOptions       - Is a array of options which will be shown after
 *                                                the searchable options
 * @property {Object}  keySelectedOption        - Like value, but holds a temporary option which is
 *                                                selected via the keyboard
 * @property {Boolean} secondLevelOnly          - If set to true, only the second level of the option list
 *                                                is selectable and searchable.
 * @property {String}  valueLookupKey           - If unset, when an item is selected the entire item
 *                                                will be set as the value of the form-select control.
 *                                                On the other hand if this is set, when an item is selected,
 *                                                the form-select control will use this property name to
 *                                                look up its value within the selected item instead.
 * @property {Boolean} search                   - Enables or disables the search functionality
 * @property {String}  optionTemplate           - A string which holds a JS template to render an option
 * @property {String}  optionTemplateSubLevel   - A string which holds a JS template to render an option
 *                                                which is a child of an option (child of a group)
 *
 * @property {String}  selectedTemplate         - A string which holds a JS template to render the
 *                                                text which gets displayed after selection
 * @property {String}  selectedTemplateSubLevel - A string which holds a JS template to render the
 *                                                text which gets displayed after selection of a 2nd
 *                                                level option
 *
 * @property {Boolean} disabled                 - A boolean that disables the form-select/button when set to `true`
 * @property {Function} filterFunction          - Override point for filter function that is used to filter options by
 *                                                See default function below for argument requirements
 *
 * @example
 *
 *  {{reporting/form-select
 *    defaultText='Select school'
 *    staticBeforeOptions=beforeOptions
 *    options=schoolOptions
 *    search=true
 *    searchPlaceholder='Search Teachers'
 *    optionClick=changeScope
 *    value=selectedOption
 *    valueLookupKey='value'
 *    optionTemplate='<i class="fa fa-user"></i> ${text} - ${uid}'
 *    optionTemplateSubLevel='${text} - ${uid}'
 *    selectedTemplate='${text}'
 *    selectedTemplateSubLevel='Sub - ${text}'
 *  }}
 *
 *  Option structure, a plain object which need the following props, but can have more.
 *  Please note that you can create recursive structures. The same structure applies to the staticBefore|After options
 *
 *  [
 *    {
 *      text: 'This is a text',
 *      id: 1,
 *      uid: 1,
 *      value: 10
 *    },
 *    {
 *      text: 'This is a text',
 *      id: 2,
 *      uid: 2,
 *      value: [{ text: 'ha', ... }]
 *    },
 *  ]
 */
@keyResponder
export class FormComponentsFormSelect extends Component<Signature> {
  @service activeRoute!: ActiveRouteService

  get componentId() {
    return guidFor(this)
  }

  get componentElement() {
    return document.querySelector(`[component-id="${this.componentId}"]`)
  }

  @tracked keyboardActivated = false

  @tracked keySelectedOption?: SelectOption | null

  @tracked isSelected?: boolean

  @tracked searchTerm = ''

  get defaultText() {
    return this.args.defaultText ?? 'Please override'
  }

  get staticBeforeOptions() {
    return this.args.staticBeforeOptions ?? []
  }

  get staticAfterOptions() {
    return this.args.staticAfterOptions ?? []
  }

  scrollOffset = 48

  get optionTemplate() {
    return this.args.optionTemplate ?? '${text}'
  }

  get optionTemplateSubLevel() {
    return this.args.optionTemplateSubLevel ?? '${text}'
  }

  get selectedTemplate() {
    return this.args.selectedTemplate ?? '${text}'
  }

  get selectedTemplateSubLevel() {
    return this.args.selectedTemplateSubLevel ?? '${text}'
  }

  openAction() {
    this.args.openAction?.()
  }

  closeAction() {
    this.args.closeAction?.()
  }

  optionClick(value?: OptionValue) {
    this.args.optionClick?.(value)
  }

  get activeOption() {
    return this.args.value
  }

  get offset() {
    return this.args.search ? this.scrollOffset : 0
  }

  get options() {
    return this.args.options ?? []
  }

  /**
   * Event handler to close the dropdown which blurs the button and
   * triggers the focusOut event on the component (due to the event bubbling up)
   */
  @onKey('Escape', { event: 'keydown' })
  onEscKeyDown() {
    this.closeDropdown()
  }

  /**
   * Keyboard event handler for the ArrowDown key which gets fired when we press down.
   * We need to prevent the default behavior of this button when the dropdown is active. If we
   * would not do this we would scroll the page natively
   */
  @onKey('ArrowDown', { event: 'keydown' })
  onArrowDownKeyDown(event: KeyboardEvent) {
    event.preventDefault()
  }

  /**
   * Keyboard event handler for the ArrowUp key which gets fired when we press down.
   * We need to prevent the default behavior of this button when the dropdown is active. If we
   * would not do this we would scroll the page natively
   */
  @onKey('ArrowUp', { event: 'keydown' })
  onArrowUpKeyDown(event: KeyboardEvent) {
    event.preventDefault()
  }

  /**
   * Keyboard event handler for the Tab key which gets fired when we press down.
   * We need to prevent the default behavior of this button when the dropdown is active.
   */
  @onKey('Tab', { event: 'keydown' })
  onTabKeyDown(event: KeyboardEvent) {
    event.preventDefault()
    if (this.isSelected) this.closeDropdown()
  }

  /**
   * Keyboard event handler for the arrowDown key which selects the next option and
   * scrolls to it smoothly. Please notice this happens on KEY UP
   */
  @onKey('Tab', { event: 'keyup' })
  onTabKeyUp(event: KeyboardEvent) {
    event.preventDefault()
  }

  /**
   * Keyboard event handler for the arrowDown key which selects the next option and
   * scrolls to it smoothly. Please notice this happens on KEY UP
   */
  @onKey('ArrowDown', { event: 'keyup' })
  onArrowDownKeyUp() {
    this.nextOption()
    this.scrollToOption()
  }

  /**
   * Keyboard event handler for the arrowUp key which selects the previous option and
   * scrolls to it smoothly. Please notice this happens on KEY UP
   */
  @onKey('ArrowUp', { event: 'keyup' })
  onArrowUpKey() {
    this.previousOption()
    this.scrollToOption()
  }

  /**
   * Keyboard event handler for the enter key which sets the currently
   * selected option via the list-click action.
   */
  @onKey('Enter', { event: 'keyup' })
  onEnterKey() {
    /*
     * Use the option selected by the keyboard. If falsey (enter hit when nothing selected, eg no search results), then
     * use the currently active option instead. It is possible for both of these to be falsey, in which case do nothing.
     */
    const activeOption = this.keySelectedOption || this.activeOption
    if (!isNone(activeOption)) this.listClick({ option: activeOption })
  }

  /**
   * Creates a one level array of all the search options
   */
  get flatOptions() {
    const options = this.searchOptions
    return options.reduce<SelectOption[]>(flattenArray, [])
  }

  get flatOptionsFilteredForSecondLevelOnly() {
    const { flatOptions } = this
    const { secondLevelOnly } = this.args
    // only return child options
    if (secondLevelOnly) {
      return flatOptions.filter((option) => option.parentId !== undefined)
    }
    return flatOptions
  }

  /**
   * The text gets displayed when we have selected an option or set the a value
   * If we dont have a value set we will fallback to a default text
   * This will use a template to render the selected option.
   *
   */
  get text() {
    const activeOption = this.getActiveOption()
    if (!activeOption) return this.defaultText

    const { selectedTemplate } = this
    const { selectedTemplateSubLevel } = this

    let text

    if (activeOption.parentId) {
      text = renderTemplateToString(selectedTemplateSubLevel, activeOption)
    } else {
      text = renderTemplateToString(selectedTemplate, activeOption)
    }
    return text
  }

  get showResetButton() {
    return this.activeOption && this.args.resetAction && this.isSelected
  }
  /**
   * This property is used to display the options of the dropdown.
   * If search is enabled we will apply a fuzzy search addon which has a little bit better
   * search results for instance for wrongly typed names and such.
   *
   */
  get searchOptions() {
    const beforeOptions = this.staticBeforeOptions.map((opt) => ({ ...opt, isStatic: true }))
    const afterOptions = this.staticAfterOptions.map((opt) => ({ ...opt, isStatic: true }))

    const options = this.options.filter(Boolean)

    if (isEmpty(options)) return [...beforeOptions, ...afterOptions]
    if (isEmpty(this.searchTerm)) return [...beforeOptions, ...options, ...afterOptions]

    const filteredOptions = this.filterWithFilterFunction(options, this.searchTerm)

    return [...beforeOptions, ...filteredOptions, ...afterOptions]
  }

  /**
   * The default filter function is an adapted implementation of one found in our data-tables addon. Re-implementing it
   * here to avoid requiring it as a dependency.
   * This function is overrideable.
   *
   */
  filterFunction(items: SelectOption[], searchTerm: string) {
    // For a given data item, this function returns true if every keyword is present in the data
    const textFilterMatches = (dataString: string, searchText: string) => {
      const filterKeywords = searchText
        .toLowerCase()
        .split(' ')
        .filter((str) => str.length > 0)
      return filterKeywords.every((keyword) => dataString.match(keyword))
    }

    const filterPredicate = (dataItem: SelectOption) => {
      const dataString = dataItem.text?.toLowerCase() ?? ''
      const itemMatches = textFilterMatches(dataString, searchTerm)
      return itemMatches
    }

    // When filtering data items by the text
    return items.filter(filterPredicate)
  }

  /**
   *
   * There are 2 options:
   * 1. Search on the first level only, which only reduces the first level
   * 2. Search on the second level which only reduces secondary options
   *
   */
  filterWithFilterFunction(options: SelectOption[], searchTerm: string) {
    const { secondLevelOnly } = this.args

    if (secondLevelOnly) {
      // we need to clone the object as we are modifying the value prop. This also makes
      // sure we are not calling render twice.
      return cloneDeep(options).reduce<SelectOption[]>((accumulator, option) => {
        const firstLevelOptions = option.value
        // dont search in first level elements
        if (!isArray(firstLevelOptions)) return accumulator

        const result = this.filterFunction(firstLevelOptions, searchTerm)

        // set search result to the current first level option
        // and dont add it when we dont have any results at all.
        option.value = result
        if (result.length) accumulator.push(option)
        return accumulator
      }, [])
    }
    return this.filterFunction(options, searchTerm)
  }

  /**
   * Selects and sets the next option in the dropdown based on the flat representation of the options.
   * This is important for the keyboard controls.
   */
  nextOption() {
    const activeOption = this.getActiveOption()
    const flatOptions = this.flatOptionsFilteredForSecondLevelOnly
    const flatOptionsCount = flatOptions.length

    const currentIndex = activeOption ? flatOptions.indexOf(activeOption) : -1
    let nextIndex = currentIndex + 1

    if (nextIndex === flatOptionsCount) nextIndex = flatOptionsCount - 1
    this.keySelectedOption = flatOptions[nextIndex]
  }

  /**
   * Selects and sets the previous option in the dropdown based on the flat representation of the options.
   * This is important for the keyboard controls.
   */
  previousOption() {
    const activeOption = this.getActiveOption()
    const flatOptions = this.flatOptionsFilteredForSecondLevelOnly
    const currentIndex = flatOptions.indexOf(activeOption as SelectOption)
    let prevIndex = currentIndex - 1

    if (prevIndex <= 0) prevIndex = 0
    this.keySelectedOption = flatOptions[prevIndex]
  }

  /**
   * This returns a dom element by uid
   */
  getSelectedDomElement(): HTMLElement | null | undefined {
    const activeOption = this.getActiveOption()
    const optionValue = this.getTheOptionValue(activeOption as SelectOption)

    const isStringOrNumber = typeof optionValue === 'string' || typeof optionValue === 'number'
    if (isStringOrNumber) return this.componentElement?.querySelector(`[data-id="${optionValue}"]`)
  }

  findOptionByUid(uid: string | number) {
    const flatOptions = this.flatOptions
    return flatOptions.find((option) => this.getTheOptionValue(option) === uid) || null
  }

  /**
   * ScrollToOptions jumps to the selected element
   */
  scrollToOption() {
    const selectedElement = this.getSelectedDomElement()
    if (!selectedElement) return

    const topPos = selectedElement.offsetTop

    const scroller = this.componentElement?.querySelector('.scroller > ul')

    if (scroller) {
      scroller.scrollTop = -this.offset + topPos
    }
  }

  /**
   * When the component becomes focused through a button which bubbles up to the component
   * then we open the dropdown, activate keyboard inputs, and scroll to the currently active component.
   */
  @action
  onTriggerClick(event: MouseEvent) {
    if (this.args.disabled) return

    const isDropDownOpen = this.isSelected
    if (isDropDownOpen === true) {
      return true
    }
    event.preventDefault()
    event.stopPropagation()
    this.openDropdown()
  }

  openDropdown() {
    this.isSelected = true
    this.keyboardActivated = true
    this.openAction()

    this.scrollToOption()
  }

  @action
  closeDropdown() {
    this.keySelectedOption = null
    this.keyboardActivated = false
    this.closeAction()
    this.isSelected = false
  }

  /**
   * Helper method which always returns the current active option no matter what input type
   * and selected value is given.
   *
   */
  private getActiveOption() {
    const activeOption = this.keySelectedOption || this.activeOption

    if (typeof activeOption === 'string' || typeof activeOption === 'number') {
      return this.findOptionByUid(activeOption)
    }
    // fall back onto value if uid is not set, some dropdown do not use uid
    const id = this.getTheOptionValue(activeOption)

    if (typeof id === 'string' || typeof id === 'number') {
      return this.findOptionByUid(id)
    }
    return null
  }

  /**
   * Returns the actual return value where we check if we have valueLookupKey first and fallback to
   * `uid` or `value` for compatibility reasons. To make absolutely sure we are retrieving the correct
   * value we have to check if the a value is not undefined and return right away.
   */
  private getTheOptionValue(option?: SelectOption) {
    if (!option) return null
    // use fake xyz prop to force an undefined value (safety option)
    const valueLookupKey = this.args.valueLookupKey || 'xyz'

    if (option[valueLookupKey] !== undefined) return option[valueLookupKey]
    if (option.uid !== undefined) return option.uid
    if (option.value !== undefined) return option.value

    throw new Error('Cant retrieve a value without a defined valueLookupKey or uid or value')
  }

  /**
   * Resetting the dropdown will unselect the selected value, close the dropdown and
   * call the resetAction so the consuming app can respond to that change.
   */
  @action
  resetSelection(e: Event) {
    e.preventDefault()
    e.stopPropagation()

    this.args.resetAction?.()
    this.closeDropdown()
    return false
  }

  @action
  listClick(optionComponent: { option: SelectOption | string | number }) {
    const data = optionComponent.option
    let returnValue

    if (typeof data === 'string' || typeof data === 'number') {
      returnValue = data
    } else {
      returnValue = this.args.valueLookupKey ? data[this.args.valueLookupKey] : data
    }

    this.optionClick(returnValue)

    this.closeDropdown()

    return false
  }

  @action
  resetSearch() {
    this.searchTerm = ''

    const element = this.componentElement?.querySelector('input[type="text"]')

    if (element) {
      ;(element as HTMLInputElement).focus()
    }
  }

  @action
  onSearchInput(event: Event) {
    this.searchTerm = (event.target as HTMLInputElement)?.value
  }

  <template>
    <div
      component-id={{this.componentId}}
      class="form-components-form-select dropdown form-select {{if @search 'has-search'}}"
      ...attributes
    >
      {{! Styled similarly to a muted UiButton with themed outline - text colour is lighter though }}
      {{! Ideally we could just use UiButton here, but the behaviour needed is different - we want the outline applied while the dropdown is open, rather than just on focus }}
      <button
        data-test-form-select-button
        type="button"
        class="form-components-form-select-button border-dusty-black-100 text-dusty-black-300 hover:bg-dusty-black-50 hover:text-dusty-black-300 focus:bg-dusty-black-50 focus:text-dusty-black-300 disabled:border-dusty-black-100 disabled:bg-dusty-black-50 disabled:text-dusty-black-200 inline-flex select-none items-center gap-2 rounded-md border bg-white p-2 text-left outline outline-2 outline-transparent transition-all duration-200 disabled:pointer-events-none disabled:cursor-default
          {{if this.isSelected 'scroller-opened'}}
          {{if
            (eq this.activeRoute.subscriptionType 'reading')
            'focus:outline-oceany-blue-300/[0.75] [&.scroller-opened]:outline-oceany-blue-300/[0.75]'
          }}
          {{if
            (eq this.activeRoute.subscriptionType 'maths')
            'focus:outline-ms-green-300/[0.75] [&.scroller-opened]:outline-ms-green-300/[0.75]'
          }}
          {{if
            (eq this.activeRoute.subscriptionType 'writing')
            'focus:outline-wl-blue-300/[0.75] [&.scroller-opened]:outline-wl-blue-300/[0.75]'
          }}"
        disabled={{@disabled}}
        {{on "click" this.onTriggerClick}}
      >
        <span>{{this.text}}</span>
        {{#if this.showResetButton}}
          <span
            data-test-form-select-reset
            aria-label={{t "components.formComponents.resetSelectionAria"}}
            role="button"
            {{on "click" this.resetSelection}}
          >
            <FaIcon @icon="circle-xmark" class="text-dusty-black-300" />
          </span>
        {{/if}}
        {{! This empty span is here to produce a larger gap to the arrow, than between the reset icon and text. }}
        <span></span>
        <FaIcon @icon="caret-down" @size="lg" @transform="shrink-2" class="text-dusty-black-300 ml-auto" />
      </button>
      {{#if this.isSelected}}
        <div class="scroller {{if this.isSelected 'block' 'hidden'}}">
          <ul data-test-form-select-dropdown>
            {{#if @search}}
              <li class="search" data-test-form-select-search>
                <input
                  data-test-form-select-search-input
                  type="text"
                  name="select-search"
                  value={{this.searchTerm}}
                  aria-label={{t "components.formComponents.searchAria"}}
                  placeholder={{@searchPlaceholder}}
                  autocomplete="off"
                  {{on "input" this.onSearchInput}}
                  {{autoFocus}}
                />
                {{#if this.searchTerm}}
                  <span
                    role="button"
                    aria-label={{t "components.formComponents.resetSelectionAria"}}
                    {{on "click" this.resetSearch}}
                  >
                    <FaIcon @icon="circle-xmark" />
                  </span>
                {{else}}
                  <FaIcon @icon="search" />
                {{/if}}
              </li>
            {{/if}}
            {{#each this.searchOptions as |option index|}}
              <FormSelectOption
                @option={{option}}
                @listClickAction={{this.listClick}}
                @value={{@value}}
                @keySelectedOptionValue={{this.keySelectedOption}}
                @parentId={{this.componentId}}
                @optionIndex="{{index}}"
                @optionTemplate={{this.optionTemplate}}
                @optionTemplateSubLevel={{this.optionTemplateSubLevel}}
                @valueLookupKey={{@valueLookupKey}}
                @secondLevelOnly={{@secondLevelOnly}}
              />
            {{else}}
              <li class="default" data-test-form-select-no-results>{{t "components.formComponents.noResults"}}</li>
            {{/each}}
          </ul>
        </div>
        <button type="button" class="dropdown-overlay" {{on "click" this.closeDropdown}}></button>
      {{/if}}
    </div>
  </template>
}

export default FormComponentsFormSelect

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FormComponents::FormSelect': typeof FormComponentsFormSelect
  }
}
