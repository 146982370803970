import Component from '@glimmer/component'
import colors from '@blakeelearning/blake-colours/colours'
import SeriesBar from 'district-ui-client/components/primitives/series-bar/component'
import { t } from 'ember-intl'
import type { SeriesBarOptions } from 'highcharts'

export interface AssessmentScoresData {
  assessment_scores?: {
    average_attempts?: number
    average_score?: number
    scores?: {
      /**
       * "0-49", "50-79" or "80-100"
       */
      range: string
      student_count: number
    }[]
  }
}

interface Args {
  data?: AssessmentScoresData
}

interface Signature {
  Args: Args
  Element: HTMLDivElement
}

/**
 * Display a chart of assessment scores
 */
export class DashboardAssessmentScoresChart extends Component<Signature> {
  get scores() {
    const desiredOrder = ['80-100', '50-79', '0-49']
    const scores = this.args.data?.assessment_scores?.scores ?? []
    return scores.sort((a, b) => desiredOrder.indexOf(a.range) - desiredOrder.indexOf(b.range))
  }

  /**
   * The input data for the chart
   */
  get bars(): SeriesBarOptions[] {
    return [{ data: this.scores.map((s) => s.student_count), type: 'bar' }]
  }

  /**
   * The series labels for the chart.
   * A list of score ranges.
   */
  get categories() {
    return this.scores.map((s) => `${s.range}%`)
  }

  get colours() {
    return [colors.msGreen300, colors.yolkyYellow300, colors.watermelonyRed300]
  }

  <template>
    <div class="flex h-full flex-col" data-test-assessment-scores ...attributes>
      <div data-test-dashboard-subtitle class="flex justify-between gap-4 text-xs">
        <span data-test-average-score>
          {{t "reporting.dashboardSubtitles.assessmentScores.score" score=@data.assessment_scores.average_score}}
        </span>
        <span data-test-average-attempts>
          {{t
            "reporting.dashboardSubtitles.assessmentScores.attempts"
            attempts=@data.assessment_scores.average_attempts
          }}
        </span>
      </div>
      <SeriesBar
        @data={{this.bars}}
        @categories={{this.categories}}
        @colours={{this.colours}}
        @colorByPoint={{true}}
        @yAxisLabel={{t "reporting.statBoxes.assessmentScoresTotals.numOfStudents"}}
      />
    </div>
  </template>
}
export default DashboardAssessmentScoresChart
