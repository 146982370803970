import { PaneHeader } from 'district-ui-client/components/pane/header'
import { t } from 'ember-intl'
import Component from '@glimmer/component'
import { eq } from 'ember-truth-helpers'
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'
import type { Product } from 'district-ui-client/domain/product'
import { isProduct } from 'district-ui-client/domain/product'
import { productsForSubscriptionType } from 'district-ui-client/domain/product'
import { service } from '@ember/service'
import type { IntlService } from 'ember-intl'
import { on } from '@ember/modifier'
import type RouterService from '@ember/routing/router-service'
import { UIScope, validUiScopesFor } from 'district-ui-client/domain/ui-scope'
import ReportingPeriodSelect from 'district-ui-client/components/reporting/period-select'
import type { TOC } from '@ember/component/template-only'
import type { SelectOption } from '@blakeelearning/ember-select'
import { SelectBox } from 'district-ui-client/components/select-box'
import type ActiveRouteService from 'district-ui-client/services/active-route'
import { assert } from '@ember/debug'
import type SessionService from 'district-ui-client/services/session'
import type Store from '@ember-data/store'
import type ReportingService from 'district-ui-client/services/reporting'
import { Lozenge } from 'district-ui-client/components/lozenge'
import { FeatureNotification } from 'district-ui-client/helpers/feature-notification'
import { FeatureNotificationId } from 'district-ui-client/services/feature-notification'
import type { ReportPath } from 'district-ui-client/services/report-registry'
import type ReportRegistryService from 'district-ui-client/services/report-registry'
import { isReportPath, isStandardsBasedReport, isMultiSchoolReport } from 'district-ui-client/services/report-registry'
import type GradeSetsService from 'district-ui-client/services/grade-sets'
import { ThemedButton } from 'district-ui-client/components/themed-button'
import { defaultDateRange, defaultLegacyDateRange } from 'district-ui-client/reporting/ui-scope/route'
import { Tooltip, TooltipInfo } from 'district-ui-client/components/tooltip'
import { MultiSelect } from 'district-ui-client/components/multi-select'
import type School from 'district-ui-client/models/school'
import { DividerHorizontal } from 'district-ui-client/components/divider-horizontal'

interface Signature {
  Args: {
    uiScope: UIScope
  }
}

export class ReportingPane extends Component<Signature> {
  @service intl!: IntlService
  @service router!: RouterService
  @service activeRoute!: ActiveRouteService
  @service session!: SessionService
  @service reporting!: ReportingService
  @service store!: Store
  @service reportRegistry!: ReportRegistryService

  get uiScope() {
    return this.args.uiScope
  }

  get subscriptionType() {
    return this.activeRoute.subscriptionType
  }

  get missingStandards() {
    return !this.session.currentDistrict.standardsSet && isStandardsBasedReport(this.selectedReport)
  }

  get validUiScopes(): UIScope[] {
    const subscriptionType = this.subscriptionType
    assert('subscriptionType required for ui-scope-select', typeof subscriptionType !== 'undefined')
    return validUiScopesFor(this.session.currentDistrict, this.store.peekAll('school').slice(), subscriptionType)
  }

  get selectedProduct(): Product {
    const { reportingProduct } = this.activeRoute
    assert('reporting pane should only be used on a reporting route for a product', reportingProduct)
    return reportingProduct
  }

  get selectedReport(): ReportPath {
    if (isReportPath(this.router.currentRouteName)) {
      return this.router.currentRouteName
    }
    assert(`
    reporting pane used on unrecognised route name: "${this.router.currentRouteName}"
    see isReportPath for details.
    `)
  }

  onProductChange = (option: SelectOption) => {
    // get first report for the chosen product
    if (!isProduct(option.value)) return
    const reportPath = this.reportRegistry.reportPathsForProduct(option.value)[0]
    this.goToReport(reportPath, true)
  }

  onReportChange = ({ value }: SelectOption) => {
    if (!isReportPath(value)) return

    // reset the filters when traversing between multi-school / legacy reports.
    const resetFilters = isMultiSchoolReport(value) !== isMultiSchoolReport(this.selectedReport)

    this.goToReport(value, resetFilters)
  }

  goToReport = (reportPath: ReportPath, resetFilters: boolean) => {
    // always reset page, band etc.
    const queryParamsToReset = { pageNumber: 1, band: 'all' }
    if (resetFilters) {
      // reset scope & filters when going between modern and legacy
      void this.router.transitionTo(reportPath, 'district', this.session.currentDistrict.id, {
        queryParams: {
          ...queryParamsToReset,
          dateRange: undefined,
          studentGrade: undefined,
          contentLevel: undefined,
          schoolIds: undefined,
        },
      })
    } else {
      // keep same scope & filters
      void this.router.transitionTo(reportPath, this.uiScope.scope, this.uiScope.id, {
        queryParams: { ...queryParamsToReset },
      })
    }
  }

  get selectedSchoolId() {
    return this.uiScope.scope === 'school' ? this.uiScope.id : 'all'
  }

  // - selecting "All" sets scope to district
  // - selecting a school sets scope to school
  // - legacy reports to not support multiple schools in school scope
  onLegacySchoolChange = ({ value }: SelectOption) => {
    let uiScope: UIScope
    if (value === 'all') {
      uiScope = new UIScope('district', this.session.currentDistrict.id)
    } else {
      uiScope = new UIScope('school', value)
    }

    this.goToFilter({ uiScope })
  }

  onUiScopeChange = (uiScope: UIScope) => {
    this.goToFilter({ uiScope, queryParams: { schoolIds: uiScope.subScopes.map((school) => school.id) } })
  }

  get selectedGradeId(): string {
    return this.reporting.studentGrade
  }

  onGradeChange = ({ value }: SelectOption) => {
    this.goToFilter({ queryParams: { studentGrade: value } })
  }

  get selectedContentLevel(): string {
    return this.reporting.contentLevel
  }

  onContentLevelChange = ({ value }: SelectOption) => {
    this.goToFilter({ queryParams: { contentLevel: value } })
  }

  onDateChange = (dateRange: string) => {
    this.goToFilter({ queryParams: { dateRange } })
  }

  goToFilter = ({ uiScope, queryParams }: { uiScope?: UIScope; queryParams?: Record<string, unknown> }) => {
    /* always reset page, band etc. need to explicitly give same dateRange else it sometimes resets to default when
     * moving to new scope / dynamic segments for some reason.
     */
    const queryParamsToReset = { pageNumber: 1, band: 'all', dateRange: this.reporting.periodDateRange }
    if (uiScope) {
      void this.router.replaceWith(this.selectedReport, uiScope.scope, uiScope.id, {
        queryParams: { ...queryParamsToReset, ...queryParams },
      })
    } else {
      void this.router.replaceWith(this.selectedReport, { queryParams: { ...queryParamsToReset, ...queryParams } })
    }
  }

  get resetFilterButtonDisabled() {
    const dateRange = isMultiSchoolReport(this.selectedReport) ? defaultDateRange : defaultLegacyDateRange
    return (
      this.uiScope.scope === 'district' &&
      !this.uiScope.subScopes.length &&
      this.reporting.periodDateRange === dateRange &&
      this.selectedGradeId === 'all'
    )
  }

  resetFilters = () => {
    this.goToReport(this.selectedReport, true)
  }

  <template>
    <PaneHeader class="mb-8">{{t "sideNav.reports"}}</PaneHeader>
    <div class="flex flex-col space-y-6">
      <ProductPicker
        @subscriptionType={{this.subscriptionType}}
        @selected={{this.selectedProduct}}
        @onProductChange={{this.onProductChange}}
      />
      <ReportPicker
        @product={{this.selectedProduct}}
        @selected={{this.selectedReport}}
        @onReportChange={{this.onReportChange}}
      />
      <DividerHorizontal />
      <div class="flex content-center items-center justify-between text-sm font-semibold">
        {{t "filterBy"}}
      </div>
      {{#if (isMultiSchoolReport this.selectedReport)}}
        <MultiSchoolPicker
          @uiScope={{this.uiScope}}
          @validUiScopes={{this.validUiScopes}}
          @onUiScopeChange={{this.onUiScopeChange}}
          @subscriptionType={{this.subscriptionType}}
          @disabled={{this.missingStandards}}
        />
        <GradePicker
          @selected={{this.selectedGradeId}}
          @onGradeChange={{this.onGradeChange}}
          @disabled={{this.missingStandards}}
        />
        <ContentLevelPicker
          @contentLevels={{this.reporting.productContentLevels}}
          @selected={{this.selectedContentLevel}}
          @onContentLevelChange={{this.onContentLevelChange}}
          @disabled={{this.missingStandards}}
        />
        <DatePicker @onDateChange={{this.onDateChange}} @disabled={{this.missingStandards}} />
      {{else}}
        <LegacySchoolPicker
          @validUiScopes={{this.validUiScopes}}
          @selected={{this.selectedSchoolId}}
          @onSchoolChange={{this.onLegacySchoolChange}}
          @subscriptionType={{this.subscriptionType}}
        />
        <GradePicker @selected={{this.selectedGradeId}} @onGradeChange={{this.onGradeChange}} @disabled={{true}} />
        <ContentLevelPicker
          @contentLevels={{this.reporting.productContentLevels}}
          @selected={{this.selectedContentLevel}}
          @onContentLevelChange={{this.onContentLevelChange}}
          @disabled={{true}}
        />
        <DatePicker @onDateChange={{this.onDateChange}} />
      {{/if}}
    </div>
    <ThemedButton
      data-test-reset-filters
      class="mt-6"
      @style="neutral"
      @disabled={{this.resetFilterButtonDisabled}}
      {{on "click" this.resetFilters}}
    >
      <RotateLeftIcon />
      <span class="font-medium">{{t "resetFilters"}}</span>
    </ThemedButton>
  </template>
}

const RotateLeftIcon: TOC<unknown> = <template>
  <svg
    class="inline h-[20px] w-[20px] rotate-0 fill-current transition-transform duration-500 group-enabled:transition-none group-disabled:-rotate-[360deg]"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="group-disabled:fill-neutral-75 duration-500 group-enabled:fill-neutral-400 group-enabled:transition-none group-disabled:transition-colors"
      d="M4.26071 10.7147H3.93098C3.41506 10.7147 3 10.2849 3 9.75059V4.60846C3 4.21878 3.22499 3.86526 3.57411 3.71662C3.92323 3.56798 4.32277 3.64833 4.59043 3.92552L6.20414 5.59671C9.60223 2.12176 15.0756 2.13381 18.4543 5.63688C21.8486 9.15201 21.8486 14.8485 18.4543 18.3637C15.0601 21.8788 9.55956 21.8788 6.16535 18.3637C5.68046 17.8615 5.68046 17.046 6.16535 16.5438C6.65023 16.0417 7.43769 16.0417 7.92258 16.5438C10.347 19.0546 14.2765 19.0546 16.701 16.5438C19.1254 14.033 19.1254 9.9635 16.701 7.4527C14.2882 4.95395 10.3897 4.94189 7.96137 7.41253L9.55568 9.06765C9.82334 9.34484 9.90092 9.75862 9.75739 10.1202C9.61387 10.4817 9.27251 10.7147 8.89623 10.7147H4.26071Z"
    />
  </svg>
</template>

interface LegacySchoolPickerSignature {
  Element: HTMLElement
  Args: {
    validUiScopes: UIScope[]
    selected: SelectOption['value']
    onSchoolChange: (value: SelectOption) => void
    subscriptionType?: SubscriptionType
    disabled?: boolean
  }
}

class LegacySchoolPicker extends Component<LegacySchoolPickerSignature> {
  @service intl!: IntlService

  @service store!: Store

  get options(): ({ isActive: boolean } & SelectOption)[] {
    return this.args.validUiScopes
      .map((uiScope) => {
        if (uiScope.scope === 'district') {
          return {
            label: this.intl.t('allSchools', { count: this.args.validUiScopes.length - 1 }),
            value: 'all',
            isActive: true,
          }
        }
        const school = this.store.peekRecord('school', uiScope.id)
        const isActive = Boolean(this.args.subscriptionType && school?.utilisationFor(this.args.subscriptionType))
        return { label: `${school?.name ?? ''}${isActive ? '' : '*'}`, value: uiScope.id, isActive }
      })
      .sort((a, b) => {
        if (a.value === 'all') return -1 // "all" first
        if (b.value === 'all') return 1
        return a.label.localeCompare(b.label) // else alphabetical
      })
  }

  get showExpiredLabel() {
    return this.options.some((option) => !option.isActive)
  }

  <template>
    <label class="flex flex-col gap-y-1" data-test-legacy-school-picker ...attributes>
      <span class="text-neutral-250 text-sm font-medium">{{t "school(s)"}}</span>
      <div>
        <SelectBox
          @style="subtle"
          @options={{this.options}}
          @value={{@selected}}
          @onSelect={{@onSchoolChange}}
          @disabled={{@disabled}}
          @searchPlaceholder={{t "reporting.scopes.schoolSearchPlaceholder"}}
          @beforeLabel={{if this.showExpiredLabel (t "reporting.scopes.expiredSubscriptionLabel")}}
        >
          <:option as |option|>
            <span class={{if (eq option.value "all") "font-semibold"}}>{{option.label}}</span>
          </:option>
        </SelectBox>
        {{#if @disabled}}<Tooltip @text={{t "reporting.scopes.disabledFilterTooltip"}} />{{/if}}
      </div>
    </label>
  </template>
}

interface MultiSchoolPickerSignature {
  Element: HTMLElement
  Args: {
    uiScope: UIScope
    validUiScopes: UIScope[]
    onUiScopeChange: (uiScope: UIScope) => void
    subscriptionType?: SubscriptionType
    disabled?: boolean
  }
}

class MultiSchoolPicker extends Component<MultiSchoolPickerSignature> {
  @service intl!: IntlService

  @service store!: Store

  @service session!: SessionService

  get validSchools() {
    return this.args.validUiScopes.reduce<School[]>((schools, uiScope) => {
      if (uiScope.scope === 'school') {
        const school = this.store.peekRecord('school', uiScope.id)
        if (school) return [...schools, school]
      }
      return schools
    }, [])
  }

  get selectedSchoolIds() {
    if (this.args.uiScope.scope === 'school') return [this.args.uiScope.id]

    // must be district scope

    // use schools from subscopes if any
    if (this.args.uiScope.subScopes.length) return this.args.uiScope.subScopes.map((subScope) => subScope.id)

    // otherwise all valid schools for district
    return this.validSchools.map((school) => school.id)
  }

  get options(): ({ isActive: boolean } & SelectOption)[] {
    return this.validSchools
      .map((school) => {
        const isActive = Boolean(this.args.subscriptionType && school.utilisationFor(this.args.subscriptionType))
        return { label: `${school.name}${isActive ? '' : '*'}`, value: school.id, isActive }
      })
      .sort((a, b) => a.label.localeCompare(b.label))
  }

  /**
   * Converts selectedItems to a UIScope that can be transitioned to
   */
  onSchoolChange = (selectedItems: SelectOption['value'][]) => {
    // If selection contains zero schools, ignore it - their prior selection will be kept
    if (selectedItems.length === 0) {
      return
    }

    const isAllSelected = this.validSchools.every((school) => selectedItems.includes(school.id))

    let uiScope: UIScope
    if (isAllSelected) {
      uiScope = new UIScope('district', this.session.currentDistrict.id)
    } else {
      const subScopes = selectedItems.map((schoolId) => new UIScope('school', schoolId))
      uiScope = new UIScope('district', this.session.currentDistrict.id, subScopes)
    }

    this.args.onUiScopeChange(uiScope)
  }

  // ALL - "All Schools (count)"
  // MANY - "(count) schools"
  // ONE - "The School Name"
  get placeholder() {
    if (this.selectedSchoolIds.length === this.validSchools.length) {
      return this.intl.t('reporting.scopes.allSchoolsCount', { count: this.selectedSchoolIds.length })
    }
    if (this.selectedSchoolIds.length === 1) {
      const school = this.validSchools.find((school) => school.id === this.selectedSchoolIds[0])
      if (school) return school.name
    }
    return this.intl.t('reporting.scopes.someSchools', { count: this.selectedSchoolIds.length })
  }

  get showExpiredLabel() {
    return this.options.some((schoolOption) => !schoolOption.isActive)
  }

  <template>
    <label class="flex flex-col gap-y-1" data-test-multi-school-picker ...attributes>
      <span class="text-neutral-250 text-sm font-medium">{{t "school(s)"}}</span>
      <MultiSelect
        @style="subtle"
        @placeholder={{this.placeholder}}
        @searchPlaceholder={{t "reporting.scopes.schoolSearchPlaceholder"}}
        @beforeLabel={{if this.showExpiredLabel (t "reporting.scopes.expiredSubscriptionLabel")}}
        @selectAllLabel={{t "reporting.scopes.allSchoolsCount" count=this.validSchools.length}}
        @options={{this.options}}
        @selectedItems={{this.selectedSchoolIds}}
        @onCommit={{this.onSchoolChange}}
        @disabled={{@disabled}}
      />
      {{#if @disabled}}<Tooltip @text={{t "reporting.scopes.disabledFilterTooltip"}} />{{/if}}
    </label>
  </template>
}

interface GradePickerSignature {
  Element: HTMLElement
  Args: {
    selected: SelectOption['value']
    onGradeChange: (opt: SelectOption) => void
    disabled?: boolean
  }
}

class GradePicker extends Component<GradePickerSignature> {
  @service intl!: IntlService

  @service store!: Store

  @service gradeSets!: GradeSetsService

  get options(): SelectOption[] {
    const allOption = { label: this.intl.t('reporting.scopes.allGrades'), value: 'all' }
    const gradeOptions = this.gradeSets.gradePositions.map((grade) => ({
      label: this.gradeSets.findByPosition(grade)?.fullName,
      value: String(grade),
    }))
    return [allOption, ...gradeOptions]
  }

  <template>
    <label class="flex flex-col gap-y-1" data-test-student-grade-picker ...attributes>
      <span class="text-neutral-250 text-sm font-medium">{{t "studentGrade"}}</span>
      <div>
        <SelectBox
          @style="subtle"
          @options={{this.options}}
          @value={{@selected}}
          @onSelect={{@onGradeChange}}
          @disabled={{@disabled}}
        />
        {{#if @disabled}}<Tooltip @text={{t "reporting.scopes.disabledFilterTooltip"}} />{{/if}}
      </div>
    </label>
  </template>
}

interface ContentLevelPickerSignature {
  Element: HTMLElement
  Args: {
    contentLevels: number[]
    selected: SelectOption['value']
    onContentLevelChange: (opt: SelectOption) => void
    disabled?: boolean
  }
}

export class ContentLevelPicker extends Component<ContentLevelPickerSignature> {
  @service intl!: IntlService

  @service store!: Store

  @service gradeSets!: GradeSetsService

  get options(): SelectOption[] {
    const allOption = { label: this.intl.t('reporting.scopes.allLevels'), value: 'all' }
    const options = this.args.contentLevels.map((grade) => ({
      label: this.gradeSets.findByPosition(grade)?.fullName,
      value: String(grade),
    }))
    return [allOption, ...options]
  }

  <template>
    <label class="flex flex-col gap-y-1" data-test-content-level-picker ...attributes>
      <span class="text-neutral-250 text-sm font-medium">
        {{t "contentLevel"}}
        {{#unless @disabled}}<TooltipInfo
            data-test-content-level-info-tooltip
            @text={{t "reporting.scopes.contentLevelTooltip"}}
          />{{/unless}}
      </span>
      <div>
        <SelectBox
          @style="subtle"
          @options={{this.options}}
          @value={{@selected}}
          @onSelect={{@onContentLevelChange}}
          @disabled={{@disabled}}
        />
        {{#if @disabled}}<Tooltip
            data-test-content-level-disabled-tooltip
            @text={{t "reporting.scopes.disabledFilterTooltip"}}
          />{{/if}}
      </div>
    </label>
  </template>
}

interface DatePickerSignature {
  Args: {
    onDateChange: (dateRange: string) => void
    disabled?: boolean
  }
}

const DatePicker: TOC<DatePickerSignature> = <template>
  <label class="flex flex-col gap-y-1" data-test-date-picker>
    <span class="text-neutral-250 text-sm font-medium">{{t "reportingPeriod"}}</span>
    <div>
      <ReportingPeriodSelect class="w-full" @disabled={{@disabled}} @onDateChange={{@onDateChange}} />
      {{#if @disabled}}<Tooltip @text={{t "reporting.scopes.disabledFilterTooltip"}} />{{/if}}
    </div>
  </label>
</template>

interface ProductPickerSignature {
  Args: {
    subscriptionType?: SubscriptionType
    selected?: Product
    onProductChange: (opt: SelectOption) => void
  }
}

export class ProductPicker extends Component<ProductPickerSignature> {
  @service intl!: IntlService

  @service reportRegistry!: ReportRegistryService

  /**
   * Display product options for the subscription type, that we have reports for
   */
  get productsAsOptions() {
    const products = this.args.subscriptionType ? productsForSubscriptionType(this.args.subscriptionType) : []

    return products
      .filter((product) => this.reportRegistry.reportPathsForProduct(product).length)
      .map((product) => ({ label: this.intl.t('products.' + product), value: product }))
  }

  <template>
    <label class="flex flex-col gap-y-1" data-test-product-picker>
      <span class="text-neutral-250 text-sm font-medium">{{t "program"}}</span>
      <SelectBox
        @style="subtle"
        @options={{this.productsAsOptions}}
        @value={{@selected}}
        @onSelect={{@onProductChange}}
      />
    </label>
  </template>
}

interface ReportPickerSignature {
  Args: {
    product: Product
    selected: string
    onReportChange: (value: SelectOption) => void
  }
}

class ReportPicker extends Component<ReportPickerSignature> {
  @service intl!: IntlService

  @service reportRegistry!: ReportRegistryService

  get reportsAsOptions(): SelectOption[] {
    const { product } = this.args
    return this.reportRegistry.reportPathsForProduct(product).map((reportPath) => ({
      value: reportPath,
      label: this.reportRegistry.reportName(reportPath).replace('<br />', ' '),
    }))
  }

  <template>
    <label class="flex flex-col gap-y-1" data-test-report-picker>
      <div class="flex items-center gap-2">
        <span class="text-neutral-250 text-sm font-medium">{{t "report"}}</span>
        {{! show "NEW" lozenge if there's a new report for the selected product }}
        {{#if (FeatureNotification FeatureNotificationId.NewReportForProduct reportProduct=@product)}}
          <Lozenge>{{t "new"}}</Lozenge>
        {{/if}}
      </div>
      <SelectBox @style="subtle" @options={{this.reportsAsOptions}} @value={{@selected}} @onSelect={{@onReportChange}}>
        <:option as |option|>
          <div class="flex items-center justify-between space-x-2">
            <span class="flex-grow">{{option.label}}</span>
            {{! show "NEW" lozenge if option is a new report }}
            {{#if (FeatureNotification FeatureNotificationId.NewReportForPath reportPath=option.value)}}
              <Lozenge class="shrink-0 grow-0">{{t "new"}}</Lozenge>
            {{/if}}
          </div>
        </:option>
      </SelectBox>
    </label>
  </template>
}
