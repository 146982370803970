import Component from '@glimmer/component'
import { getSortViewModel } from 'district-ui-client/utils/sorting'
import { updateSortColumns, type SortingConfig } from '@blakeelearning/data-tables/utils/sorting'
import { on } from '@ember/modifier'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'

interface Signature {
  Element: HTMLTableCellElement
  Args: {
    sortKey: string
    sortingConfig: SortingConfig
    onSortClick: (sortingConfig: SortingConfig) => void
  }
  Blocks: {
    default: []
  }
}

export class SortableCell extends Component<Signature> {
  get sortViewModel() {
    return getSortViewModel(this.args.sortingConfig, this.args.sortKey)
  }

  updateSort = () => {
    this.args.onSortClick(updateSortColumns(this.args.sortingConfig, this.args.sortKey))
  }

  <template>
    {{! template-lint-disable no-invalid-interactive }}
    <th class="cursor-pointer select-none" ...attributes {{on "click" this.updateSort}}>
      {{yield}}
      <FaIcon @icon={{this.sortViewModel.icon}} class="print:hidden" />
    </th>
  </template>
}

export default SortableCell
