import Component from '@glimmer/component'
import BaseHighcharterComponent from 'district-ui-client/components/base/highcharter'
import { reportingTitle } from 'district-ui-client/utils/giraffe/highcharts-config'
import type { PlotOptions } from 'highcharts'
import {
  type SubtitleOptions,
  type SeriesPieOptions,
  type VerticalAlignValue,
  type Options,
  type TooltipOptions,
} from 'highcharts'

interface Args {
  colours?: string[]
  tooltip?: TooltipOptions
  subtitle?: string
  innerSize?: string
  size?: string
  data: SeriesPieOptions[]
}

interface Signature {
  Element: HTMLDivElement
  Args: Args
}

export class PieChart extends Component<Signature> {
  verticalAlign: VerticalAlignValue = 'middle'

  get chartOptions(): Options {
    const disabled = { enabled: false }
    const { subtitle, plotOptions } = this
    const backgroundColor = 'transparent'
    const options = {
      colors: this.args.colours,
      chart: {
        type: 'pie',
        backgroundColor,
        margin: 0,
        spacing: [10, 10, 10, 10],
      },
      plotOptions,
      tooltip: this.args.tooltip,
      title: reportingTitle(''),
      subtitle,
      legend: disabled,
    }
    return options
  }

  get highchartsOptions(): Options {
    return { ...this.chartOptions, series: this.args.data }
  }

  get plotOptions(): PlotOptions {
    return {
      pie: {
        innerSize: this.args.innerSize ?? '80%',
        size: this.args.size ?? '100%',
      },
    }
  }

  get subtitle(): SubtitleOptions {
    return {
      text: this.args.subtitle,
      verticalAlign: 'middle',
      style: {
        fontSize: '3rem',
        fontWeight: '300',
      },
    }
  }
  <template><BaseHighcharterComponent class="h-full" @options={{this.highchartsOptions}} ...attributes /></template>
}

export default PieChart

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Primitives::PieChart': typeof PieChart
  }
}
