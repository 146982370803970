import Component from '@glimmer/component'
import SeriesColumn from 'district-ui-client/components/primitives/series-column/component'
import colours from '@blakeelearning/blake-colours/colours'
import type { SeriesColumnOptions } from 'highcharts'

export interface BooksReadByLevelData {
  books_read_by_level: {
    range: string
    count: number
  }[]
}

interface Signature {
  Args: {
    data: BooksReadByLevelData
  }
}

export class BooksReadByLevelDashboardChart extends Component<Signature> {
  get sortedData() {
    return this.args.data.books_read_by_level.sort((a, b) => a.range.localeCompare(b.range))
  }

  get categories() {
    return this.sortedData.map((item) => item.range)
  }

  get seriesData() {
    return this.sortedData.map((item) => item.count)
  }

  get chartData(): SeriesColumnOptions[] {
    return [{ data: this.seriesData, type: 'column' }]
  }

  get colour() {
    return colours.purplyPink300
  }

  <template>
    <div class="flex h-full flex-col" data-test-books-read-by-level>

      <SeriesColumn
        @data={{this.chartData}}
        @categories={{this.categories}}
        @colours={{Array this.colour}}
        @dataLabelsEnabled={{true}}
        @xAxisLabel="Lexile range"
      />

    </div>
  </template>
}

export default BooksReadByLevelDashboardChart
