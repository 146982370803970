import type { TOC } from '@ember/component/template-only'
import ModalDialog from 'ember-modal-dialog/components/modal-dialog'

interface Signature {
  Args: {
    close: () => void
    containerClass?: string
  }
  Blocks: {
    default: []
  }
}

export const BaseModal: TOC<Signature> = <template>
  <ModalDialog
    @onClose={{@close}}
    @translucentOverlay={{false}}
    @overlayClass="z-modal bg-modal-overlay"
    @containerClass="p-0 overflow-hidden z-auto {{@containerClass}} min-w-[32rem] max-w-[48rem] rounded-2xl shadow-modal"
  >
    {{yield}}
  </ModalDialog>
</template>

export default BaseModal

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    BaseModal: typeof BaseModal
  }
}
