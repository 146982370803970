import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper'
import { or } from 'ember-truth-helpers'
import { not } from 'ember-truth-helpers'
import { TimeOnTaskUsageChart } from 'district-ui-client/components/charts/time-on-task/usage/component'
import { TimeOnTaskTable } from 'district-ui-client/components/reporting/time-on-task/table'
import Component from '@glimmer/component'
import type ReTimeOnTaskRoute from 'district-ui-client/reporting/ui-scope/re/time-on-task/route'
import RouteTemplate from 'ember-route-template'
import { SubscriptionType } from 'district-ui-client/domain/subscription-type'
import themeKey from 'district-ui-client/helpers/theme-key'

interface Signature {
  Args: {
    model: ModelFor<ReTimeOnTaskRoute>
  }
}

export class ReportingReTimeOnTaskRouteTemplate extends Component<Signature> {
  get isDistrictScope() {
    return this.args.model.scope.scope === 'district'
  }

  get timeOnTaskUsage() {
    const { timeOnTaskUsage } = this.args.model
    if (!timeOnTaskUsage) return
    return {
      summary: timeOnTaskUsage.time_on_task_summary,
      months: timeOnTaskUsage.time_on_task_by_month,
    }
  }

  <template>
    <DetailedPageWrapper
      @empty={{or (not @model.timeOnTaskUsage) (not @model.timeOnTaskHistory)}}
      @viewMode={{if this.isDistrictScope "table" "chart"}}
    >
      <div class="print:border-dusty-black-50 mb-5 rounded-md bg-white p-2 print:rounded-none print:border">
        <TimeOnTaskUsageChart
          data-test-time-on-task-usage-chart
          @data={{this.timeOnTaskUsage}}
          @uiScope={{@model.scope}}
          @mainTheme={{themeKey SubscriptionType.Reading}}
        />
      </div>

      {{#if this.isDistrictScope}}
        {{#if @model.timeOnTaskHistory}}
          <TimeOnTaskTable @schools={{@model.scopedData.schools}} @data={{@model.timeOnTaskHistory}} />
        {{/if}}
      {{/if}}
    </DetailedPageWrapper>
  </template>
}
export default RouteTemplate(ReportingReTimeOnTaskRouteTemplate)
