import type { TOC } from '@ember/component/template-only'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import { t } from 'ember-intl'
import { InputText } from 'district-ui-client/components/input-text'

interface FormItemSignature {
  Element: HTMLLabelElement
  Blocks: {
    default: []
  }
  Args: {
    label: string
    required?: boolean
  }
}

export const FormItem: TOC<FormItemSignature> = <template>
  <label class="space-y-1 font-normal">
    <div class="text-neutral-250 text-sm font-medium">{{@label}}
      {{#if @required}}<abbr
          title={{t "support.isRequired" field=@label}}
          class="text-red-300 no-underline"
        >*</abbr>{{/if}}
    </div>
    {{yield}}
  </label>
</template>

export const FormItemHorizontal: TOC<FormItemSignature> = <template>
  <label class="flex flex-wrap items-center gap-1 font-normal [&_*]:grow">
    <div class="text-neutral-250 min-w-[33%] max-w-60 basis-0 text-sm font-medium">{{@label}}
      {{#if @required}}<abbr title={{t "support.isRequired" field=@label}} class="text-red-300">*</abbr>{{/if}}
    </div>
    {{yield}}
  </label>
</template>

interface TextFieldSignature {
  Element: HTMLInputElement
  Args: {
    required?: boolean
    placeholder?: string
    hasError?: boolean
    readOnly?: boolean
  }
}

export const TextField: TOC<TextFieldSignature> = <template>
  <InputText
    class="font-normal"
    placeholder={{@placeholder}}
    required={{@required}}
    @readOnly={{@readOnly}}
    @borderColorClass={{if @hasError "border-red-250"}}
    ...attributes
  />
</template>

interface TextAreaSignature {
  Element: HTMLTextAreaElement
  Args: {
    required?: boolean
    placeholder?: string
    hasError?: boolean
  }
}

export const TextArea: TOC<TextAreaSignature> = <template>
  <textarea
    rows="3"
    class="placeholder:text-neutral-250 w-full rounded-lg border-2 px-3 py-2 text-sm font-normal
      {{if @hasError 'border-red-250' 'border-neutral-75'}}"
    type="text"
    placeholder={{@placeholder}}
    required={{@required}}
    ...attributes
  ></textarea>
</template>

interface SupportingTextErrorSignature {
  Element: HTMLElement
  Args: {
    error?: string
  }
  Blocks: {
    default: []
  }
}

export const SupportingTextError: TOC<SupportingTextErrorSignature> = <template>
  <div class="text-red-350 flex items-center space-x-1 text-xs font-medium" ...attributes>
    {{#if @error}}
      <FaIcon @icon="diamond-exclamation" class="text-red-350" />
      <span>{{@error}}</span>
    {{else}}
      {{! occupy vertical space even when error text not shown }}
      <span>&nbsp;</span>
    {{/if}}
  </div>
</template>
