// <MailTo
//   email='example.com'
//   cc=['cc-1@example.com','cc-2@example.com']
//   subject='The Subject'
//   body='The Body'
// >
//   This is the link text or element
// </MailTo?

import Component from '@glimmer/component'

interface Signature {
  Args: {
    email: string
    cc: string[]
    subject: string
    body: string
  }
  Blocks: {
    default: []
  }
}

export class MailTo extends Component<Signature> {
  get href() {
    const params = []
    const email = this.args.email || ''
    const encodedEmail = encodeURIComponent(email)

    const cc = this.args.cc || ''
    if (cc.length > 0) {
      const encodedCC = cc.map(encodeURIComponent)
      const ccSegment = `cc=${encodedCC.join(',')}`
      params.push(ccSegment)
    }

    const { subject } = this.args
    if (subject) {
      const encodedSubject = encodeURIComponent(subject)
      params.push(`subject=${encodedSubject}`)
    }

    const { body } = this.args
    if (body) {
      const encodedBody = encodeURIComponent(body)
      params.push(`body=${encodedBody}`)
    }

    const href = `mailto:${encodedEmail}?${params.join('&')}`
    return href
  }

  <template>
    <a
      data-test-mail-to-anchor
      href={{this.href}}
      class="text-oceany-blue-300 hover:text-oceany-blue-350 focus:text-oceany-blue-350"
    >{{yield}}</a>
  </template>
}

export default MailTo
