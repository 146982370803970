import Component from '@glimmer/component'
import { service } from '@ember/service'
import { eq } from 'ember-truth-helpers'
import type { IntlService } from 'ember-intl'
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter'
import { AwardsEarnedTrophy } from 'district-ui-client/components/charts/dashboard/awards-earned/trophies/trophy'
import trophyGold from '@images/awards-earned/trophy-gold.svg'
import trophySilver from '@images/awards-earned/trophy-silver.svg'
import trophyBronze from '@images/awards-earned/trophy-bronze.svg'

enum TrophyAward {
  Gold = 'gold',
  Silver = 'silver',
  Bronze = 'bronze',
}

export interface AwardsEarnedData {
  awards_earned: {
    [TrophyAward.Gold]: number
    [TrophyAward.Silver]: number
    [TrophyAward.Bronze]: number
  }
}

export interface TrophyViewModel {
  value: string | number
  label: string
  colour: TrophyAward
  src: string
}

interface Signature {
  Element: HTMLDivElement
  Args: {
    data?: AwardsEarnedData
  }
}

const trophyData = {
  [TrophyAward.Gold]: {
    labelKey: 'awards.gold',
    src: trophyGold,
  },
  [TrophyAward.Silver]: {
    labelKey: 'awards.silver',
    src: trophySilver,
  },
  [TrophyAward.Bronze]: {
    labelKey: 'awards.bronze',
    src: trophyBronze,
  },
}

export class AwardsEarnedTrophies extends Component<Signature> {
  @service intl!: IntlService

  get trophies(): TrophyViewModel[] {
    return Object.values(TrophyAward).map((key) => {
      return {
        value: formatNumber(this.args.data?.awards_earned[key] ?? 0, 0),
        label: this.intl.t(trophyData[key].labelKey),
        colour: key,
        src: trophyData[key].src,
      }
    })
  }

  <template>
    <div
      class="mx-auto flex h-full max-w-md items-center justify-center px-6"
      data-test-awards-earned-trophies
      ...attributes
    >
      {{#each this.trophies as |trophy|}}
        <AwardsEarnedTrophy
          class="w-1/3 {{if (eq trophy.colour TrophyAward.Silver) 'mt-12' '-mt-12'}}"
          @trophy={{trophy}}
        />
      {{/each}}
    </div>
  </template>
}

export default AwardsEarnedTrophies
