import Component from '@glimmer/component'
import { ExportButton, type ExportOption } from 'district-ui-client/components/export-button/component'
import { not } from 'ember-truth-helpers'

interface Signature {
  Args: {
    viewMode: 'table' | 'chart'
    isDataPresent?: boolean
    emailAllStudentsCSV?: () => void
  }
  Element: HTMLDivElement
}

export class DashboardControls extends Component<Signature> {
  exportButtonWormhole = document.querySelector('#export-button-wormhole')

  get optionsToInclude(): ExportOption[] {
    const options: { table: ExportOption[]; chart: ExportOption[] } = {
      table: [
        {
          id: 'csv',
          label: 'CSV',
          icon: 'file-excel',
          iconPrefix: 'far',
        },
        {
          id: 'allStudentsCsv',
          label: 'CSV - All Students',
          icon: 'envelope',
          iconPrefix: 'far',
        },
      ],
      chart: [
        {
          id: 'print',
          label: 'Print',
          icon: 'print',
        },
      ],
    }

    return options[this.args.viewMode]
  }

  <template>
    <div class="flex flex-wrap items-center justify-center gap-3 print:hidden" ...attributes>
      {{#if this.exportButtonWormhole}}
        {{#in-element this.exportButtonWormhole}}
          <ExportButton
            @viewMode={{@viewMode}}
            @exportOptions={{this.optionsToInclude}}
            @disabled={{not @isDataPresent}}
            @emailAllStudentsCSV={{@emailAllStudentsCSV}}
          />
        {{/in-element}}
      {{/if}}
    </div>
  </template>
}

export default DashboardControls
