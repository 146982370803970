/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { classNames } from '@ember-decorators/component'
import { alias } from '@ember/object/computed'
import Component from '@ember/component'
import { set, action, computed } from '@ember/object'
import { isEmpty } from '@ember/utils'
import { A } from '@ember/array'
import {
  dtoComputed,
  makeSortPriorityQueueAdjuster,
  makeDtoComputedForMultiColSort,
} from '@blakeelearning/data-tables/components/data-table-operations'
import lowerCase from 'lodash/lowerCase'

const multiColSortKeypathScope = 'dtoConfig.sorting'

/**
 * Component to wrap data table operations in a way to provide
 * tab filtering, field filtering, sorting, pagination functionality
 * to other components
 *
 * expected data format is a config object with pagination, filtering
 * and sorting keys with objects in them.
 *
 * pagination should have currentPage and perPage keys
 * filtering should have tabFilterValue,filterColumns, filterFieldValue,
 * filterFunctions (including doFilterForTab) keys
 * sorting needs to be an empty object
 *
 * we also need to pass a setInConfig closure action in, like this:
 *
 *     setInConfig(keyPath, newValue) {
 *     const config = get(this, 'model.matchSchoolsTableConfig')
 *     // We have an ember run later here to get around the
 *     // double-change-during-render issue doesn't happen
 *     Ember.run.later(() => { set(config, keyPath, newValue) })
 *
 *
 *
 * @example
 *   {{#clever-ui/table
 *     data=schoolMatchRows
 *     config=config
 *     setInConfigAction=(action 'setInConfig')
 *     as |transformedData tableAction|
 *   }}
 *   {{/clever-ui/table}}
 *
 * @class  CleverUITableComponent
 */

@classNames('__clever-ui__table__38f79')
export default class Table extends Component {
  data = null

  config = null

  @alias('dtoConfig.pagination.totalNumOfPages')
  totalNumOfPages

  /*
   * Data Table Operations related boilerplate
   */
  @computed
  get tableActions() {
    const sortingTableActionNames = ['sortByNumber', 'sortByDate', 'sortByValue', 'sortByGrade', 'sort']
    const sortingTableActions = sortingTableActionNames.reduce((returnObj, actionName) => {
      returnObj[actionName] = makeSortPriorityQueueAdjuster(actionName, multiColSortKeypathScope)
      return returnObj
    }, {})
    return { ...sortingTableActions }
  }

  @computed(
    'dtoConfig.filtering.{filterColumns,filterFieldValue,filterFunctions.doFilterForTab}',
    'dtoConfig.pagination.{currentPage,perPage}',
    'setInConfigAction',
  )
  get dataOperations() {
    return {
      doPagination: dtoComputed('data.[]', 'dtoConfig.pagination.{currentPage,perPage}', function (rows) {
        const currentPage = this.dtoConfig?.pagination?.currentPage
        const perPage = this.dtoConfig?.pagination?.perPage
        if (isEmpty(currentPage) || isEmpty(perPage)) return rows
        const upperIndex = currentPage * perPage
        const lowerIndex = (currentPage - 1) * perPage
        return A(rows).slice(lowerIndex, upperIndex)
      }),
      doSetTotalNumOfPagesAndEnsureEndPageInBounds: dtoComputed(
        'data.[]',
        'dtoConfig.pagination.{perPage,currentPage}',
        function (rows) {
          // this function exists to set the number of pages for the pagination control box
          // but also to re-set the current page if it falls out of bounds.
          const pagination = this.dtoConfig?.pagination
          const { perPage } = pagination
          const dataLength = rows.length
          const totalNumOfPages = Math.ceil((dataLength || 1) / perPage)
          // it might seem strange to do it this way, but it's required so we don't
          // end up having a double-set in the case where we re-set the current page
          // actions are somewhat special in that they sit on a queue rather than being set directly
          const configSetter = this.setInConfigAction
          configSetter('pagination.totalNumOfPages', totalNumOfPages)
          const currentPage = this.dtoConfig?.pagination?.currentPage
          if (currentPage > totalNumOfPages) configSetter('pagination.currentPage', totalNumOfPages)
          return rows
        },
      ),
      doTabFiltering: dtoComputed(
        'data.[]',
        'dtoConfig.filtering.{doFilterForTab,tabFilterValue,filterFunctions}',
        function (rowsAsEmberArray) {
          const rows = rowsAsEmberArray.slice()
          let filteringFunction = this.dtoConfig?.filtering?.filterFunctions?.doFilterForTab
          if (!filteringFunction) filteringFunction = (data) => data
          return filteringFunction.call(this, rows)
        },
      ),
      doFilterByField: dtoComputed(
        'data.[]',
        'dtoConfig.filtering.filterFieldValue',
        'dtoConfig.filtering.filterColumns.[]',
        function (rows) {
          const filterKeywordFieldValue = this.dtoConfig?.filtering?.filterFieldValue
          const columnsToFilterOn = this?.dtoConfig?.filtering?.filterColumns
          if (isEmpty(columnsToFilterOn) || isEmpty(filterKeywordFieldValue)) return rows
          const filterKeywords = lowerCase(filterKeywordFieldValue).split()
          const getDataToFilterOnFromDataItem = (dataItem) => {
            const dataItemsToFilterOn = columnsToFilterOn.map((column) => {
              const dataFromColAsString = `${dataItem?.[column]}`
              return dataFromColAsString
            })
            return lowerCase(dataItemsToFilterOn.join(' '))
          }
          const anyKeywordIsInSearchData = (searchData) => filterKeywords.some((keyword) => searchData.match(keyword))
          const includeDataItem = (dataItem) => anyKeywordIsInSearchData(getDataToFilterOnFromDataItem(dataItem))
          return rows.filter(includeDataItem)
        },
      ),
      doMultiColSort: makeDtoComputedForMultiColSort(multiColSortKeypathScope),
    }
  }

  @alias('config')
  dtoConfig

  operationsStack() {
    return [
      'doTabFiltering',
      'doMultiColSort',
      'doFilterByField',
      'doSetTotalNumOfPagesAndEnsureEndPageInBounds',
      'doPagination',
    ]
  }

  @action
  changePage(newPageNumber) {
    set(this, 'dtoConfig.pagination.currentPage', newPageNumber)
  }

  /*
   *  Action for setting data into the config object
   */
  @action
  setInConfig(...args) {
    this.setInConfigAction(...args)
  }
}
