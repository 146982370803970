import Component from '@glimmer/component'
import { ManageHeading } from 'district-ui-client/components/manage-heading'
import { RolloverAlert } from 'district-ui-client/components/rollover-alert'
import { TeacherTable } from 'district-ui-client/components/teacher-table/component'
import { t, type IntlService } from 'ember-intl'
import PopoverStandardDropdown from '@blakeelearning/popovers/components/popover/dropdowns/standard'
import { UiButton } from 'district-ui-client/components/ui-button'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import ActionMenu from 'district-ui-client/components/base/action-menu'
import ActionMenuItem from 'district-ui-client/components/base/action-menu/item'
import { on } from '@ember/modifier'
import UiButtonLinkTo from 'district-ui-client/components/ui-button/link-to'
import { ModalDefault } from 'district-ui-client/components/modal-default'
import { fn } from '@ember/helper'
import { service } from '@ember/service'
import type SessionService from 'district-ui-client/services/session'
import type AlertService from 'district-ui-client/services/alert'
import type TeacherOperationsService from 'district-ui-client/services/teacher-operations'
import type { Log } from '@blakeelearning/log'
import type ActiveRouteService from 'district-ui-client/services/active-route'
import type Store from '@ember-data/store'
import type AuthToken from '@blakeelearning/auth/services/auth-token'
import { isEmpty } from '@ember/utils'
import { tracked } from '@glimmer/tracking'
import type School from 'district-ui-client/models/school'
import type ManageTeachersIndexRoute from 'district-ui-client/subscription-type/manage/teachers/index/route'
import RouteTemplate from 'ember-route-template'
import type ManageTeachersIndexController from 'district-ui-client/subscription-type/manage/teachers/index/controller'
import { hash } from '@ember/helper'
import { or } from 'ember-truth-helpers'

interface Signature {
  Args: {
    model: ModelFor<ManageTeachersIndexRoute>
    controller: ManageTeachersIndexController
  }
}

export class ManageTeachersIndexRouteTemplate extends Component<Signature> {
  @service session!: SessionService

  @service alert!: AlertService

  @service teacherOperations!: TeacherOperationsService

  @service intl!: IntlService

  @service log!: Log

  @service activeRoute!: ActiveRouteService

  @service authToken!: AuthToken

  @service store!: Store

  @tracked selectedIds: string[] = []

  @tracked showChangeSchoolModal = false

  get isCleverDisabled() {
    return this.session.currentDistrict.cleverDisabledManagementFor(this.subscriptionType)
  }

  get is3EDisabled() {
    return this.args.model.has3ESchool
  }

  get groupActionsDisabled() {
    return isEmpty(this.selectedIds) || this.isCleverDisabled || this.is3EDisabled
  }

  get subscriptionType() {
    return this.activeRoute.subscriptionType
  }

  setChangeSchoolModal = (value: boolean) => {
    this.showChangeSchoolModal = value
  }

  updateSelectedIds = (selectedIds: string[]) => {
    this.selectedIds = selectedIds
  }

  changeSchool = async (school: School) => {
    const teachers = this.selectedTeachers

    const response = await this.teacherOperations.moveTeachersToSchool(teachers, school, this.subscriptionType)

    if (response.ok) {
      this.uncheckSelectedItems()
      this.alert.show({
        message: `Successfully moved ${teachers.length} teachers`,
      })
    } else {
      this.alert.show({
        type: 'critical',
        message: 'Failed to move teachers',
      })
    }

    this.setChangeSchoolModal(false)
  }

  uncheckSelectedItems() {
    this.selectedIds = []
  }

  get selectedTeachers() {
    return this.args.controller.teacherQueryResult.filter((t) => this.selectedIds.includes(t.id))
  }

  loginDetails = async () => {
    const teachers = this.selectedTeachers
    if (teachers.length > 0) {
      const response = await this.teacherOperations.sendLoginDetails(teachers, this.subscriptionType)
      if (response.ok) {
        this.uncheckSelectedItems()
        this.alert.showWithDismiss({ message: this.intl.t('subscriptionType.manageTeachers.sendLoginDetailsSuccess') })
      } else {
        this.log.error('failed to send login details', await response.text())
        this.alert.show({
          type: 'critical',
          message: this.intl.t('errorMessages.loginDetails'),
        })
      }
    }
  }

  deleteTeachers = async () => {
    const teachers = this.selectedTeachers
    if (teachers.length > 0) {
      const confirmMessage = `Are you sure you wish to delete ${teachers.length} teacher(s)`
      const confirmResponse = window.confirm(confirmMessage)
      if (!confirmResponse) return

      const successMessage = `Successfully deleted ${teachers.length} teachers`

      try {
        const promises = teachers.map((teacher) => teacher.destroyRecord({ adapterOptions: { bulk: true } }))
        await Promise.all(promises)

        this.alert.showWithDismiss({ message: successMessage })
        this.uncheckSelectedItems()
      } catch (e: any) {
        teachers.forEach((teacher) => teacher.rollbackAttributes())

        this.log.error('failed to delete teachers', e)
        this.alert.show({
          type: 'critical',
          message: this.intl.t('errorMessages.deleteTeachers'),
        })
      }
    }
  }

  passwordReset = async () => {
    const teachers = this.selectedTeachers
    if (teachers.length > 0) {
      const response = await this.teacherOperations.passwordReset(teachers)
      if (response.ok) {
        this.uncheckSelectedItems()
        this.alert.showWithDismiss({ message: this.intl.t('subscriptionType.manageTeachers.passwordResetSuccess') })
      } else {
        this.log.error('failed to request password reset', await response.text())
        this.alert.show({
          type: 'critical',
          message: this.intl.t('errorMessages.passwordReset'),
        })
      }
    }
  }

  exportTeachers = async () => {
    const school = this.args.model.schoolsForSubType.find(
      (school: School) => school.id === this.args.controller.schoolId,
    )

    const response = await this.teacherOperations.exportTeachers(this.subscriptionType, school)
    if (response.ok) {
      this.alert.showWithDismiss({
        message: this.intl.t('subscriptionType.manageTeachers.exportTeachers.exportCsvSuccess'),
      })
    } else {
      this.log.error('failed to export teachers', await response.text())
      this.alert.show({
        type: 'critical',
        message: this.intl.t('subscriptionType.manageTeachers.exportTeachers.exportCsvError'),
      })
    }
  }

  <template>
    <ManageHeading>{{t "subscriptionType.manageTeachers.index.manageTeachers"}}</ManageHeading>
    <RolloverAlert />

    <div class="mt-6">
      <TeacherTable
        @teachers={{@controller.teacherQueryResult}}
        @selectedIds={{this.selectedIds}}
        @updateSelectedIds={{this.updateSelectedIds}}
        @subscriptionType={{this.subscriptionType}}
        @isCleverDisabled={{this.isCleverDisabled}}
        @is3EDisabled={{this.is3EDisabled}}
        @sortedSchoolsOptions={{@controller.sortedSchoolsOptions}}
        @selectSchool={{@controller.selectSchool}}
        @selectedSchoolOption={{@controller.selectedSchoolOption}}
        @isTeachersPending={{@controller.teacherQueryPending}}
      >

        <div class="mx-3 mb-3 inline-block align-middle">
          <PopoverStandardDropdown @disabled={{this.groupActionsDisabled}} as |dropdown status actions|>
            <UiButton
              data-test-bulk-actions
              class="muted"
              @corners="rounded"
              aria-haspopup="menu"
              disabled={{this.groupActionsDisabled}}
              {{dropdown.makeTrigger}}
            >{{t "subscriptionType.manageTeachers.index.groupActions"}}<FaIcon @icon="caret-down" class="ml-1" />
            </UiButton>

            <dropdown.content>
              <ActionMenu data-test-actions-menu>
                <ActionMenuItem
                  id="change-school-button"
                  {{on "click" (fn this.setChangeSchoolModal true)}}
                  {{on "click" actions.close}}
                >
                  <FaIcon @icon="building-columns" @fixedWidth={{true}} />
                  {{t "subscriptionType.manageTeachers.index.changeSchool"}}
                </ActionMenuItem>
                <ActionMenuItem
                  id="delete-teachers-button"
                  {{on "click" this.deleteTeachers}}
                  {{on "click" actions.close}}
                >
                  <FaIcon @icon="trash" @fixedWidth={{true}} />
                  {{t "subscriptionType.manageTeachers.index.delete"}}
                </ActionMenuItem>
                <ActionMenuItem {{on "click" this.passwordReset}} {{on "click" actions.close}}>
                  <button type="button">
                    <FaIcon @icon="lock" @fixedWidth={{true}} />
                    {{t "subscriptionType.manageTeachers.index.resetPassword"}}
                  </button>
                </ActionMenuItem>

                <ActionMenuItem {{on "click" this.loginDetails}} {{on "click" actions.close}}>
                  <FaIcon @icon="right-to-bracket" @fixedWidth={{true}} />
                  {{t "subscriptionType.manageTeachers.index.loginDetails"}}
                </ActionMenuItem>
              </ActionMenu>
            </dropdown.content>
          </PopoverStandardDropdown>
        </div>

        <div class="mx-3 mb-3 inline-flex justify-center align-middle">
          <UiButtonLinkTo
            @route="subscription-type.manage.teachers.new"
            @query={{hash schoolId=@controller.schoolId}}
            class="muted"
            @corners="rounded rounded-r-none"
            @disabled={{or this.isCleverDisabled this.is3EDisabled}}
            data-test-add-teacher-link
          >
            <FaIcon @icon="user" class="mr-1" />{{t "subscriptionType.manageTeachers.index.addSingleTeacher"}}
          </UiButtonLinkTo>
          <UiButtonLinkTo
            @route="subscription-type.manage.teachers.import-teachers"
            class="muted"
            @corners="rounded rounded-r-none"
            @disabled={{or this.isCleverDisabled this.is3EDisabled}}
            data-test-import-teachers-link
          >
            <FaIcon @icon="users" class="mr-1" />{{t
              "subscriptionType.manageTeachers.index.importTeachers"
            }}</UiButtonLinkTo>

          <UiButtonLinkTo
            @route="subscription-type.manage.teachers.add-existing-teachers"
            class="muted"
            @corners="rounded-none"
            @disabled={{or this.isCleverDisabled this.is3EDisabled}}
          >
            <FaIcon @icon="users" class="mr-1" />{{t
              "subscriptionType.manageTeachers.index.addExistingTeachers"
            }}</UiButtonLinkTo>

          <UiButton class="muted" @corners="rounded rounded-l-none" {{on "click" this.exportTeachers}}>
            <FaIcon @icon="download" class="mr-1" />{{t
              "subscriptionType.manageTeachers.index.exportTeachers"
            }}</UiButton>
        </div>
      </TeacherTable>
    </div>

    {{#if this.showChangeSchoolModal}}
      <ModalDefault @onClose={{fn this.setChangeSchoolModal false}} as |modal|>
        <modal.header>
          {{t "subscriptionType.manageTeachers.index.changeSchoolModalTitle"}}
        </modal.header>
        <modal.body class="max-h-1/2-screen">
          <div class="stacked-buttons">
            {{#each @model.schoolsForSubType as |school|}}
              <UiButton class="muted" {{on "click" (fn this.changeSchool school)}} data-test-change-school-for-teacher>
                {{school.name}}
              </UiButton>
            {{/each}}
          </div>
        </modal.body>
      </ModalDefault>
    {{/if}}
  </template>
}

export default RouteTemplate(ManageTeachersIndexRouteTemplate)
