import type { TOC } from '@ember/component/template-only'
import type ReportingMathsCourseProgressTotalsRoute from 'district-ui-client/reporting/ui-scope/maths/course-progress/totals/route'
import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper'
import { not } from 'ember-truth-helpers'
import { ChartsCourseProgressTotals } from 'district-ui-client/components/charts/course-progress/totals/component'
import { t } from 'ember-intl'
import RouteTemplate from 'ember-route-template'
import fullGradeName from 'district-ui-client/helpers/full-grade-name'

interface Signature {
  Args: {
    model: ModelFor<ReportingMathsCourseProgressTotalsRoute>
  }
}

export const ReportingMathsCourseProgressTotalsRouteTemplate: TOC<Signature> = <template>
  <DetailedPageWrapper @empty={{not @model.courseProgressTotals}}>
    {{#each @model.courseProgressTotals.course_progress_totals as |gradeData|}}
      <div class="detailed-chart-wrapper">
        <ChartsCourseProgressTotals
          class="h-full"
          @data={{gradeData}}
          @precinct={{@model.precinct}}
          @product={{@model.product}}
          @grade={{fullGradeName gradeData.grade_position}}
          @tooltip={{t "reporting.tooltips.courseProgressTotals"}}
        />
      </div>
    {{/each}}
  </DetailedPageWrapper>
</template>

export default RouteTemplate(ReportingMathsCourseProgressTotalsRouteTemplate)
