import Component from '@glimmer/component'
import { LinkTo } from '@ember/routing'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import type { SafeString } from '@ember/template'

export enum SummaryBoxColorScheme {
  Default = 'default',
  LightBlue = 'light-blue',
  DarkBlue = 'dark-blue',
  Green = 'green',
  DarkGreen = 'dark-green',
  Orange = 'orange',
  Pink = 'pink',
  White = 'white',
}

export const SummaryBoxColorSchemeClasses = {
  [SummaryBoxColorScheme.Default]: {
    header: 'bg-dusty-black-300',
    stats: 'bg-dusty-black-100',
  },
  [SummaryBoxColorScheme.LightBlue]: {
    header: 'bg-oceany-blue-300',
    stats: 'bg-oceany-blue-100',
  },
  [SummaryBoxColorScheme.DarkBlue]: {
    header: 'bg-storm-blue-300',
    stats: 'bg-storm-blue-100',
  },
  [SummaryBoxColorScheme.Green]: {
    header: 'bg-grapey-green-300',
    stats: 'bg-grapey-green-100',
  },
  [SummaryBoxColorScheme.DarkGreen]: {
    header: 'bg-forest-green-300',
    stats: 'bg-forest-green-100',
  },
  [SummaryBoxColorScheme.Orange]: {
    header: 'bg-juicy-orange-300',
    stats: 'bg-juicy-orange-100',
  },
  [SummaryBoxColorScheme.Pink]: {
    header: 'bg-purply-pink-300',
    stats: 'bg-purply-pink-100',
  },
  [SummaryBoxColorScheme.White]: {
    header: 'bg-white',
    stats: 'bg-white',
  },
}

export enum SummaryBoxType {
  Single = 'single',
  Double = 'double',
  Triple = 'triple',
  Many = 'many',
}

export const SummaryBoxTypeClasses = {
  [SummaryBoxType.Single]: {
    statWrapper: 'flex flex-grow flex-col',
    statBox: 'flex flex-grow flex-col justify-center p-2',
    statTitle: 'text-3xl',
  },
  [SummaryBoxType.Double]: {
    statWrapper: 'flex flex-grow flex-col',
    statBox: 'flex-grow p-2',
    statTitle: 'text-3xl',
  },
  [SummaryBoxType.Triple]: {
    statWrapper: 'flex flex-grow flex-row flex-wrap',
    statBox: 'relative flex-grow w-1/2 p-2',
    statTitle: 'text-3xl',
  },
  [SummaryBoxType.Many]: {
    statWrapper: 'flex flex-grow flex-col flex-nowrap',
    statBox: 'flex flex-grow justify-between items-center w-auto p-2',
    statTitle: 'order-2 text-xl',
  },
}

interface Stat {
  value: string | SafeString
  label: string
}
interface Args {
  stats: Stat[]
  colorScheme?: SummaryBoxColorScheme
  title?: string
  grade?: string
  link?: {
    route: string
    text: string
  }
}

interface Signature {
  Args: Args
  Element: HTMLDivElement
  Blocks: {
    default: []
  }
}
/**
 * Summary box chart. Takes a list of stat objects and display them.
 */
export class SummaryBox extends Component<Signature> {
  get statsType() {
    switch (this.args.stats.length) {
      case 1:
        return SummaryBoxType.Single
      case 2:
        return SummaryBoxType.Double
      case 3:
        return SummaryBoxType.Triple
      default:
        return SummaryBoxType.Many
    }
  }

  get statsView() {
    return this.args.stats.map((statItem, index) => {
      return {
        ...statItem,
        id: index + 1,
        showHorizontalDivider:
          (this.isDouble && index === 1) || (this.isTriple && index === 2) || (this.isMany && index !== 0),
        showVerticalDivider: this.isTriple && index === 0,
      }
    })
  }

  get isDouble() {
    return this.statsType === SummaryBoxType.Double
  }

  get isTriple() {
    return this.statsType === SummaryBoxType.Triple
  }

  get isMany() {
    return this.statsType === SummaryBoxType.Many
  }

  get statTypeClasses() {
    const statKey = this.statsType
    return SummaryBoxTypeClasses[statKey]
  }

  get colorSchemeClasses() {
    const colorScheme = this.args.colorScheme ?? SummaryBoxColorScheme.Default
    return SummaryBoxColorSchemeClasses[colorScheme]
  }

  <template>
    <div data-test-summary-box class="flex flex-col overflow-hidden rounded-l-md print:rounded-none" ...attributes>
      <div
        data-test-summary-box-header
        class="px-3 py-2
          {{this.colorSchemeClasses.header}}
          print:border-dusty-black-200 print:!bg-dusty-black-50 print:m-[1px] print:w-[calc(100%-2px)] print:!overflow-hidden print:border-b"
      >
        {{#if @title}}
          <h2 data-test-summary-box-title class="my-0.5 text-base font-bold text-white print:text-black">
            {{@title}}
          </h2>
          {{#if @grade}}
            <p class="m-0 font-bold text-white print:text-black">
              {{@grade}}
            </p>
          {{/if}}
        {{else}}
          <h2 data-test-summary-box-title class="my-0.5 text-base font-bold text-white print:text-black">
            {{@grade}}
          </h2>
        {{/if}}
      </div>
      {{#if (has-block)}}
        <div
          data-test-summary-box-content
          class="flex flex-grow flex-col px-3 py-2 print:!bg-transparent {{this.colorSchemeClasses.stats}}"
        >
          {{yield}}
        </div>
      {{else}}
        <div
          data-test-summary-box-content="{{this.statsType}}"
          class="{{this.statTypeClasses.statWrapper}} px-3 py-2 print:!bg-transparent {{this.colorSchemeClasses.stats}}"
        >
          {{#each this.statsView as |stat|}}
            {{#if stat.showHorizontalDivider}}
              <hr class="print:border-dusty-black-200 m-0 w-full border-white" />
            {{/if}}

            <div
              data-test-summary-box-stat="{{stat.id}}"
              class="{{this.statTypeClasses.statBox}} {{if stat.showVerticalDivider 'border-r border-white'}}"
            >
              <h3 data-test-summary-box-stat-title class="{{this.statTypeClasses.statTitle}} m-0 font-extralight">
                {{stat.value}}
              </h3>
              <p class="m-0 font-semibold print:!font-bold">
                {{stat.label}}
              </p>
            </div>
          {{/each}}

          {{#if @link}}
            <LinkTo
              @route={{@link.route}}
              class="text-oceany-blue-350 hover:text-oceany-blue-325 focus:text-oceany-blue-325 block pb-2 text-base font-semibold print:hidden"
            >
              {{@link.text}}
              <FaIcon @icon="circle-right" />
            </LinkTo>
          {{/if}}
        </div>
      {{/if}}
    </div>
  </template>
}

export default SummaryBox
