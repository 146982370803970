import Component from '@glimmer/component'
import { capitalize } from '@ember/string'
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter'
import { roundAccurately } from 'district-ui-client/utils/round'
import { PieChart } from 'district-ui-client/components/primitives/pie-chart/component'
import type { Point, SeriesPieOptions } from 'highcharts'
import colours from '@blakeelearning/blake-colours/colours'
import { reportingToolTip as toolTipConfig } from 'district-ui-client/utils/giraffe/highcharts-config'

/**
 * Display a donut chart of books read by genre (fiction/nonfiction).
 */

export interface BooksReadByGenreData {
  books_read_by_genre: {
    fiction: number
    nonfiction: number
  }
}

interface Signature {
  Args: {
    data: BooksReadByGenreData
    product: 're' | 'rex'
  }
}

export class BooksReadByGenreDashboardChart extends Component<Signature> {
  get chartData(): SeriesPieOptions[] {
    const data = this.booksReadData

    return [
      {
        data,
        dataLabels: { enabled: false },
        showInLegend: true,
        type: 'pie',
      },
    ]
  }

  get chartInnerSize() {
    return '80%'
  }

  get chartSubtitle() {
    const subtitle = formatNumber(this.totalCount, 1)
    return `<h1>${subtitle}</h1>`
  }

  get chartSize() {
    return '80%'
  }

  get booksReadData(): [string, number][] {
    const values = this.args.data.books_read_by_genre
    const keys = ['nonfiction', 'fiction'] as const
    return keys.map((key) => {
      return [capitalize(key), values[key]]
    })
  }

  get totalCount() {
    const values = this.args.data.books_read_by_genre
    const keys = ['nonfiction', 'fiction'] as const
    return keys.reduce((total, key) => {
      return total + values[key]
    }, 0)
  }

  get colours() {
    return [colours.purplyPink300, colours.purplyPink400]
  }

  get legendData() {
    const total = this.totalCount
    const data = this.booksReadData

    const [[nonfictionName, nonfiction], [fictionName, fiction]] = data

    let fictionPercentage = 0
    let nonfictionPercentage = 0

    if (total !== 0) {
      fictionPercentage = (fiction / total) * 100
      nonfictionPercentage = (nonfiction / total) * 100
    }

    return {
      left: { name: fictionName, percentage: roundAccurately(fictionPercentage, 1) },
      right: { name: nonfictionName, percentage: roundAccurately(nonfictionPercentage, 1) },
    }
  }

  get tooltipFormatter() {
    return function (this: Point) {
      return `<b>${this.name}</b>: ${formatNumber(this.y ?? 0, 2)}`
    }
  }

  get tooltip() {
    const formatter = this.tooltipFormatter
    return toolTipConfig('y', '', { formatter })
  }

  <template>
    <div class="relative flex h-full flex-col pb-7 text-3xl font-light print:!font-light" data-test-books-read-by-genre>
      <PieChart
        @data={{this.chartData}}
        @subtitle={{this.chartSubtitle}}
        @colours={{this.colours}}
        @size={{this.chartSize}}
        @innerSize={{this.chartInnerSize}}
        @tooltip={{this.tooltip}}
      />
      <div data-test-books-read-by-genre-legend class="absolute bottom-2 left-0 right-0 flex">
        <div data-test-books-read-by-genre-label="left" class="ml-[5%] flex flex-col items-center">
          <div
            class="bg-purply-pink-500 print:!bg-purply-pink-500 h-[10px] w-[10px] rounded-full print:!rounded-full"
          ></div>
          <div
            data-test-books-read-by-genre-percentage
            class="text-dusty-black-500 text-3xl font-thin print:!font-thin"
          >
            {{this.legendData.left.percentage}}%
          </div>
          <div data-test-books-read-by-genre-name class="text-dusty-black-500 m-0 text-base font-bold print:!font-bold">
            {{this.legendData.left.name}}
          </div>
        </div>

        <div data-test-books-read-by-genre-label="right" class="ml-auto mr-[5%] flex flex-col items-center">
          <div
            class="bg-purply-pink-300 print:!bg-purply-pink-300 h-[10px] w-[10px] rounded-full print:!rounded-full"
          ></div>
          <div
            data-test-books-read-by-genre-percentage
            class="text-dusty-black-500 text-3xl font-thin print:!font-thin"
          >
            {{this.legendData.right.percentage}}%
          </div>
          <div data-test-books-read-by-genre-name class="text-dusty-black-500 m-0 text-base font-bold print:!font-bold">
            {{this.legendData.right.name}}
          </div>
        </div>
      </div>
    </div>
  </template>
}

export default BooksReadByGenreDashboardChart
