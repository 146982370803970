import type {
  BaseReport,
  BaseReportMetaPaging,
  BaseReportMetaStandardsSet,
} from 'district-ui-client/services/reporting'
import type { Task } from 'ember-concurrency'
import { on } from '@ember/modifier'
import { formatNumber, t } from 'ember-intl'
import { Panel, PanelHeader, PanelTitle, PanelBody } from 'district-ui-client/components/section'
import { ThemedButton } from 'district-ui-client/components/themed-button'
import { TooltipInfo } from 'district-ui-client/components/tooltip'
import { formatValue } from 'district-ui-client/utils/format-value'
import { TableContainer, Table, TRHead, TBody, TH, TD, TFootPageSelector } from 'district-ui-client/components/table'
import { TabGroup, TabItem } from 'district-ui-client/components/tabs'
import type { BandKey } from 'district-ui-client/components/reporting/lesson-performance/overview'
import Component from '@glimmer/component'
import { service } from '@ember/service'
import { eq, or } from 'ember-truth-helpers'
import { fn } from '@ember/helper'
import NoDataMessage from 'district-ui-client/components/no-data-message'
import type ReportingService from 'district-ui-client/services/reporting'
import { Product } from 'district-ui-client/domain/product'

interface StudentLessonPerformance {
  first_name: string
  last_name: string
  grade: string
  school_name: string
  lessons_completed: number
  average_score: Nullable<number> // percentage (rounded), frontend will format with %
}

export interface LessonPerformanceResultsReportData extends BaseReport {
  aggregate: {
    total_schools: number
    total_students: number
  }
  results: StudentLessonPerformance[]
  meta: BaseReportMetaPaging & BaseReportMetaStandardsSet
}

interface Signature {
  Args: {
    reportData: LessonPerformanceResultsReportData
    isReloading?: boolean
    exportCsv?: Task<void, unknown[]>
    band: 'all' | BandKey
    setBand: (band: 'all' | BandKey) => void
  }
}

const avgScoreColor = (avgScore: Nullable<number>): string => {
  if (avgScore === null) {
    return ''
  } else if (avgScore < 50) {
    return 'text-red-350'
  } else if (avgScore < 80) {
    return 'text-yellow-400'
  } else {
    return 'text-green-350'
  }
}

export class LessonPerformanceResults extends Component<Signature> {
  @service reporting!: ReportingService

  get isEmptyReport(): boolean {
    return this.args.reportData.results.length === 0
  }

  get isFastPhonics() {
    return this.reporting.product === Product.FP
  }

  <template>
    <Panel data-test-lesson-performance-results-panel>
      <TabGroup class="mx-6">
        <TabItem @active={{eq @band "all"}} {{on "click" (fn @setBand "all")}} data-test-results-tab="all">
          {{t "all"}}
        </TabItem>
        {{! template-lint-disable no-bare-strings }}
        <TabItem @active={{eq @band "0-49"}} {{on "click" (fn @setBand "0-49")}} data-test-results-tab="0-49">
          0–49%
        </TabItem>
        <TabItem @active={{eq @band "50-79"}} {{on "click" (fn @setBand "50-79")}} data-test-results-tab="50-79">
          50–79%
        </TabItem>
        <TabItem @active={{eq @band "80-100"}} {{on "click" (fn @setBand "80-100")}} data-test-results-tab="80-100">
          80–100%
        </TabItem>
      </TabGroup>
      <PanelHeader>
        <div class="flex items-start justify-between gap-4">
          <div class="space-y-2">
            <PanelTitle>
              {{#if this.isFastPhonics}}
                {{t "reporting.lessonPerformance.lessonResultsPeak"}}
              {{else}}
                {{t "reporting.lessonPerformance.lessonResults"}}
              {{/if}}
            </PanelTitle>
            <div class="text-sm">
              {{t
                "reporting.showingDataForStudentsFromSchools"
                studentCount=(formatNumber @reportData.aggregate.total_students)
                schoolCount=(formatNumber @reportData.aggregate.total_schools)
              }}
            </div>
          </div>
          {{#if @exportCsv}}
            <ThemedButton
              data-test-export-csv
              class="print:hidden"
              @style="theme"
              @disabled={{or @exportCsv.isRunning this.isEmptyReport}}
              {{on "click" @exportCsv.perform}}
            >
              {{t "exportCsv"}}
            </ThemedButton>
          {{/if}}
        </div>
      </PanelHeader>
      <PanelBody @tight={{true}}>
        <TableContainer>
          {{#if this.isEmptyReport}}
            <NoDataMessage class="m-6" data-test-report-empty />
          {{else}}
            <Table data-test-lesson-performance-results-table @minWidth="min-w-[52rem]">
              <thead>
                <TRHead>
                  <TH class="w-1/3" @sortKey="first_name">
                    {{t "reporting.lessonPerformance.headers.firstName"}}
                  </TH>
                  <TH class="w-1/3" @sortKey="last_name">
                    {{t "reporting.lessonPerformance.headers.lastName"}}
                  </TH>
                  <TH class="w-28" @sortKey="grade">{{t "reporting.lessonPerformance.headers.grade"}}</TH>
                  <TH class="w-1/3" @sortKey="school_name">{{t "reporting.lessonPerformance.headers.schoolName"}}</TH>
                  <TH class="w-44" @sortKey="lessons_completed">
                    {{#if this.isFastPhonics}}
                      {{t "reporting.lessonPerformance.headers.lessonsCompletedPeaks" htmlSafe=true}}
                    {{else}}
                      {{t "reporting.lessonPerformance.headers.lessonsCompleted"}}
                    {{/if}}
                  </TH>
                  <TH class="w-32" @sortKey="average_score">
                    {{t "reporting.lessonPerformance.headers.averageScore" htmlSafe=true}}
                    <TooltipInfo
                      @text={{if
                        this.isFastPhonics
                        (t "reporting.lessonPerformance.headers.averageScoreTooltipPeak")
                        (t "reporting.lessonPerformance.headers.averageScoreTooltip")
                      }}
                    />
                  </TH>
                </TRHead>
              </thead>
              <TBody class={{if @isReloading "opacity-50"}}>
                {{#each @reportData.results as |resultItem|}}
                  <tr class="hover:bg-blue-50">
                    <TD>{{resultItem.first_name}}</TD>
                    <TD>{{resultItem.last_name}}</TD>
                    <TD>{{resultItem.grade}}</TD>
                    <TD>{{resultItem.school_name}}</TD>
                    <TD>{{resultItem.lessons_completed}}</TD>
                    <TD class="font-semibold" @textColor={{avgScoreColor resultItem.average_score}}>{{formatValue
                        resultItem.average_score
                        append="%"
                      }}</TD>
                  </tr>
                {{/each}}
              </TBody>
              <TFootPageSelector @pageCount={{@reportData.meta.total_pages}} />
            </Table>
          {{/if}}
        </TableContainer>
      </PanelBody>
    </Panel>
  </template>
}
