import Component from '@glimmer/component'
import { service } from '@ember/service'
import { eq } from 'ember-truth-helpers'
import type { IntlService } from 'ember-intl'
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter'
import { AwardsEarnedRibbon } from 'district-ui-client/components/charts/dashboard/awards-earned/ribbons/ribbon'
import ribbonGold from '@images/awards-earned/ribbon-gold.svg'
import ribbonSilver from '@images/awards-earned/ribbon-silver.svg'
import ribbonBronze from '@images/awards-earned/ribbon-bronze.svg'

enum RibbonAward {
  Gold = 'gold',
  Silver = 'silver',
  Bronze = 'bronze',
}

export interface AwardsEarnedData {
  awards_earned: {
    [RibbonAward.Gold]: number
    [RibbonAward.Silver]: number
    [RibbonAward.Bronze]: number
  }
}

export interface RibbonViewModel {
  value: string | number
  label: string
  color: RibbonAward
  legend?: string
  legendClassNames?: string
  src: string
}

interface Signature {
  Element: HTMLDivElement
  Args: {
    data?: AwardsEarnedData
    withLegend?: boolean
  }
}

const ribbonData = {
  [RibbonAward.Gold]: {
    labelKey: 'awards.gold',
    legend: '91-100%',
    legendClassNames: 'bg-medal-gold',
    src: ribbonGold,
  },
  [RibbonAward.Silver]: {
    labelKey: 'awards.silver',
    legend: '80-90%',
    legendClassNames: 'bg-medal-silver',
    src: ribbonSilver,
  },
  [RibbonAward.Bronze]: {
    labelKey: 'awards.bronze',
    legend: '65-79%',
    legendClassNames: 'bg-medal-bronze',
    src: ribbonBronze,
  },
}

export class AwardsEarnedRibbons extends Component<Signature> {
  @service intl!: IntlService

  get ribbons(): RibbonViewModel[] {
    return Object.values(RibbonAward).map((key) => {
      return {
        value: formatNumber(this.args.data?.awards_earned[key] ?? 0, 0),
        label: this.intl.t(ribbonData[key].labelKey),
        color: key,
        legend: this.args.withLegend ? ribbonData[key].legend : undefined,
        legendClassNames: this.args.withLegend ? ribbonData[key].legendClassNames : undefined,
        src: ribbonData[key].src,
      }
    })
  }

  <template>
    <div
      class="mx-auto flex h-full max-w-md items-center justify-center gap-1 px-8 py-6"
      data-test-awards-earned-ribbons
      ...attributes
    >
      {{#each this.ribbons as |ribbon|}}
        <AwardsEarnedRibbon
          class="w-1/3 {{if (eq ribbon.color RibbonAward.Silver) 'mt-12' '-mt-12'}}"
          @ribbon={{ribbon}}
        />
      {{/each}}
    </div>
  </template>
}

export default AwardsEarnedRibbons
