import type { TOC } from '@ember/component/template-only'
import type RexCourseProgressAveragesRoute from 'district-ui-client/reporting/ui-scope/rex/course-progress/averages/route'
import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper'
import { not } from 'ember-truth-helpers'
import { ChartsCourseProgressAverages } from 'district-ui-client/components/charts/course-progress/averages/component'
import { t } from 'ember-intl'
import RouteTemplate from 'ember-route-template'
import { Precinct } from 'district-ui-client/domain/precinct'

interface Signature {
  model: ModelFor<RexCourseProgressAveragesRoute>
}

export const ReportingRexCourseProgressAveragesRouteTemplate: TOC<Signature> = <template>
  <DetailedPageWrapper @empty={{not @model.courseProgressAverages}}>
    {{#each @model.courseProgressAverages.course_progress_averages as |gradeData|}}
      <div class="detailed-chart-wrapper">
        <ChartsCourseProgressAverages
          class="h-full"
          @data={{gradeData}}
          @precinct={{Precinct.REX_READING}}
          @tooltip={{t "reporting.tooltips.courseProgressAverages"}}
          @title={{t "reporting.chartTitles.courseProgressAverages"}}
        />
      </div>
    {{/each}}
  </DetailedPageWrapper>
</template>

export default RouteTemplate(ReportingRexCourseProgressAveragesRouteTemplate)
