import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper'
import { not } from 'ember-truth-helpers'
import { ChartsWorkingAtGradeLevelTotals } from 'district-ui-client/components/charts/working-at-grade-level/totals/component'
import fullGradeName from 'district-ui-client/helpers/full-grade-name'
import type WorkingAtGradeLevelRoute from 'district-ui-client/reporting/ui-scope/maths/working-at-grade-level/route'
import type { TOC } from '@ember/component/template-only'
import { t } from 'ember-intl'
import RouteTemplate from 'ember-route-template'

interface Signature {
  model: ModelFor<WorkingAtGradeLevelRoute>
}

export const ReportingMsWorkingAtGradeLevelRouteTemplate: TOC<Signature> = <template>
  <DetailedPageWrapper @empty={{not @model.workingAtGradeLevel}}>
    {{#each @model.workingAtGradeLevel.working_at_grade_level_totals_by_grade as |gradeData|}}
      <div class="detailed-chart-wrapper">
        <ChartsWorkingAtGradeLevelTotals
          class="h-60"
          @data={{gradeData}}
          @product={{@model.product}}
          @grade={{fullGradeName gradeData.grade_position}}
          @tooltip={{t "reporting.tooltips.workingAtGradeLevel"}}
        />
      </div>
    {{/each}}
  </DetailedPageWrapper>
</template>

export default RouteTemplate(ReportingMsWorkingAtGradeLevelRouteTemplate)
