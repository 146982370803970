import Component from '@glimmer/component'
import { basicAverage } from 'district-ui-client/utils/giraffe/average-calculator'
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format'
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter'
import { service } from '@ember/service'
import colors from '@blakeelearning/blake-colours/colours'
import type { IntlService } from 'ember-intl'
import { TooltipInfo } from 'district-ui-client/components/tooltip'
import { LeftSplitChart } from 'district-ui-client/components/left-split-chart'
import { SummaryBox, SummaryBoxColorScheme } from 'district-ui-client/components/summary-box'
import { SeriesColumn } from 'district-ui-client/components/primitives/series-column/component'
import { t } from 'ember-intl'
import type { LegendOptions, SeriesColumnOptions } from 'highcharts'

/**
 * Puts together all the pieces for the quiz scores totals chart.

 */

export interface QuizScoresData {
  grade_position: number
  summary: { category: string; count: number }[]
  months: { count: Nullable<number>; month: string }[]
}

interface Signature {
  Args: {
    data?: QuizScoresData
    tooltip?: string
    grade?: string
    legend?: LegendOptions
  }
  Element: HTMLDivElement
}

export class ChartsQuizScoresTotals extends Component<Signature> {
  @service intl!: IntlService

  /**
   * Computed list of summary statistics, extracted from the input data.
   * Display total quizzes completed and student counts, and average quizzes per student.
   */
  get summaryStats() {
    const summaryData = this.args?.data?.summary ?? []
    const total = summaryData.find((d) => d.category === 'total_count')?.count ?? 0
    const studentCount = summaryData.find((d) => d.category === 'student_count')?.count ?? 0
    const average = basicAverage(total, studentCount)

    return [
      {
        label: this.intl.t('reporting.statBoxes.assessmentScoresTotals.totals.re'),
        value: formatNumber(total, 1),
      },
      {
        label: this.intl.t('reporting.statBoxes.courseProgress.studentCount'),
        value: formatNumber(studentCount, 1),
      },
      {
        label: this.intl.t('reporting.statBoxes.assessmentScoresTotals.average.re'),
        value: formatNumber(average, 1),
      },
    ]
  }

  /**
   * Computed list of column data, extracted from the input data.
   * Formats month data for Highcharts.
   */
  get columns(): SeriesColumnOptions[] {
    const monthData = this.args.data?.months ?? []
    const color = colors.stormBlue300
    return [{ name: 'count', data: monthData.map((d) => d.count), color, type: 'column' }]
  }

  /**
   * Computed list of category data, extracted from the input data.
   * Formats month data for Highcharts for use as column labels.
   * Converts month dates into short names.
   */
  get categories() {
    return this.args.data?.months?.map((m) => convertDateFormat(m.month)) ?? []
  }

  <template>
    <div data-test-quiz-scores-totals class="relative" ...attributes>
      <TooltipInfo class="z-tooltip absolute right-3 top-3 text-xs print:hidden" @text={{@tooltip}} />
      <LeftSplitChart>
        <:left>
          <SummaryBox @grade={{@grade}} @stats={{this.summaryStats}} @colorScheme={{SummaryBoxColorScheme.DarkBlue}} />
        </:left>
        <:right>
          <SeriesColumn
            @title={{t "reporting.chartTitles.quizScoresTotals"}}
            @data={{this.columns}}
            @categories={{this.categories}}
            @stackingStyle="normal"
            @legend={{@legend}}
            @chartSpacing={{Array 10 10 10 10}}
          />
        </:right>
      </LeftSplitChart>
    </div>
  </template>
}

export default ChartsQuizScoresTotals
