import type { TOC } from '@ember/component/template-only'
import { uniqueId } from '@ember/helper'
import { hash } from '@ember/helper'
import { LinkTo } from '@ember/routing'
import { or } from 'ember-truth-helpers'

/**
 * This file contains several components, ordered by intended hierarchial usage.
 *
 * Intended to follow the typical example of a HTML menu
 * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/group_role
 *
 * @example This example can be found at that link
<div role="menu">
  <ul role="group">
    <li role="menuitem">Inbox</li>
    <li role="menuitem">Archive</li>
    <li role="menuitem">Trash</li>
  </ul>
  <ul role="group">
    <li role="menuitem">Custom Folder 1</li>
    <li role="menuitem">Custom Folder 2</li>
    <li role="menuitem">Custom Folder 3</li>
  </ul>
  <ul role="group">
    <li role="menuitem">New Folder</li>
  </ul>
</div>
 *
 * @example The above can be replicated with themed components like;
<ThemedMenu>
  <ThemedMenuGroup>
    <ThemedMenuItem>Inbox</ThemedMenuItem>
    <ThemedMenuItem>Archive</ThemedMenuItem>
    <ThemedMenuItem>Trash</ThemedMenuItem>
  </ThemedMenuGroup>
  <ThemedMenuGroup> ... </ThemedMenuGroup>
  ...
</ThemedMenu>
 *
 * @example Typical usage is as dropdown content, so can be wrapped in ThemedDropdownContent to get border/shadow/etc;
<ThemedDropdownContent>
  <ThemedMenu>
    ...
  </ThemedMenu>
</ThemedDropdownContent>
 */

/**
 * Should contain either MenuGroup's or MenuItem's directly
 *
 * div rather than ul, as there may be other elements (like search, or multiple groups of lists with headers) within the menu
 */
export const ThemedMenu: TOC<{ Element: HTMLDivElement; Blocks: { default: [] } }> = <template>
  <div role="menu" class="p-3" ...attributes>
    {{yield}}
  </div>
</template>

interface MenuGroupSignature {
  Args: { label?: string }
  Blocks: { default: [] }
}
/**
 * Should contain only MenuItem's
 */
export const ThemedMenuGroup: TOC<MenuGroupSignature> = <template>
  {{#let (uniqueId) as |id|}}
    <div data-test-menu-group={{or @label true}}>
      {{#if @label}}
        <label for={{id}} class="text-neutral-250 px-3 pb-2 pt-3 text-xs font-medium uppercase">{{@label}}</label>
      {{/if}}
      <ul id={{id}} role="group">
        {{yield}}
      </ul>
    </div>
  {{/let}}
</template>

const classNames =
  'aria-selected:text-blue-350 selected:text-blue-350 hover:bg-neutral-75 cursor-pointer select-none rounded px-3 py-2 text-sm transition-colors active:bg-neutral-100 aria-selected:bg-blue-100 selected:bg-blue-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-blue-325'

interface MenuItemSignature {
  Element: HTMLLIElement
  Blocks: { default: [] }
}

export const ThemedMenuItem: TOC<MenuItemSignature> = <template>
  <li role="menuitem" class={{classNames}} ...attributes>
    {{yield}}
  </li>
</template>

interface LinkToSignature {
  Element: HTMLAnchorElement
  Args: {
    route?: string
    query?: Record<string, unknown>
    activeClass?: string
    'current-when'?: boolean | string
  }
  Blocks: {
    default: []
  }
}

export const ThemedMenuItemLinkTo: TOC<LinkToSignature> = <template>
  <LinkTo
    role="menuitem"
    class={{classNames}}
    @route={{if @route @route}}
    @query={{if @query @query (hash)}}
    @activeClass={{@activeClass}}
    @current-when={{@current-when}}
    ...attributes
  >
    {{yield}}
  </LinkTo>
</template>
