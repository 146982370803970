import type { TOC } from '@ember/component/template-only'
import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper'
import { not } from 'ember-truth-helpers'
import type ReAwardsEarnedRoute from 'district-ui-client/reporting/ui-scope/re/awards-earned/route'
import { ChartsAwardsEarnedCertificatesByMonth } from 'district-ui-client/components/charts/awards-earned/certificates/by-month'
import fullGradeName from 'district-ui-client/helpers/full-grade-name'
import { t } from 'ember-intl'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import { Tooltip } from 'district-ui-client/components/tooltip'
import RouteTemplate from 'ember-route-template'

interface Signature {
  Args: {
    model: ModelFor<ReAwardsEarnedRoute>
  }
}

export const ReAwardsEarnedRouteTemplate: TOC<Signature> = <template>
  <DetailedPageWrapper @empty={{not @model.awardsEarnedTotals}}>
    {{#each @model.awardsEarnedTotals.awards_earned_totals as |gradeData|}}
      <div class="detailed-chart-wrapper relative">
        <ChartsAwardsEarnedCertificatesByMonth
          data-test-awards-earned-certificates-totals
          class="h-full"
          @title={{t "reporting.chartTitles.awardsEarnedTotals.re"}}
          @product={{@model.product}}
          @data={{gradeData}}
          @grade={{fullGradeName gradeData.grade_position}}
          @yAxisTitle={{t "reporting.chartTitles.awardsEarnedTotals.re"}}
        />
        <span class="absolute right-0.5 top-0 print:hidden">
          <FaIcon @icon="circle-info" @size="sm" class="text-dusty-black-500 cursor-pointer" />
          <Tooltip @text={{t "reporting.tooltips.awardsEarnedTotals.re" htmlSafe=true}} />
        </span>
      </div>
    {{/each}}
  </DetailedPageWrapper>
</template>

export default RouteTemplate(ReAwardsEarnedRouteTemplate)
