import Component from '@glimmer/component'
import { service } from '@ember/service'
import type SchoolRolloverService from 'district-ui-client/services/school-rollover'
import { AlertType, InlineAlert } from 'district-ui-client/components/inline-alert'
import { t } from 'ember-intl'

interface Signature {
  Element: HTMLElement
}

export class RolloverAlert extends Component<Signature> {
  @service schoolRollover!: SchoolRolloverService

  get schoolsInRollover() {
    return this.schoolRollover.schoolsInRollover
  }

  <template>
    {{#if this.schoolsInRollover}}
      <InlineAlert @type={{AlertType.Critical}} @showClose={{true}} ...attributes data-test-rollover-alert>
        <div class="space-y-2">
          <h2 class="m-0 text-sm font-medium">
            {{t "rollovers.modalTitle"}}
          </h2>
          <p class="m-0 text-sm">{{t "rollovers.alert"}}</p>
          <ul class="list-disc px-5 pt-1 text-sm font-bold">
            {{#each this.schoolsInRollover as |school|}}
              <li>
                <span>{{school.name}}</span>
              </li>
            {{/each}}
          </ul>
        </div>
      </InlineAlert>
    {{/if}}
  </template>
}

export default RolloverAlert
