import type { TOC } from '@ember/component/template-only'

interface PageTitleSignature {
  Element: HTMLDivElement
  Blocks: {
    default: []
  }
}

export const PageTitle: TOC<PageTitleSignature> = <template>
  <div class="h-10 text-2xl" ...attributes>
    {{yield}}
  </div>
</template>

interface SectionTitleSignature {
  Element: HTMLDivElement
  Blocks: {
    default: []
  }
}

/**
 * Intended for smaller headings of sections, typically not rendered in a panel or tile but just on plain background.
 */
export const SectionTitle: TOC<SectionTitleSignature> = <template>
  <div class="text-lg font-semibold" ...attributes>
    {{yield}}
  </div>
</template>
