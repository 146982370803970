import Service from '@ember/service'
import downloadjs from 'downloadjs'
import type { UnparseConfig } from 'papaparse'
import { unparse } from 'papaparse'

export type DownloadCsvOptions = UnparseConfig & {
  filename?: string
  mimeType?: string
}

/**
 * Download files from javascript, with good cross-browser support
 *
 * @see http://danml.com/download.html
 */
export default class FileDownload extends Service {
  download = downloadjs

  /**
   * CSV data and initiates a download of that data.
   * Creates the csv file using papaparse.
   * Delegates to downloading files to download.js
   *
   * @see https://www.papaparse.com/docs#json-to-csv
   *
   * @param data                   The CSV data that will be downloaded when the link is clicked.
   * @param filename='export.csv' The name of the file to be created.
   * @param mimeType='text/csv'    The MIME content-type of the file to download.
   */
  downloadAsCsv(
    data: unknown[][],
    { filename = 'export.csv', mimeType = 'text/csv', header = true, quotes = true }: DownloadCsvOptions = {},
  ): void {
    const csvString = unparse(data, { header, quotes })
    const csvWithBOM = this._addByteOrderMark(csvString)
    const csvFile = this._encodeUTF8(csvWithBOM)
    this.download(csvFile, filename, mimeType)
  }

  /**
   * Needed for excel to read utf-8 encoded csvs correctly
   *
   * https://stackoverflow.com/questions/31959487/utf-8-encoidng-issue-when-exporting-csv-file-javascript
   */
  _addByteOrderMark(csv: string): string {
    return `\ufeff${csv}`
  }

  /**
   * If the string was generated from HTML (eg reading an exportable table) the CSV string may contain characters
   * like &nbsp; even if these don't show up in console.
   *
   * These need to be encoded to UTF-8, as that's what we want the downloaded CSV files to be.
   *
   * Encoder taken from
   * https://stackoverflow.com/a/6738330
   *
   * @param csv string to encode to UTF8
   */
  _encodeUTF8(csv: string): string {
    return unescape(encodeURIComponent(csv))
  }
}

declare module '@ember/service' {
  interface Registry {
    'file-download': FileDownload
  }
}
