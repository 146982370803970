import RouteTemplate from 'ember-route-template'
import type Owner from '@ember/owner'
import type { TOC } from '@ember/component/template-only'
import { t, type IntlService } from 'ember-intl'
import { PageTitle } from 'district-ui-client/components/page-title'
import { Panel, PanelHeader, PanelTitle, PanelBody } from 'district-ui-client/components/section'
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon'
import type SupportRoute from 'district-ui-client/subscription-type/support/route'
import { SubscriptionType } from 'district-ui-client/domain/subscription-type'
import { eq } from 'ember-truth-helpers'
import Component from '@glimmer/component'
import { service } from '@ember/service'
import { SupportingTextError, FormItem, TextArea, TextField } from 'district-ui-client/components/base/form'
import SelectBox from 'district-ui-client/components/select-box'
import { tracked } from '@glimmer/tracking'
import type { SelectOption } from '@blakeelearning/ember-select'
import ThemedButton from 'district-ui-client/components/themed-button'
import { trackedFunction } from 'reactiveweb/function'
import type Store from '@ember-data/store'
import type SessionService from 'district-ui-client/services/session'
import { concat } from '@ember/helper'
import { isPresent } from '@ember/utils'
import type { Schema } from 'yup'
import { object, string, ValidationError } from 'yup'
import { on } from '@ember/modifier'
import { fn } from '@ember/helper'
import { task } from 'ember-concurrency'
import type FlashQueueService from 'district-ui-client/services/flash-queue'
import config from 'district-ui-client/config/environment'
import { join } from 'district-ui-client/utils/uri'
import type AuthToken from '@blakeelearning/auth/services/auth-token'
import { getOwner, setOwner } from '@ember/owner'
import { assert } from '@ember/debug'
import mapKeys from 'lodash/mapKeys'

interface SupportPageSignature {
  model: ModelFor<SupportRoute>
}

export const SupportPage: TOC<SupportPageSignature> = <template>
  <div class="mx-auto max-w-screen-lg px-8 py-10">
    <PageTitle class="mb-8">{{t "support.title"}}</PageTitle>
    <HelpAndFaqPanel class="mb-6" @subscriptionType={{@model.subscriptionType}} />
    <SupportPanel @subscriptionType={{@model.subscriptionType}} />
  </div>
</template>

export default RouteTemplate(SupportPage)

interface HelpAndFaqPanelSignature {
  Element: HTMLElement
  Args: {
    subscriptionType: SubscriptionType
  }
}

export class HelpAndFaqPanel extends Component<HelpAndFaqPanelSignature> {
  @service intl!: IntlService

  get help() {
    const subscriptionType = this.args.subscriptionType
    return {
      title: this.intl.t(`support.${subscriptionType}.help.title`),
      description: this.intl.t(`support.${subscriptionType}.help.description`),
      link: this.intl.t(`support.${subscriptionType}.help.link`),
    }
  }
  get faq() {
    const subscriptionType = this.args.subscriptionType
    return {
      title: this.intl.t(`support.${subscriptionType}.faq.title`),
      description: this.intl.t(`support.${subscriptionType}.faq.description`),
      link: this.intl.t(`support.${subscriptionType}.faq.link`),
    }
  }

  <template>
    <Panel ...attributes>
      <PanelHeader>
        <PanelTitle>{{t "support.helpAndFaq"}}</PanelTitle>
      </PanelHeader>
      <PanelBody>
        <ActionItem
          data-test-help
          @subscriptionType={{@subscriptionType}}
          @title={{this.help.title}}
          @description={{this.help.description}}
          @link={{this.help.link}}
        >
          <:icon>
            <FaIcon class="h-6 w-6" @icon="cloud-question" />
          </:icon>
        </ActionItem>
        <ActionItem
          data-test-faq
          @subscriptionType={{@subscriptionType}}
          @title={{this.faq.title}}
          @description={{this.faq.description}}
          @link={{this.faq.link}}
        >
          <:icon>
            <FaIcon class="h-6 w-6" @icon="comment-dots" />
          </:icon>
        </ActionItem>
      </PanelBody>
    </Panel>
  </template>
}

interface ActionItemSignature {
  Element: HTMLAnchorElement
  Blocks: {
    icon: []
  }
  Args: {
    title: string
    description: string
    link: string
    subscriptionType: SubscriptionType
  }
}

const ActionItem: TOC<ActionItemSignature> = <template>
  <a
    href={{@link}}
    target="_blank"
    rel="noopener noreferrer"
    class="flex w-full cursor-pointer items-center space-x-4 rounded-lg p-4 hover:bg-neutral-50"
    ...attributes
  >
    <div
      class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg p-[5px] text-white
        {{if (eq @subscriptionType SubscriptionType.Reading) 'bg-[linear-gradient(135deg,#E59BC5_0%,#CB378C_100%)]'}}
        {{if (eq @subscriptionType SubscriptionType.Maths) 'bg-[linear-gradient(135deg,#9BD6F0_0%,#1E98CE_100%)]'}}
        {{if (eq @subscriptionType SubscriptionType.Writing) 'bg-[linear-gradient(135deg,#E59BC5_0%,#CB378C_100%)]'}}"
    >
      {{yield to="icon"}}
    </div>
    <div class="flex grow flex-col space-y-1 text-sm">
      <div class="font-medium">{{@title}}</div>
      <div>{{@description}}</div>
    </div>
    <FaIcon @icon="arrow-up-right-from-square" class="pr-4" />
  </a>
</template>

interface SupportPanelSignature {
  Element: HTMLElement
  Args: {
    subscriptionType: SubscriptionType
  }
}

const queryTypeOptions: SelectOption[] = [
  { value: 'register_students', label: 'Registering and adding students' },
  { value: 'subscription_query', label: 'Subscription query' },
  { value: 'technical_issue', label: 'Technical issue' },
  { value: 'other', label: 'Other' },
] as const

type QueryType = (typeof queryTypeOptions)[number]['value']

export class SupportPanel extends Component<SupportPanelSignature> {
  @service intl!: IntlService

  <template>
    <Panel ...attributes data-test-support-panel>
      <PanelHeader>
        <PanelTitle>{{t "support.contactUs"}}</PanelTitle>
      </PanelHeader>
      <PanelBody class="space-y-6">
        <ContactDetails class="mt-1" @subscriptionType={{@subscriptionType}} />
        <SupportForm @subscriptionType={{@subscriptionType}} />
      </PanelBody>
    </Panel>
  </template>
}

interface ContactDetailsSignature {
  Element: HTMLDivElement
  Args: {
    subscriptionType: SubscriptionType
  }
}

export class ContactDetails extends Component<ContactDetailsSignature> {
  @service store!: Store

  @service session!: SessionService

  partnerContactDetails = trackedFunction(this, async () => {
    const { subscriptionType } = this.args
    const [partnerContactDetails] = (await this.store.query('partner-contact-detail', {
      scope: `districts/${this.session.currentDistrict.id}`,
      filter: { subscriptionType },
    })).slice()
    return partnerContactDetails
  }); /* prettier-ignore */ /* prettier-ignore-end */

  <template>
    <div class="space-y-6" ...attributes>
      <div class="text-sm">{{t "support.messageTeam" subscriptionType=(t (concat "subscriptions." @subscriptionType))}}
        {{t "support.responseTime"}}</div>
      <div class="space-y-1 text-sm">
        <div>{{t "support.forUrgentMatters"}}</div>
        <div class="space-x-1 font-semibold">
          <FaIcon @icon="phone" />
          <span>
            {{this.partnerContactDetails.value.schoolSupportPhone}}
            {{#if this.partnerContactDetails.value.schoolSupportPhoneTollFree}}{{t "support.tollFree"}}{{/if}}
          </span>
        </div>
        <div>{{this.partnerContactDetails.value.schoolSupportPhoneHours}}</div>
      </div>
    </div>
  </template>
}

interface FormSchema {
  nameOrLogin: string
  schoolName: string
  email: string
  queryType?: QueryType
  description: string
}

type FormKey = keyof FormSchema

type FormErrors = Record<FormKey, string>

interface SupportFormSignature {
  Element: HTMLDivElement
  Args: {
    subscriptionType: SubscriptionType
  }
}

const formKeys: FormKey[] = ['nameOrLogin', 'schoolName', 'email', 'queryType', 'description']

const isFormKey = (value: unknown): value is FormKey => {
  return formKeys.includes(value as FormKey)
}

class SupportFormData implements FormSchema {
  @service intl!: IntlService
  @service session!: SessionService

  @tracked queryType?: QueryType = undefined
  @tracked nameOrLogin: string
  @tracked schoolName = ''
  @tracked email: string
  @tracked description = ''

  formSchema: Schema<FormSchema>

  // holds all the errors for the form
  @tracked error: Nullable<ValidationError> = null

  constructor(context: object) {
    const owner = getOwner(context)
    assert('missing owner for support form', owner)
    setOwner(this, owner)

    const { session, intl } = this

    this.nameOrLogin = session.currentDisco.login
    this.email = session.currentDisco.email

    this.formSchema = object({
      nameOrLogin: string().required().label(intl.t('support.fields.nameOrLogin')),
      schoolName: string().required().label(intl.t('support.fields.schoolName')),
      email: string().email().required().label(intl.t('support.fields.email')),
      queryType: string()
        .oneOf(['register_students', 'subscription_query', 'technical_issue', 'other'])
        .required()
        .label(intl.t('support.fields.queryType')),
      description: string().required().label(intl.t('support.fields.description')),
    })
  }

  validate = async () => {
    try {
      await this.formSchema.validate(this, {
        abortEarly: false,
        strict: true,
      })

      this.error = null
      return true
    } catch (error) {
      if (error instanceof ValidationError) {
        this.error = error
      }
      return false
    }
  }

  // only returns a single error for each form field
  get errors() {
    const initial: Partial<FormErrors> = {}
    return this.error?.inner.reduce<Partial<FormErrors>>((acc, { path: key, message }) => {
      // does the error path match one of the form field keys?
      if (!isFormKey(key)) {
        return acc
      }
      // add/overwrite error message for the key
      return { ...acc, [key]: message }
    }, initial)
  }

  setValue = async (key: FormKey, event: Event) => {
    if (!(event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement)) {
      return
    }
    this[key] = event.target.value
    if (this.error) {
      await this.validate()
    }
  }

  setSelectValue = async (key: FormKey, option: SelectOption) => {
    this[key] = option.value
    if (this.error) {
      await this.validate()
    }
  }
}

export class SupportForm extends Component<SupportFormSignature> {
  @service authToken!: AuthToken

  @service intl!: IntlService

  @service session!: SessionService

  @service flashQueue!: FlashQueueService

  @tracked form: SupportFormData

  constructor(owner: Owner, args: SupportFormSignature['Args']) {
    super(owner, args)
    this.form = new SupportFormData(this)
  }

  submitForm = task({ drop: true }, async () => {
    if (await this.form.validate()) {
      const success = await this.makeRequest(this.form)
      if (success) {
        this.flashQueue.addSuccess({
          title: this.intl.t('support.sent'),
          subtitle: this.intl.t('support.responseTime'),
        })
        this.resetForm()
      } else {
        this.flashQueue.addFail({
          title: this.intl.t('support.error'),
          subtitle: this.intl.t('support.errorDescription'),
        })
      }
    }
  })

  resetForm() {
    this.form = new SupportFormData(this)
    this.form.schoolName = ''
    this.form.queryType = undefined
    this.form.description = ''
  }

  makeRequest = async (params: SupportFormData) => {
    const uri = join(config.readingEggsV1Url, 'district_ui', 'coordinator_operations')
    const formData: Record<string, string> = {
      command: this.args.subscriptionType === SubscriptionType.Maths ? 'contact_ms_team' : 'contact_re_team',
      ...mapKeys(
        {
          name: params.nameOrLogin,
          school_name: params.schoolName,
          email: params.email,
          problem_type: params.queryType || '',
          problem_description: params.description,
        },
        (_value, key) => `data[${key}]`,
      ),
    }

    const result = await fetch(uri, {
      method: 'POST',
      headers: {
        Authorization: this.authToken.token ?? '',
        'content-type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(formData).toString(),
    })
    return result.ok
  }

  <template>
    <div class="flex flex-col space-y-2" ...attributes>
      <FormItem @label={{t "support.fields.nameOrLogin"}} @required={{true}}>
        <TextField
          data-test-name-or-login
          class="w-full"
          @required={{true}}
          value={{this.form.nameOrLogin}}
          @hasError={{isPresent this.form.errors.nameOrLogin}}
          {{on "input" (fn this.form.setValue "nameOrLogin")}}
        />
        <SupportingTextError data-test-name-or-login-errors @error={{this.form.errors.nameOrLogin}} />
      </FormItem>
      <FormItem @label={{t "support.fields.schoolName"}} @required={{true}}>
        <TextField
          data-test-school-name
          class="w-full"
          @required={{true}}
          value={{this.form.schoolName}}
          @placeholder={{t "support.fields.schoolNamePlaceholder"}}
          @hasError={{isPresent this.form.errors.schoolName}}
          {{on "input" (fn this.form.setValue "schoolName")}}
        />
        <SupportingTextError data-test-school-name-errors @error={{this.form.errors.schoolName}} />
      </FormItem>
      <FormItem @label={{t "support.fields.email"}} @required={{true}}>
        <TextField
          data-test-email
          type="email"
          class="w-full"
          @required={{true}}
          value={{this.form.email}}
          @hasError={{isPresent this.form.errors.email}}
          {{on "input" (fn this.form.setValue "email")}}
        />
        <SupportingTextError data-test-email-errors @error={{this.form.errors.email}} />
      </FormItem>
      <FormItem @label={{t "support.fields.queryType"}} @required={{true}}>
        <SelectBox
          data-test-query-type
          @style={{if (isPresent this.form.errors.queryType) "neutral-error" "neutral"}}
          @options={{queryTypeOptions}}
          @value={{this.form.queryType}}
          @onSelect={{fn this.form.setSelectValue "queryType"}}
          @matchTriggerWidth={{true}}
          @placeholder={{t "support.fields.queryTypePlaceholder"}}
        />
        <SupportingTextError data-test-query-type-errors @error={{this.form.errors.queryType}} />
      </FormItem>
      <FormItem @label={{t "support.fields.description"}} @required={{true}}>
        <TextArea
          data-test-description
          value={{this.form.description}}
          @placeholder={{t "support.fields.descriptionPlaceholder"}}
          @hasError={{isPresent this.form.errors.description}}
          {{on "input" (fn this.form.setValue "description")}}
        />
        <SupportingTextError data-test-description-errors @error={{this.form.errors.description}} />
      </FormItem>
      <span class="text-neutral-250 text-xs font-medium">
        {{t "support.requiredFields"}}
      </span>
      <ThemedButton
        data-test-submit
        class="self-start"
        @style="theme"
        @disabled={{this.submitForm.isRunning}}
        {{on "click" this.submitForm.perform}}
      >
        {{t "support.submit"}}
      </ThemedButton>
    </div>
  </template>
}
