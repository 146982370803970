import { on } from '@ember/modifier'
import { Panel } from 'district-ui-client/components/panel'
import { RolloverAlert } from 'district-ui-client/components/rollover-alert'
import RouteTemplate from 'ember-route-template'
import UiButton from 'district-ui-client/components/ui-button'
import { t, type IntlService } from 'ember-intl'
import Component from '@glimmer/component'
import { service } from '@ember/service'
import type Store from '@ember-data/store'
import type TeacherOperationsService from 'district-ui-client/services/teacher-operations'
import type ActiveRouteService from 'district-ui-client/services/active-route'
import type { Log } from '@blakeelearning/log'
import { tracked } from '@glimmer/tracking'
import { trackedFunction } from 'reactiveweb/function'
import type SessionService from 'district-ui-client/services/session'
import type AlertService from 'district-ui-client/services/alert'
import themeKey from 'district-ui-client/helpers/theme-key'
import { BaseSelect } from 'district-ui-client/components/base/select'
import { eventValue } from 'district-ui-client/helpers/event-value'
import { SubscriptionType } from 'district-ui-client/domain/subscription-type'
import type ManageTeachersNewController from 'district-ui-client/subscription-type/manage/teachers/new/controller'
import { eq, not } from 'ember-truth-helpers'
import { didCancel, task } from 'ember-concurrency'
import type Teacher from 'district-ui-client/models/teacher'
import { hasEmailExistsError } from 'district-ui-client/errors/email-already-exists-error'
import { InputText } from 'district-ui-client/components/input-text'

interface Signature {
  Args: {
    controller: ManageTeachersNewController
  }
}

export class ManageTeachersNewRouteTemplate extends Component<Signature> {
  @service teacherOperations!: TeacherOperationsService

  @service activeRoute!: ActiveRouteService

  @service store!: Store

  @service alert!: AlertService

  @service log!: Log

  @service intl!: IntlService

  @service session!: SessionService

  get subscriptionType() {
    return this.activeRoute.subscriptionType
  }

  @tracked firstName = ''

  @tracked lastName = ''

  @tracked email = ''

  @tracked emailConfirmation = ''

  @tracked accountType = 'teacher-subscription'

  loadSchools = trackedFunction(this, async () => {
    const schools = await this.store.query('school', {
      scope: `districts/${this.session.currentDistrict.id}`,
      include: 'contact-detail', // to help mocking in factory guy, which has issues with scope arg alone
    })

    return schools
  })
  get accountTypeOptions() {
    return [
      { label: 'Trial', value: 'teacher-subscription' },
      { label: 'School Subscription', value: 'school-subscription' },
    ]
  }

  get schools() {
    return this.loadSchools.value?.slice() ?? []
  }

  get school() {
    return this.schools.find((school) => school.id === this.args.controller.schoolId)
  }

  cancel = () => {
    this.backToPrevious()
  }

  setAccountType = (accountType: string) => {
    this.accountType = accountType
  }

  setFirstName = (firstName: string) => {
    this.firstName = firstName
  }

  setLastName = (lastName: string) => {
    this.lastName = lastName
  }

  setEmail = (email: string) => {
    this.email = email
  }

  setEmailConfirmation = (emailConfirmation: string) => {
    this.emailConfirmation = emailConfirmation
  }

  setSchoolId = (schoolId: string) => {
    this.args.controller.schoolId = schoolId
  }

  createTeacherTask = task({ drop: true }, async (newTeacher: Teacher) => {
    const { subscriptionType, accountType, school } = this

    await newTeacher.save({
      adapterOptions: { subscriptionType, accountType },
    })
    const newClass = this.store.createRecord('school-class', {
      name: `${newTeacher.firstName} ${newTeacher.lastName}'s ${this.intl.t(subscriptionType)} Class`,
      subscriptionType,
      school,
      teachers: [newTeacher],
      gamesAccess: true,
      playroomAccess: true,
      backgroundMusicDisabled: false,
      mathseedsPrimeAccess: subscriptionType === SubscriptionType.Maths,
    })
    await newClass.save()
  })

  createTeacher = async (event: Event) => {
    event.preventDefault()

    if (this.email !== this.emailConfirmation) {
      this.alert.showWithDismiss({ type: 'critical', message: 'Email and confirmation fields do not match' })
      return
    }

    const newTeacher = this.store.createRecord('teacher', {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      login: this.email,
      school: this.school,
    })

    try {
      await this.createTeacherTask.perform(newTeacher)

      this.backToPrevious()
      this.alert.showWithDismiss({
        message: 'Successfully created a teacher',
      })
    } catch (errorPayload: unknown) {
      newTeacher.rollbackAttributes()
      if (didCancel(errorPayload)) return

      if (hasEmailExistsError(errorPayload)) {
        this.formError('Email already exists')
        return
      }
      this.log.error('failed to create teacher')
      return this.formError('Failed to create teacher')
    }
  }

  formError = (message: string) => {
    this.alert.showWithDismiss({
      type: 'critical',
      message,
    })
  }

  backToPrevious() {
    window.history.back()
  }

  <template>
    <RolloverAlert />
    <div class="mx-auto mt-6 md:w-full lg:w-2/3">
      <Panel
        @theme={{themeKey this.subscriptionType}}
        @title={{t "subscriptionType.manageTeachers.new.addANewTeacher"}}
      >
        <form class="space-y-4" {{on "submit" this.createTeacher}}>
          <div class="flex items-center justify-between">
            <label for="school-dropdown" class="w-1/4">{{t "school"}}</label>
            <BaseSelect
              data-test-school-selector
              id="school-dropdown"
              class="w-3/4"
              value={{@controller.schoolId}}
              required={{true}}
              @themeKey={{themeKey this.subscriptionType}}
              {{on "input" (eventValue this.setSchoolId)}}
            >
              <option value="">{{t "clever.selectSchoolText"}}</option>
              {{#each this.schools as |school|}}
                <option value={{school.id}} selected={{eq @controller.schoolId school.id}}>{{school.name}}
                </option>
              {{/each}}
            </BaseSelect>
          </div>
          <div class="flex items-center justify-between">
            <label for="first-name" class="w-1/4">{{t "subscriptionType.manageTeachers.new.firstName"}}</label>
            <InputText
              data-test-first-name-input
              id="first-name"
              class="w-3/4"
              required={{true}}
              disabled={{not this.school}}
              value={{this.firstName}}
              {{on "input" (eventValue this.setFirstName)}}
            />
          </div>

          <div class="flex items-center justify-between">
            <label for="last-name" class="w-1/4">{{t "subscriptionType.manageTeachers.new.lastName"}}</label>
            <InputText
              data-test-last-name-input
              id="last-name"
              class="w-3/4"
              required={{true}}
              disabled={{not this.school}}
              value={{this.lastName}}
              {{on "input" (eventValue this.setLastName)}}
            />
          </div>

          <div class="flex items-center justify-between">
            <label for="email" class="w-1/4">{{t "subscriptionType.manageTeachers.new.email"}}</label>
            <InputText
              data-test-email-input
              id="email"
              class="w-3/4"
              type="email"
              required={{true}}
              disabled={{not this.school}}
              value={{this.email}}
              {{on "input" (eventValue this.setEmail)}}
            />
          </div>

          <div class="flex items-center justify-between">
            <label for="email-confirmation" class="w-1/4">{{t
                "subscriptionType.manageTeachers.new.emailConfirmation"
              }}</label>
            <InputText
              data-test-email-confirmation-input
              id="email-confirmation"
              class="w-3/4"
              type="email"
              required={{true}}
              disabled={{not this.school}}
              value={{this.emailConfirmation}}
              {{on "input" (eventValue this.setEmailConfirmation)}}
            />
          </div>
          <div class="flex items-center justify-between">
            <label for="account-type" class="w-1/4">{{t "subscriptionType.manageTeachers.new.accountType"}}</label>
            <BaseSelect
              id="account-type"
              class="w-3/4"
              value={{this.accountType}}
              @themeKey={{themeKey this.subscriptionType}}
              disabled={{not this.school}}
              {{on "input" (eventValue this.setAccountType)}}
            >
              {{#each this.accountTypeOptions as |accountTypeOption|}}
                <option value={{accountTypeOption.value}}>{{accountTypeOption.label}}</option>
              {{/each}}
            </BaseSelect>
          </div>

          <div class="text-right">
            <UiButton class="muted" {{on "click" this.cancel}}>{{t
                "subscriptionType.manageTeachers.new.cancel"
              }}</UiButton>
            <UiButton data-test-submit disabled={{not this.school}} @buttonType="submit" class="regular">{{t
                "subscriptionType.manageTeachers.new.create"
              }}</UiButton>
          </div>
        </form>
      </Panel>
    </div>
  </template>
}

export default RouteTemplate(ManageTeachersNewRouteTemplate)
