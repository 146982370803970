import Component from '@glimmer/component'
import { service } from '@ember/service'
import { set } from '@ember/object'
import { on } from '@ember/modifier'
import type { IntlService } from 'ember-intl'

interface Args {
  data?: { isChecked?: boolean; [key: string]: unknown }[]
  checkedProperty?: string
}

interface Signature {
  Element: HTMLInputElement
  Args: Args
}
/**
 * @deprecated don't store checked state on items, rather keep a separate list of ids
 */
export class CheckAllInput extends Component<Signature> {
  @service intl!: IntlService

  get data() {
    return this.args.data ?? []
  }

  get checkedPropName() {
    return this.args.checkedProperty ?? 'isChecked'
  }

  get allChecked() {
    return this.data.length && this.data.every((item) => item[this.checkedPropName])
  }

  get ariaLabel() {
    return this.allChecked ? this.intl.t('uncheckAll') : this.intl.t('checkAll')
  }

  onToggleSelectAll = () => {
    const newValue = !this.allChecked
    this.data.forEach((item) => {
      set(item, this.checkedPropName, newValue)
    })
  }

  <template>
    <input
      data-test-all-checkbox
      type="checkbox"
      aria-label={{this.ariaLabel}}
      checked={{this.allChecked}}
      {{on "input" this.onToggleSelectAll}}
      ...attributes
    />
  </template>
}

export default CheckAllInput

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CheckAllInput: typeof CheckAllInput
  }
}
