import Component from '@glimmer/component'
import { action } from '@ember/object'
import type Highcharts from 'highcharts'
import config from 'district-ui-client/config/environment'
import highcharts from '@blakeelearning/highcharts/modifiers/highcharts'
import printReflow from 'district-ui-client/modifiers/print-reflow'
import chartReflow from 'district-ui-client/modifiers/chart-reflow'

// Provide access to highcharts-more charts, which we currently use for 'columnrange' for primitives/series-column-range
import 'highcharts'
import 'highcharts/highcharts-more'

interface Signature {
  Element: HTMLDivElement
  Args: {
    options: Highcharts.Options
    setChart?: (chart: Highcharts.Chart) => unknown
  }
}

export default class BaseHighcharterComponent extends Component<Signature> {
  chart?: Highcharts.Chart

  @action
  setChart(chart: Highcharts.Chart) {
    this.chart = chart
    this.args.setChart?.(chart)
  }

  get options(): Highcharts.Options {
    if (this.args.options.chart && typeof this.args.options.chart?.animation === 'undefined') {
      // allows the application to decide if it wants animation. handy to disable for tests
      // only disable if strictly true
      const animation = config.disableChartAnimation === true ? { duration: 0 } : true
      return {
        ...this.args.options,
        chart: { ...this.args.options.chart, animation },
        // @ts-expect-error - chart type funnel3d doesnt accept animation object - we don't use it, ignore for now
        series: this.args.options.series
          ? [...this.args.options.series.map((seriesItem) => ({ ...seriesItem, animation }))]
          : undefined,
        plotOptions: {
          ...this.args.options.plotOptions,
          series: { ...this.args.options.plotOptions?.series, animation },
          bar: { ...this.args.options.plotOptions?.bar, animation },
          pie: { ...this.args.options.plotOptions?.pie, animation },
          column: { ...this.args.options.plotOptions?.column, animation },
          columnrange: { ...this.args.options.plotOptions?.columnrange, animation },
          scatter: { ...this.args.options.plotOptions?.scatter, animation },
          line: { ...this.args.options.plotOptions?.line, animation },
        },
      }
    }
    return this.args.options
  }

  <template>
    <div
      {{highcharts options=this.options callback=this.setChart}}
      {{printReflow this.chart}}
      {{chartReflow this.chart}}
      ...attributes
    ></div>
  </template>
}
