import type { TOC } from '@ember/component/template-only'

export type DropdownButtonStyle = 'subtle' | 'neutral' | 'neutral-error' | 'theme'

/**
 * default; resting state
 * hover; while mouse hovers element (:hover)
 * press; while mouse clicks down on element (:active)
 * focus; user tabs onto element (:focus)
 * open; resting state, while dropdown is open
 */
function classNames(style: DropdownButtonStyle): string {
  const common =
    'focus-visible:outline focus-visible:outline-2 focus-visible:outline-blue-325 focus-visible:outline-offset-2'
  const neutralStyles = `text-neutral-400 open:text-blue-350 disabled:text-neutral-250 bg-white hover:bg-neutral-75 active:bg-neutral-100 open:bg-blue-100 disabled:bg-neutral-50 border-2 open:border-blue-100`
  switch (style) {
    case 'theme':
      return `${common} text-white open:text-blue-350 disabled:text-neutral-250 bg-blue-325 hover:bg-blue-350 active:bg-blue-400 open:bg-blue-100 disabled:bg-neutral-50`
    case 'neutral':
      return `${common} ${neutralStyles} border-neutral-75`
    case 'neutral-error':
      return `${common} ${neutralStyles} border-red-250`
    case 'subtle':
      return `${common} text-neutral-400 open:text-blue-350 disabled:text-neutral-250 bg-neutral-50 hover:bg-neutral-75 active:bg-neutral-100 open:bg-blue-100 disabled:bg-neutral-50`
  }
}

interface DropdownButtonSignature {
  Element: HTMLButtonElement
  Args: {
    style: DropdownButtonStyle
    disabled?: boolean
  }
  Blocks: { default: [] }
}

export const ThemedDropdownButton: TOC<DropdownButtonSignature> = <template>
  <button
    type="button"
    class="rounded-lg px-4 py-2 text-sm font-normal transition-colors {{classNames @style}}"
    ...attributes
    {{! After splattributes forces use of @disabled for disabling, to keep consistent usage }}
    disabled={{@disabled}}
  >
    {{yield}}
  </button>
</template>

interface DropdownContentSignature {
  Element: HTMLDivElement
  Args: {
    /**
     * Likely want to constrain the width in most cases, except when dropdown is configured to match trigger width.
     */
    constrainWidth?: boolean
  }
  Blocks: { default: [] }
}

/**
 * Provides a container with round corners and shadow
 */
export const ThemedDropdownContent: TOC<DropdownContentSignature> = <template>
  <div
    class="border-neutral-75 shadow-dropdown mt-1 overflow-hidden rounded-lg border-2 bg-white
      {{if @constrainWidth 'min-w-48 max-w-96'}}"
    ...attributes
  >
    {{yield}}
  </div>
</template>
