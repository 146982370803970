import Component from '@glimmer/component'
import { isNone } from '@ember/utils'
import type { Duration } from 'date-fns'
import {
  add,
  sub,
  differenceInYears,
  differenceInMonths,
  differenceInWeeks,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns'
import { formatValue } from 'district-ui-client/utils/format-value'

export enum Interval {
  Years = 'years',
  Months = 'months',
  Weeks = 'weeks',
  Days = 'days',
  Hours = 'hours',
  Minutes = 'minutes',
  Seconds = 'seconds',
}

export interface DurationConfig {
  dataInterval: string
  splitDurationOptions: DurationOptions
}

export type DurationOptions = Partial<{
  max: Interval
  min: Interval
  maxIntervals: number
}>

interface Signature {
  Element: HTMLTableCellElement
  Args: {
    value: Nullable<number> | undefined
    durationConfig: DurationConfig
  }
}

/**
 * 'value' should be an integer, along with a durationConfig object defined on the column. Alternatively, 'value' can be
 * provided as a ready-made split duration hash, bypassing the need for config.
 *
 * @param {Integer|DurationHash} value
 * @param {String} durationConfig.dataInterval - 'seconds', 'minutes', 'hours', 'days', etc, it determines what the
 * provided integer value indicates
 * @param {String} durationConfig.splitDurationOptions - how to split the duration and display it eg '3d 2h 5m' or just
 * '3d 2h'. See the split-duration util for the options type.
 *
 * split duration hash example:
 * {
 *   years: number
 *   months: number
 *   weeks: number
 *   days: number
 *   hours: number
 *   minutes: number
 *   seconds: number
 * }
 */
export class DurationCell extends Component<Signature> {
  /**
   * Value provided to duration-unit should be an integer, so use the value along with with dataInterval and split options
   * to create a duration hash, that can later be formatted to a string
   */
  get durationHash() {
    const { value } = this.args
    const { dataInterval, splitDurationOptions } = this.args.durationConfig

    const valueDefined = !isNone(value)
    if (valueDefined && dataInterval && splitDurationOptions) {
      // Convert the integer to a duration hash
      const duration = { [dataInterval]: value }
      return splitDuration(duration, splitDurationOptions)
    }
    // if no value, interval or split duration config given, return an empty hash
    return {}
  }

  get durationFormatEmptyConfig() {
    return { replaceZero: true }
  }

  get almostZeroFormat() {
    const minInterval = this.args.durationConfig.splitDurationOptions.min ?? Interval.Seconds
    return `<1${getIntervalUnit(minInterval)}`
  }

  get durationHashFormat() {
    const { value } = this.args
    const { durationFormatEmptyConfig, almostZeroFormat } = this

    const { durationHash } = this
    const isNoDuration = Object.keys(durationHash).length === 0

    const isZeroDuration = Object.values(durationHash).every((intervalValue) => !intervalValue) // true if zeros for all duration intervals

    if (isNoDuration || typeof value !== 'number') {
      // An empty duration hash, this indicates missing value or config
      // Format the missing result as dictated by the format config
      return formatValue(null, durationFormatEmptyConfig)
    }
    if (isZeroDuration) {
      /**
       * A duration hash of all zeros. This indicates either a value of 0, or a value that was less than the minimum
       * interval defined in the split options.
       *
       * Since the latter case isn't really zero (just close to it), we typically want to display an alternative
       * 'almost zero' formatting here.
       */
      if (value > 0) {
        // almost zero, use alternate format
        return almostZeroFormat
      }
      // truly zero, format the zero result as dictated by the format config
      return formatValue(0, durationFormatEmptyConfig)
    }
    // Format the result as normal, joining a series of duration interval values and units
    const durationArray = Object.entries(durationHash).filter(([_interval, intervalValue]) => intervalValue !== 0)
    const formattedDurationArray = durationArray.map(
      ([interval, intervalValue]) => `${String(intervalValue)}${getIntervalUnit(interval)}`,
    )
    return formattedDurationArray.join(' ')
  }

  <template>
    <td ...attributes>
      {{this.durationHashFormat}}
    </td>
  </template>
}

const differenceIn = {
  [Interval.Years]: differenceInYears,
  [Interval.Months]: differenceInMonths,
  [Interval.Weeks]: differenceInWeeks,
  [Interval.Days]: differenceInDays,
  [Interval.Hours]: differenceInHours,
  [Interval.Minutes]: differenceInMinutes,
  [Interval.Seconds]: differenceInSeconds,
}

/**
 * Processes a date-fns Duration into a new Duration with a desired set of intervals, based on the options given
 */
function splitDuration(value: Duration, options: DurationOptions = {}): Duration {
  const { min, max, maxIntervals } = options

  const intervals = Object.values(Interval)

  // Set defaults to 'all intervals'
  const maxIntervalIndex = max ? intervals.indexOf(max) : 0
  const minPrecisionIndex = min ? intervals.indexOf(min) : intervals.length

  const maxIntervalCount = maxIntervals || intervals.length

  // Create a new empty/zero duration
  const newDuration: Duration = {}
  let intervalCount = 0

  // Keep track of duration remaining to be processe. Currently, date-fns has limited support for durations (they're
  // working on it) so it's easier to work in dates, where we have access to the full library of utils.
  const start = new Date(0)
  let dateRemaining = add(start, value)

  intervals.forEach((interval, i) => {
    newDuration[interval] = 0 // Start by zero-ing each interval, in case any keys were unset

    // If this interval is allowed to be calculated
    if (i >= maxIntervalIndex && i <= minPrecisionIndex && intervalCount < maxIntervalCount) {
      const intervalValue = differenceIn[interval](dateRemaining, start) // decimals truncated for us

      // If there's duration at this interval to be processed
      if (intervalValue > 0) {
        intervalCount += 1
        newDuration[interval] = intervalValue
        // Remove that amount of time from the remaining duration, so that it isn't counted twice
        const processedDuration = { [interval]: intervalValue }
        dateRemaining = sub(dateRemaining, processedDuration)
      }
    }
  })

  return newDuration
}

function getIntervalUnit(interval: string) {
  switch (interval as Interval) {
    case Interval.Years:
      return 'y'
    case Interval.Months:
      return 'M'
    case Interval.Weeks:
      return 'w'
    case Interval.Days:
      return 'd'
    case Interval.Hours:
      return 'h'
    case Interval.Minutes:
      return 'm'
    case Interval.Seconds:
      return 's'
    default:
      return ''
  }
}

export default DurationCell
